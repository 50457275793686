import { Zone } from "luxon";

import DateTime from "../types/DateTime";
import { Customer } from "./Customer";
import { ServiceCategory } from "./ServiceCategory";
import { ServiceSubCategory } from "./ServiceSubCategory";
import { EnhancedSurgeon } from "./Surgeon";

/** The dto model used to transact Appointments with the backend. */
export class Appointment {
  /** The primary key of the appointment. */
  id?: string;
  /** The external primary key of the appointment. */
  externalId!: string;
  /** The key of the room associated with the appointment. */
  roomId!: string;
  /** The key of the slot associated with the appointment. */
  slotId?: string;
  /** The key of the surgeon associated with the appointment. */
  surgeonId?: string;
  /** The UTC ISO value of the start time of the appointment. */
  startTimeUtc!: string;
  /** The UTC ISO value of the end time of the appointment. */
  endTimeUtc!: string;
  /** The associated business units of the appointment. */
  businessUnits!: {
    id: string;
    name: string;
    weight?: number;
  }[];
  /** The associated services of the appointment. */
  services!: AppointmentService[];
  /** The associated customer of the appointment. */
  customer!: Customer;
}

/** A service linked to an appointment. */
interface AppointmentService {
  /** The primary key of the service. */
  id: string;
  /** The plain text name of the service. */
  name: string;
  /** The associated category of the service. */
  category: ServiceCategory;
  /** THe associated sub-category of the service. */
  subCategory?: ServiceSubCategory;
}

/** Expanded Appointment model with additional utility props and a static builder. */
export class EnhancedAppointment extends Appointment {
  /** The enhanced surgeon assigned to the appointment. */
  surgeon?: EnhancedSurgeon;
  /** The full datetime object for the appointment's start time. */
  startDateTime!: DateTime;
  /** The full datetime object for the appointment's end time. */
  endDateTime!: DateTime;
  /** The aggregate weight of the appointment's distinct services. */
  weight!: number;
  /** The distinct services linked to the appointment. */
  distinctServices!: AppointmentService[];
  /** Flag indicating if the appointment contains at least one distinct service has an enabled category. */
  isSurgery!: boolean;

  /** Builder for the class from the base and associated params. */
  static fromBase(
    appt: Appointment,
    /** ONLY enabled service categories. */
    enabledServiceCategories: ServiceCategory[],
    surgeon: EnhancedSurgeon,
    zone: Zone
  ): EnhancedAppointment {
    const serviceDict: Record<string, AppointmentService> = {};
    appt.services.forEach(service => (serviceDict[service.name] = service));
    const distinctServices = Object.values(serviceDict).sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    return {
      ...appt,
      surgeon,
      startDateTime: DateTime.fromISO(appt.startTimeUtc).setZone(zone),
      endDateTime: DateTime.fromISO(appt.endTimeUtc).setZone(zone),
      distinctServices,
      weight: distinctServices.reduce((sum, service) => sum + (service.subCategory?.weightValue || 0), 0),
      isSurgery: enabledServiceCategories.some(sc =>
        distinctServices.some(apptService => apptService.category.id === sc.id)
      )
    };
  }
}
