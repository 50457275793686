import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Tooltip
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { ReductionReason } from "../../../models/ReductionReason";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import { AdminTool } from "../Administration";
import AdministrationFormLabel from "../AdministrationFormLabel";
import AdministrationTool from "../AdministrationTool";

interface LocalReductionReason extends ReductionReason {
  isTop: boolean;
  isBottom: boolean;
}
const toolReadme = [
  "The Reduction Reasons tool allows you to set the available options a user can select when they are reducing slots in the scheduler."
];
const reductionReasonsLabelReadme = [
  "Show or hide reasons be selecting the switch.",
  "Reduction Reasons are shown to the user in the same order shown here. Change the order of the list to change the order in the scheduler."
];

const ReductionReasons: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const [reductionReasons, setReductionReasons] = useState<LocalReductionReason[]>([]);
  const [newReductionReason, setNewReductionReason] = useState<ReductionReason>();
  // For storing the last known good state from the BE.
  const reductionReasonsRef = useRef<ReductionReason[]>([]);

  // #region GET Reduction Reasons
  const [reductionReasonsRequest] = useState<UseCbpProps>({
    name: "Get Reduction Reasons",
    request: { url: "/reductionreasons", config: { params: { isAdmin: true } } }
  });
  const { response: reductionReasonsResponse, isLoading: loadingReductionReasons } =
    useCbp<ReductionReason[]>(reductionReasonsRequest);

  // #region PUT Reduction Reasons
  const [updateReductionReasonsRequest, setUpdateReductionReasonsRequest] = useState<UseCbpProps<ReductionReason[]>>();
  const {
    response: updateReductionReasonsResponse,
    isLoading: updateIsLoading,
    error: updateReductionReasonsError
  } = useCbp<ReductionReason[], ReductionReason[]>(updateReductionReasonsRequest);
  // #endregion

  /* Format the array by sorting by our esoteric order and setting DisplayOrder. */
  const formatReductionReasons = (reasons: ReductionReason[] | LocalReductionReason[]) => {
    const maxNotDisabledCount = reasons.filter(p => !p.isDisabled).length;

    reasons = reasons.sort((a, b) => {
      if (a.displayOrder && b.displayOrder && a.displayOrder > b.displayOrder) return 1;

      if (a.displayOrder && b.displayOrder && a.displayOrder < b.displayOrder) return -1;

      if (!a.displayOrder && !b.displayOrder && a.description > b.description) return 1;

      if (!a.displayOrder && !b.displayOrder && a.description < b.description) return -1;

      if (!a.displayOrder && b.displayOrder) return 1;

      if (a.displayOrder && !b.displayOrder) return -1;

      return 0;
    });

    return reasons.map((v, i) => ({
      ...v!,
      displayOrder: v.isDisabled ? undefined : i + 1,
      isTop: i === 0,
      isBottom: i === maxNotDisabledCount - 1
    }));
  };

  /* Swap the positions of two ReductionReasons. */
  const swapReductionReasons = (item: LocalReductionReason, direction: "Next" | "Previous") => {
    const foundReductionReasonIndex = reductionReasons.indexOf(item);

    if (foundReductionReasonIndex < 0) return;

    const foundReductionReason = reductionReasons[foundReductionReasonIndex];
    const nextReductionReason = reductionReasons[foundReductionReasonIndex + (direction === "Next" ? 1 : -1)];

    setReductionReasons(reasons =>
      // Create a new array swapping the affected display orders.
      formatReductionReasons(
        reasons.map((reason: LocalReductionReason) => {
          if (foundReductionReason && reason.id === foundReductionReason.id)
            return { ...reason, displayOrder: nextReductionReason.displayOrder };
          if (nextReductionReason && reason.id === nextReductionReason.id)
            return { ...reason, displayOrder: foundReductionReason.displayOrder };

          return reason;
        })
      )
    );
  };

  useEffect(() => {
    if (reductionReasonsResponse) {
      // Since this came from the BE set the last known good state.
      reductionReasonsRef.current = reductionReasonsResponse;
      setReductionReasons(formatReductionReasons(reductionReasonsResponse));
    }
  }, [reductionReasonsResponse]);

  useEffect(() => {
    if (updateReductionReasonsResponse) {
      // Since this came from the BE set the last known good state.
      reductionReasonsRef.current = updateReductionReasonsResponse;
      setReductionReasons(formatReductionReasons(updateReductionReasonsResponse));
      setUpdateReductionReasonsRequest(undefined);
    }
  }, [updateReductionReasonsResponse]);

  useEffect(() => {
    if (updateReductionReasonsError) {
      // On error reset to the last known good state from the BE.
      setReductionReasons(formatReductionReasons(reductionReasonsRef.current));
      setUpdateReductionReasonsRequest(undefined);
    }
  }, [updateReductionReasonsError]);

  useEffect(() => setIsContentLoading(loadingReductionReasons), [loadingReductionReasons]);

  const onDisableStateChange = (item: LocalReductionReason) => {
    const foundReductionReason = reductionReasons.find(p => p.id === item.id);

    if (!foundReductionReason) return;

    foundReductionReason.isDisabled = !foundReductionReason.isDisabled;
    if (foundReductionReason.isDisabled) foundReductionReason.displayOrder = undefined;
    else foundReductionReason.displayOrder = reductionReasons?.filter(p => !p.isDisabled).length;

    setReductionReasons(reasons =>
      // Create a new array replacing the altered item.
      formatReductionReasons(
        reasons.map((reason: LocalReductionReason) =>
          foundReductionReason && reason.id === foundReductionReason.id ? foundReductionReason : reason
        )
      )
    );
  };

  const onSaveChanges = () => {
    const outputReductionReasons: ReductionReason[] = reductionReasons.map<ReductionReason>(v => ({
      id: v.id,
      description: v.description,
      displayOrder: v.displayOrder,
      isDisabled: v.isDisabled
    }));

    if (newReductionReason)
      outputReductionReasons.push({
        ...newReductionReason,
        displayOrder: reductionReasons.filter(p => !p.isDisabled).length + 1
      });

    setUpdateReductionReasonsRequest({
      name: "Update Reduction Reasons",
      request: { url: "/reductionreasons", method: "put", data: outputReductionReasons }
    });

    setNewReductionReason(undefined);
  };
  const onCancelChanges = () => {
    if (newReductionReason) setNewReductionReason(undefined);
    setReductionReasons(formatReductionReasons(reductionReasonsRef.current));
  };
  const onDownClick = (item: LocalReductionReason) => swapReductionReasons(item, "Next");
  const onUpClick = (item: LocalReductionReason) => swapReductionReasons(item, "Previous");
  const onNewReason = () => setNewReductionReason({ description: "", displayOrder: undefined, isDisabled: false });
  const onChangeNewReductionReason = (e: ChangeEvent<HTMLInputElement>) =>
    setNewReductionReason(reason => ({ ...reason!, description: e.target.value }));
  const onClickNewReductionReasonDelete = () => setNewReductionReason(undefined);

  return (
    <AdministrationTool title="Reduction Reasons" readme={toolReadme} sx={{ minWidth: "40rem" }}>
      <Grid className="m-0 w-100" container spacing={3} direction="column">
        <Grid item>
          <AdministrationFormLabel label="Reduction Reasons" readme={reductionReasonsLabelReadme} />
          <SelectList
            items={reductionReasons}
            itemKey="id"
            disabled={updateIsLoading}
            listItem={ReductionReasonItem}
            handlers={[onDownClick, onUpClick, onDisableStateChange]}
            value={null}
          />
        </Grid>
        {newReductionReason && (
          <Grid item container>
            <Grid item xs>
              <FormControl fullWidth>
                <TextField
                  label="New Reduction Reason"
                  variant="standard"
                  onChange={onChangeNewReductionReason}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item>
              <Tooltip title="Cancel New Reduction Reason">
                <IconButton onClick={onClickNewReductionReasonDelete}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Button disabled={updateIsLoading || Boolean(newReductionReason)} onClick={onNewReason}>
              New Reduction Reason
            </Button>
          </Grid>
          <Grid item>
            <Grid container spacing={2} wrap="nowrap">
              <Grid item>
                <Button
                  disabled={
                    updateIsLoading ||
                    !reductionReasons ||
                    JSON.stringify(reductionReasons) === JSON.stringify(reductionReasonsRef.current)
                  }
                  onClick={onCancelChanges}
                  color="secondary"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                {updateIsLoading ? (
                  <CircularProgress />
                ) : (
                  <Button disabled={newReductionReason?.description === ""} onClick={onSaveChanges}>
                    Save Changes
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const ReductionReasonItem: React.FC<CustomListItem<LocalReductionReason>> = ({
  data: reductionReason,
  handlers: handlers,
  ...rest
}: CustomListItem<LocalReductionReason>) => {
  const [onDownClick, onUpClick, onDisableStateChange] = handlers ?? [];

  if (!onDownClick) throw "onDownClick handler not passed.";
  if (!onUpClick) throw "onUpClick handler not passed.";
  if (!onDisableStateChange) throw "onDisableStateChange handler not passed.";

  return (
    <ListItem {...(rest as unknown as Record<string, unknown>)}>
      <ListItemIcon>
        {!reductionReason.isDisabled && (
          <IconButton onClick={() => onDownClick(reductionReason)} disabled={reductionReason.isBottom}>
            <KeyboardArrowDownIcon />
          </IconButton>
        )}
      </ListItemIcon>
      <ListItemIcon>
        {!reductionReason.isDisabled && (
          <IconButton onClick={() => onUpClick(reductionReason)} disabled={reductionReason.isTop}>
            <KeyboardArrowUpIcon />
          </IconButton>
        )}
      </ListItemIcon>
      <ListItemText primary={reductionReason.description} />
      <ListItemSecondaryAction>
        <Tooltip title={reductionReason?.isDisabled ? "Enable" : "Disable"}>
          <Switch
            checked={reductionReason?.isDisabled ? false : true}
            onChange={() => onDisableStateChange(reductionReason)}
          />
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ReductionReasons;
