/** The dto model used to transact weight categories with the backend. */
export class WeightCategory {
  /** The primary key of the weight category. */
  id!: string;
  /** The plain text display name of the weight category. */
  name!: string;
  /** The value of the weight capacity for the category. */
  weightValue!: number;

  /** Comparator sorting weight categories by their weight value from least to greatest. */
  static ValueComparator = (item1: WeightCategory, item2: WeightCategory): number =>
    item1.weightValue - item2.weightValue;
}
