import { CardProps } from "@mui/material";
import { DateTime } from "luxon";
import { FunctionComponent, PropsWithChildren } from "react";

import CalendarDayCard from "./CalendarDayCard";

export enum DayOfWeekOrder {
  "SUNDAY_FIRST",
  "MONDAY_FIRST",
  "SATURDAY_FIRST"
}

/** Props to control a calendar day item. */
export interface DayItemProps<T = unknown> {
  /** Custom data available to the component to assist in rendering. */
  data: T;
  /** The date that the day item represents. */
  date: DateTime;
  /** The current date in the same timezone as date. */
  today: DateTime;
  /** Flag indicating if the day item is within the currently viewed month and is not a padding component. */
  isInMonth: boolean;
}

/** Props to control the `<Calendar />`. */
export interface CalendarProps<T = unknown> {
  /** A custom object containing data to provide to the individual days keyed by the ISO Date string of the day
   * that should be supplied with the object.
   * @example data for Card representing Sept 1st 2021: `{"2021-09-01": MY_DATA_OBJECT, ... }`.
   */
  data?: Record<string, T>;
  /** The current date in the same timezone as date. */
  today: DateTime;
  /** A custom component override for the month day cards. */
  DayItem?: FunctionComponent<DayItemProps<T>> | typeof CalendarDayCard;
  /** A custom ordering for the days of the week.
   * @defaultValue follows Luxon's default weekday indices: `DayOfWeekOrder.MONDAY_FIRST`.
   */
  dayOfWeekOrder?: DayOfWeekOrder;
  /** A collection of days of the week that should be hidden from the view. Order is irrelevant. */
  hideWeekdays?: WeekdayKey[];
  /** A datetime representing the month and year that should be shown. */
  month: DateTime;
}

/** Class keys used by the `<Calendar />` component. */
export enum CalendarClassKeys {
  dayOfWeekHeader = "sb_calendar_dayOfWeekHeader"
}

export type CalendarDayCardProps<T = never> = PropsWithChildren<DayItemProps<T> & Omit<CardProps, "variant">>;

export type WeekdayKey = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";

export type WeekdayRecord<T> = Record<WeekdayKey, T>;
