import { Button, CircularProgress, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { LocalStorageKeys } from "../../../Constants";
import { Block, BlockType, EnhancedBlock } from "../../../models/Block";
import { Calendar } from "../../../models/Calendar";
import { CbpError } from "../../../models/CbpError";
import { Center } from "../../../models/Center";
import { Room } from "../../../models/Room";
import { EnhancedRoomDay } from "../../../models/RoomDay";
import { EnhancedSurgeon } from "../../../models/Surgeon";
import DateTime from "../../../types/DateTime";
import { PlannerViewProps, PlannerViewType } from "../../../types/PlannerViewProps";
import { UserSettings } from "../../../types/UserSettings";
import { loadFromLocal } from "../../../utils/Helpers";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import CalendarComponent from "../../Helpers/Calendar/Calendar";
import { DayOfWeekOrder, WeekdayKey } from "../../Helpers/Calendar/types";
import MonthViewOptions from "../MonthlyView/MonthlyViewOptions";
import PlannerNavigation from "../PlannerNavigation";
import BatchEditViewControls from "./BatchEditViewControls";
import BatchEditViewDayCard, { EditDayItem } from "./BatchEditViewDayCard";

interface PutBlocksResponse {
  calendar: Calendar;
  errors: CbpError<Block>[];
}

interface Props extends PlannerViewProps {
  setCalendar: (calendar: Calendar) => void;
}

type EditCalendar = Record<string, EditDayItem>;

const BatchEditView: React.FC<Props> = ({
  calendarProps,
  centerHours,
  date,
  today,
  editDisabled,
  isLoading,
  isLoadingUpdate,
  selectedSubCategories,
  serviceSubCategories,
  simpleCenter,
  simpleCenters,
  setCalendar,
  setCenter,
  setCenterHoursRequest,
  setDate,
  setSelectedSubCategories
}: Props) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [userSettings] = useState(loadFromLocal<UserSettings>(LocalStorageKeys.userSettings));
  const [surgeon, setSurgeon] = useState<EnhancedSurgeon>();
  const [room, setRoom] = useState<Room>();
  const [editCalendar, setEditCalendar] = useState<EditCalendar>();
  const [dayOfWeekOrder, setDayOfWeekOrder] = useState(
    userSettings?.defaultDayOfWeekStart !== undefined ? userSettings.defaultDayOfWeekStart : DayOfWeekOrder.SUNDAY_FIRST
  );
  const [onlyWorkDays, setOnlyWorkDays] = useState<{ enabled: boolean; days: WeekdayKey[] }>({
    enabled: false,
    days: []
  });
  const calendarPropsRef = useRef(calendarProps);
  const centerHoursRef = useRef(centerHours);
  const disabledRef = useRef(editDisabled);

  useEffect(() => {
    centerHoursRef.current = centerHours;
  }, [centerHours]);

  // update operational days on center change
  useEffect(() => {
    setOnlyWorkDays(oldState => ({
      enabled:
        // if first calendar and user settings turn on only operational days, do so
        (!calendarPropsRef.current && calendarProps && userSettings?.defaultShowOnlyWorkDays) ||
        // otherwise maintain enabled state
        oldState.enabled,
      days: calendarProps ? Center.getWorkDays(calendarProps.center) : []
    }));
    calendarPropsRef.current = calendarProps;
  }, [calendarProps]);

  // #region PUT Blocks
  const [putBlocksRequest, setPutBlocksRequest] = useState<UseCbpProps<Block[]>>();
  const { response: putBlocksResponse, isLoading: loadingPutBlocks } = useCbp<PutBlocksResponse, Block[]>(
    putBlocksRequest
  );
  useEffect(() => {
    if (putBlocksResponse) {
      // group errors by day
      const errorDays: Record<string, CbpError<Block>[]> = {};
      putBlocksResponse.errors.forEach(error => {
        if (!error.data) return;
        const dayIso = DateTime.fromISO(error.data.startTimeUtc).toISODate();
        if (!errorDays[dayIso]) errorDays[dayIso] = [];
        errorDays[dayIso].push(error);
      });
      // clear all dirty edits
      setEditCalendar(oldCalendar => {
        const newCalendar: EditCalendar = {};
        Object.keys(oldCalendar!).forEach(dayIso => {
          newCalendar[dayIso] = {
            ...oldCalendar![dayIso],
            slotCount: undefined,
            error: errorDays[dayIso]?.length ? "Errors occurred when saving slots." : undefined,
            errorData: errorDays[dayIso]
          };
        });
        return newCalendar;
      });
      setCalendar(putBlocksResponse.calendar);
      enqueueSnackbar("Calendar Updated", { variant: "success" });
    }
  }, [putBlocksResponse]);
  // disable edits while loading
  useEffect(
    () =>
      setEditCalendar(oldCal => {
        if (!oldCal) return oldCal;
        const newCal = { ...oldCal };
        Object.keys(newCal).forEach(dayIso => {
          newCal[dayIso] = { ...newCal[dayIso], disabled: isLoading };
        });
        return newCal;
      }),
    [loadingPutBlocks]
  );
  // #endregion

  // update the edit state when receiving a new calendar
  useEffect(() => {
    if (!calendarProps) return setEditCalendar(undefined);
    const newCalendar: EditCalendar = {};
    // get the minimum weight value for all subcategories
    const fitServiceWeight = selectedSubCategories.length
      ? selectedSubCategories.reduce((sum, subCat) => sum + (subCat.weightValue || 0), 0)
      : serviceSubCategories.length
      ? serviceSubCategories.reduce(
          (minimum, subCategory) =>
            subCategory.weightValue && subCategory.weightValue < minimum ? subCategory.weightValue : minimum,
          100
        )
      : 0;
    setEditCalendar(oldCalendar => {
      if (!oldCalendar) oldCalendar = {};
      Object.keys(calendarProps.calendar.days).forEach(dayIso => {
        newCalendar[dayIso] = {
          assigned: false,
          centerZone: calendarProps.center.luxonTimeZone,
          disabled: false,
          fitServiceWeight,
          surgeon,
          room,
          roomDays: calendarProps.calendar.days[dayIso],
          slotCount: undefined,
          error: oldCalendar![dayIso]?.error,
          errorData: oldCalendar![dayIso]?.errorData,
          onAssignToggle: () =>
            // on toggle assignment update toggle state, set initial slot count, and clear errors
            setEditCalendar(oldCalendar => {
              const oldValue = oldCalendar![dayIso];
              oldCalendar![dayIso] = {
                ...oldValue,
                assigned: !oldValue.assigned,
                error: !oldValue.assigned ? oldValue.error : undefined,
                errorData: undefined,
                slotCount: !oldValue.assigned
                  ? oldValue.roomDays
                      .find(rd => rd.roomId === oldValue.room!.id)!
                      .blocks.filter(b => b.blockType === BlockType.Slot).length
                  : undefined
              };
              (!centerHoursRef.current || !centerHoursRef.current[dayIso]) && setCenterHoursRequest(dayIso);
              return { ...oldCalendar };
            }),
          onSlotChange: (slotCount?: number) =>
            setEditCalendar(oldCalendar => {
              const priorSlotsCount = oldCalendar![dayIso].roomDays
                .find(rd => rd.roomId === oldCalendar![dayIso].room!.id)!
                .blocks.filter(b => b.blockType === BlockType.Slot).length;
              oldCalendar![dayIso] = {
                ...oldCalendar![dayIso],
                // add an error if the slot number field value is less than the currently assigned slot count.
                error: slotCount !== undefined && slotCount < priorSlotsCount ? "Slots cannot be reduced." : undefined,
                errorData: undefined,
                slotCount
              };
              return { ...oldCalendar };
            }),
          onInputFocus: (date: string) =>
            (!centerHoursRef.current || !centerHoursRef.current[date]) && setCenterHoursRequest(date)
        };
      });
      return newCalendar;
    });
  }, [calendarProps, room, selectedSubCategories, serviceSubCategories, surgeon]);

  // #region Actions
  const onSaveChanges = useCallback((editCalendar: EditCalendar, centerId: string) => {
    let errors = false;
    const editedDays = Object.entries(editCalendar).filter(([, day]) => {
      errors = errors || Boolean(day.error);
      return day.slotCount;
    });
    if (errors) return enqueueSnackbar("Cannot Save While there are Errors", { variant: "warning" });
    if (!editedDays.length) return enqueueSnackbar("No Changes Detected", { variant: "warning" });

    const errorDates: string[] = [];

    // build a flat array of all slot changes from a new slot spread on each day
    const updateSlots = editedDays.reduce((collector, [isoDate, day]) => {
      const hours = centerHoursRef.current[isoDate];
      if (!hours) throw `Unable to find center start hours for ${isoDate}.`;
      const headerStart = day.surgeon!.startDateTime || hours.startDateTime;
      const headerEnd = headerStart.plus({ minutes: 30 });
      const roomDay: EnhancedRoomDay = {
        ...day.roomDays.find(rd => rd.roomId === day.room!.id)!,
        surgeon: EnhancedSurgeon.forDay(day.surgeon!, hours.startDateTime),
        surgeonId: day.surgeon!.id,
        header: {
          blockType: BlockType.Header,
          endDateTime: headerEnd,
          endTimeUtc: headerEnd.toUTC().toISO(),
          notes: "",
          room: day.room!,
          roomId: day.room!.id,
          startDateTime: headerStart,
          startTimeUtc: headerStart.toUTC().toISO(),
          surgeonId: day.surgeon!.id,
          surgeon: EnhancedSurgeon.forDay(day.surgeon!, hours.startDateTime)
        }
      };
      // collect the new slots, and ensure that the selected surgeon is assigned to them.
      const newSlots = EnhancedRoomDay.buildNewSlots(roomDay, day.slotCount!, hours.startDateTime);
      // if the final slot ends after closing time, note an error
      if (newSlots[newSlots.length - 1].endDateTime > hours.endDateTime)
        errorDates.push(hours.startDateTime.toISODate());
      return collector.concat(newSlots.map(block => ({ ...block, surgeon: day.surgeon!, surgeonId: day.surgeon!.id })));
    }, [] as EnhancedBlock[]);

    // if there were errors, flag those days with an error
    if (errorDates.length) {
      setEditCalendar(oldCalendar => {
        errorDates.forEach(dayIso => {
          oldCalendar![dayIso] = { ...oldCalendar![dayIso], error: "Slots would end after closing time." };
        });
        return { ...oldCalendar };
      });
    } else
      setPutBlocksRequest({
        name: "Update Schedule",
        request: {
          method: "put",
          url: `centers/${centerId}/blocks/batch`,
          data: updateSlots.map(slot => EnhancedBlock.toBase(slot))
        }
      });
  }, []);
  const onClear = useCallback(() => {
    setEditCalendar(oldCalendar => {
      if (!oldCalendar) return oldCalendar;
      const newCalendar: EditCalendar = {};
      Object.entries(oldCalendar).forEach(([key, value]) => (newCalendar[key] = { ...value, slotCount: undefined }));
      return newCalendar;
    });
  }, []);
  const updateOnlyWorkDays = useCallback(
    (enabled: boolean) => setOnlyWorkDays(oldState => ({ ...oldState, enabled })),
    []
  );
  const onChangeDate = (date: DateTime) => {
    setDate(DateTime.utc(date.year, date.month, 1), PlannerViewType.BATCH_EDIT);
  };
  // #endregion

  // when releasing the editing lock, revert any pending changes and push to the month view
  useEffect(() => {
    if (disabledRef.current === false && editDisabled === true) {
      setRoom(undefined);
      setSurgeon(undefined);
      setEditCalendar(oldCalendar => {
        if (!oldCalendar) return oldCalendar;
        const newCalendar: EditCalendar = {};
        Object.entries(oldCalendar).forEach(
          ([key, value]) => (newCalendar[key] = { ...value, surgeon: undefined, room: undefined, slotCount: undefined })
        );
        return newCalendar;
      });
      history.push(`/planner/month?center=${simpleCenter.id}&date=${date.toISODate()}`);
    }
    disabledRef.current = editDisabled;
  }, [editDisabled, simpleCenter, date]);
  const updatedMessage = calendarProps?.calendar.updatedDateTime ? (
    <>
      <div>Last Updated:</div>
      <div>{calendarProps.calendar.updatedBy || "System"}</div>
      <div>{calendarProps.calendar.updatedDateTime.toLocaleString(DateTime.DATETIME_SHORT)}</div>
    </>
  ) : (
    "No Changes"
  );

  return (
    <PlannerNavigation
      date={date}
      disabled={loadingPutBlocks}
      editDisabled={editDisabled}
      isCalendarLoaded={Boolean(calendarProps)}
      isLoading={isLoading}
      isLoadingUpdate={isLoadingUpdate}
      selectedSubCategories={selectedSubCategories}
      serviceSubCategories={serviceSubCategories}
      settingsMenu={
        <MonthViewOptions
          dayOfWeekOrder={dayOfWeekOrder}
          hiddenWeekDays={onlyWorkDays.enabled}
          setDayOfWeekOrder={setDayOfWeekOrder}
          setOnlyWorkDays={updateOnlyWorkDays}
        />
      }
      simpleCenter={simpleCenter}
      simpleCenters={simpleCenters}
      setCenter={setCenter}
      setDate={onChangeDate}
      setSelectedSubCategories={setSelectedSubCategories}
      updatedMessage={updatedMessage}
      view={PlannerViewType.BATCH_EDIT}
    >
      {calendarProps ? (
        <Grid container className="h-100 w-100" direction="column" alignItems="center" rowSpacing={2} wrap="nowrap">
          <Grid className="w-100" item>
            <BatchEditViewControls
              disabled={editDisabled || simpleCenter.id !== calendarProps?.center.id || false}
              surgeon={surgeon}
              surgeons={calendarProps.center.surgeons}
              room={room}
              rooms={calendarProps.center.rooms}
              setRoom={room => setRoom(room)}
              setSurgeon={surgeon => setSurgeon(surgeon)}
            />
          </Grid>
          <Grid className="w-100 m-0" item xs>
            <CalendarComponent
              data={editCalendar}
              today={today}
              month={date.set({ day: 1 })}
              DayItem={BatchEditViewDayCard as never}
              hideWeekdays={onlyWorkDays.enabled ? onlyWorkDays.days : []}
              dayOfWeekOrder={dayOfWeekOrder}
            />
          </Grid>
          <Grid item container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button color="secondary" disabled={editDisabled || loadingPutBlocks} onClick={onClear}>
                Clear
              </Button>
            </Grid>
            {loadingPutBlocks ? (
              <Grid item>
                <CircularProgress size="2rem" />
              </Grid>
            ) : (
              <Grid item>
                <Button disabled={editDisabled} onClick={() => onSaveChanges(editCalendar!, calendarProps.center.id)}>
                  Submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <CircularProgress size="5rem" />
        </Grid>
      )}
    </PlannerNavigation>
  );
};

export default BatchEditView;
