import { Box, BoxProps, styled } from "@mui/material";
import { pink } from "@mui/material/colors";

import { BlockType } from "../../../models/Block";

interface ColoredBoxProps extends BoxProps {
  variant: BlockType | "surgeryAppt" | "otherAppt" | "error";
}

const ScheduleBox = styled(Box, {
  shouldForwardProp: prop => prop !== "variant"
})<ColoredBoxProps>(({ variant, theme }) => ({
  ...(variant === BlockType.Slot && {
    "&": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }
  }),
  ...(variant === BlockType.Other && {
    "&": { backgroundColor: theme.palette.grey[400], color: theme.typography.body1.color }
  }),
  ...(variant === "surgeryAppt" && {
    "&": {
      backgroundColor: pink[600],
      color: theme.palette.secondary.contrastText
    }
  }),
  ...(variant === "otherAppt" && {
    "&": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText
    }
  }),
  ...(variant === "error" && {
    "&": {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText
    }
  })
}));

export default ScheduleBox;
