import { Box, CircularProgress, Grid } from "@mui/material";
import React from "react";

interface Props {
  /** Flag to indicate if the shade should be visible. */
  show: boolean;
}

/** A loading shade which fills its container and draws on top of its siblings.
 * NOTE: The direct parent of this component should have `position: "relative"` and `overflow: "hidden"`.
 */
const LoadingShade: React.FC<Props> = ({ show }: Props) =>
  show ? (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 1000,
          backgroundColor: "black",
          opacity: 0.2
        }}
      />
      <Box sx={{ height: "100%", width: "100%", position: "absolute", zIndex: 1001 }}>
        <Grid container sx={{ height: "100%" }} justifyContent="center" alignItems="center">
          <CircularProgress size="5rem" />
        </Grid>
      </Box>
    </>
  ) : (
    <></>
  );

export default LoadingShade;
