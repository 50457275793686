import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Grid, IconButton, Paper, Popover, Theme, Tooltip, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { PropsWithChildren, useState } from "react";

export interface AdministrationToolProps {
  title: string;
  readme: string[];
  sx?: SxProps<Theme>;
}

const AdministrationTool: React.FC<PropsWithChildren<AdministrationToolProps>> = ({
  readme,
  sx,
  title,
  children
}: PropsWithChildren<AdministrationToolProps>) => {
  const [descriptionAnchor, setDescriptionAnchor] = useState<HTMLElement>();

  return (
    <Grid container direction="column" alignItems="center" spacing={4} sx={{ height: "100%" }} wrap="nowrap">
      <Grid item container>
        <Grid item xs>
          <Typography variant="h5" align="center">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="More Info">
            <IconButton onClick={e => setDescriptionAnchor(e.currentTarget)}>
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item container xs justifyContent="center" alignItems="start" sx={{ width: "100%" }}>
        <Paper elevation={3} sx={{ maxWidth: "70rem", p: 2, ...sx }}>
          {children}
        </Paper>
      </Grid>
      <Popover
        open={Boolean(descriptionAnchor)}
        anchorEl={descriptionAnchor}
        onClose={() => setDescriptionAnchor(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Grid className="m-0" container spacing={2} sx={{ p: 2, maxWidth: "40rem" }}>
          {readme.map((paragraph, index) => (
            <Grid item key={index}>
              <Typography variant="body1">{paragraph}</Typography>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Grid>
  );
};

export default AdministrationTool;
