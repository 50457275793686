import { Button, ButtonProps, Grid, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

/** Represents a pair between a validation callback function and its resulting failure condition text. */
export interface ValidationItem {
  /** A callback method for determining the validity of this item. */
  validationMethod: () => boolean | undefined;
  /** The text to be displayed in the event that the validationMethod returns false. A callback function is acceptable if necessary. */
  validationText: (() => string) | string;
}

interface Props extends ButtonProps {
  /** Collection of ValidationItems to be evaluated. */
  message?: string;
}

/** Helper for rendering a button with a maintained disabled state using validation callbacks. */
const ValidationButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  disabled,
  message,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Grid item container direction="column" alignItems="center">
      <Grid item>
        <Button disabled={Boolean(message) || disabled} {...rest}>
          {children}
        </Button>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        {message && (
          <Typography color="error" variant="caption">
            {message}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ValidationButton;
