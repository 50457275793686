import "./index.css";

import { PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { msalConfig } from "./components/Login/MsalConfig";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(<App msalInstance={msalInstance} />, document.getElementById("root"));
