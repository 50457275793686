import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import AppContext from "../../../contexts/AppContext";
import { Surgeon, SurgeonCenter } from "../../../models/Surgeon";
import { WeightCategory } from "../../../models/WeightCategory";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import TimeRangePicker from "../../Helpers/TimeRangePicker";
import ValidationButton from "../../Helpers/ValidationButton";
import { AdminTool } from "../Administration";
import AdministrationFormLabel from "../AdministrationFormLabel";
import AdministrationTool from "../AdministrationTool";

const toolReadme = [
  "The Surgeons tool allows you to configure all of the settings for a surgeon.",
  "Note: You can only change settings for surgeons whom work at a Center for which you are an authorized administrator."
];
const generalSettingsReadme = [
  "A surgeon's weight category controls the maximum weight they should receive for a given work day.",
  "A surgeon with a higher order weight category can accept more complex or time-consuming surgeries in a single day.",
  "Surgeons without a set weight category automatically inherit the default weight category.",
  "NOTE: Only CBP Admins can change general surgeon settings."
];
const prefHoursReadme = [
  "A surgeon's preferred hours control the time that they wish to perform surgery.",
  "Both start and end times must be set, or both must be blank.",
  "In the planner, when new slots are assigned to a surgeon, they will automatically start at the surgeon's preferred start time.",
  "Additionally, new slots may not be added that would end after the surgeon's preferred end time.",
  "Surgeons that do not have set preferred hours will use the operational hours for the center for the given day."
];
const centerSettingsReadme = [
  "A surgeon has settings specific to each center that they are associated with.",
  "A user may only modify surgeon-center settings for centers in which they may edit the schedule.",
  "Minutes between procedures sets the automatic time spacing between new slots that are created in the Planner.",
  "A surgeon will be recommended for assignment to their preferred room in the planner.",
  "If the center you are looking for is not shown then the surgeon is not assigned to that center in Zenoti.",
  "If there is no room selector for a given center, then that center does not have any rooms with active categories.",
  "If header note is set, the message will be appended to the end of the header note for the given surgeon and center.",
  "NOTE: A user will only be able to change surgeon center settings for centers in which the user is authorized as a Center Editor."
];

// There is a 1024 character limit for notes in the CRM.
// Set a limit on the surgeon center note field short of that to allow adequate space for the note prefix.
const surgeonNoteCharacterLimit = 900;

/** Represents the dropdown option when selecting a Surgeon. */
interface SurgeonOption {
  /** Reference to the surgeon in the option. */
  surgeon: Surgeon;
  /** Dropdown option text. */
  label: string;
}

const buildSurgeonOption = (surgeon?: Surgeon): SurgeonOption | undefined =>
  !surgeon ? undefined : { surgeon, label: `${surgeon.firstName} ${surgeon.lastName}` };

// Construct an updated Surgeon object based on the params.
const buildUpdatedSurgeon = (
  surgeonOption: SurgeonOption,
  surgeonCenter?: SurgeonCenter,
  weightCategory?: WeightCategory
): Surgeon => {
  const updatedSurgeon = { ...surgeonOption!.surgeon };
  if (surgeonCenter)
    updatedSurgeon.surgeonCenters = { ...updatedSurgeon.surgeonCenters, [surgeonCenter.center.id]: surgeonCenter };
  if (weightCategory) updatedSurgeon.weightCategory = weightCategory;
  return updatedSurgeon;
};

const SurgeonConfig: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { user } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedSurgeonOption, setSelectedSurgeonOption] = useState<SurgeonOption>();
  const [weightCategories, setWeightCategories] = useState<WeightCategory[]>([]);
  const [selectedSurgeonCenter, setSelectedSurgeonCenter] = useState<SurgeonCenter>();
  const [selectedWeightCategory, setSelectedWeightCategory] = useState<WeightCategory>();
  const [surgeonOptions, setSurgeonOptions] = useState<SurgeonOption[]>([]);

  // #region GET Categories Request
  const [getWeightCategoriesRequest] = useState({
    name: "Get Weight Categories",
    request: { url: "WeightCategories" }
  });
  const { response: getWeightCategoriesResponse } = useCbp<WeightCategory[]>(getWeightCategoriesRequest);
  useEffect(() => setWeightCategories(getWeightCategoriesResponse || []), [getWeightCategoriesResponse]);
  // #endregion

  // #region GET Surgeons Query
  const [getSurgeonsRequest] = useState<UseCbpProps>({ name: "Get Surgeons", request: { url: "surgeons" } });
  const { response: getSurgeonsResponse } = useCbp<Surgeon[]>(getSurgeonsRequest);
  useEffect(
    () => setSurgeonOptions(getSurgeonsResponse?.map(e => buildSurgeonOption(e)!) || []),
    [getSurgeonsResponse]
  );
  // #endregion

  // #region PUT Surgeon Query
  const [updateSurgeonRequest, setUpdateSurgeonRequest] = useState<UseCbpProps<Surgeon>>();
  const { response: updateSurgeonResponse, isLoading: updateIsLoading } = useCbp<Surgeon, Surgeon>(
    updateSurgeonRequest
  );
  useEffect(() => {
    if (updateSurgeonResponse) {
      const updatedSurgeonOptions = surgeonOptions!;
      const surgeonIndex = updatedSurgeonOptions?.findIndex(s => s.surgeon.id === updateSurgeonResponse.id) ?? -1;
      if (surgeonIndex === -1) throw `Surgeon update returned unknown surgeon id ${updateSurgeonResponse.id}.`;
      updatedSurgeonOptions[surgeonIndex] = buildSurgeonOption({
        ...updatedSurgeonOptions[surgeonIndex].surgeon,
        ...updateSurgeonResponse,
        surgeonCenters: {
          ...updatedSurgeonOptions[surgeonIndex].surgeon.surgeonCenters,
          ...updateSurgeonResponse.surgeonCenters
        }
      })!;
      setSurgeonOptions(updatedSurgeonOptions);
      setSelectedSurgeonOption(updatedSurgeonOptions[surgeonIndex]);
      setSelectedSurgeonCenter(undefined);
      setSelectedWeightCategory(updateSurgeonResponse.weightCategory);
      enqueueSnackbar("Surgeon Updated", { variant: "success" });
    }
  }, [updateSurgeonResponse]);
  // #endregion

  /** Compares the original selected surgeon object against a surgeon constructed from all pending changes and returns a flag for whether or not any changes exist. */
  const isPendingChanges = (): boolean => {
    const before = JSON.stringify(
      surgeonOptions.find(so => so.surgeon.id === selectedSurgeonOption!.surgeon.id)?.surgeon
    );
    const after = JSON.stringify(
      buildUpdatedSurgeon(selectedSurgeonOption!, selectedSurgeonCenter, selectedWeightCategory)
    );
    return before !== after;
  };

  //#region Actions
  const onChangeGap = (e: ChangeEvent<HTMLInputElement>) =>
    setSelectedSurgeonCenter(sc => SurgeonCenter.SetGapFromMinutes(sc!, Number(e.target.value) || 0));
  const onSelectSurgeon = (_: unknown, value: SurgeonOption | null) => {
    setSelectedSurgeonOption(value || undefined);
    setSelectedWeightCategory(value?.surgeon.weightCategory || undefined);
  };
  const onSaveChanges = () => {
    setUpdateSurgeonRequest({
      name: "Update Surgeon",
      request: {
        url: `surgeons/${selectedSurgeonOption!.surgeon.id}`,
        method: "put",
        data: buildUpdatedSurgeon(selectedSurgeonOption!, selectedSurgeonCenter, selectedWeightCategory)
      }
    });
  };
  const onSelectRoom = (event: SelectChangeEvent<string | null>) =>
    setSelectedSurgeonCenter(sc => ({
      ...sc!,
      preferredRoom: selectedSurgeonCenter!.center.rooms.find(r => r.id === event.target.value)
    }));
  const onReset = () => {
    setSelectedSurgeonCenter(undefined);
    setSelectedWeightCategory(selectedSurgeonOption?.surgeon.weightCategory);
    setSelectedSurgeonOption(surgeonOption =>
      surgeonOption
        ? { ...surgeonOption, surgeon: { ...getSurgeonsResponse!.find(s => s.id === surgeonOption.surgeon.id)! } }
        : undefined
    );
  };
  //#endregion

  const preferredHoursValid =
    (selectedSurgeonOption?.surgeon.preferredEnd && selectedSurgeonOption?.surgeon.preferredStart) ||
    (!selectedSurgeonOption?.surgeon.preferredEnd && !selectedSurgeonOption?.surgeon.preferredStart);

  // show spinner while loading
  useEffect(
    () => setIsContentLoading(!getSurgeonsResponse || !getWeightCategoriesResponse),
    [getSurgeonsResponse, getWeightCategoriesResponse]
  );

  let validationMessage = "";
  if (selectedSurgeonOption && isPendingChanges()) validationMessage = "";
  else if (!selectedSurgeonOption) validationMessage = "Please select a Surgeon";
  else if (!preferredHoursValid)
    validationMessage = "Preferred start/end times must both be set, or must both be blank.";

  return (
    <AdministrationTool title="Surgeons" readme={toolReadme} sx={{ minWidth: "50rem" }}>
      <Grid className="m-0 w-100" container spacing={3} direction="column">
        <Grid item container justifyContent="center">
          <Autocomplete
            options={surgeonOptions}
            disabled={updateIsLoading}
            renderInput={params => <TextField {...params} label="Surgeon" />}
            getOptionLabel={adGroup => adGroup.label}
            value={selectedSurgeonOption || null}
            isOptionEqualToValue={(option, value) => option.surgeon.id === value.surgeon.id}
            onChange={onSelectSurgeon}
            sx={{ minWidth: "20rem" }}
          />
        </Grid>
        <Grid item>
          <Grid className="w-100" container direction="column" spacing={2}>
            <Grid item>
              <AdministrationFormLabel label="General Settings" readme={generalSettingsReadme} />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item container direction="column" xs spacing={2}>
                <Grid item>
                  <FormControl>
                    <InputLabel>Weight Category</InputLabel>
                    <Select
                      label="Weight Category"
                      value={selectedWeightCategory?.id || ""}
                      disabled={!selectedSurgeonOption || !user.applicationPermissions.isAdminEditor}
                      onChange={e => setSelectedWeightCategory(weightCategories.find(wc => wc.id === e.target.value))}
                      sx={{ minWidth: "20rem" }}
                    >
                      {weightCategories.map((wc, index) => (
                        <MenuItem value={wc.id} key={index}>
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>{wc.name}</Grid>
                            <Grid item>
                              <Chip size="small" label={`Weight: ${wc.weightValue}`} />
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <AdministrationFormLabel label="Preferred Work Hours" readme={prefHoursReadme} />
            </Grid>
            <Grid item>
              <TimeRangePicker
                start={selectedSurgeonOption?.surgeon.preferredStart || undefined}
                end={selectedSurgeonOption?.surgeon.preferredEnd || undefined}
                disabled={updateIsLoading || !selectedSurgeonOption || !user.applicationPermissions.isAdminEditor}
                onChange={(start?: string, end?: string) =>
                  setSelectedSurgeonOption(so =>
                    so ? { ...so, surgeon: { ...so.surgeon, preferredStart: start, preferredEnd: end } } : undefined
                  )
                }
              />
            </Grid>
            <Grid item>
              <AdministrationFormLabel label="Center Settings" readme={centerSettingsReadme} />
            </Grid>
            <Grid item container spacing={2} wrap="nowrap">
              <Grid item xs>
                <SelectList
                  items={selectedSurgeonOption ? Object.values(selectedSurgeonOption.surgeon.surgeonCenters) : []}
                  value={selectedSurgeonCenter || null}
                  itemKey={(value: SurgeonCenter, item: SurgeonCenter) => value.center.id === item.center.id}
                  disabled={updateIsLoading}
                  listItem={CenterItem}
                  onChange={surgeonCenter => setSelectedSurgeonCenter(surgeonCenter || undefined)}
                />
              </Grid>
              <Grid item container direction="column" xs spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      type="number"
                      label="Minutes Between Procedures"
                      disabled={
                        !selectedSurgeonCenter ||
                        !user.centerPermissions[selectedSurgeonCenter.center.id]?.isCapacityEditor
                      }
                      onChange={onChangeGap}
                      value={(selectedSurgeonCenter && Surgeon.GapToMinutes(selectedSurgeonCenter)) || ""}
                    />
                  </Grid>
                  <Grid item xs>
                    {selectedSurgeonCenter?.center.rooms.some(r => !r.roomCategory.isDisabled) ? (
                      <FormControl fullWidth>
                        <InputLabel>Preferred Room</InputLabel>
                        <Select
                          label="Preferred Room"
                          value={selectedSurgeonCenter?.preferredRoom?.id || ""}
                          disabled={
                            !selectedSurgeonCenter ||
                            !user.centerPermissions[selectedSurgeonCenter.center.id]?.isCapacityEditor
                          }
                          onChange={onSelectRoom}
                        >
                          {(selectedSurgeonCenter?.center.rooms.filter(r => !r.roomCategory.isDisabled) || []).map(
                            (room, index) => (
                              <MenuItem value={room.id} key={index}>
                                {room.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography variant="body1">No available rooms.</Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    type="text"
                    multiline
                    rows={4}
                    label="Header Note"
                    disabled={
                      !selectedSurgeonCenter ||
                      !user.centerPermissions[selectedSurgeonCenter.center.id]?.isCapacityEditor
                    }
                    onChange={e => setSelectedSurgeonCenter(sc => ({ ...sc!, message: e.target.value || undefined }))}
                    value={selectedSurgeonCenter?.message || ""}
                    inputProps={{ maxLength: surgeonNoteCharacterLimit }}
                  />
                  <Typography variant="caption">
                    {surgeonNoteCharacterLimit - (selectedSurgeonCenter?.message?.length || 0)} Characters Remaining
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center" spacing={2}>
                  <Grid item>
                    <Button color="secondary" disabled={!selectedSurgeonOption || updateIsLoading} onClick={onReset}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    {updateIsLoading ? (
                      <CircularProgress />
                    ) : (
                      <ValidationButton message={validationMessage} onClick={onSaveChanges}>
                        Save Changes
                      </ValidationButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const CenterItem: React.FC<CustomListItem<SurgeonCenter>> = ({
  data: surgeonCenter,
  selected,
  ...rest
}: CustomListItem<SurgeonCenter>) => {
  const gapMinutes = Surgeon.GapToMinutes(surgeonCenter);
  return (
    <ListItem {...(rest as Record<string, unknown>)}>
      <ListItemIcon>
        <Checkbox edge="start" checked={selected} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText primary={surgeonCenter.center.name} />
      {surgeonCenter?.preferredRoom && (
        <ListItemIcon>
          <Chip size="small" label={surgeonCenter.preferredRoom.name} />
        </ListItemIcon>
      )}
      {Boolean(gapMinutes) && (
        <ListItemIcon>
          <Chip size="small" label={`${gapMinutes} Minutes`} />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

export default SurgeonConfig;
