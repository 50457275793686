import { Divider, Grid, Typography } from "@mui/material";
import { DateTime, Interval } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import SchedulerBody from "./SchedulerBody";
import SchedulerItem from "./SchedulerItem";
import {
  ScheduleItem,
  ScheduleRow,
  SchedulerClassKey,
  SchedulerProps,
  SchedulerTimeIncrement,
  SchedulerTimeIncrementWidth
} from "./types";

const Scheduler = <TItem extends ScheduleItem, TRow extends ScheduleRow<TItem>>({
  disabled = false,
  ColumnHeader,
  orientation = "horizontal",
  rows,
  RowHeader,
  ScheduleItem = SchedulerItem as never,
  timeIncrement = SchedulerTimeIncrement.HALF_HOUR,
  timeIncrementWidth = SchedulerTimeIncrementWidth.MEDIUM,
  timeRange,
  placeModeFirstTarget,
  placeModeRowIndex,
  canPlaceModeClick,
  formatZoneLabel,
  onPlaceModeClick,
  onDrop,
  onResize
}: SchedulerProps<TItem, TRow>): JSX.Element => {
  const timeRangeRef = useRef<Interval>(Interval.fromDateTimes(DateTime.utc(), DateTime.utc()));
  const [timeHeaders, setTimeHeaders] = useState<DateTime[]>([]);
  const [hContainerRef, setHContainerRef] = useState<HTMLDivElement | null>(null);
  const [zIndexMap, setZIndexMap] = useState<Record<number, number>>({});

  // calculate the time axis values
  useEffect(() => {
    const distinctZIndecies: number[] = [];
    let start = timeRange?.start;
    let end = timeRange?.end;
    rows.forEach((row, rowIndex) =>
      row.items.forEach((item, itemIndex) => {
        // validate all item times are within given range.
        if (!item.startDateTime || !item.endDateTime || item.startDateTime > item.endDateTime)
          throw `Schedule item at row ${rowIndex} position ${itemIndex} has missing or unacceptable time values.`;
        // expand time range to fit all items if necessary
        if (!start || item.startDateTime < start) start = item.startDateTime;
        if (!end || item.endDateTime > end) end = item.endDateTime;
        // get distinct item z indices
        if (item.zIndex !== undefined && !distinctZIndecies.some(z => z === item.zIndex))
          distinctZIndecies.push(item.zIndex);
      })
    );
    const newRange =
      start && end
        ? Interval.fromDateTimes(start, end)
        : Interval.fromDateTimes(DateTime.fromObject({ hour: 0 }), DateTime.fromObject({ hour: 23 }));
    setTimeHeaders(() => {
      timeRangeRef.current = newRange;
      // build an array of DateTimes between the given times incrementing with the given number of minutes
      const times = [newRange.start];
      let increment = newRange.start.plus({ minutes: timeIncrement });
      while (increment < newRange.end) {
        times.push(increment);
        increment = increment.plus({ minutes: timeIncrement });
      }
      return times;
    });
    // simple map distinct z indices into a hierarchy
    if (distinctZIndecies.length) {
      let zIndexMapValue = 1;
      const newZIndexMap: Record<number, number> = {};
      distinctZIndecies.sort();
      distinctZIndecies.forEach(z => (newZIndexMap[z] = zIndexMapValue++));
      setZIndexMap(newZIndexMap);
    }
  }, [timeIncrement, timeRange, rows]);

  // on orientation change, scroll to origin and toggle the overflowY mode
  useEffect(() => {
    if (!hContainerRef) return;
    if (orientation === "vertical") hContainerRef.style.overflowY = "auto";
    else {
      hContainerRef.scrollTo({ top: 0, left: 0 });
      hContainerRef.style.overflowY = "hidden";
    }
  }, [hContainerRef, orientation]);

  return orientation === "horizontal" ? (
    <Grid
      className={`${SchedulerClassKey.root} ${SchedulerClassKey.root_h}`}
      container
      wrap="nowrap"
      columnSpacing={1}
      sx={theme => {
        const timeRowHorizontal = { height: `calc(${theme.typography.h6.fontSize} + 10px)` };
        const itemRowHorizontal = { height: "100px" };
        return {
          "&": { width: "100%" },
          [`& .${SchedulerClassKey.backgroundWrapper_h}`]: { overflowX: "auto", overflowY: "hidden" },
          [`& .${SchedulerClassKey.columnHeadersWrapper}`]: { zIndex: 3 },
          [`& .${SchedulerClassKey.columnHeadersWrapper_h}`]: { height: "100%" },
          [`& .${SchedulerClassKey.columnHeaderSpacer_h}`]: { ...timeRowHorizontal },
          [`& .${SchedulerClassKey.columnHeaderTypography_h}`]: {
            ...timeRowHorizontal,
            display: "inline-block",
            width: "100%"
          },
          [`& .${SchedulerClassKey.dropZoneActive}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          },
          [`& .${SchedulerClassKey.dropZoneVisible}`]: { backgroundColor: theme.palette.action.selected },
          [`& .${SchedulerClassKey.itemRoot}`]: {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.secondary.contrastText,
            position: "relative"
          },
          [`& .${SchedulerClassKey.itemRoot_h}`]: { height: "100%" },
          [`& .${SchedulerClassKey.itemWrapper_h}`]: { ...itemRowHorizontal },
          [`& .${SchedulerClassKey.itemsWrapper}`]: { zIndex: 5, position: "relative" },
          // TODO: figure out the dynamic positioning formula
          [`& .${SchedulerClassKey.itemsWrapper_h}`]: { top: `calc(-100% + ${timeRowHorizontal.height})` },
          [`& .${SchedulerClassKey.rowHeader_h}`]: { ...itemRowHorizontal }
        };
      }}
    >
      {RowHeader && (
        <Grid item>
          <Grid container direction="column">
            <Grid className={`${SchedulerClassKey.columnHeaderSpacer_h}`} item />
            <Grid item container direction="column" spacing={2} wrap="nowrap">
              {rows.map((row, rowIndex) => (
                <Grid
                  className={`${SchedulerClassKey.rowHeader_h}`}
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  key={rowIndex}
                >
                  <RowHeader disabled={disabled} placeModeRowIndex={placeModeRowIndex} row={row} rowIndex={rowIndex} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        className={`${SchedulerClassKey.backgroundWrapper_h}`}
        item
        xs
        ref={ref => {
          setHContainerRef(ref);
          return ref;
        }}
      >
        <Grid
          className={`${SchedulerClassKey.columnHeadersWrapper} ${SchedulerClassKey.columnHeadersWrapper_h}`}
          container
          wrap="nowrap"
        >
          {timeHeaders.map((time, index) => (
            <Grid className="h-100 m-0 p-0" item key={index}>
              <span>
                {ColumnHeader ? (
                  <ColumnHeader time={time} />
                ) : (
                  <Grid
                    display="inline-flex"
                    justifyContent="start"
                    sx={{ m: 0, width: `${timeIncrementWidth}px`, maxWidth: `${timeIncrementWidth}px` }}
                  >
                    <Grid item sx={{ ml: 1 }}>
                      <Typography
                        className={`${SchedulerClassKey.columnHeaderTypography_h}`}
                        variant="h6"
                        component="span"
                      >
                        {time.toFormat("h:mm")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={`${SchedulerClassKey.columnHeaderTypography_h}`}
                        variant="body2"
                        component="span"
                      >
                        {time.toFormat("a")}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </span>
              <Divider orientation="vertical" />
            </Grid>
          ))}
        </Grid>
        <Grid
          className={`${SchedulerClassKey.itemsWrapper} ${SchedulerClassKey.itemsWrapper_h}`}
          container
          direction="column"
          spacing={2}
          wrap="nowrap"
        >
          <DndProvider backend={HTML5Backend}>
            <SchedulerBody
              disabled={disabled}
              orientation={orientation}
              rows={rows}
              ScheduleItem={ScheduleItem}
              timeIncrement={timeIncrement}
              timeIncrementWidth={timeIncrementWidth}
              timeRange={timeRangeRef.current}
              zIndexMap={zIndexMap}
              placeModeFirstTarget={placeModeFirstTarget}
              placeModeRowIndex={placeModeRowIndex}
              formatZoneLabel={formatZoneLabel}
              canPlaceModeClick={canPlaceModeClick}
              onPlaceModeClick={onPlaceModeClick}
              onDrop={onDrop}
              onResize={onResize}
            />
          </DndProvider>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid
      className={`${SchedulerClassKey.root} ${SchedulerClassKey.root_v}`}
      container
      wrap="nowrap"
      direction="column"
      rowSpacing={2}
      sx={theme => {
        const timeRowVertical = { width: "100px" };
        const itemRowVertical = { width: "225px" };
        return {
          "&": { height: "100%" },
          [`& .${SchedulerClassKey.backgroundWrapper_v}`]: { width: "100%", overflowX: "hidden", overflowY: "auto" },
          [`& .${SchedulerClassKey.columnHeadersWrapper}`]: { zIndex: 3 },
          [`& .${SchedulerClassKey.columnHeadersWrapper_v}`]: { width: "100%" },
          [`& .${SchedulerClassKey.columnHeaderSpacer_v}`]: { ...timeRowVertical, flexShrink: 0 },
          [`& .${SchedulerClassKey.columnHeaderTypography_v}`]: {},
          [`& .${SchedulerClassKey.dropZoneActive}`]: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          },
          [`& .${SchedulerClassKey.dropZoneVisible}`]: { backgroundColor: theme.palette.action.selected },
          [`& .${SchedulerClassKey.itemRoot}`]: {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.secondary.contrastText,
            position: "relative"
          },
          [`& .${SchedulerClassKey.itemRoot_v}`]: { width: "100%" },
          [`& .${SchedulerClassKey.itemWrapper_v}`]: { ...itemRowVertical },
          [`& .${SchedulerClassKey.itemsWrapper}`]: { zIndex: 5, position: "relative" },
          // TODO: figure out the dynamic positioning formula
          [`& .${SchedulerClassKey.itemsWrapper_v}`]: { marginLeft: "calc(-100% + 172px)" },
          [`& .${SchedulerClassKey.rowHeader_v}`]: { ...itemRowVertical }
        };
      }}
    >
      {RowHeader && (
        <Grid item>
          <Grid container wrap="nowrap">
            <Grid className={`${SchedulerClassKey.columnHeaderSpacer_v}`} item />
            <Grid className="h-100" item container spacing={2} wrap="nowrap">
              {rows.map((row, rowIndex) => (
                <Grid
                  className={`${SchedulerClassKey.rowHeader_v}`}
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  key={rowIndex}
                >
                  <RowHeader disabled={disabled} placeModeRowIndex={placeModeRowIndex} row={row} rowIndex={rowIndex} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid className={`${SchedulerClassKey.backgroundWrapper_v}`} item container xs wrap="nowrap">
        <Grid
          className={`${SchedulerClassKey.columnHeadersWrapper} ${SchedulerClassKey.columnHeadersWrapper_v}`}
          container
          direction="column"
          wrap="nowrap"
        >
          {timeHeaders.map((time, index) => (
            <Grid className="m-0 p-0" item key={index}>
              <Divider style={{ marginTop: "-1px" }} />
              <span>
                {ColumnHeader ? (
                  <ColumnHeader time={time} />
                ) : (
                  <Grid
                    container
                    display="inline-flex"
                    alignItems="start"
                    sx={{ m: 0, height: `${timeIncrementWidth}px`, maxHeight: `${timeIncrementWidth}px` }}
                  >
                    <Grid item container alignItems="center">
                      <Grid item>
                        <Typography
                          className={`${SchedulerClassKey.columnHeaderTypography_v}`}
                          variant="h6"
                          component="span"
                        >
                          {time.toFormat("h:mm")}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={`${SchedulerClassKey.columnHeaderTypography_v}`}
                          variant="body2"
                          component="span"
                        >
                          {time.toFormat("a")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </span>
            </Grid>
          ))}
        </Grid>
        <Grid
          className={`${SchedulerClassKey.itemsWrapper} ${SchedulerClassKey.itemsWrapper_v}`}
          container
          spacing={2}
          wrap="nowrap"
        >
          <DndProvider backend={HTML5Backend}>
            <SchedulerBody
              disabled={disabled}
              orientation={orientation}
              rows={rows}
              ScheduleItem={ScheduleItem}
              timeIncrement={timeIncrement}
              timeIncrementWidth={timeIncrementWidth}
              timeRange={timeRangeRef.current}
              zIndexMap={zIndexMap}
              placeModeFirstTarget={placeModeFirstTarget}
              placeModeRowIndex={placeModeRowIndex}
              canPlaceModeClick={canPlaceModeClick}
              formatZoneLabel={formatZoneLabel}
              onPlaceModeClick={onPlaceModeClick}
              onDrop={onDrop}
              onResize={onResize}
            />
          </DndProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Scheduler;
