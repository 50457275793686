import { Button, Grid, TextField, TextFieldProps } from "@mui/material";
import React from "react";

type TimeTextFieldProps = Omit<TextFieldProps, "value" | "type" | "onChange">;

interface Props {
  /** If the child input components should be disabled. */
  disabled?: boolean;
  /** The state value of the end date. */
  end?: string;
  /** Props of the end time picker. */
  endProps?: TimeTextFieldProps;
  /** Flag to enforce 100% width expansion on the component container. */
  fullWidth?: true;
  /** Flag to hide the clear button. */
  hideClear?: true;
  /** The state value of the start date. */
  start?: string;
  /** Flag to show seconds on the time inputs. */
  showSeconds?: true;
  /** Props of the start time picker. */
  startProps?: TimeTextFieldProps;
  /** Handler for a time picker change event. */
  onChange?: (start?: string, end?: string) => void;
}

const trimSeconds = (time: string) => time.replace(/(\d\d:\d\d)(:\d\d)/, "$1");

/** Helper component for form inputs that require a time range selector. */
const TimeRangePicker: React.FC<Props> = ({
  disabled = false,
  end,
  endProps = {},
  fullWidth,
  hideClear,
  start,
  showSeconds,
  startProps = {},
  onChange
}: Props) => {
  const onStartChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(value || undefined, end);
  const onEndChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(start, value || undefined);
  const onClear = () => onChange && onChange(undefined, undefined);
  return (
    <Grid className={fullWidth ? "w-100" : ""} container alignItems="center" spacing={2} wrap="nowrap">
      <Grid item xs={fullWidth}>
        <TextField
          fullWidth={fullWidth}
          label="Start Time"
          type="time"
          value={start ? (showSeconds ? start : trimSeconds(start)) : ""}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 300 }}
          onChange={onStartChange}
          {...startProps}
        />
      </Grid>
      <Grid item xs={fullWidth}>
        <TextField
          fullWidth={fullWidth}
          label="End Time"
          type="time"
          value={end ? (showSeconds ? end : trimSeconds(end)) : ""}
          disabled={disabled}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 300 }}
          onChange={onEndChange}
          {...endProps}
        />
      </Grid>
      {!hideClear && (
        <Grid item>
          <Button disabled={disabled} onClick={onClear} color="secondary">
            Clear
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeRangePicker;
