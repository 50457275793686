import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";

import { ServiceCategory } from "../../../models/ServiceCategory";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import { AdminTool } from "../Administration";
import AdministrationTool from "../AdministrationTool";

const baseRequest = { url: "serviceCategories" };
const toolReadme = [
  "The Service Categories tool allows you to set the availability of service categories that are allowed in CBP.",
  "Categories which are enabled will be included in selection of related entities, such as subcategories.",
  "All enabled sub-categories with the same name must have the same weight within all categories in which they are present. Failing to do so will cause unintended behaviors.",
  "EX: If the 'Body' subcategory exists within two categories, it must have the same weight value in both locations."
];

const ServiceCategoryConfig: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [serviceCategories, setServiceCategories] = useState<ServiceCategory[]>();
  const [selectedServiceCategory, setSelectedServiceCategory] = useState<ServiceCategory>();
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    if (!selectedServiceCategory) {
      setSaveDisabled(true);
      return;
    }

    const currentSubCategory = serviceCategories?.find(p => p.id === selectedServiceCategory.id);

    if (!currentSubCategory) {
      setSaveDisabled(true);
      return;
    }

    if (currentSubCategory.isDisabled !== selectedServiceCategory.isDisabled) {
      setSaveDisabled(false);
      return;
    }

    if (
      selectedServiceCategory.subCategories.some(ssc =>
        currentSubCategory.subCategories.some(oldSsc => ssc.id === oldSsc.id && ssc.weightValue != oldSsc.weightValue)
      )
    )
      setSaveDisabled(false);
    else setSaveDisabled(true);
  }, [serviceCategories, selectedServiceCategory]);

  // #region GET ServiceCategories Query
  const [getServiceCategoriesRequest] = useState<UseCbpProps>({ name: "Get Service Categories", request: baseRequest });
  const { response: getServiceCategoriesResponse } = useCbp<ServiceCategory[]>(getServiceCategoriesRequest);
  useEffect(() => {
    if (getServiceCategoriesResponse) setServiceCategories(getServiceCategoriesResponse);
  }, [getServiceCategoriesResponse]);
  // #endregion

  // #region PUT ServiceCategory
  const [updateServiceCategoriesRequest, setUpdateServiceCategoriesRequest] = useState<UseCbpProps<ServiceCategory>>();
  const { response: updateServiceCategoriesResponse, isLoading: updateIsLoading } = useCbp<
    ServiceCategory,
    ServiceCategory
  >(updateServiceCategoriesRequest);
  useEffect(() => {
    if (updateServiceCategoriesResponse) {
      setServiceCategories(serviceCategories => {
        const updatedCategories = [...serviceCategories!];
        const index = updatedCategories.findIndex(category => category.id === updateServiceCategoriesResponse.id);
        updatedCategories[index] = updateServiceCategoriesResponse;
        return updatedCategories;
      });
      setSelectedServiceCategory(undefined);
      setSaveDisabled(true);
      enqueueSnackbar("Service Category Updated", { variant: "success" });
    }
  }, [updateServiceCategoriesResponse]);
  //#endregion

  // #region Actions
  const onDisableStateChange = (_: unknown, checked: boolean) => {
    setSelectedServiceCategory(sc => ({ ...sc!, isDisabled: !checked }));
  };

  const onSaveChanges = () => {
    setUpdateServiceCategoriesRequest({
      name: "Update Service Category",
      request: {
        url: `${baseRequest.url}/${selectedServiceCategory!.id}`,
        method: "put",
        data: selectedServiceCategory!
      }
    });
  };

  const onWeightChange = (e: ChangeEvent<HTMLInputElement>) =>
    setSelectedServiceCategory(sc => {
      if (!sc) return;

      const newSubCategories = [
        ...sc.subCategories.map(v => {
          if (v.id == e.target.name)
            return { ...v, weightValue: e.target.value === "" ? 0 : Math.min(Math.max(0, Number(e.target.value)), 99) };

          return v;
        })
      ];

      return { ...sc, subCategories: newSubCategories };
    });
  // #endregion

  // show spinner while loading
  useEffect(() => setIsContentLoading(!serviceCategories), [serviceCategories]);

  return (
    <AdministrationTool title="Service Categories" readme={toolReadme} sx={{ minWidth: "45rem" }}>
      <Grid className="m-0 w-100" container spacing={3} direction="column">
        <Grid item container>
          <Grid item container xs direction="column" alignItems="center" spacing={2}>
            <SelectList
              title="Service Categories"
              items={serviceCategories}
              value={selectedServiceCategory || null}
              itemKey="id"
              disabled={updateIsLoading}
              listItem={CategoryItem}
              onChange={category => setSelectedServiceCategory(category ? { ...category } : undefined)}
            />
          </Grid>
          <Grid item container xs direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6">
                Settings for {selectedServiceCategory ? selectedServiceCategory.name : ""}
              </Typography>
            </Grid>
            <Grid
              container
              item
              className={selectedServiceCategory ? "editing" : ""}
              spacing={2}
              justifyContent="center"
            >
              <Grid item>
                <FormControlLabel
                  control={<Switch />}
                  disabled={!selectedServiceCategory}
                  checked={(selectedServiceCategory && !selectedServiceCategory.isDisabled) || false}
                  onChange={onDisableStateChange}
                  label="Enabled"
                />
              </Grid>
            </Grid>
            {selectedServiceCategory?.subCategories && !selectedServiceCategory?.isDisabled && (
              <Grid
                container
                item
                direction="column"
                className={selectedServiceCategory ? "editing" : ""}
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">SubCategory Weights:</Typography>
                </Grid>
                {selectedServiceCategory?.subCategories.map(v => (
                  <Grid item key={v.id}>
                    <TextField
                      label={v.name}
                      value={v?.weightValue || ""}
                      type="number"
                      name={v.id}
                      onChange={onWeightChange}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid item>
              {updateIsLoading ? (
                <CircularProgress />
              ) : (
                <Button disabled={!selectedServiceCategory || saveDisabled} onClick={onSaveChanges}>
                  Save Changes
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const CategoryItem: React.FC<CustomListItem<ServiceCategory>> = ({
  data: serviceCategory,
  selected,
  ...rest
}: CustomListItem<ServiceCategory>) => {
  return (
    <>
      <ListItem {...(rest as Record<string, unknown>)}>
        <ListItemIcon>
          <Checkbox edge="start" checked={selected} tabIndex={-1} />
        </ListItemIcon>
        <ListItemText primary={serviceCategory.name} />
        {!serviceCategory.isDisabled && (
          <ListItemIcon>
            <Chip size="small" label="Enabled" />
          </ListItemIcon>
        )}
      </ListItem>
    </>
  );
};

export default ServiceCategoryConfig;
