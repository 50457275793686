import { DateTime, Zone } from "luxon";

/** The dto model used to transact CenterHours with the backend. */
export class CenterHours {
  /** The key of the associated center. */
  centerId!: string;
  /** The UTC ISO value of the start of operational hours for the center. */
  startTimeUtc!: string;
  /** The UTC ISO value of the end of operational hours for the center. */
  endTimeUtc!: string;
}

/** Expanded CenterHours model with additional utility props. */
export class EnhancedCenterHours extends CenterHours {
  /** The full datetime object for the operational hours start time. */
  startDateTime!: DateTime;
  /** The full datetime object for the operational hours end time. */
  endDateTime!: DateTime;

  /** Build an enhanced model from its base and additional params. */
  static fromBase(centerHours: CenterHours, luxonTimeZone: Zone): EnhancedCenterHours {
    return {
      ...centerHours,
      startDateTime: DateTime.fromISO(centerHours.startTimeUtc).setZone(luxonTimeZone),
      endDateTime: DateTime.fromISO(centerHours.endTimeUtc).setZone(luxonTimeZone)
    };
  }
}
