import CloseIcon from "@mui/icons-material/Close";
import { AppBar, AppBarProps, Collapse, Grid, IconButton, Toolbar } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";

interface Props extends AppBarProps {
  /** Flag to show a close button on the notification allowing dismissal by the user. */
  dismissable?: boolean;
}

/** A contained banner with open/close animations and rendering its children on the notification bar. */
const NotificationBanner: React.FC<PropsWithChildren<Props>> = ({
  dismissable,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  const [show, setShow] = useState(false);
  useEffect(() => setShow(Boolean(children)), [children]);
  return (
    <Collapse in={show}>
      <AppBar position="static" color="secondary" {...rest}>
        <Toolbar sx={{ justifyContent: "center" }} variant="dense">
          <Grid className="p-0 w-100" container>
            <Grid item container justifyContent="center" alignItems="center" xs>
              {children}
            </Grid>
            {dismissable && (
              <Grid item>
                <IconButton
                  size="small"
                  onClick={() => setShow(false)}
                  sx={theme => ({ alignContent: "end", color: theme.palette.secondary.contrastText })}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </Collapse>
  );
};

export default NotificationBanner;
