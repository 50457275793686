import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { Interval } from "luxon";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Route, useHistory } from "react-router-dom";

import { LocalStorageKeys } from "../../Constants";
import AppContext from "../../contexts/AppContext";
import { Block, BlockType } from "../../models/Block";
import { Calendar, EnhancedCalendar } from "../../models/Calendar";
import { Center, EnhancedCenter, SimpleCenter } from "../../models/Center";
import { CenterHours, EnhancedCenterHours } from "../../models/CenterHours";
import { CenterLock, EnhancedCenterLock, SignalRCenterLock } from "../../models/CenterLock";
import { ReductionReason } from "../../models/ReductionReason";
import { ServiceCategory } from "../../models/ServiceCategory";
import { ServiceSubCategory } from "../../models/ServiceSubCategory";
import DateTime from "../../types/DateTime";
import { PlannerViewType } from "../../types/PlannerViewProps";
import { UserSettings } from "../../types/UserSettings";
import { loadFromLocal, saveToLocal } from "../../utils/Helpers";
import useCbp, { UseCbpProps } from "../../utils/UseCbp";
import BatchEditView from "./BatchEditView/BatchEditView";
import DayView from "./DayView/DayView";
import MonthlyView from "./MonthlyView/MonthlyView";

const updatedLocksTopic = (centerId: string) => `CenterLocksUpdated/${centerId}`;
const updateCalendarTopic = "CalendarUpdated";

// select a default center id from: query search params > user settings > first
const coalesceDefaultCenterId = (
  searchParams: { centerId?: string },
  userSettings: UserSettings,
  centers?: SimpleCenter[]
): SimpleCenter | undefined => {
  // if unloaded, do not default
  if (!centers?.length) return undefined;
  // if search param was provided, use it
  if (searchParams?.centerId) {
    const searchCenter = centers.find(c => c.id === searchParams?.centerId);
    if (searchCenter) return searchCenter;
  }
  // if no user settings, default to first
  if (!userSettings) return centers[0];
  // default to user settings specified center, else the first
  const centerId = userSettings.defaultLastCenter ? userSettings.defaultLastCenterId : userSettings.defaultCenterId;
  return centers.find(c => c.id === centerId) || centers[0];
};

const Planner: React.FC = () => {
  const history = useHistory();
  const {
    centerLock,
    payloads,
    user,
    userCenterLock,
    setCenterLock,
    setBannerNotification,
    setUserCenterLock,
    subscribe,
    unsubscribe
  } = useContext(AppContext);
  const [userSettings] = useState(loadFromLocal<UserSettings>(LocalStorageKeys.userSettings) || new UserSettings());
  const [searchParams] = useState<{ centerId?: string; date?: DateTime }>(() => {
    if (!history.location.search) return {};
    const searchParams = new URLSearchParams(history.location.search);
    const centerId = searchParams.get("center");
    const dateMatch = searchParams.get("date");

    // Get the date from the URL and assume it is in local time.
    const date = dateMatch ? DateTime.fromISO(dateMatch) : undefined;
    return { centerId: centerId || undefined, date: date?.isValid ? date : undefined };
  });
  const [center, setCenter] = useState<EnhancedCenter>();
  const [simpleCenters, setSimpleCenters] = useState(
    loadFromLocal<SimpleCenter[]>(LocalStorageKeys.planner.simpleCenters)
  );
  const [centerHours, setCenterHours] = useState<Record<string, Record<string, EnhancedCenterHours>>>({});
  const [date, setDate] = useState(searchParams?.date || DateTime.now().startOf("day").toUTC());
  const [today, setToday] = useState(DateTime.now().startOf("day").toUTC());
  const [selectedSubCategories, setSelectedSubCategories] = useState<ServiceSubCategory[]>([]);
  const [serviceCategories, setServiceCategories] = useState<ServiceCategory[]>();
  const [serviceSubCategories, setServiceSubCategories] = useState<ServiceSubCategory[]>();
  const [simpleCenter, setSimpleCenter] = useState(() =>
    coalesceDefaultCenterId(searchParams, userSettings, simpleCenters)
  );

  const [calendarProps, setCalendarProps] =
    useState<{ center: EnhancedCenter; calendar: EnhancedCalendar; date: DateTime }>();
  const simpleCenterRef = useRef(simpleCenter);
  const centerRef = useRef<EnhancedCenter>();
  const calendarRef = useRef<{ calendar: Calendar; centerId: string }>();
  const dateRef = useRef(date);
  const calendarUpdateRef = useRef<{ Timestamp: string; CenterId: string; UserId: string }>();
  const serviceCategoriesRef = useRef(serviceCategories);

  // cache the most recent center and update its state
  const saveSimpleCenter = (center: SimpleCenter) => {
    saveToLocal<UserSettings>({ ...userSettings, defaultLastCenterId: center.id }, LocalStorageKeys.userSettings);
    history.push(`${history.location.pathname}?center=${center.id}&date=${date.toISODate()}`);
    simpleCenterRef.current = center;
    setSimpleCenter(center);
  };

  useEffect(() => {
    // Translate the selected date into the Center timezone when the Center changes.
    // Take the desired date (either from the url or local time), create it in the Center timezone, then translate to UTC.
    // Results in dates like 2022-11-11 06:00:00Z (Central Time).
    const translatedDate = DateTime.fromObject(
      { year: dateRef.current.year, month: dateRef.current.month, day: dateRef.current.day },
      { zone: center?.luxonTimeZone.name }
    ).toUTC();

    setDate(translatedDate);

    dateRef.current = translatedDate;

    // Update "today" when the selected Center changes.
    // Take now in local time, translate to Center timezone, truncate to start of day, then translate to UTC.
    // Extra zone translation before truncation ensures that we don't have odd behavior close to Center midnight.
    // This will result in times like 2022-11-11 06:00:00Z (Central Time), which can be compared exactly with the date above.
    const localToday = DateTime.now().setZone(center?.luxonTimeZone.name).startOf("day").toUTC();

    setToday(
      DateTime.fromObject(
        { year: localToday.year, month: localToday.month, day: localToday.day },
        { zone: center?.luxonTimeZone.name }
      ).toUTC()
    );

    updateViewProps();
  }, [center]);

  // on first render
  useEffect(() => {
    // update the url if we have a default center id
    if (simpleCenter) {
      saveToLocal<UserSettings>(
        { ...userSettings, defaultLastCenterId: simpleCenter.id },
        LocalStorageKeys.userSettings
      );
      history.push(`${history.location.pathname}?center=${simpleCenter.id}&date=${date.toISODate()}`);
    }
    // on un-mounting the page clear the lock banner
    return () => setBannerNotification(undefined);
  }, []);

  // #region Reduction Reasons
  const [reductionReasonsRequest] = useState<UseCbpProps>({
    name: "Get Reduction Reasons",
    request: { url: "/reductionreasons" }
  });
  const { response: reductionReasonsResponse } = useCbp<ReductionReason[]>(reductionReasonsRequest);
  // #endregion
  // #region Center Locks
  const [getLocksRequest, setGetLocksRequest] = useState(
    simpleCenter && { name: "Get Locks", request: { url: `centers/${simpleCenter.id}/locks` } }
  );
  const { response: getLocksResponse } = useCbp<CenterLock | Partial<CenterLock>>(getLocksRequest);
  // on get locks, if the user owns one update the context
  useEffect(() => {
    if (getLocksResponse === undefined) return;
    if (simpleCenterRef.current) {
      const newLock = getLocksResponse.displayName
        ? new EnhancedCenterLock(getLocksResponse as CenterLock, simpleCenterRef.current)
        : undefined;
      if (getLocksResponse.userId === user.id) {
        setUserCenterLock(newLock);
        setCenterLock(undefined);
      } else setCenterLock(newLock);
    }
  }, [getLocksResponse]);
  // #endregion

  // #region Get Center
  const [getCenterRequest, setGetCenterRequest] = useState<UseCbpProps | undefined>(
    simpleCenter ? { name: "Get Center", request: { url: `centers/${simpleCenter.id}` } } : undefined
  );
  const { response: getCenterResponse } = useCbp<Center>(getCenterRequest);
  useEffect(() => {
    if (getCenterResponse) {
      // build enhanced props
      const luxonTimeZone = DateTime.getZone(getCenterResponse.timeZone.currentName);
      const surgeons = getCenterResponse.surgeons.map(s => {
        const startDateTime = s.preferredStart ? DateTime.utcFromLocalTime(s.preferredStart, luxonTimeZone) : undefined;
        const endDateTime = s.preferredEnd ? DateTime.utcFromLocalTime(s.preferredEnd, luxonTimeZone) : undefined;
        const terminationDateTimeLocal = s.terminationDate ? DateTime.fromISO(s.terminationDate) : undefined;
        const terminationDateTime = terminationDateTimeLocal
          ? DateTime.utc(terminationDateTimeLocal.year, terminationDateTimeLocal.month, terminationDateTimeLocal.day)
          : undefined;
        return { ...s, startDateTime, endDateTime, terminationDateTime };
      });
      const enhancedCenter = {
        ...getCenterResponse,
        surgeons,
        rooms: getCenterResponse.rooms.filter(r => !r.roomCategory.isDisabled),
        luxonTimeZone
      };
      setCenter(enhancedCenter);
      centerRef.current = enhancedCenter;
      setCenterHours({});
      updateViewProps();
    }
  }, [getCenterResponse]);
  // #endregion

  // #region Signal R
  useEffect(() => {
    if (!simpleCenter) return;
    const topics = [updatedLocksTopic(simpleCenter.id), updateCalendarTopic];
    subscribe(topics);
    return () => unsubscribe(topics);
  }, [simpleCenter]);
  useEffect(() => {
    if (centerRef.current) {
      const signalRLock = payloads[updatedLocksTopic(centerRef.current.id)] as SignalRCenterLock | undefined;
      if (signalRLock) {
        // clear or set lock based on expiration status
        const lock = EnhancedCenterLock.fromSignalR(signalRLock, centerRef.current);
        if (lock.userId === user.id) {
          setUserCenterLock(lock.expirationDateTime > DateTime.utc() ? lock : undefined);
          setCenterLock(undefined);
        } else setCenterLock(lock.expirationDateTime > DateTime.utc() ? lock : undefined);
      }
    }
    const updatedCalendars = payloads[updateCalendarTopic] as typeof calendarUpdateRef.current;
    // if there is an update to the current calendar made by another user, get the update
    if (
      updatedCalendars &&
      updatedCalendars.CenterId === simpleCenterRef.current?.id &&
      updatedCalendars.UserId !== user.id &&
      updatedCalendars.Timestamp !== calendarUpdateRef.current?.Timestamp
    ) {
      setGetCalendarRequest({
        name: "Get Calendar",
        request: {
          url: `centers/${simpleCenterRef.current.id}/calendars`,
          config: { params: { month: `${date.set({ day: 1 }).toISODate()}` } }
        }
      });
      calendarUpdateRef.current = { ...updatedCalendars };
    }
  }, [payloads]);
  // #endregion

  // #region Get Centers
  const [getCentersRequest] = useState(
    !simpleCenters ? { name: "Get Centers", request: { url: "/centers" } } : undefined
  );
  const { response: getCentersResponse } = useCbp<Center[]>(getCentersRequest);
  useEffect(() => {
    if (getCentersResponse) {
      const newSimpleCenters = getCentersResponse.map(({ id, name }) => ({ id, name }));
      setSimpleCenters(newSimpleCenters);
      const newSimpleCenter = coalesceDefaultCenterId(searchParams, userSettings, newSimpleCenters);
      if (newSimpleCenter) {
        saveSimpleCenter(newSimpleCenter!);
        simpleCenterRef.current = newSimpleCenter!;
        setGetCenterRequest({ name: "Get Center", request: { url: `centers/${newSimpleCenter!.id}` } });
        setGetCalendarRequest({
          name: "Get Calendar",
          request: {
            url: `centers/${newSimpleCenter!.id}/calendars`,
            config: { params: { month: `${date.set({ day: 1 }).toISODate()}` } }
          }
        });
        saveToLocal(newSimpleCenters, LocalStorageKeys.planner.simpleCenters);
      }
    }
  }, [getCentersResponse]);
  // #endregion

  // #region Get Services
  const [getServiceCategoriesRequest] = useState<UseCbpProps>({
    name: "Get Service Categories",
    request: { url: "serviceCategories" }
  });
  const { response: getServiceCategoriesResponse } = useCbp<ServiceCategory[]>(getServiceCategoriesRequest);
  useEffect(() => {
    // filter to only enabled categories
    const enabledCategories = getServiceCategoriesResponse?.filter(c => !c.isDisabled);
    setServiceCategories(enabledCategories);
    serviceCategoriesRef.current = enabledCategories;
    // aggregate distinct subcategories of active categories by name into a single array
    const distinctNameSubCategories: Record<string, ServiceSubCategory> = {};
    enabledCategories
      ?.reduce((collector, category) => collector.concat(category.subCategories), [] as ServiceSubCategory[])
      .forEach(subcat => (distinctNameSubCategories[subcat.name] = subcat));
    setServiceSubCategories(
      enabledCategories
        ? Object.values(distinctNameSubCategories).sort((sc1, sc2) => (sc1.name > sc2.name ? 1 : -1))
        : undefined
    );
    updateViewProps();
  }, [getServiceCategoriesResponse]);
  // #endregion

  const setCalendar = useCallback((calendar: Calendar) => {
    // re-key as ISO Dates
    const newCalendar: Calendar = { ...calendar, days: {} };
    Object.entries(calendar.days).forEach(([truncatedDateKey, roomDays]) => {
      newCalendar.days[DateTime.fromTruncatedISO(truncatedDateKey).toUTC().toISODate()] = roomDays;
    });
    calendarRef.current = { calendar: newCalendar, centerId: simpleCenterRef.current!.id };
    updateViewProps();
  }, []);

  // #region Get Calendar
  const [getCalendarRequest, setGetCalendarRequest] = useState<UseCbpProps | undefined>(
    (simpleCenter && {
      name: "Get Center",
      request: {
        url: `centers/${simpleCenter.id}/calendars`,
        config: { params: { month: `${date.set({ day: 1 }).toISODate()}` } }
      }
    }) ||
      undefined
  );
  const { response: getCalendarResponse, isLoading: calendarIsLoading } = useCbp<Calendar>(getCalendarRequest);
  useEffect(() => {
    if (getCalendarResponse) setCalendar(getCalendarResponse);
  }, [getCalendarResponse]);
  // #endregion

  // #region Get Operating Hours
  const [getCenterHoursRequest, setGetCenterHoursRequest] = useState<UseCbpProps>();
  const { response: getCenterHoursResponse } = useCbp<CenterHours>(getCenterHoursRequest);
  useEffect(() => {
    if (!getCenterHoursResponse) return;
    setCenterHours(hours => {
      const centerHours = hours[centerRef.current!.id] || {};
      centerHours[
        DateTime.fromISO(getCenterHoursResponse.startTimeUtc, { zone: centerRef.current!.luxonTimeZone }).toISODate()
      ] = EnhancedCenterHours.fromBase(getCenterHoursResponse, centerRef.current!.luxonTimeZone);
      return { ...hours, [centerRef.current!.id]: { ...centerHours } };
    });
  }, [getCenterHoursResponse]);
  // #endregion

  // #region Put Locks
  const [putLocksRequest, setPutLocksRequest] = useState<UseCbpProps>();
  useCbp(putLocksRequest);
  // #endregion

  // keep the edit lock banner up to date
  useEffect(() => {
    setBannerNotification(
      centerLock && {
        content: `${centerLock.centerName} is locked by ${centerLock.displayName} until ${DateTime.fromISO(
          centerLock.expiration
        ).toLocaleString(DateTime.TIME_SIMPLE)}.`
      }
    );
    if (!centerLock) return;
    // prepare a callback to remove the lock when it expires
    const timeToExpireInMs = Interval.fromDateTimes(DateTime.utc(), centerLock.expirationDateTime).length(
      "milliseconds"
    );
    const clearLockTimeoutId = setTimeout(() => {
      setCenterLock(oldLock => {
        // if the lock has already expired or is expiring as predicted, clear the notification
        if (
          !oldLock ||
          (oldLock.centerId === centerLock.centerId && oldLock.expirationDateTime.equals(centerLock.expirationDateTime))
        ) {
          setBannerNotification(undefined);
          return undefined;
        }
        // else do nothing
        return oldLock;
      });
    }, timeToExpireInMs);
    return () => clearTimeout(clearLockTimeoutId);
  }, [centerLock]);

  // #region Actions
  const onChangeCenter = useCallback(
    (centerId: string) => {
      const newSimpleCenter = simpleCenters!.find(c => c.id === centerId);
      if (!newSimpleCenter) throw `Unable to find center with id {${centerId}}.`;
      saveSimpleCenter(newSimpleCenter);
      if (user.centerPermissions[centerId]?.isCapacityEditor)
        setGetLocksRequest({ name: "Get Locks", request: { url: `centers/${centerId}/locks` } });
      setGetCenterRequest({ name: "Get Center", request: { url: `centers/${centerId}` } });
      setGetCalendarRequest({
        name: "Get Calendar",
        request: {
          url: `centers/${centerId}/calendars`,
          config: { params: { month: `${dateRef.current.set({ day: 1 }).toISODate()}` } }
        }
      });
    },
    [simpleCenters]
  );
  const onChangeDate = useCallback(
    (newDate: DateTime, view: PlannerViewType) => {
      setDate(oldDate => {
        // if month changed, get the calendar
        if ((oldDate.month != newDate.month || oldDate.year != newDate.year) && simpleCenterRef.current)
          setGetCalendarRequest({
            name: "Get Calendar",
            request: {
              url: `centers/${simpleCenterRef.current.id}/calendars`,
              config: { params: { month: `${newDate.set({ day: 1 }).toISODate()}` } }
            }
          });
        history.push(`/planner/${view}?center=${simpleCenterRef.current?.id}&date=${newDate.toISODate()}`);
        dateRef.current = DateTime.fromObject(
          { year: newDate.year, month: newDate.month, day: newDate.day },
          { zone: center?.luxonTimeZone.name }
        ).toUTC();
        updateViewProps();
        return DateTime.fromObject(
          { year: newDate.year, month: newDate.month, day: newDate.day },
          { zone: center?.luxonTimeZone.name }
        ).toUTC();
      });

      // Update "today" when the selected date changes.
      const localToday = DateTime.now().startOf("day").toUTC();

      setToday(
        DateTime.fromObject(
          { year: localToday.year, month: localToday.month, day: localToday.day },
          { zone: center?.luxonTimeZone.name }
        ).toUTC()
      );
    },
    [center]
  );
  const updateCalendar = useCallback((blocks: Block[]) => {
    // group response blocks by room id
    const updatedRoomBlocks: Record<string, Block[]> = {};
    blocks.forEach(block => {
      if (updatedRoomBlocks[block.roomId]) updatedRoomBlocks[block.roomId].push(block);
      else updatedRoomBlocks[block.roomId] = [block];
    });
    const isoDate = dateRef.current.toISODate();
    // update calendar room days with new blocks
    Object.entries(updatedRoomBlocks).forEach(([roomId, updatedBlocks]) => {
      const latestBlockUpdate = updatedBlocks
        .map(block => ({ updatedDateTime: DateTime.fromISO(block.updatedAtUtc!), updatedBy: block.updatedBy }))
        .reduce((latest, block) => (latest.updatedDateTime! > block.updatedDateTime! ? latest : block));
      let roomIndex = calendarRef.current!.calendar.days[isoDate].findIndex(rd => rd.roomId === roomId);
      if (roomIndex === -1) {
        calendarRef.current!.calendar.days[isoDate].push({ roomId, blocks: updatedBlocks });
        roomIndex = calendarRef.current!.calendar.days[isoDate].length - 1;
      } else calendarRef.current!.calendar.days[isoDate][roomIndex].blocks = updatedBlocks;
      calendarRef.current!.calendar.days[isoDate][roomIndex].surgeonId = updatedBlocks.find(
        p => p.blockType == BlockType.Header && p.roomId == roomId
      )?.surgeonId;
      calendarRef.current!.calendar.days[isoDate][roomIndex].updatedAtUtc = latestBlockUpdate.updatedDateTime.toISO();
      calendarRef.current!.calendar.days[isoDate][roomIndex].updatedBy = latestBlockUpdate.updatedBy;
    });

    calendarRef.current!.calendar.days[isoDate] = calendarRef.current!.calendar.days[isoDate].filter(
      v => updatedRoomBlocks[v.roomId]
    );

    updateViewProps();
    // update lock lease
    setPutLocksRequest({
      name: "Update Lock",
      request: { method: "post", url: `centers/${simpleCenterRef.current?.id}/locks` }
    });
  }, []);
  // we should only update the major view props when all props are in sync
  const updateViewProps = useCallback(() => {
    // skip if center/calendar props are not in sync
    if (
      !simpleCenterRef.current ||
      !centerRef.current ||
      centerRef.current.id !== simpleCenterRef.current.id ||
      calendarRef.current?.centerId !== centerRef.current.id ||
      !serviceCategoriesRef.current
    )
      return;

    // skip if the date and calendar month are not in sync
    const calendarMonth = DateTime.fromISO(Object.keys(calendarRef.current.calendar.days)[0]);
    if (calendarMonth.month !== dateRef.current.month || calendarMonth.year !== dateRef.current.year) return;

    // update the surgeon start/end times for the current day
    const { year, month, day } = dateRef.current;
    const centerDay = DateTime.fromObject({ year, month, day }, { zone: centerRef.current!.luxonTimeZone });
    const surgeons = centerRef.current.surgeons.map(s => {
      const startDateTime = s.preferredStart ? DateTime.setTimeFromString(centerDay, s.preferredStart) : undefined;
      const endDateTime = s.preferredEnd ? DateTime.setTimeFromString(centerDay, s.preferredEnd) : undefined;
      return { ...s, startDateTime, endDateTime };
    });
    centerRef.current = { ...centerRef.current, surgeons };

    // TODO: only rebuild calendar if we are changing months
    const calendar = EnhancedCalendar.fromBase(
      calendarRef.current.calendar,
      centerDay.zone,
      centerRef.current!.rooms,
      serviceCategoriesRef.current!,
      centerRef.current!.surgeons
    );
    setCalendarProps({ calendar, center: centerRef.current, date: dateRef.current.plus(0) });
  }, []);
  const setCenterHoursRequest = useCallback(
    (date: string) =>
      setGetCenterHoursRequest({
        name: "Get Center Hours",
        request: { url: `centers/${simpleCenterRef.current?.id}/hours`, config: { params: { date } } }
      }),
    []
  );
  // #endregion

  // editing is disabled if the user does not own the lock for the current center
  const editDisabled = !userCenterLock || userCenterLock.centerId !== calendarProps?.center.id;

  // is loading an updated calendar
  const isLoadingUpdate =
    // calendar request is pending
    calendarIsLoading &&
    // when the center is unchanged
    calendarProps?.center.id === simpleCenter?.id &&
    // and the month is unchanged
    calendarProps?.date.month === date.month &&
    calendarProps?.date.year === date.year;

  return (
    <Box className="h-100">
      {date &&
      center &&
      simpleCenters &&
      simpleCenter &&
      serviceCategories &&
      serviceSubCategories &&
      reductionReasonsResponse ? (
        <>
          <Route path={`/planner/${PlannerViewType.DAY}`}>
            <DayView
              calendarProps={calendarProps}
              centerHours={centerHours[simpleCenter.id]}
              date={date}
              today={today}
              editDisabled={editDisabled}
              isLoading={calendarIsLoading}
              isLoadingUpdate={isLoadingUpdate}
              reductionReasons={reductionReasonsResponse}
              selectedSubCategories={selectedSubCategories}
              serviceSubCategories={serviceSubCategories}
              simpleCenter={simpleCenter}
              simpleCenters={simpleCenters}
              setCenter={onChangeCenter}
              setCenterHoursRequest={setCenterHoursRequest}
              setDate={onChangeDate}
              setSelectedSubCategories={setSelectedSubCategories}
              updateCalendar={updateCalendar}
            />
          </Route>
          <Route path={`/planner/${PlannerViewType.MONTH}`}>
            <MonthlyView
              calendarProps={calendarProps}
              centerHours={centerHours[simpleCenter.id]}
              date={date}
              today={today}
              editDisabled={editDisabled}
              isLoading={calendarIsLoading}
              isLoadingUpdate={isLoadingUpdate}
              selectedSubCategories={selectedSubCategories}
              serviceSubCategories={serviceSubCategories}
              simpleCenter={simpleCenter}
              simpleCenters={simpleCenters}
              setCenter={onChangeCenter}
              setCenterHoursRequest={setCenterHoursRequest}
              setDate={onChangeDate}
              setSelectedSubCategories={setSelectedSubCategories}
              updateCalendar={updateCalendar}
            />
          </Route>
          <Route path={`/planner/${PlannerViewType.BATCH_EDIT}`}>
            <BatchEditView
              calendarProps={calendarProps}
              centerHours={centerHours[simpleCenter.id]}
              date={date}
              today={today}
              editDisabled={editDisabled}
              isLoading={calendarIsLoading}
              isLoadingUpdate={isLoadingUpdate}
              selectedSubCategories={selectedSubCategories}
              serviceSubCategories={serviceSubCategories}
              simpleCenter={simpleCenter}
              simpleCenters={simpleCenters}
              setCalendar={setCalendar}
              setCenter={onChangeCenter}
              setCenterHoursRequest={setCenterHoursRequest}
              setDate={onChangeDate}
              setSelectedSubCategories={setSelectedSubCategories}
              updateCalendar={updateCalendar}
            />
          </Route>
        </>
      ) : simpleCenters && !simpleCenters.length ? (
        <Grid
          className="w-100 h-100"
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="h5">You have not been authorized to work with any Centers.</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              Please request access from your manager, and then re-log into the application.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">If you believe this to be in error, please contact IT.</Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="w-100 h-100 m-0" justifyContent="center" alignItems="center">
          <CircularProgress size="5rem" />
        </Grid>
      )}
    </Box>
  );
};

export default Planner;
