import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Grid, IconButton, Popover, Tooltip, Typography } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";

interface Props {
  label: string;
  readme: string[];
}

const AdministrationFormLabel: React.FC<Props> = ({ label, readme }: PropsWithChildren<Props>) => {
  const [readmeAnchor, setReadmeAnchor] = useState<HTMLElement>();

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="h6">{label}</Typography>
      </Grid>
      <Grid item>
        <Tooltip title="More Info">
          <IconButton onClick={e => setReadmeAnchor(e.currentTarget)} size="small">
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Popover
        open={Boolean(readmeAnchor)}
        anchorEl={readmeAnchor}
        onClose={() => setReadmeAnchor(undefined)}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        <Grid className="m-0" container spacing={2} sx={{ p: 2, maxWidth: "40rem" }}>
          {readme.map((paragraph, index) => (
            <Grid item key={index}>
              <Typography variant="body1">{paragraph}</Typography>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Grid>
  );
};

export default AdministrationFormLabel;
