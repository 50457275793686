import React from "react";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /** Whether the tab panel's content is rendered or not. */
  visible: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

/** Helper for rendering child components based on a state flag in tabbed implementations. */
const TabPanel: React.FC<Props> = ({ visible, children, ...rest }: Props) => {
  return (
    <div role="tabpanel" hidden={!visible} {...rest}>
      {visible && children}
    </div>
  );
};

export default TabPanel;
