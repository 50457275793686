/* eslint-disable @typescript-eslint/ban-types */
import { orange, purple } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// declare modifications to the theme object for typescript
declare module "@mui/material/styles" {
  interface Theme {
    custom: {};
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {};
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    success: true;
    error: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    captionStrikethrough: true;
  }
}

// SETUP THEME COLORS/FORMATTING ETC HERE
let theme = createTheme({
  palette: {
    primary: {
      main: "#DF822C",
      // main: orange[700], // closest MUI color
      light: orange[200]
    },
    secondary: {
      main: "#611549",
      // main: purple[900], // closest MUI color
      light: purple[50],
      contrastText: "#ffffff"
    }
  },
  shape: {
    borderRadius: 5
  }
});

// SETUP THEMED COMPONENTS HERE
theme = createTheme(theme, {
  components: {
    MuiAutocomplete: {
      defaultProps: {
        clearOnEscape: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: { whiteSpace: "nowrap" }
      }
    },
    MuiCardHeader: {
      variants: [
        {
          props: { color: "focus" },
          style: { backgroundColor: theme.palette.action.focus }
        }
      ]
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "success" },
          style: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText
          }
        },
        {
          props: { variant: "error" },
          style: {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.error.contrastText
          }
        }
      ]
    },
    MuiPaper: {
      styleOverrides: {
        elevation3: { borderRadius: "10px" }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: { padding: theme.spacing(0.5, 1) }
      }
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "captionStrikethrough" },
          style: { ...theme.typography.caption, textDecoration: "line-through" }
        }
      ]
    }
  },
  // add customized theme settings that will be shared between components
  custom: {}
});

export default theme;
