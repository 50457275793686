import { DateTime } from "luxon";

import { Center, SimpleCenter } from "./Center";

/** The model received on the payload of a center locks signal r broadcast. */
export interface SignalRCenterLock {
  /** The UTC ISO value of the of the lock's expiration. */
  Expiration: string;
  /** The key of the associated user. */
  UserId: string;
  /** The plain text display name of the associated user. */
  DisplayName: string;
}

/** The dto model used to transact center locks with the backend. */
export class CenterLock {
  /** The UTC ISO value of the of the lock's expiration. */
  expiration!: string;
  /** The key of the associated user. */
  userId!: string;
  /** The plain text display name of the associated user. */
  displayName!: string;
}

/** Expanded CenterLock model with additional utility props. */
export class EnhancedCenterLock extends CenterLock {
  /** The key of the associated center. */
  centerId: string;
  /** The plain text name of the associated center. */
  centerName: string;
  /** The full datetime object for the locks expiration. */
  expirationDateTime: DateTime;

  constructor(lock: CenterLock, center: SimpleCenter) {
    super();

    this.expiration = lock.expiration;
    this.userId = lock.userId;
    this.displayName = lock.displayName;
    this.centerId = center.id;
    this.centerName = center.name;
    this.expirationDateTime = DateTime.fromISO(lock.expiration);
  }

  static fromSignalR(payload: SignalRCenterLock, center: Center): EnhancedCenterLock {
    const { Expiration: expiration, UserId: userId, DisplayName: displayName } = payload;
    const { id: centerId, name: centerName } = center;
    return { expiration, userId, displayName, centerId, centerName, expirationDateTime: DateTime.fromISO(expiration) };
  }
}
