import { DateTime, Zone } from "luxon";

import { Room } from "./Room";
import { EnhancedSurgeon } from "./Surgeon";

/** The dto model used to transact Blocks with the backend. */
export class Block {
  /** The primary key of the block. */
  id?: string;
  /** The external primary key of the block. */
  externalId?: string;
  /** The key of the reduction reason associated with the block. */
  reductionReasonId?: string;
  /** The key of the room associated with the block. */
  roomId!: string;
  /** The key of the surgeon associated with the block. */
  surgeonId?: string;
  /** The UTC ISO value of the start time of the block. */
  startTimeUtc!: string;
  /** The UTC ISO value of the end time of the block. */
  endTimeUtc!: string;
  /** The UTC ISO value of the most recent time the block was updated. */
  updatedAtUtc?: string;
  /** The username belonging to the owner of the most recent change to the block. */
  updatedBy?: string;
  /** The type of the block. */
  blockType!: BlockType;
  /** The misc user notes for the block. */
  notes!: string;

  /** Compares the child props of two blocks, returns true if any underlying values are different, false otherwise. */
  static isChanged(block1: Block, block2: Block): boolean {
    return (
      block1.id !== block2.id ||
      block1.externalId !== block2.externalId ||
      block1.reductionReasonId !== block2.reductionReasonId ||
      block1.roomId !== block2.roomId ||
      block1.surgeonId !== block2.surgeonId ||
      block1.startTimeUtc !== block2.startTimeUtc ||
      block1.endTimeUtc !== block2.endTimeUtc ||
      block1.blockType !== block2.blockType ||
      block1.notes !== block2.notes
    );
  }
}

/** The various types of a block item on the schedule. */
export enum BlockType {
  /** A CRM block of type header. It is created by CBP to indicate the surgeon assigned to a given room day. */
  Header = 1,
  /** A CRM block of type surgery created by CBP. It indicates a potential surgery appointment opportunity. */
  Slot = 2,
  /** A CRM block of any type not already assigned here. It indicates a time during which a surgery appointment may not be scheduled. */
  Other = 3,
  /** A CRM block of type surgery which was created directly in the CRM.
   * @remarks These are ignored by CBP and should not be sent to the front-end.
   */
  CrmSlot = 4
}

/** Expanded Block model with parsed values and linked child props. */
export class EnhancedBlock extends Block {
  /** The full datetime object for the block's start time. */
  startDateTime!: DateTime;
  /** The full datetime object for the block's end time. */
  endDateTime!: DateTime;
  /** The full datetime object for the most recent time the block was updated. */
  updatedDateTime?: DateTime;
  /** The surgeon associated with the block. */
  surgeon?: EnhancedSurgeon;
  /** The room associated with the block. */
  room!: Room;

  /** Build an `Block` from an `EnhancedBlock`. */
  static toBase({
    id,
    externalId,
    reductionReasonId,
    roomId,
    surgeonId,
    startTimeUtc,
    endTimeUtc,
    blockType,
    notes
  }: EnhancedBlock): Block {
    return {
      id,
      externalId,
      reductionReasonId,
      roomId,
      surgeonId,
      startTimeUtc,
      endTimeUtc,
      blockType,
      notes
    };
  }

  /** Build an `EnhancedBlock` from a `Block` and associated required params. */
  static fromBase(
    /** The source block to build from. */
    block: Block,
    /** The luxon time zone for the center to which the block belongs. */
    zone: Zone,
    /** The room to which the block belongs. */
    room: Room,
    /** The surgeon to which the block is assigned. */
    surgeon?: EnhancedSurgeon
  ): EnhancedBlock {
    return {
      ...block,
      room,
      surgeon,
      startDateTime: DateTime.fromISO(block.startTimeUtc, { zone }),
      endDateTime: DateTime.fromISO(block.endTimeUtc, { zone })
    };
  }
}
