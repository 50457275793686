import { DayOfWeekOrder } from "../components/Helpers/Calendar/types";
import { SchedulerTimeIncrement, SchedulerTimeIncrementWidth } from "../components/Helpers/Scheduler/types";

export class UserSettings {
  /** Flag indicating if the planner should default the center to the most recent center selected. */
  defaultLastCenter: boolean;
  /** The id of the most recent center to be selected in the planner. */
  defaultLastCenterId?: string;
  /** The id of the center to be statically used as the default in the planner. */
  defaultCenterId?: string;
  /** The default view type to be used in the planner.
   * @defaultValue `"month"`.
   */
  defaultCalendarView: "day" | "week" | "month";
  /** The default weekday in the left-most column of the planner month view.
   * @defaultValue `"DayOfWeekOrder.SUNDAY_FIRST"`.
   */
  defaultDayOfWeekStart: DayOfWeekOrder;
  /** The default minute increment to be used by the scheduler in the planner day view.
   * @defaultValue `SchedulerTimeIncrement.HALF_HOUR` === `30`.
   */
  defaultSchedulerIncrement: number;
  /** The default ordering to be used by the scheduler in the planner day view.
   * @defaultValue `"room"`.
   */
  defaultSchedulerOrder: "surgeon" | "room";
  /** The default pixel width to be used by a single increment of time by the scheduler in the planner day view.
   * @defaultValue `SchedulerTimeIncrementWidth.MEDIUM`.
   */
  defaultSchedulerSize: number;
  /** The default orientation to display the scheduler in when the user visits the day view.
   * @defaultValue `"vertical"`.
   */
  defaultSchedulerOrientation: "horizontal" | "vertical";
  /** The default value to include days on the month view in which the current center is not set to be operational.
   * @defaultValue `true`.
   */
  defaultShowOnlyWorkDays: boolean;

  constructor() {
    (this.defaultLastCenter = true),
      (this.defaultLastCenterId = undefined),
      (this.defaultCenterId = undefined),
      (this.defaultCalendarView = "month"),
      (this.defaultDayOfWeekStart = DayOfWeekOrder.SUNDAY_FIRST),
      (this.defaultSchedulerIncrement = SchedulerTimeIncrement.HALF_HOUR),
      (this.defaultSchedulerOrder = "room"),
      (this.defaultSchedulerOrientation = "vertical"),
      (this.defaultSchedulerSize = SchedulerTimeIncrementWidth.MEDIUM),
      (this.defaultShowOnlyWorkDays = true);
  }
}
