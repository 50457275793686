import { Interval } from "luxon";
import { v4 as uuidv4 } from "uuid";

import { DragHandle, MinimumTimeSpanMinutes, ScheduleItem, ScheduleRow } from "../components/Helpers/Scheduler/types";
import { EnhancedAppointment } from "../models/Appointment";
import { Block, BlockType, EnhancedBlock } from "../models/Block";
import { CbpError } from "../models/CbpError";
import { EnhancedRoomDay, TimeItem } from "../models/RoomDay";
import { EnhancedSurgeon, Surgeon } from "../models/Surgeon";
import DateTime from "./DateTime";

export interface DayViewSchedulerItem extends ScheduleItem {
  /** If this item is a block then this should have a value. */
  block?: EnhancedBlock;
  /** If this item is an appointment then this should have a value. */
  appointment?: EnhancedAppointment;
  /** A request error that occurred when committing changes regarding this item. */
  error?: CbpError<Block>;
  /** Handler for when this item is clicked in the Scheduler. */
  onClick: (event: React.MouseEvent<HTMLElement>, item: DayViewSchedulerItem) => void;
}
export class RoomRow extends EnhancedRoomDay implements ScheduleRow<DayViewSchedulerItem> {
  // props implemented from ScheduleRow
  /** uuidv4 for comparison. */
  id!: string;
  /** The schedule items to be rendered on this row. */
  items!: DayViewSchedulerItem[];

  // extended props
  date!: DateTime;
  orderBy!: "room" | "surgeon";
  fitServiceWeight!: number;

  static comparator(type: "room" | "surgeon" = "room") {
    return (row1: RoomRow, row2: RoomRow): number => {
      if (type === "surgeon") {
        if (row1.surgeon && row2.surgeon)
          return `${Surgeon.GetName(row1.surgeon)}` < `${Surgeon.GetName(row2.surgeon)}` ? -1 : 1;
        else if (row1.surgeon && !row2.surgeon) return -1;
        else if (!row1.surgeon && row2.surgeon) return 1;
      }
      return row1.room.name < row2.room.name ? -1 : 1;
    };
  }

  /** Identify obstacles in a RoomDay of the specified types.
   * `"block"` is any block object, slots or otherwise.
   * `"surgery"` is any appointment that has at least one enabled service subcategory.
   * `"partial-surgery"` is any appointment that has at least
   */
  static getObstacles(
    /** The RoomRow to be evaluated for scheduling obstacles. */
    roomRow: RoomRow,
    /** The types of schedule items we want identified as obstacles. */
    params: ("block" | "appointment" | "surgery" | "non-surgery")[]
  ): TimeItem[] {
    const filteredBlocksRow: RoomRow = {
      ...roomRow,
      // only consider blocks who are not the moving item
      blocks: roomRow.items.reduce((collector, item) => {
        if (item.block) collector.push(item.block);
        return collector;
      }, [] as EnhancedBlock[])
    };
    return EnhancedRoomDay.getObstacles(filteredBlocksRow, params);
  }

  static canDrop(row: RoomRow, movingItem: DayViewSchedulerItem, time: DateTime): boolean {
    // disallow changing rows
    if (movingItem?.block?.roomId !== row.room.id || !movingItem?.block?.surgeonId) return false;
    // remove the item being moved from the list of obstacles
    const filteredRow = { ...row, items: row.items.filter(item => item.id !== movingItem.id) };
    const obstacles = RoomRow.getObstacles(filteredRow, ["block", "non-surgery"]);
    return !RoomRow.isObstacleCollision(obstacles, time, movingItem.timeSpanMinutes);
  }

  static canResize(
    row: RoomRow,
    movingItem: { id: string; block: { roomId: string }; startDateTime: DateTime; endDateTime: DateTime },
    dragHandle: DragHandle,
    time: DateTime
  ): boolean {
    // disallow changing rows
    if (movingItem?.block?.roomId !== row.room.id) return false;
    // remove the item being moved from the list of obstacles
    const filteredRow = { ...row, items: row.items.filter(item => item.id !== movingItem.id) };
    const obstacles = RoomRow.getObstacles(filteredRow, ["block", "non-surgery"]);
    if (dragHandle === DragHandle.LEFT) {
      // disallow drag in the wrong direction
      if (time >= movingItem.endDateTime) return false;
      return !RoomRow.isObstacleCollision(
        obstacles,
        time,
        Interval.fromDateTimes(time, movingItem.endDateTime).length("minutes")
      );
    } else {
      // adjust the target to the ending of the targeted time span
      const rightTime = time.plus({ minutes: MinimumTimeSpanMinutes });
      // disallow drag in the wrong direction
      if (rightTime < movingItem.startDateTime) return false;
      // disallow if a collision occurs
      return !RoomRow.isObstacleCollision(
        obstacles,
        movingItem.startDateTime,
        Interval.fromDateTimes(movingItem.startDateTime, rightTime).length("minutes")
      );
    }
  }

  static fromBaseArray(
    roomDays: EnhancedRoomDay[],
    date: DateTime,
    orderBy: "room" | "surgeon",
    errors: CbpError<Block>[],
    onClick: (event: React.MouseEvent<HTMLElement>, item: DayViewSchedulerItem) => void
  ): RoomRow[] {
    const rows: RoomRow[] = roomDays.map(roomDay => {
      const apptItems: DayViewSchedulerItem[] = roomDay.appointments.map(appt => {
        const timeSpanMinutes = Interval.fromDateTimes(appt.startDateTime, appt.endDateTime).length("minutes");
        const item: DayViewSchedulerItem = {
          id: uuidv4(),
          appointment: appt,
          endDateTime: appt.endDateTime,
          startDateTime: appt.startDateTime,
          timeSpanMinutes,
          zIndex: 1,
          onClick
        };
        return item;
      });
      const blockItems: DayViewSchedulerItem[] = roomDay.blocks
        .filter(b => b.blockType !== BlockType.Header)
        .map(block => {
          const timeSpanMinutes = Interval.fromDateTimes(block.startDateTime, block.endDateTime).length("minutes");
          // identify appointments linked to the block
          const linkedAppt = roomDay.appointments.some(p => p.slotId == block.id);
          const item: DayViewSchedulerItem = {
            id: uuidv4(),
            block: { ...block },
            endDateTime: block.endDateTime.plus({ minutes: 0 }),
            startDateTime: block.startDateTime.plus({ minutes: 0 }),
            timeSpanMinutes,
            // TODO: Figure out why types are not compatible and remove type check skip
            canDrop: (block.blockType === BlockType.Slot && !linkedAppt ? RoomRow.canDrop : undefined) as never,
            canResize: (block.blockType === BlockType.Slot && !linkedAppt ? RoomRow.canResize : undefined) as never,
            error: block.id ? errors.find(e => e.data?.id === block.id) : undefined,
            onClick
          };
          return item;
        });

      const roomRow = {
        ...roomDay,
        id: uuidv4(),
        date,
        fitServiceWeight: 0,
        items: apptItems.concat(blockItems),
        orderBy,
        room: { ...roomDay.room }
      };

      return roomRow;
    });
    rows.sort(RoomRow.comparator(orderBy));
    return rows;
  }

  /** Get the interval which contains all schedule items and surgeon preferred work hours in the given props. */
  static getTimeRange(rows: RoomRow[], surgeons: EnhancedSurgeon[], date: DateTime): Interval {
    const startTimes =
      // aggregate block start times
      rows
        .reduce((collector, row) => collector.concat(row.items.map(i => i.startDateTime)), [] as DateTime[])
        // aggregate surgeon preferred start times
        .concat(surgeons.map(s => s.startDateTime).filter(time => Boolean(time)) as DateTime[]);
    const endTimes =
      // aggregate block end times
      rows
        .reduce((collector, row) => collector.concat(row.items.map(i => i.endDateTime)), [] as DateTime[])
        // aggregate surgeon preferred end times
        .concat(surgeons.map(s => s.endDateTime).filter(time => Boolean(time)) as DateTime[]);
    // take the earliest start, or default of 8 AM.
    const minStart = startTimes.length ? DateTime.min(...startTimes) : date.set({ hour: 8 });
    // take the latest end, or default of 5 PM.
    const maxEnd = endTimes.length ? DateTime.max(...endTimes) : date.set({ hour: 18 });
    // take the floor to the hour of the start
    return Interval.fromDateTimes(minStart.set({ minute: 0 }).setZone(date.zone), maxEnd.setZone(date.zone));
  }
}

export interface Schedule {
  /** The maximum start end end times bounding the schedule.
   * This should always be the center's operating hours for the given schedule day.
   */
  bounds: Interval;
  /** The index of the row currently in place mode. Unset if no rows are in place mode. */
  placeModeRowIndex?: number;
  /** The placeholder for the first time clicked during place mode. */
  placeModeFirstTarget?: DateTime;
  /** The rendered state of the scheduler rows. */
  rows: RoomRow[];
}
