import { DateTime, Zone } from "luxon";

import { Room } from "./Room";
import { EnhancedRoomDay, RoomDay, roomDayComparator } from "./RoomDay";
import { ServiceCategory } from "./ServiceCategory";
import { EnhancedSurgeon } from "./Surgeon";

/** The dto model used to transact Calendars with the backend. */
export class Calendar {
  /** The goal number of surgeries to perform for the given calendar month. */
  centerGoal?: number;
  /** The room day information keyed by the UTC ISO value of the associated calendar date. */
  days!: Record<string, RoomDay[]>;
  /** The UTC ISO value of the most recent aggregate time a calendar element was updated. */
  updatedAtUtc?: string;
  /** The username belonging to the owner of the most recent aggregate change to a calendar element. */
  updatedBy?: string;
}

/** Expanded Calendar model with additional utility props. */
export class EnhancedCalendar extends Calendar {
  /** The enhanced room day information keyed by the UTC ISO date value of the associated calendar day. */
  days!: Record<string, EnhancedRoomDay[]>;
  /** The datetime object of the most recent aggregate time a calendar element was updated. */
  updatedDateTime?: DateTime;

  static fromBase(
    base: Calendar,
    centerLuxonTimeZone: Zone,
    rooms: Room[],
    enabledServiceCategories: ServiceCategory[],
    surgeons: EnhancedSurgeon[]
  ): EnhancedCalendar {
    // Enhance the calendar w/ surgeons and rooms, distinct by room and surgeon
    const enhancedCalendar: EnhancedCalendar = {
      days: {},
      updatedBy: base.updatedBy,
      updatedDateTime: base.updatedAtUtc ? DateTime.fromISO(base.updatedAtUtc) : undefined,
      centerGoal: base.centerGoal
    };
    Object.keys(base.days).forEach(key => {
      const enhancedRoomDays: EnhancedRoomDay[] = base.days[key].map(rd => {
        const room = rooms.find(r => r.id === rd.roomId);
        if (!room) throw `Unable to locate room with id {${rd.roomId}} on day {${key}}.`;
        return new EnhancedRoomDay(rd, centerLuxonTimeZone, room, enabledServiceCategories, surgeons);
      });
      // fill room days that were missing data
      rooms
        .filter(room => !enhancedRoomDays.some(rd => rd.roomId === room.id))
        .forEach(room =>
          enhancedRoomDays.push({ roomId: room.id, room, appointments: [], appointmentsWeight: 0, blocks: [] })
        );
      enhancedCalendar.days[key] = enhancedRoomDays.sort(roomDayComparator);
    });
    return enhancedCalendar;
  }
}
