import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { Box, Chip, Grid, Tooltip, Typography, styled } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";

import { BlockType } from "../../../models/Block";
import { EnhancedRoomDay } from "../../../models/RoomDay";
import { Surgeon } from "../../../models/Surgeon";
import CalendarDayCard, { StyledCalendarDayCardProps } from "../../Helpers/Calendar/CalendarDayCard";
import { DayItemProps } from "../../Helpers/Calendar/types";

const StyledCalendarDayCard = styled(CalendarDayCard)<StyledCalendarDayCardProps>(({ color }) => ({
  ...(color !== "outOfMonth" && { "&.MuiCard-root": { cursor: "pointer" } })
}));

export interface MonthDayItem {
  /** The weight of a service that we want to attempt to fit into a surgeon's capacity. */
  fitServiceWeight?: number;
  /** The room day data for the given day. */
  roomDays: EnhancedRoomDay[];
  /** Callback when this card is clicked. */
  onClick: () => void;
}

const buildInstructionalMessage = (
  slotCount: number,
  surgeryCount: number,
  surgeryTotalWeight: number,
  surgeonWeight: number,
  fitServiceWeight?: number
): string => {
  const overweight = surgeryTotalWeight > surgeonWeight;
  const overflowing = surgeryCount > slotCount;

  const overWeightDiff = Math.abs(surgeonWeight - surgeryTotalWeight);
  const overflowDiff = Math.abs(slotCount - surgeryCount);
  const overflowSlotSuffix = overflowDiff === 1 ? "" : "s";
  const overflowSurgerySuffix = overflowDiff === 1 ? "y" : "ies";
  if (overweight || overflowing) {
    if (overweight && overflowing)
      return `This room assignment is exceeding its slot count by ${overflowDiff} and the surgeon has exceeded weight allowance by ${overWeightDiff}. Surgeries should be removed, or ${overflowDiff} slot${overflowSlotSuffix} should be added and surgery services should be changed.`;
    if (overweight)
      return `The surgeon in this room has has exceeded weight allowance by ${overWeightDiff}. Surgeries should be removed, or surgery services should be changed.`;
    return `This room assignment is exceeding its slot count by ${overflowDiff}. Surgeries should be removed, or ${overflowDiff} slot${overflowSlotSuffix} should be added.`;
  }
  const metCapacity = surgeryCount === slotCount;
  const metWeight = surgeryTotalWeight === surgeonWeight && surgeonWeight > 0;
  if (metCapacity || metWeight) {
    if (metCapacity && metWeight) return "This room assignment has met both slot goal and surgeon weight allowance.";
    if (metCapacity) return "This room assignment has met its slot goal.";
    return "This room assignment has met its surgeon weight allowance.";
  }

  if (fitServiceWeight !== undefined) {
    const targetOverweight = fitServiceWeight + surgeryTotalWeight > surgeonWeight;
    if (targetOverweight)
      return `This room has ${overflowDiff} free slot${overflowSlotSuffix}, but cannot fit an additional surgery weighing ${fitServiceWeight}.`;
    return `This room has ${overflowDiff} free slot${overflowSlotSuffix}, and can fit the specified additional surgery weighing ${fitServiceWeight}.`;
  }
  if (surgeryTotalWeight < surgeonWeight)
    return `This room assignment has ${overflowDiff} free slot${overflowSlotSuffix} and the surgeon has ${overWeightDiff} free weight. You can add ${overflowDiff} surger${overflowSurgerySuffix} and/or additional weight here.`;
  return `This room assignment has ${overflowDiff} free slot${overflowSlotSuffix}. You can add ${overflowDiff} surger${overflowSurgerySuffix} with 0 weight cost here.`;
};

const MonthlyViewDayCard: React.FC<DayItemProps<MonthDayItem>> = ({
  data,
  date,
  today,
  ...rest
}: DayItemProps<MonthDayItem>) => {
  // only display rows for room days containing blocks of type "slot" or appointments.
  const displayRows =
    data?.roomDays
      ?.map(rowDay => ({
        ...rowDay,
        blocks: rowDay.blocks.filter(b => b.blockType === BlockType.Slot),
        appointments: rowDay.appointments?.filter(
          a =>
            // Display all surgeries regardless of assignment.
            a.isSurgery
        )
      }))
      .filter(rowDay => rowDay.blocks.length || rowDay.appointments?.length) || [];

  return (
    <StyledCalendarDayCard {...rest} date={date} today={today} data={data as never} onClick={data?.onClick}>
      {displayRows.map((roomDay, index) => {
        const surgeonWeightCapacity = roomDay.surgeon?.weightCategory?.weightValue || 0;
        const isSurgeonTerminated = roomDay.surgeon?.terminationDateTime && roomDay.surgeon.terminationDateTime <= date;
        const chipVariant =
          // if surgeon will have been terminated, color as over-capacity
          isSurgeonTerminated ||
          // or if appointments exceed the surgeon's slots, color as over-capacity
          roomDay.appointments.length > roomDay.blocks.length
            ? "warning"
            : // otherwise, if the surgeon has remaining slots
            roomDay.appointments.length < roomDay.blocks.length
            ? // and if the slots are in the past and were missed color as missed, otherwise under-capacity
              date.toLocal() < today
              ? "error"
              : "success"
            : // finally, color as default
              undefined;

        // if capacity exceeds the surgeon's maximum, show the over-weight token
        const showOverweightIcon = roomDay.appointmentsWeight + (data?.fitServiceWeight || 0) > surgeonWeightCapacity;
        const chipNumerator = roomDay.blocks.length - roomDay.appointments.length;
        const chipDenominator = roomDay.blocks.length;
        return (
          <Grid item xs key={index}>
            <Grid container justifyContent="space-between" wrap="nowrap">
              <Grid item>
                <Typography variant={roomDay?.surgeon && isSurgeonTerminated ? "captionStrikethrough" : "caption"}>
                  {roomDay.surgeon
                    ? `${Surgeon.GetName(roomDay.surgeon)} - ${roomDay.room.name}`
                    : `${roomDay.room.name}`}
                </Typography>
              </Grid>
              {roomDay.surgeon ? (
                <Grid item>
                  <Tooltip
                    arrow
                    placement="right"
                    title={
                      <>
                        <div>{`${roomDay.appointments.length} Surger${
                          roomDay.appointments.length !== 1 ? "ies" : "y"
                        }, ${roomDay.blocks.length} Slot${roomDay.blocks.length !== 1 ? "s" : ""}`}</div>
                        <div>{`${roomDay.appointmentsWeight} Surgical Appointment Weight, ${
                          roomDay.surgeon.weightCategory?.weightValue || 0
                        } Surgeon Weight`}</div>
                        <Box sx={{ opacity: 0 }}>spacer</Box>
                        {isSurgeonTerminated ? (
                          <div>{`Surgeon was terminated effective ${roomDay.surgeon.terminationDateTime?.toLocaleString(
                            DateTime.DATE_MED
                          )}`}</div>
                        ) : (
                          <div>
                            {buildInstructionalMessage(
                              roomDay.blocks.length,
                              roomDay.appointments.length,
                              roomDay.appointmentsWeight,
                              surgeonWeightCapacity,
                              data?.fitServiceWeight
                            )}
                          </div>
                        )}
                      </>
                    }
                  >
                    <Chip
                      label={
                        <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                          {`${chipNumerator}/${chipDenominator}`}
                          {showOverweightIcon && (
                            <FitnessCenterIcon color="secondary" sx={{ ml: 0.35, fontSize: "0.8rem" }} />
                          )}
                        </Box>
                      }
                      color={chipVariant}
                      size="small"
                    />
                  </Tooltip>
                </Grid>
              ) : (
                <Grid item>
                  <Tooltip
                    arrow
                    placement="right"
                    title={
                      <>
                        <div>{`${roomDay.appointments.length} Surger${
                          roomDay.appointments.length !== 1 ? "ies" : "y"
                        }, ${roomDay.blocks.length} Slot${roomDay.blocks.length !== 1 ? "s" : ""}`}</div>
                        <Box sx={{ opacity: 0 }}>spacer</Box>
                        <div>
                          {"This room is not assigned to a Surgeon"}
                          {roomDay.appointments.length
                            ? `, but has ${roomDay.appointments.length === 1 ? "a " : ""}Surger${
                                roomDay.appointments.length !== 1 ? "ies" : "y"
                              } for ${
                                roomDay.appointments
                                  .filter(p => p.isSurgery)
                                  .flatMap(value => value.surgeon)
                                  ?.at(0)?.firstName
                              } ${
                                roomDay.appointments
                                  .filter(p => p.isSurgery)
                                  .flatMap(value => value.surgeon)
                                  ?.at(0)?.lastName
                              }.`
                            : `.`}
                        </div>
                      </>
                    }
                  >
                    <Chip
                      label={
                        <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                          {`${chipNumerator}/${chipDenominator}`}
                        </Box>
                      }
                      color="warning"
                      size="small"
                    />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      })}
    </StyledCalendarDayCard>
  );
};

export default MonthlyViewDayCard;
