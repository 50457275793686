import { Chip, Grid, Paper, Typography } from "@mui/material";
import React, { ReactNode } from "react";

import Logo from "../../assets/CLogo-lowRez.png";

const LoginCard: React.FC<{ children?: ReactNode }> = ({ children }: { children?: ReactNode }) => {
  return (
    <Paper sx={{ height: "23rem", width: "42rem", padding: "1rem 2rem 1rem 2rem" }} elevation={3}>
      <Grid
        className="h-100"
        container
        direction="column"
        alignItems="center"
        wrap="nowrap"
        justifyContent="space-between"
      >
        <Grid container item justifyContent="center" alignItems="center" spacing={1} wrap="nowrap">
          <Grid item>
            <img src={Logo} style={{ width: "6rem" }} />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ ml: -1 }}>
              enter Bandwidth Planning
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs alignItems="center">
          {children}
        </Grid>
        <Grid item>
          <Chip label={`${process.env.REACT_APP_ENVNAME} - ${process.env.REACT_APP_VERSION_NO}`} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoginCard;
