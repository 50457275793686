import { Autocomplete, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { User } from "../../../models/User";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import { AdminTool } from "../Administration";
import AdministrationFormLabel from "../AdministrationFormLabel";
import AdministrationTool from "../AdministrationTool";

interface CenterGridRow {
  id: string;
  group: string;
  center: string;
  permissions: string;
}
interface AppGridRow {
  id: string;
  group: string;
  permissions: string;
}
const centersGridColumns: GridColumns = [
  {
    field: "group",
    headerName: "Active Directory Group",
    disableColumnMenu: true,
    minWidth: 200,
    editable: false
  },
  {
    field: "center",
    headerName: "Center",
    disableColumnMenu: true,
    minWidth: 200,
    editable: false
  },
  {
    field: "permissions",
    headerName: "Permissions",
    disableColumnMenu: true,
    minWidth: 300,
    editable: false
  }
];

const appGridColumns: GridColumns = [
  {
    field: "group",
    headerName: "Active Directory Group",
    disableColumnMenu: true,
    minWidth: 200,
    editable: false
  },
  {
    field: "permissions",
    headerName: "Permissions",
    disableColumnMenu: true,
    minWidth: 300,
    editable: false
  }
];

const toolReadme = [
  "The Inspect User Permissions tool allows you to inspect the assigned permissions for a given user.",
  "If a user is a member of an active directory group with assigned permissions, the user gains all of those permissions.",
  "If a user must be granted access to a center, they must be associated with at least one group with permissions for that center.",
  "If a user must be denied access to a center, they must not be associated any groups which provide permissions for that center.",
  'The contents of a table can be copied to the pastebin by clicking the relevant "copy" button.',
  "Note: Changes to a user's active directory groups will immediately update in this view, however the user must log out and in again for changed to become effective."
];

const InspectUserPermissions: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState<{ appRows: AppGridRow[]; centerRows: CenterGridRow[]; unusedGroups: string[] }>({
    appRows: [],
    centerRows: [],
    unusedGroups: []
  });
  const [selectedUser, setSelectedUser] = useState<User>();

  // #region GET All Users
  const [usersRequest] = useState({ name: "Get All Users", request: { url: "users" } });
  const { response: getUsersResponse } = useCbp<User[]>(usersRequest);
  // #endregion

  // #region Get User
  const [userRequest, setUserRequest] = useState<UseCbpProps>();
  const { response: getUserResponse, isLoading } = useCbp<User>(userRequest);
  useEffect(() => {
    if (getUserResponse) {
      // map user's permissions into table rows data
      const appRows: AppGridRow[] = [];
      let centerRows: CenterGridRow[] = [];
      const unusedGroups: string[] = [];
      getUserResponse.activeDirectoryGroups?.forEach(group => {
        centerRows = centerRows.concat(
          group.centerPermissions.map(cp => ({
            id: `${group.id}/${cp.center.id}`,
            center: cp.center.name,
            group: group.name,
            permissions: `${
              cp.isCapacityEditor
                ? cp.isCenterEditor
                  ? "Schedule, Center Settings"
                  : "Schedule"
                : cp.isCenterEditor
                ? "Center Settings"
                : ""
            }`
          }))
        );
        if (group.applicationPermissions)
          appRows.push({
            id: group.id,
            group: group.name,
            permissions: `${
              group.applicationPermissions.isAdminEditor
                ? group.applicationPermissions.isApplicationEditor
                  ? "Administrator, Manager"
                  : "Manager"
                : group.applicationPermissions.isApplicationEditor
                ? "Administrator"
                : ""
            }`
          });
        if (!group.applicationPermissions && !group.centerPermissions.length) unusedGroups.push(group.name);
      });
      setRows({ appRows, centerRows, unusedGroups });
    }
  }, [getUserResponse]);
  // #endregion

  // #region Actions
  const onUserSelect = (_: unknown, user: User | null) => {
    setSelectedUser(user || undefined);
    if (user) setUserRequest({ name: "Get User", request: { url: `users/${user.id}` } });
  };
  const onCopy = (copyTable: "Center Permissions" | "Application Permissions") => {
    let output = "";
    if (copyTable === "Application Permissions") {
      output += `Application Permissions for ${selectedUser?.displayName}`;
      output += "\nActive Directory Group\tPermissions";
      output = rows.appRows.reduce((collector, row) => collector + `\n${row.group}\t${row.permissions}`, output);
    } else {
      output += `Center Permissions for ${selectedUser?.displayName}`;
      output += "\nActive Directory Group\tCenter\tPermissions";
      output = rows.centerRows.reduce(
        (collector, row) => collector + `\n${row.group}\t${row.center}\t${row.permissions}`,
        output
      );
    }
    try {
      navigator.clipboard.writeText(output);
      enqueueSnackbar(`${copyTable} Copied`, { variant: "info" });
    } catch (e) {
      console.log(`Unhandled Exception: ${e}`);
      enqueueSnackbar("An unknown error occurred, please contact IT.", { variant: "error" });
    }
  };
  //#endregion

  // show spinner while loading
  useEffect(() => setIsContentLoading(!getUsersResponse), [getUsersResponse]);

  return (
    <AdministrationTool title="Inspect User Permissions" readme={toolReadme} sx={{ minWidth: "60rem" }}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Autocomplete
            options={getUsersResponse || []}
            renderInput={params => <TextField {...params} label="User" />}
            getOptionLabel={user => user.displayName}
            value={selectedUser || null}
            onChange={onUserSelect}
            sx={{ minWidth: "20rem" }}
          />
        </Grid>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid container item xs direction="column" spacing={2} sx={{ width: "100%" }}>
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <AdministrationFormLabel
                    label="Application Permissions"
                    readme={[
                      "The appllication permissions granted to the user by their active directory group memberships."
                    ]}
                  />
                </Grid>
                <Grid item>
                  <Button onClick={() => onCopy("Application Permissions")} disabled={!rows.appRows.length}>
                    Copy
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <DataGrid style={{ minHeight: "15rem" }} rows={rows.appRows} columns={appGridColumns} hideFooter />
              </Grid>
            </Grid>
            <Grid item xs container direction="column" spacing={2} sx={{ width: "100%" }}>
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <AdministrationFormLabel
                    label="Center Permissions"
                    readme={["The center permissions granted to the user by their active directory group memberships."]}
                  />
                </Grid>
                <Grid item>
                  <Button onClick={() => onCopy("Center Permissions")} disabled={!rows.centerRows.length}>
                    Copy
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <DataGrid
                  style={{ minHeight: "15rem" }}
                  rows={rows.centerRows}
                  columns={centersGridColumns}
                  hideFooter
                />
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2} sx={{ width: "100%" }}>
              <Grid item>
                <AdministrationFormLabel
                  label="Group Memberships Without Assigned Permissions"
                  readme={[
                    "These are groups to which the user is a member that do not have any assigned permissions in CBP."
                  ]}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {rows.unusedGroups.reduce((collector, name) => (collector ? `${collector}, ${name}` : name), "")}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </AdministrationTool>
  );
};

export default InspectUserPermissions;
