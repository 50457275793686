import { Box, Grid, Typography, styled } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

import CalendarDayCard from "./CalendarDayCard";
import { CalendarClassKeys, CalendarProps, DayItemProps, DayOfWeekOrder, WeekdayKey } from "./types";

const weekdays: WeekdayKey[] = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
const dayOfWeekMappings: Record<DayOfWeekOrder, Record<number, number>> = {
  [DayOfWeekOrder.SUNDAY_FIRST]: { 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 7, 7: 1 },
  [DayOfWeekOrder.MONDAY_FIRST]: { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7 },
  [DayOfWeekOrder.SATURDAY_FIRST]: { 1: 3, 2: 4, 3: 5, 4: 6, 5: 7, 6: 1, 7: 2 }
};
const dayOfWeekIndexToKey: Record<WeekdayKey, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
};

const UnstyledCalendar = <T,>({
  data = {},
  today,
  DayItem = CalendarDayCard,
  dayOfWeekOrder = DayOfWeekOrder.MONDAY_FIRST,
  hideWeekdays,
  month
}: CalendarProps<T>): JSX.Element => {
  const dayOfWeekMap = dayOfWeekMappings[dayOfWeekOrder];
  const [weeks, setWeeks] = useState<DayItemProps<T>[][]>([]);
  const [dayHeaders, setDayHeaders] = useState<string[]>([]);

  // build the calendar day grid
  useEffect(() => {
    const newWeeks: DayItemProps<T>[][] = [[]];

    // fill days from month prior to target month
    let date = month.plus({ hour: 0 });
    for (let x = 1; x < dayOfWeekMap[month.weekday]; x++) {
      date = date.minus({ days: 1 });
      newWeeks[0].unshift({ data: data[date.toISO()], date, today, isInMonth: false });
    }

    // fill days in the target month
    date = month.plus({ days: 0 });
    let lastWeekIndex = 0;
    while (date.month === month.month) {
      lastWeekIndex = newWeeks.length - 1;
      newWeeks[lastWeekIndex].push({ data: data[date.toISO()], date, today, isInMonth: true });
      date = date.plus({ days: 1 });
      if (date.month === month.month && newWeeks[lastWeekIndex].length === 7) newWeeks.push([]);
    }

    // fill days from month after target month
    while (newWeeks[lastWeekIndex].length < 7) {
      newWeeks[lastWeekIndex].push({ data: data[date.toISO()], date, today, isInMonth: false });
      date = date.plus({ days: 1 });
    }
    setWeeks(newWeeks);
  }, [dayOfWeekMap, hideWeekdays, month]);

  // build the day of the week header rows
  useEffect(
    () =>
      setDayHeaders(
        Object.entries(dayOfWeekMap)
          .sort((first, second) => (first[1] > second[1] ? 1 : -1))
          .map(([first]) => weekdays[Number(first) - 1])
          .filter(name => !hideWeekdays?.some(d => d === name))
          .map(name => `${name[0].toUpperCase()}${name.substring(1)}`)
      ),
    [dayOfWeekMap, hideWeekdays]
  );

  // remove hidden days and empty weeks
  const filteredWeeks: DayItemProps<T>[][] = [];
  weeks.forEach(week => {
    const filteredWeek: DayItemProps<T>[] = [];
    week.forEach(day => {
      if (!hideWeekdays?.some(d => day.date.weekday === dayOfWeekIndexToKey[d])) filteredWeek.push(day);
    });
    if (filteredWeek.some(day => day.isInMonth)) filteredWeeks.push(filteredWeek);
  });
  const now = DateTime.now();

  return (
    <Grid className="h-100" container direction="column" spacing={2} wrap="nowrap">
      <Grid className="w-100" item>
        <Grid container spacing={1}>
          {dayHeaders.map(name => (
            <Grid item xs key={name}>
              <Box className={CalendarClassKeys.dayOfWeekHeader}>
                <Typography align="center">{name}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid className="h-100" container direction="column" spacing={1} wrap="nowrap">
          {filteredWeeks.map((week, weekIndex) => (
            <Grid item container xs spacing={1} wrap="nowrap" key={weekIndex}>
              {week.map((dayProps, dayIndex) => (
                <Grid item xs key={`${weekIndex}/${dayIndex}`}>
                  <DayItem
                    {...dayProps}
                    data={data[dayProps.date.toISODate()] as never}
                    color={
                      !dayProps.isInMonth
                        ? "outOfMonth"
                        : dayProps.date.day === now.day &&
                          dayProps.date.month === now.month &&
                          dayProps.date.year === now.year
                        ? "today"
                        : "inMonth"
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

/** Styled Calendar*/
// TODO Figure out why this doesn't work
const Calendar = styled(UnstyledCalendar)<CalendarProps>(({ theme }) => ({
  [`& .${CalendarClassKeys.dayOfWeekHeader}`]: {
    p: 1,
    boxShadow: theme.shadows[1],
    borderRadius: 2
  }
}));

export default Calendar;
