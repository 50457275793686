import { Interval } from "luxon";
import React from "react";
import { useDragLayer } from "react-dnd";

import SchedulerRow from "./SchedulerRow";
import { DragHandle, ScheduleItem, ScheduleRow, SchedulerBodyProps } from "./types";

const SchedulerBody = <TItem extends ScheduleItem, TRow extends ScheduleRow<TItem>>({
  disabled,
  rows,
  orientation,
  ScheduleItem,
  timeIncrement,
  timeIncrementWidth,
  timeRange,
  zIndexMap,
  placeModeFirstTarget,
  placeModeRowIndex,
  canPlaceModeClick,
  formatZoneLabel,
  onPlaceModeClick,
  onDrop,
  onResize
}: SchedulerBodyProps<TItem, TRow>): JSX.Element => {
  const { dragItem, dragType } = useDragLayer(monitor => ({
    dragItem: (monitor.getItem() as ScheduleItem | null) || undefined,
    dragType: (monitor.getItemType() as DragHandle | "item" | null) || undefined
  }));

  return (
    <>
      {rows.map((row, rowIndex) => (
        <SchedulerRow
          dragItemType={dragType}
          isPlaceModeActive={placeModeRowIndex === rowIndex}
          orientation={orientation}
          placeModeFirstTarget={placeModeFirstTarget}
          timeIncrement={15}
          timeIncrementWidth={(15 / timeIncrement) * timeIncrementWidth}
          timeRange={timeRange}
          canDrop={
            dragType === "item"
              ? dragItem?.canDrop && (time => dragItem!.canDrop!(row, dragItem, time))
              : dragType
              ? dragItem?.canResize && (time => dragItem!.canResize!(row, dragItem, dragType, time))
              : undefined
          }
          canPlaceModeClick={canPlaceModeClick}
          formatZoneLabel={formatZoneLabel}
          onPlaceModeClick={onPlaceModeClick ? targetDateTime => onPlaceModeClick(rowIndex, targetDateTime) : undefined}
          onDrop={
            dragType === "item"
              ? onDrop && ((startDateTime, item) => onDrop(rowIndex, startDateTime, item))
              : dragType
              ? onResize && ((startDateTime, item) => onResize(rowIndex, startDateTime, item, dragType))
              : undefined
          }
          key={rowIndex}
        >
          {row.items.map((item, itemIndex) => {
            const gapMinutes =
              Interval.fromDateTimes(timeRange.start, item.startDateTime).length("minutes") -
              row.items.slice(0, itemIndex).reduce((sum, item) => sum + item.timeSpanMinutes, 0);

            return (
              <ScheduleItem
                gapMinutes={gapMinutes}
                item={item}
                itemIndex={itemIndex}
                isMovingDisabled={disabled || !onDrop || placeModeRowIndex !== undefined}
                isResizingDisabled={disabled || !onResize || placeModeRowIndex !== undefined}
                orientation={orientation}
                rowIndex={rowIndex}
                timeIncrement={timeIncrement}
                timeIncrementWidth={timeIncrementWidth}
                zIndex={item.zIndex && zIndexMap[item.zIndex]}
                key={item.id}
              />
            );
          })}
        </SchedulerRow>
      ))}
    </>
  );
};

export default SchedulerBody;
