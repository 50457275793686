import {
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { Interval } from "luxon";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { BlockType } from "../../../models/Block";
import { ReductionReason } from "../../../models/ReductionReason";
import { EnhancedRoomDay } from "../../../models/RoomDay";
import { EnhancedSurgeon, Surgeon } from "../../../models/Surgeon";
import DateTime from "../../../types/DateTime";
import { DayViewFormProps } from "../../../types/DayViewFormProps";
import { DayViewSchedulerItem, RoomRow } from "../../../types/DayViewSchedulerTypes";
import { ScheduleItem } from "../../Helpers/Scheduler/types";
import ValidationButton from "../../Helpers/ValidationButton";

interface EditSurgeonForm {
  slotCount: number;
  reductionReason?: ReductionReason;
  surgeon?: EnhancedSurgeon;
}

interface Props extends DayViewFormProps {
  /** Properties that are required to create new scheduler items. */
  itemProps: Pick<DayViewSchedulerItem, "onClick">;
  /** The reduction reasons that can be selected when reducing slots. */
  reductionReasons: ReductionReason[];
  /** The index of the row this form governs. */
  rowIndex: number;
  /** The surgeons which can be switched to. */
  surgeons: EnhancedSurgeon[];
  /** Callback when this form is closed. */
  onClose: () => void;
}

const newBlockDurationMinutes = 150;

/** Constructs a new slot schedule item. Restores a reduced slot if provided. */
const buildSlotItem = (
  /** Mandatory props for creating a new scheduler item. */
  itemProps: Props["itemProps"],
  /** The end time of the new item. */
  endDateTime: DateTime,
  /** The start time of the new item. */
  startDateTime: DateTime,
  /** The row the item will be added to. */
  roomRow: RoomRow,
  /** A reduced slot that should be reused. */
  reducedSlot?: DayViewSchedulerItem
): DayViewSchedulerItem =>
  reducedSlot
    ? {
        ...reducedSlot,
        startDateTime,
        endDateTime,
        timeSpanMinutes: Interval.fromDateTimes(startDateTime, endDateTime).length("minutes"),
        block: {
          ...reducedSlot.block!,
          endDateTime,
          endTimeUtc: endDateTime.toISO(),
          reductionReasonId: undefined,
          startDateTime,
          startTimeUtc: startDateTime.toISO()
        }
      }
    : {
        id: uuidv4(),
        ...itemProps,
        canDrop: RoomRow.canDrop as never,
        canResize: RoomRow.canResize as never,
        startDateTime,
        endDateTime,
        timeSpanMinutes: Interval.fromDateTimes(startDateTime, endDateTime).length("minutes"),
        block: {
          surgeonId: roomRow.surgeon!.id,
          roomId: roomRow.room.id,
          blockType: BlockType.Slot,
          surgeon: roomRow.surgeon,
          room: roomRow.room,
          notes: "",
          startDateTime,
          startTimeUtc: startDateTime.toISO(),
          endDateTime,
          endTimeUtc: endDateTime.toISO(),
          reductionReasonId: undefined
        }
      };

const DayViewEditSurgeonForm: React.FC<Props> = ({
  itemProps,
  reductionReasons,
  rowIndex,
  schedule,
  surgeons,
  onClose,
  setSchedule
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<EditSurgeonForm>({ slotCount: 0 });
  useEffect(() => {
    setForm(form => ({
      ...form,
      slotCount: schedule.rows[rowIndex].items.filter(
        i => i?.block?.blockType === BlockType.Slot && !i.block.reductionReasonId
      ).length
    }));
  }, [schedule.rows]);
  const onSubmit = () => {
    setSchedule(oldSchedule => {
      // if surgeon changed, reassign assigned header & slots
      if (form.surgeon) {
        oldSchedule.rows[rowIndex].surgeonId = form.surgeon.id;
        oldSchedule.rows[rowIndex].surgeon = form.surgeon;
        if (oldSchedule.rows[rowIndex].header) {
          oldSchedule.rows[rowIndex].header!.surgeonId = form.surgeon.id;
          oldSchedule.rows[rowIndex].header!.surgeon = form.surgeon;
        }
        oldSchedule.rows[rowIndex].items = oldSchedule.rows[rowIndex].items.map(item =>
          item.block?.blockType === BlockType.Slot
            ? { ...item, block: { ...item.block, surgeonId: form.surgeon!.id, surgeon: form.surgeon } }
            : item
        );
      }

      // build setup variables
      const newItems: DayViewSchedulerItem[] = [];
      const carryOverItems = oldSchedule.rows[rowIndex].items.filter(
        item => item.appointment || item.block?.blockType !== BlockType.Slot
      );
      const reducedSlots = oldSchedule.rows[rowIndex].items.filter(item => item.block?.reductionReasonId);
      const oldSlotItems = oldSchedule.rows[rowIndex].items.filter(
        item => item?.block?.blockType === BlockType.Slot && !item.block.reductionReasonId
      );
      const postOpGapMinutes = Surgeon.GapToMinutes(oldSchedule.rows[rowIndex].surgeon!);

      // update slots if the count changed
      if (form.slotCount !== oldSlotItems.length) {
        // when we are reducing slots
        if (form.slotCount < oldSlotItems.length) {
          let slotsToRemove = oldSlotItems.length - form.slotCount;
          // first remove unsaved slots
          let index = 0;
          while (slotsToRemove && index < oldSlotItems.length) {
            if (oldSlotItems[index].block && !oldSlotItems[index].block!.id) {
              oldSlotItems.splice(index, 1);
              slotsToRemove--;
            } else index++;
          }
          // then reduce saved ones
          index = 0;
          while (slotsToRemove && index < oldSlotItems.length) {
            if (oldSlotItems[index].block && !oldSlotItems[index].block!.reductionReasonId) {
              oldSlotItems[index].block!.reductionReasonId = form.reductionReason!.id;
              slotsToRemove--;
            }
            index++;
          }
          if (slotsToRemove) throw `Failed to reduce the specified number of slots, ${slotsToRemove} remaining.`;
        }
        // when we are increasing slots
        else if (form.slotCount > oldSlotItems.length) {
          let slotsToCreate = form.slotCount - oldSlotItems.length;
          const freeSpaceObstacles = RoomRow.getObstacles(oldSchedule.rows[rowIndex], ["block", "appointment"]);
          let index = 0;

          // first try to place new slots underneath any appointments
          const fillUnderSurgeryObstacles = RoomRow.getObstacles(oldSchedule.rows[rowIndex], ["block", "non-surgery"]);
          const surgeries = oldSchedule.rows[rowIndex].appointments.filter(appt => appt.isSurgery);
          while (slotsToCreate && index < surgeries.length) {
            if (
              !RoomRow.isObstacleCollision(
                fillUnderSurgeryObstacles,
                surgeries[index].startDateTime,
                Interval.fromDateTimes(surgeries[index].startDateTime, surgeries[index].endDateTime).length("minutes")
              )
            ) {
              const newItem = buildSlotItem(
                itemProps,
                surgeries[index].endDateTime.plus({ hours: 0 }),
                surgeries[index].startDateTime.plus({ hours: 0 }),
                oldSchedule.rows[rowIndex],
                reducedSlots.shift()
              );
              newItems.push(newItem);
              const newObstacle = { startDateTime: newItem.startDateTime, endDateTime: newItem.endDateTime };
              fillUnderSurgeryObstacles.push(newObstacle);
              freeSpaceObstacles.push(newObstacle);
              slotsToCreate--;
            }
            index++;
          }

          // lastly, fill available space
          let newSlotStartTime =
            oldSchedule.rows[rowIndex].surgeon!.startDateTime?.plus({ hour: 0 }) ||
            schedule.bounds.start.plus({ hour: 0 });
          const maxEndTime = oldSchedule.rows[rowIndex].surgeon!.endDateTime || schedule.bounds.end;
          for (index = 0; index < slotsToCreate; index++) {
            const { startDateTime, endDateTime } = EnhancedRoomDay.getNewItemTime(
              freeSpaceObstacles,
              newSlotStartTime,
              newBlockDurationMinutes,
              postOpGapMinutes
            );
            // if we are exceeding our max end time, break and notify the user why we didn't finish
            if (endDateTime > maxEndTime) {
              const slotsRemaining = slotsToCreate - index;
              enqueueSnackbar(
                `Schedule Overflow: ${
                  slotsRemaining
                    ? `${slotsRemaining} slot${slotsRemaining === 1 ? "" : "s"} not created.`
                    : "No slots created."
                }`,
                { variant: "warning" }
              );
              break;
            }
            const newItem = buildSlotItem(
              itemProps,
              endDateTime,
              startDateTime,
              oldSchedule.rows[rowIndex],
              reducedSlots.shift()
            );
            newItems.push(newItem);
            freeSpaceObstacles.push({ startDateTime, endDateTime });
            newSlotStartTime = endDateTime.plus({ minutes: postOpGapMinutes });
          }
        }
      }
      oldSchedule.rows[rowIndex] = {
        ...oldSchedule.rows[rowIndex],
        items: newItems
          .concat(carryOverItems)
          .concat(oldSlotItems)
          .concat(reducedSlots)
          .sort(ScheduleItem.chronoComparator)
      };
      return {
        ...oldSchedule,
        rows: [...oldSchedule.rows.sort(RoomRow.comparator(schedule.rows[rowIndex].orderBy))]
      };
    });
    onClose();
  };
  const slotCount = schedule.rows[rowIndex].items.filter(
    i => i?.block?.blockType === BlockType.Slot && !i.block.reductionReasonId
  ).length;
  const noChange = !form.surgeon && form.slotCount === slotCount;
  let validationMessage = "";
  if (!noChange && form.slotCount < slotCount && !form.reductionReason)
    validationMessage = "You must provide a reduction reason.";
  return (
    <>
      <CardHeader
        title={schedule.rows[rowIndex].room.name}
        subheader={schedule.rows[rowIndex].surgeon ? Surgeon.GetName(schedule.rows[rowIndex].surgeon!) : undefined}
      />
      <CardContent>
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item container spacing={2} direction="column">
            <Grid item>
              <FormControl fullWidth>
                <InputLabel>Change Surgeon</InputLabel>
                <Select
                  fullWidth
                  value={form.surgeon?.id || ""}
                  disabled={!surgeons.length}
                  onChange={e =>
                    e.target.value &&
                    setForm(form => ({ ...form, surgeon: surgeons.find(s => s.id === e.target.value)! }))
                  }
                >
                  {surgeons.map(surgeon => (
                    <MenuItem value={surgeon.id} key={surgeon.id}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography variant="body2">{Surgeon.GetName(surgeon)}</Typography>
                        </Grid>
                        <Grid item>
                          {schedule.rows[rowIndex].room.id === surgeon.preferredRoom?.id && (
                            <Chip color="primary" label="Preferred" size="small" />
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs>
                  <TextField
                    label="Slots"
                    type="number"
                    value={String(form.slotCount)}
                    onChange={e => setForm(form => ({ ...form, slotCount: Number(e.target.value) }))}
                  />
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth>
                    <InputLabel>Change Reason</InputLabel>
                    <Select
                      fullWidth
                      value={form.reductionReason?.id || ""}
                      disabled={slotCount <= form.slotCount}
                      onChange={e =>
                        setForm(form => ({
                          ...form,
                          reductionReason: reductionReasons.find(r => r.id === e.target.value)
                        }))
                      }
                    >
                      {reductionReasons.map(reason => (
                        <MenuItem value={reason.id!} key={reason.id!}>
                          {reason.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <ValidationButton
          disabled={noChange}
          onClick={onSubmit}
          message={validationMessage}
          variant="text"
          size="small"
        >
          Submit
        </ValidationButton>
      </CardActions>
    </>
  );
};

export default DayViewEditSurgeonForm;
