import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { RoomCategory } from "../../../models/Room";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import { AdminTool } from "../Administration";
import AdministrationFormLabel from "../AdministrationFormLabel";
import AdministrationTool from "../AdministrationTool";

const toolReadme = ["The Room Categories tool allows you to set the available rooms that are shown in the planner."];

const RoomCategoryConfig: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState<RoomCategory[]>();
  const [selectedCategory, setSelectedCategory] = useState<RoomCategory>();

  // #region GET Room Categories
  const [categoriesRequest] = useState({ name: "Get Room Categories", request: { url: "roomCategories" } });
  const { response: getCategoriesResponse } = useCbp<RoomCategory[]>(categoriesRequest);
  useEffect(() => {
    if (getCategoriesResponse) setCategories(getCategoriesResponse);
  }, [getCategoriesResponse]);
  // #endregion

  // #region Update Category
  const [updateCategoryRequest, setUpdateCategoryRequest] = useState<UseCbpProps<RoomCategory>>();
  const { response: updateCategoryResponse, isLoading: loadingUpdateCategory } = useCbp<RoomCategory, RoomCategory>(
    updateCategoryRequest
  );
  useEffect(() => {
    if (updateCategoryResponse) {
      setSelectedCategory(undefined);
      setCategories(categories => {
        const index = categories!.findIndex(c => c.id === updateCategoryResponse.id);
        categories![index] = updateCategoryResponse;
        return [...categories!];
      });
      enqueueSnackbar("Room Category Updated", { variant: "success" });
    }
  }, [updateCategoryResponse]);
  //#endregion

  //#region action handlers
  const onDisabledChange = (_: unknown, checked: boolean) =>
    setSelectedCategory(category => ({ ...category!, isDisabled: !checked }));
  const onSaveChanges = () => {
    setUpdateCategoryRequest({
      name: "Update Room Category",
      request: {
        url: `roomCategories/${selectedCategory!.id}`,
        method: "put",
        data: selectedCategory
      }
    });
  };
  //#endregion

  // show spinner while loading
  useEffect(() => setIsContentLoading(!categories), [categories]);

  return (
    <AdministrationTool title="Room Categories" readme={toolReadme} sx={{ minWidth: "45rem" }}>
      <Grid className="m-0 w-100" container direction="column" alignItems="center" spacing={4}>
        <Grid container item xs spacing={2}>
          <Grid item xs>
            <AdministrationFormLabel
              label="Room Categories"
              readme={["Only rooms which belong to active categories will be shown in the Planner."]}
            />
            <SelectList
              items={categories}
              value={selectedCategory || null}
              itemKey="id"
              onChange={category => setSelectedCategory(category || undefined)}
              disabled={loadingUpdateCategory}
              listItem={ADGroupItem}
            />
          </Grid>
          <Grid item container direction="column" xs spacing={2}>
            <Grid item>
              <Typography variant="h6">
                {`${selectedCategory ? `${selectedCategory.name} ` : ""}Configuration:`}
              </Typography>
            </Grid>
            <Grid item container spacing={2} justifyContent="center">
              <FormControlLabel
                control={<Switch />}
                disabled={!selectedCategory}
                checked={(selectedCategory && !selectedCategory.isDisabled) || false}
                onChange={onDisabledChange}
                label="Enabled"
              />
            </Grid>
            <Grid item container justifyContent="center">
              {loadingUpdateCategory ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={
                    !selectedCategory ||
                    selectedCategory.isDisabled === categories!.find(c => c.id === selectedCategory.id)!.isDisabled
                  }
                  onClick={onSaveChanges}
                >
                  Save Changes
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const ADGroupItem: React.FC<CustomListItem<RoomCategory>> = ({
  data: category,
  selected,
  ...rest
}: CustomListItem<RoomCategory>) => {
  return (
    <ListItem {...(rest as Record<string, unknown>)}>
      <ListItemIcon>
        <Checkbox edge="start" checked={selected} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText primary={category.name} />
      {!category.isDisabled && (
        <ListItemIcon>
          <Chip size="small" label="Enabled" />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

export default RoomCategoryConfig;
