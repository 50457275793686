import {
  Checkbox,
  Divider,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

export interface CustomListItem<T> extends ListItemButtonProps {
  data: T;
  handlers?: ((value: T) => void)[];
  selected: boolean;
}

export const ListItem: React.FC<ListItemProps> = ({
  disableRipple,
  hideCheckBoxes,
  label,
  selected,
  ...rest
}: ListItemProps) => {
  return (
    <ListItemButton dense selected={selected} {...rest}>
      {!hideCheckBoxes && (
        <ListItemIcon>
          <Checkbox edge="start" checked={selected} tabIndex={-1} disableRipple={disableRipple} />
        </ListItemIcon>
      )}
      <ListItemText primary={label || "Unidentified Label"} />
    </ListItemButton>
  );
};

interface ClassOverides extends Record<string, string> {
  list: string;
}

interface Props<T> {
  /** Flag to disable mouse events and style the component as inactive. */
  disabled?: boolean;
  /** Flag to optionally disable the ripple animation triggered on item selection. */
  disableRipple?: true;
  /** Flag to optionally hide the check boxes, and use only item styling to indicate selected items.
   * Unused if `listItem` is provided.
   */
  hideCheckBoxes?: true;
  /** The items to render in the list. */
  items?: T[];
  /** The property name that should be used as the item's label. If the desired label does not exist or is not a direct
   * property of the item's value then `listItem` prop should be used to provide the desired value.
   * @defaultValue if `listItem` is provided this is unused, otherwise uses the stringified value of the list item.
   */
  itemLabelProperty?: keyof T;
  /** The `string` property name which should be used as its comparator to other items.
   * Alternatively, a custom comparator function between two items which returns the state of a comparison.
   * @defaultValue The comparison of the object's refs.
   */
  itemKey?: keyof T | ((value: T, itemValue: T) => boolean);
  /** Custom component render for the individual list items. */
  listItem?: React.FC<CustomListItem<T>>;
  /** Title to render at the top of the component.*/
  title?: string;
  /** The controlled state value of the component. If this component is used in a controlled manner, the value used for
   * no selected items should be `null`.
   * @defaultValue This component is uncontrolled and its value is dictated by an internal state.
   */
  value?: T | T[] | null;
  /** Callback for when the selection state of the list changes. */
  onChange?: (newValue: T extends Array<T> ? T[] : T | null) => void;
  /** Optional array of handlers to be passed to the child items. */
  handlers?: ((value: T) => void)[];
  /** Class overrides for child components. */
  classes?: Partial<ClassOverides>;
}

/** A helper component that allows you to select one or more items from a defined collection.
 * There are a host of optional settings available, and it can function in a controlled or uncontrolled state.
 * MULTIPLE ITEM SELECTION IS NOT YET SUPPORTED.
 * ITEM REORDERING IS NOT YET SUPPORTED.
 */
const SelectList = <T,>({
  disabled,
  disableRipple,
  hideCheckBoxes,
  items,
  itemLabelProperty,
  itemKey,
  listItem,
  title,
  value,
  onChange,
  handlers
}: Props<T>): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const selectedItemsRef = useRef(selectedItems);
  const compare = useRef<(value: T, item: T) => boolean>(() => false);
  const lastIndexSelected = useRef<number>();

  // clear the selection state when the value is cleared
  useEffect(() => {
    if (!value && selectedItemsRef.current.length) updateSelection(selectedItemsRef.current[0]);
  }, [value]);

  // build item comparator from itemKey prop
  useEffect(() => {
    if (typeof itemKey === "function") compare.current = itemKey;
    else if (itemKey) compare.current = (value: T, item: T) => item[itemKey] === value[itemKey];
    else compare.current = (value: T, item: T) => value === item;
  }, [itemKey]);
  // #region Actions
  const updateSelection = (selection: T) => {
    setSelectedItems(items => {
      let index: number;
      if (itemKey) index = items.findIndex(i => compare.current(i, selection));
      else index = items.findIndex(item => item === selection);
      let newValue = [...items];
      if (index === -1) {
        // TODO support multi-select
        // eslint-disable-next-line no-constant-condition
        if (false) {
          newValue.push(selection);
        } else newValue = [selection];
      } else newValue.splice(index, 1);
      lastIndexSelected.current = index;
      selectedItemsRef.current = newValue;
      if (onChange)
        onChange(
          (value === undefined
            ? newValue
            : value === null || (value as Record<string, unknown> | T[]).constructor !== Array
            ? newValue[0]
            : newValue) as T extends T[] ? T[] : T // TODO: improve so this assertions aren't necessary
        );
      return newValue;
    });
  };
  // #endregion

  return (
    <>
      {title && (
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      )}
      <List
        disablePadding
        sx={theme => ({
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.action.disabled,
          borderRadius: theme.shape.borderRadius,
          width: "100%",
          border: "solid 1px",
          ...(disabled && { opacity: 0.6, pointerEvents: "none" })
        })}
      >
        {items?.length ? (
          items.map((itemValue, index) => {
            const label = !listItem && itemLabelProperty ? String(itemValue[itemLabelProperty]) : String(itemValue);
            const selected =
              value === undefined
                ? selectedItems.some(item => compare.current(item, itemValue))
                : value
                ? compare.current(itemValue, value as T) // TODO enable multiselect
                : false;
            const onClick = () => updateSelection(itemValue);
            return (
              <span key={index}>
                {listItem ? (
                  listItem!({
                    data: itemValue,
                    selected,
                    dense: true,
                    // button: true,
                    onClick,
                    handlers: handlers
                  })
                ) : (
                  <ListItem
                    label={label}
                    hideCheckBoxes={hideCheckBoxes}
                    selected={selected}
                    disableRipple={disableRipple}
                    onClick={onClick}
                  />
                )}
                {index !== items.length - 1 && <Divider component="li" />}
              </span>
            );
          })
        ) : (
          <ListItemButton>No Items</ListItemButton>
        )}
      </List>
    </>
  );
};

interface ListItemProps extends ListItemButtonProps {
  disableRipple?: true;
  hideCheckBoxes?: boolean;
  label?: string;
  selected: boolean;
}

export default SelectList;
