import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import AppContext from "../../contexts/AppContext";
import { User } from "../../models/User";

interface Props extends RouteProps {
  children: React.ReactNode | React.ReactNode[];
  /** Predicate which authorizes the user to access the path. */
  authorize: (user: User) => boolean;
  /** Redirect path for when a user is unable to access the path. */
  redirectPath: string;
}

/** Helper for `react-router-dom.Route` which enforces authorization from the `AppContext.User`. */
const AuthorizedRoute: React.FC<Props> = ({ children, authorize, redirectPath, ...rest }: Props) => {
  const { user } = useContext(AppContext);
  const authorized = authorize(user);
  return <Route {...rest}>{authorized ? children : <Redirect to={redirectPath} />}</Route>;
};

export default AuthorizedRoute;
