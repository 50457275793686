import { FormControl, Grid, InputLabel, MenuItem, Select, Switch, Typography } from "@mui/material";
import React from "react";

import { DayOfWeekOrder } from "../../Helpers/Calendar/types";

interface Props {
  dayOfWeekOrder: DayOfWeekOrder;
  hiddenWeekDays: boolean;
  setDayOfWeekOrder: (order: DayOfWeekOrder) => void;
  setOnlyWorkDays: (active: boolean) => void;
}

const MonthlyViewOptions: React.FC<Props> = ({
  dayOfWeekOrder,
  hiddenWeekDays,
  setDayOfWeekOrder,
  setOnlyWorkDays
}: Props) => {
  return (
    <Grid className="m-0" container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs>
        <FormControl fullWidth>
          <InputLabel>Day of the Week Start</InputLabel>
          <Select
            fullWidth
            label="Day of the Week Start"
            value={dayOfWeekOrder}
            onChange={e => setDayOfWeekOrder(e.target.value as DayOfWeekOrder)}
          >
            <MenuItem value={DayOfWeekOrder.SUNDAY_FIRST}>Sunday First</MenuItem>
            <MenuItem value={DayOfWeekOrder.MONDAY_FIRST}>Monday First</MenuItem>
            <MenuItem value={DayOfWeekOrder.SATURDAY_FIRST}>Saturday First</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body2">Only Work Days</Typography>
        </Grid>
        <Grid item>
          <Switch checked={hiddenWeekDays} onChange={e => setOnlyWorkDays(e.target.checked)} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MonthlyViewOptions;
