import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ScaleIcon from "@mui/icons-material/Scale";
import ScheduleIcon from "@mui/icons-material/Schedule";
import WarningIcon from "@mui/icons-material/Warning";
import { Badge, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { CSSProperties } from "react";

import { BlockType } from "../../../models/Block";
import { EnhancedSurgeon, Surgeon } from "../../../models/Surgeon";
import { DayViewSchedulerItem, RoomRow } from "../../../types/DayViewSchedulerTypes";
import { SchedulerRowHeaderProps } from "../../Helpers/Scheduler/types";

const smallIconStyle: CSSProperties = { fontSize: ".7rem" };

const DayViewRowHeaderBuilder: (
  setShowUnassignedForm: (anchor: HTMLElement, rowIndex: number) => void,
  setShowEditForm: (anchor: HTMLElement, rowIndex: number) => void,
  setPlaceModeRowIndex: (rowIndex?: number) => void
) => React.FC<SchedulerRowHeaderProps<DayViewSchedulerItem, RoomRow>> = (
  setShowUnassignedForm,
  setShowEditForm,
  setPlaceModeRowIndex
) =>
  function DayViewRowHeader({
    disabled,
    placeModeRowIndex,
    row,
    rowIndex
  }: SchedulerRowHeaderProps<DayViewSchedulerItem, RoomRow>) {
    let prefTimes = EnhancedSurgeon.getPreferredTimesLocale(row.surgeon);
    prefTimes = prefTimes ? `Preferred Hours: ${prefTimes}` : undefined;
    const surgeonName = row.surgeon ? Surgeon.GetName(row.surgeon) : undefined;

    const surgeries =
      (row.surgeonId &&
        row.items.filter(
          item =>
            // filter to surgery appointments with surgeons matching the header surgeon
            item.appointment?.surgeonId === row.surgeonId && item.appointment?.isSurgery
        )) ||
      [];
    // aggregate slots by assignment
    const slots = row.items.filter(i => i.block?.blockType === BlockType.Slot);
    const surgeonSlots = (row.surgeonId && slots.filter(item => item.block?.surgeonId === row.surgeonId)) || [];
    const preferredHoursViolated =
      prefTimes &&
      row.items.some(i => i.startDateTime < row.surgeon!.startDateTime! || i.endDateTime > row.surgeon!.endDateTime!);
    const surgeonWeightCapacity = row.surgeon?.weightCategory?.weightValue || 0;

    const isSurgeonTerminated = row.surgeon?.terminationDateTime && row.surgeon.terminationDateTime <= row.date;
    const slotBadgeVariant = row.surgeon
      ? // if surgeon will have been terminated, color slot badges as over-capacity
        isSurgeonTerminated ||
        // if appointments exceed the surgeon's slots, color as over-capacity
        surgeries.length > surgeonSlots.length
        ? "error"
        : // otherwise, if remaining capacity can fit a service
        surgeonWeightCapacity - (row.fitServiceWeight || 0) - row.appointmentsWeight >= 0 &&
          // and if the surgeon has remaining slots, color as under-capacity
          surgeries.length < surgeonSlots.length
        ? "success"
        : // finally, color as default
          "default"
      : undefined;
    // if capacity exceeds the surgeon's maximum, color weight badge as over-capacity
    const weightBadgeVariant = row.appointmentsWeight > surgeonWeightCapacity ? "error" : "default";

    return (
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1" component="span">{`Room: ${row.room.name}`}</Typography>
            </Grid>
            {row.surgeon && (
              <Grid item container direction="column">
                <Grid item container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="body1" component="span">
                      {surgeonName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      arrow
                      title={
                        <Grid container direction="column" spacing={1}>
                          {row.header?.notes && <Grid item>{row.header?.notes}</Grid>}
                        </Grid>
                      }
                    >
                      <CommentIcon fontSize="small" />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item container spacing={1} alignItems="center">
                  <Grid item>
                    <Tooltip arrow title={`${surgeries.length} Surger${surgeries.length > 1 ? "ies" : "y"}`}>
                      <Badge
                        badgeContent={surgeries.length}
                        color={slotBadgeVariant}
                        showZero
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      >
                        <EventIcon fontSize="small" />
                      </Badge>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip arrow title={`${surgeonSlots.length} Slot${surgeonSlots.length > 1 ? "s" : ""}`}>
                      <Badge
                        badgeContent={surgeonSlots.length}
                        color={slotBadgeVariant}
                        showZero
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      >
                        <AssignmentIcon fontSize="small" />
                      </Badge>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip arrow title={`${row.surgeon.weightCategory?.weightValue || 0} Surgeon Weight`}>
                      <Badge
                        badgeContent={row.surgeon.weightCategory?.weightValue || 0}
                        color="default"
                        showZero
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      >
                        <FitnessCenterIcon fontSize="small" />
                      </Badge>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip arrow title={`${row.appointmentsWeight} Surgical Appointment Weight`}>
                      <Badge
                        badgeContent={row.appointmentsWeight}
                        color={weightBadgeVariant}
                        showZero
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      >
                        <ScaleIcon fontSize="small" />
                      </Badge>
                    </Tooltip>
                  </Grid>
                  {prefTimes && (
                    <Grid item>
                      <Tooltip arrow title={prefTimes}>
                        <Badge
                          badgeContent={
                            preferredHoursViolated ? (
                              <CheckIcon style={smallIconStyle} />
                            ) : (
                              <CloseIcon style={smallIconStyle} />
                            )
                          }
                          color={preferredHoursViolated ? "secondary" : "error"}
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        >
                          <ScheduleIcon fontSize="small" />
                        </Badge>
                      </Tooltip>
                    </Grid>
                  )}
                  {row.surgeon?.preferredRoom && (
                    <Grid item>
                      <Tooltip title={`Preferred Room: ${row.surgeon.preferredRoom.name}`}>
                        <Badge
                          badgeContent={
                            row.surgeon.preferredRoom.id === row.room.id ? (
                              <CheckIcon style={smallIconStyle} />
                            ) : (
                              <CloseIcon style={smallIconStyle} />
                            )
                          }
                          color={row.surgeon.preferredRoom.id === row.room.id ? "secondary" : "error"}
                          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        >
                          <MeetingRoomIcon fontSize="small" />
                        </Badge>
                      </Tooltip>
                    </Grid>
                  )}
                  {isSurgeonTerminated && (
                    <Grid item>
                      <Tooltip
                        title={`Surgeon was terminated effective ${row.surgeon.terminationDateTime?.toLocaleString(
                          DateTime.DATE_MED
                        )}`}
                      >
                        <WarningIcon color="error" />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!disabled && (
          <Grid item>
            {row.surgeon ? (
              <>
                <Tooltip title="Edit Slots">
                  <IconButton color="primary" onClick={e => setShowEditForm(e.currentTarget, rowIndex)} size="small">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={placeModeRowIndex === rowIndex ? "Cancel Add" : "Add Slot"}>
                  <span>
                    <IconButton
                      color="primary"
                      onClick={() => setPlaceModeRowIndex(rowIndex)}
                      size="small"
                      disabled={placeModeRowIndex !== undefined && placeModeRowIndex !== rowIndex}
                    >
                      {placeModeRowIndex === rowIndex ? <CancelIcon /> : <AddCircleIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Assign Surgeon">
                <IconButton onClick={e => setShowUnassignedForm(e.currentTarget, rowIndex)} size="small">
                  <AssignmentIndIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    );
  };

export default DayViewRowHeaderBuilder;
