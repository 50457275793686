import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
// import moment from "moment";
import { useEffect, useRef, useState } from "react";

const cancellationMessage = "The request was canceled by issuing an updated one.";

/** The Http request method type. */
export type RequestMethod = "get" | "post" | "put" | "delete";
/** Type recovery action to take for the error */
export type RecoveryType =
  /**
   * The user must take action (do something in Zenoti, refresh the page, etc).
   * These will NOT auto-dismiss, and must be done manually.
   * The toast is likely to have the action behavior embedded in the dismiss button if it is simple (refresh).
   */
  | "action"
  /**
   * The user is notified of the issue, but it has minor impact on workflow
   * These will auto-dismiss after a set timeout
   */
  | "info";

export interface HandledRequestErrorBehavior {
  /** The recovery type associated with the behavior, see below. */
  recoveryType: RecoveryType;
}
/** Props used by the useApi hook which provide configuration for the http request props and behaviors.
 * BE SURE TO USE A STATE-CONTROLLED VALUE, OTHERWISE YOU WILL LOSE CONTROL OF EXECUTING THIS HOOK. */
export interface UseApiProps<TData> {
  request: {
    /** Method of the http request, defaults to "Get". */
    method?: RequestMethod;
    /** Url for the http request. */
    url: string;
    /** Payload to be attached to the http request, optional. */
    data?: TData;
    /** Initializer for a custom Axios request config.  */
    config?: AxiosRequestConfig;
  };
  /** Enables message cancellation of pending requests when a new one is executed.
   * Defaults true if "Get" request, false otherwise. */
  isCancelable?: boolean;
  /** A token for authorization type bearer token. */
  tokenSecret?: string;
  /** The axios instance to be used in http request execution. */
  axiosInstance: AxiosInstance;
}
/** Response for the useApi hook using provided response types. */
export interface UseApiResponse<TResponseError, TResponse> {
  /** The response payload of a successfully completed request.
   * Note that some responses such as 202 accepted return `String.Empty` as their payload, and this is cast to `{}` to
   * ensure that state changes are fired correctly. Be sure to include this as a type option if expecting a non 200 success response.
   */
  response?: TResponse;
  /** The axios error containing the error response payload if any. */
  error?: Error<TResponseError>;
  /** A boolean indicating if the http request is pending or at rest. */
  isLoading: boolean;
}
type Error<TResponseError> = {
  root: AxiosError<TResponseError>;
  handledBehavior: HandledRequestErrorBehavior | null | undefined;
};

export enum Exceptions {
  MissingToken = "An authorization token was indicated as being required, however was not provided.",
  NetworkError = "A network error occurred during the execution of the http request.",
  UncaughtUnauthorizedResponse = "An unauthorized response was returned but a handled behavior for this response was not provided.",
  AuthorizationExpired = "The lifespan for the authorization provided exceeded the value allowed by the expiration threshold.",
  MissingHandledBehavior = "A handled behavior for the given response type could not be found."
}

const useApi = <TResponseError, TResponse, TData>(
  props?: UseApiProps<TData>
): UseApiResponse<TResponseError, TResponse> => {
  // outputs
  const [error, setError] = useState<Error<TResponseError>>();
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState<TResponse>();
  // internal states
  const cancelRef = useRef(axios.CancelToken.source());
  const loadingRef = useRef(isLoading);

  useEffect(() => {
    if (!props?.request) return;
    const { request: requestProps, isCancelable, tokenSecret: token, axiosInstance } = props;
    // setup request config
    const config: AxiosRequestConfig = { ...requestProps.config, headers: { ...requestProps?.config?.headers } };

    // IF no manual token provided and we require authorization for this request
    if (!config.headers?.Authorization) {
      if (token) {
        config.headers = { Authorization: `Bearer ${token}` };
      } else throw Exceptions.MissingToken;
    }
    const requestMethod = requestProps.method || "get";
    // if this request is pending and cancellable and, cancel it
    if (loadingRef.current && (isCancelable || (isCancelable === undefined && requestMethod === "get"))) {
      cancelRef.current.cancel(cancellationMessage);
      cancelRef.current = axios.CancelToken.source();
    }
    config.cancelToken = cancelRef.current?.token;
    // const requestError = errorsConfigRef?.current || errorsConfig;

    // build the request
    const fetchData = async () => {
      const request =
        requestMethod === "get"
          ? axiosInstance.get(requestProps.url, config)
          : requestMethod === "post"
          ? axiosInstance.post(requestProps.url, requestProps.data, config)
          : requestMethod === "put"
          ? axiosInstance.put(requestProps.url, requestProps.data, config)
          : axiosInstance.delete(requestProps.url, { ...config, data: requestProps.data });
      request
        .then(({ data }: AxiosResponse<TResponse>) => {
          // Responses such as 204 return string.empty as a payload which do not trigger state updates.
          // As a default, we return empty object here.
          setResponse(data || ({} as TResponse));
          loadingRef.current = false;
          setLoading(false);
          // dismissError();
        })
        .catch((error: AxiosError<TResponseError>) => {
          // if the request was canceled, swallow the error
          if (error.message === cancellationMessage) return;

          loadingRef.current = false;
          setLoading(false);

          if (error.message === "NetworkError") throw Exceptions.NetworkError;

          // const behaviors = errorsConfigRef?.current || errorsConfig;

          // let handledBehavior: HandledRequestErrorBehavior | null | undefined = undefined;
          // if error handling is enabled for this request
          // if (requestError) {
          //   // try to get the exact status behavior from the config
          //   if (error?.response?.status) handledBehavior = behaviors[error.response.status];

          //   // if a 401 error and not explicitly handled, throw
          //   if (handledBehavior === undefined && error?.response?.status === 401)
          //     throw Exceptions.UncaughtUnauthorizedResponse;

          //   // finally use the error's default behavior
          //   if (handledBehavior === undefined) handledBehavior = behaviors["default"];
          //   if (handledBehavior === undefined) throw Exceptions.MissingHandledBehavior;
          // }
          setError({
            root: error,
            handledBehavior: undefined
          });
        });
    };
    fetchData();
    loadingRef.current = true;
    setLoading(true);
  }, [props]);

  return { response, error, isLoading };
};

export default useApi;
