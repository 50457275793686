import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { BusinessUnit } from "../../../models/BusinessUnit";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import { AdminTool } from "../Administration";
import AdministrationTool from "../AdministrationTool";

const toolReadme = [
  "The Business Unit tool allows you to set the availability of business units allowed in CBP.",
  "Units which are enabled will be included in selection of related entities, such as service  subcategories."
];

const BusinessUnitConfig: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [businessUnits, setBusinessUnits] = useState<BusinessUnit[]>();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<BusinessUnit>();

  //#region GET BusinessUnits Query
  const [getBusinessUnitsRequest] = useState<UseCbpProps>({
    name: "Get Business Units",
    request: { url: "businessUnits" }
  });
  const { response: getBusinessUnitsResponse } = useCbp<BusinessUnit[]>(getBusinessUnitsRequest);
  useEffect(() => {
    if (getBusinessUnitsResponse) setBusinessUnits(getBusinessUnitsResponse);
  }, [getBusinessUnitsResponse]);
  //#endregion

  //#region PUT BusinessUnit
  const [updateBusinessUnitRequest, setUpdateBusinessUnitRequest] = useState<UseCbpProps<BusinessUnit>>();
  const { response: updateBusinessUnitResponse, isLoading: updateIsLoading } = useCbp<BusinessUnit, BusinessUnit>(
    updateBusinessUnitRequest
  );
  useEffect(() => {
    if (updateBusinessUnitResponse) {
      setBusinessUnits(businessUnits => {
        const index = businessUnits!.findIndex(businessUnit => businessUnit.id === updateBusinessUnitResponse.id);
        businessUnits![index] = updateBusinessUnitResponse;
        return [...businessUnits!];
      });
      setSelectedBusinessUnit(undefined);
      enqueueSnackbar("Business Unit Updated", { variant: "success" });
    }
  }, [updateBusinessUnitResponse]);
  //#endregion

  // #region Actions
  const onDisableStateChange = (_: unknown, checked: boolean) =>
    setSelectedBusinessUnit(bu => ({ ...bu!, isDisabled: !checked }));
  const onSaveChanges = () => {
    setUpdateBusinessUnitRequest({
      name: "Update Business Unit",
      request: {
        url: `businessUnits/${selectedBusinessUnit!.id}`,
        method: "put",
        data: selectedBusinessUnit!
      }
    });
  };
  // #endregion

  // show spinner while loading
  useEffect(() => setIsContentLoading(!businessUnits), [businessUnits]);

  return (
    <AdministrationTool title="Business Units" readme={toolReadme} sx={{ minWidth: "40rem" }}>
      <Grid className="m-0 w-100" container spacing={3} direction="column">
        <Grid item container>
          <Grid item xs>
            <SelectList
              title="Business Units"
              items={businessUnits}
              value={selectedBusinessUnit || null}
              itemKey="id"
              disabled={updateIsLoading}
              onChange={businessUnit => setSelectedBusinessUnit(businessUnit || undefined)}
              listItem={BusinessUnitItem}
            />
          </Grid>
          <Grid item container xs direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6">Settings for {selectedBusinessUnit ? selectedBusinessUnit.name : ""}</Typography>
            </Grid>
            <Grid item container spacing={2} justifyContent="center">
              <Grid item>
                <FormControlLabel
                  control={<Switch />}
                  disabled={!selectedBusinessUnit}
                  checked={(selectedBusinessUnit && !selectedBusinessUnit.isDisabled) || false}
                  onChange={onDisableStateChange}
                  label="Enabled"
                />
              </Grid>
            </Grid>
            <Grid item>
              {updateIsLoading ? (
                <CircularProgress />
              ) : (
                <Button disabled={!selectedBusinessUnit} onClick={onSaveChanges}>
                  Save Changes
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const BusinessUnitItem: React.FC<CustomListItem<BusinessUnit>> = ({
  data: businessUnit,
  selected,
  ...rest
}: CustomListItem<BusinessUnit>) => {
  return (
    <ListItem {...(rest as Record<string, unknown>)}>
      <ListItemIcon>
        <Checkbox edge="start" checked={selected} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText primary={businessUnit.name} />
      {!businessUnit.isDisabled && (
        <ListItemIcon>
          <Chip size="small" label="Enabled" />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

export default BusinessUnitConfig;
