import { DateTime } from "luxon";

import { Center } from "./Center";
import { Room } from "./Room";
import { WeightCategory } from "./WeightCategory";

class SurgeonCore {
  /** The primary key of the appointment. */
  id!: string;
  /** The plain text first name of the customer. */
  firstName!: string;
  /** The plain text last name of the customer. */
  lastName!: string;
  /** The plain text locale time value of the end time of the surgeon's preferred work start time. */
  preferredStart?: string;
  /** The plain text locale time value of the end time of the surgeon's preferred work end time. */
  preferredEnd?: string;
  /** The UTC ISO value of the end time of the surgeon's termination date. */
  terminationDate?: string;
  /** The assigned weight category of the surgeon. */
  weightCategory?: WeightCategory;

  /** Get the fully formed name for the given surgeon. */
  static GetName(surgeon: { firstName: string; lastName: string }): string {
    return `${surgeon.firstName} ${surgeon.lastName}`;
  }

  /** Get the post-op gap as an expression of integer minutes for a given surgeon-center. */
  static GapToMinutes(surgeonCenter: { postOpGap: string }): number {
    const matches = surgeonCenter.postOpGap.match(/(\d\d):(\d\d):\d\d/);
    if (!matches || matches.length <= 2)
      throw `Failed to parse time from surgeon.postOpGapp {${surgeonCenter.postOpGap}}.`;
    return Number(matches[1]) * 60 + Number(matches[2]);
  }
}

/** The dto model used to transact surgeons with the backend. */
export class Surgeon extends SurgeonCore {
  /** The associated center assignments of the surgeon keyed by the center id. */
  surgeonCenters!: Record<string, SurgeonCenter>;
}

class SurgeonCenterCore {
  /** The surgeon's preferred froom for the center assignment. */
  preferredRoom?: Room;
  /** The surgeon's preferred post-op time for a given center assignment as an expression of a locale stringified timespan. */
  postOpGap!: string;
  /** A note to be attached to header blocks to which for given surgeon and center. */
  message?: string;

  /** Build a new surgeon center from the given one and number of post op minutes. */
  static SetGapFromMinutes(surgeonCenter: SurgeonCenter, minutes: number): SurgeonCenter {
    const hours = Math.floor(minutes / 60);
    const hourMinutes = minutes % 60;
    return {
      ...surgeonCenter,
      postOpGap: `${hours < 10 ? `0${hours}` : hours}:${hourMinutes < 10 ? `0${hourMinutes}` : hourMinutes}:00`
    };
  }
}

/** The dto model used to transact surgeon's center assignments with the backend. */
export class SurgeonCenter extends SurgeonCenterCore {
  /** The associated center for the center assignment. */
  center!: Center;
}

export type CenterSurgeon = Omit<Surgeon, "surgeonCenters"> & Omit<SurgeonCenter, "center">;

/** Expanded CenterSurgeon model with linked child props for a single center. */
export class EnhancedSurgeon extends SurgeonCore implements SurgeonCenterCore {
  // interface properties
  /** The surgeon's preferred froom for the center assignment. */
  preferredRoom?: Room;
  /** The surgeon's preferred post-op time for a given center assignment as an expression of a locale stringified timespan. */
  postOpGap!: string;

  // owned properties
  /** The `DateTime` representing `startTimeUtc`. */
  startDateTime?: DateTime;
  /** The `DateTime` representing `endTimeUtc`. */
  endDateTime?: DateTime;
  /** The `DateTime` representing the `terminationDate`. */
  terminationDateTime?: DateTime;

  static forDay(surgeon: EnhancedSurgeon, { day, month, year }: DateTime): EnhancedSurgeon {
    return {
      ...surgeon,
      startDateTime: surgeon.startDateTime?.set({ day, month, year }),
      endDateTime: surgeon.endDateTime?.set({ day, month, year })
    };
  }

  static getPreferredTimesLocale(surgeon?: EnhancedSurgeon): string | undefined {
    if (surgeon?.startDateTime && surgeon.endDateTime)
      return `${surgeon.startDateTime ? `${surgeon.startDateTime.toLocaleString(DateTime.TIME_SIMPLE)} - ` : ""}${
        surgeon.endDateTime ? surgeon.endDateTime.toLocaleString(DateTime.TIME_SIMPLE) : ""
      }`;
    return undefined;
  }
}
