import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Tooltip,
  Typography,
  styled
} from "@mui/material";
import React from "react";

import { Room } from "../../../models/Room";
import { EnhancedSurgeon, Surgeon } from "../../../models/Surgeon";

const StyledSelect = styled(Select)<SelectProps>({ minWidth: "15rem" });

interface Props {
  disabled: boolean;
  room?: Room;
  rooms: Room[];
  surgeon?: EnhancedSurgeon;
  surgeons: EnhancedSurgeon[];
  setSurgeon: (surgeon?: EnhancedSurgeon) => void;
  setRoom: (room?: Room) => void;
}

const disabledMessage = "You must lock the center prior to editing it.";

const BatchEditViewControls: React.FC<Props> = ({
  disabled,
  room,
  rooms,
  surgeon,
  surgeons,
  setRoom,
  setSurgeon
}: Props) => {
  return (
    <Grid container className="h-100 w-100 m-0" justifyContent="space-around" alignItems="center" spacing={2}>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel>Surgeon:</InputLabel>
          <Tooltip title={disabled ? disabledMessage : ""}>
            <StyledSelect
              fullWidth
              value={surgeon?.id || ""}
              disabled={disabled}
              onChange={e => setSurgeon(surgeons.find(s => s.id === e.target.value))}
            >
              {surgeons.map(surgeon => (
                <MenuItem value={surgeon.id} key={surgeon.id}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="body2">{Surgeon.GetName(surgeon)}</Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </StyledSelect>
          </Tooltip>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel>Room:</InputLabel>
          <Tooltip title={disabled ? disabledMessage : ""}>
            <StyledSelect
              fullWidth
              value={room?.id || ""}
              disabled={disabled}
              onChange={e => setRoom(rooms.find(s => s.id === e.target.value))}
            >
              {rooms.map(room => (
                <MenuItem value={room.id} key={room.id}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="body2">{room.name}</Typography>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </StyledSelect>
          </Tooltip>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default BatchEditViewControls;
