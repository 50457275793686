import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import { SxProps } from "@mui/system";
import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";

import { LocalStorageKeys } from "../../Constants";
import AppContext from "../../contexts/AppContext";
import { SimpleCenter } from "../../models/Center";
import { UserSettings as UserSettingsModel } from "../../types/UserSettings";
import { loadFromLocal, saveToLocal } from "../../utils/Helpers";
import useCbp from "../../utils/UseCbp";
import { DayOfWeekOrder } from "../Helpers/Calendar/types";
import { SchedulerTimeIncrement, SchedulerTimeIncrementWidth } from "../Helpers/Scheduler/types";

const sxDisabled: SxProps = { pointerEvents: "none", opacity: 0.6 };

const UserSettings: React.FC = () => {
  const { user } = useContext(AppContext);
  const [userSettings, setUserSettings] = useState(
    loadFromLocal<UserSettingsModel>(LocalStorageKeys.userSettings) || new UserSettingsModel()
  );
  const [centers, setCenters] = useState(loadFromLocal<SimpleCenter[]>(LocalStorageKeys.planner.simpleCenters));
  const userSettingsRef = useRef(userSettings);

  // #region GET Centers
  const [centersRequest] = useState(!centers ? { name: "Get Centers", request: { url: "/centers" } } : undefined);
  const { response: centersResponse, isLoading: centersLoading } = useCbp<SimpleCenter[]>(centersRequest);
  useEffect(() => {
    if (centersResponse) {
      const simpleCenters = centersResponse.map(({ id, name }) => ({ id, name }));
      setCenters(simpleCenters);
      saveToLocal(simpleCenters, LocalStorageKeys.planner.simpleCenters);
    }
  }, [centersResponse]);
  // #endregion

  const saveChanges = (key: keyof UserSettingsModel, value: unknown) => {
    const settings = { ...userSettings, [key]: value as never };
    userSettingsRef.current = settings;
    setUserSettings(settings);
    saveToLocal(settings, LocalStorageKeys.userSettings);
  };

  return (
    <Grid container justifyContent="center" spacing={3} sx={{ p: 2 }}>
      <Grid item xs>
        <Card elevation={3}>
          <CardHeader title="User Profile" color="focus" />
          <CardContent>
            <Grid container direction="column" spacing={2} sx={{ maxHeight: "60vh", overflow: "auto" }} wrap="nowrap">
              <Grid item container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="h6">Username: </Typography>
                </Grid>
                <Grid item>{user.username}</Grid>
              </Grid>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">App Permissions: </Typography>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item>IT:</Grid>
                  <Grid item>{user.applicationPermissions.isApplicationEditor ? "Yes" : "No"}</Grid>
                  <Grid item>Administrator:</Grid>
                  <Grid item>{user.applicationPermissions.isAdminEditor ? "Yes" : "No"}</Grid>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                  {Object.entries(user.centerPermissions)
                    .map(([key, value]) => ({ value, name: centers?.find(c => c.id === key)?.name || value }))
                    .sort((i1, i2) => (i1.name > i2.name ? 1 : -1))
                    .map(({ value, name }, index) => (
                      <Grid item container direction="column" spacing={1} key={index}>
                        <Grid item>
                          <Typography variant="h6">{name}</Typography>
                        </Grid>
                        <Grid item container spacing={2}>
                          {value.isCapacityEditor || value.isCenterEditor ? (
                            <>
                              <Grid item>Edit Schedule:</Grid>
                              <Grid item>{value.isCapacityEditor ? "Yes" : "No"}</Grid>
                              <Grid item>Edit Settings:</Grid>
                              <Grid item>{value.isCenterEditor ? "Yes" : "No"}</Grid>
                            </>
                          ) : (
                            <Grid item>View Schedule Only</Grid>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Card elevation={3}>
          <CardHeader title="Planner Settings" color="focus" />
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="body1">Default Calendar View Mode</Typography>
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    value={userSettings.defaultCalendarView}
                    exclusive
                    onChange={(_, value) => saveChanges("defaultCalendarView", value)}
                  >
                    <ToggleButton value={"day"}>Day</ToggleButton>
                    <ToggleButton value={"month"}>Month</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid item container direction="column" spacing={2}>
                <Grid item container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography>Default Center is Last Selected</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={Boolean(userSettings.defaultLastCenter)}
                      onChange={(_, checked) => saveChanges("defaultLastCenter", checked)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  alignItems="center"
                  sx={userSettings.defaultLastCenter ? sxDisabled : undefined}
                >
                  <Grid item>Default Center Is</Grid>
                  <Grid item>
                    {centersLoading ? (
                      <CircularProgress />
                    ) : (
                      <Autocomplete
                        disabled={userSettings.defaultLastCenter}
                        options={centers || []}
                        renderInput={params => <TextField {...params} />}
                        getOptionLabel={center => center.name}
                        value={(centers || []).find(c => c.id === userSettings.defaultCenterId) || null}
                        onChange={(_, center) => saveChanges("defaultCenterId", center?.id)}
                        sx={{ minWidth: "15rem" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Card elevation={3}>
              <CardHeader title="Day View Settings" color="focus" />
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body1">Default Scheduler Time Increment</Typography>
                    </Grid>
                    <Grid item>
                      <ToggleButtonGroup
                        value={userSettings.defaultSchedulerIncrement}
                        exclusive
                        onChange={(_, value?: number) => value && saveChanges("defaultSchedulerIncrement", value)}
                      >
                        <ToggleButton value={SchedulerTimeIncrement.QUARTER_HOUR}>15 Minutes</ToggleButton>
                        <ToggleButton value={SchedulerTimeIncrement.HALF_HOUR}>30 Minutes</ToggleButton>
                        <ToggleButton value={SchedulerTimeIncrement.HOUR}>60 Minutes</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body1">Default Scheduler Item Size</Typography>
                    </Grid>
                    <Grid item>
                      <ToggleButtonGroup
                        value={userSettings.defaultSchedulerSize}
                        exclusive
                        onChange={(_, value?: number) => value && saveChanges("defaultSchedulerSize", value)}
                      >
                        <ToggleButton value={SchedulerTimeIncrementWidth.SMALL}>Small</ToggleButton>
                        <ToggleButton value={SchedulerTimeIncrementWidth.MEDIUM}>Medium</ToggleButton>
                        <ToggleButton value={SchedulerTimeIncrementWidth.LARGE}>Large</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body1">Order Data By</Typography>
                    </Grid>
                    <Grid item>
                      <ToggleButtonGroup
                        value={userSettings.defaultSchedulerOrder}
                        exclusive
                        onChange={(_, value?: "room" | "surgeon") =>
                          value && saveChanges("defaultSchedulerOrder", value)
                        }
                      >
                        <ToggleButton value={"room"}>Room</ToggleButton>
                        <ToggleButton value={"surgeon"}>Surgeon</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body1">Scheduler Orientation</Typography>
                    </Grid>
                    <Grid item>
                      <ToggleButtonGroup
                        value={userSettings.defaultSchedulerOrientation}
                        exclusive
                        onChange={(_, value?: "horizontal" | "vertical") =>
                          value && saveChanges("defaultSchedulerOrientation", value)
                        }
                      >
                        <ToggleButton value={"horizontal"}>Horizontal</ToggleButton>
                        <ToggleButton value={"vertical"}>Vertical</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card elevation={3}>
              <CardHeader title="Month View Settings" color="focus" />
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item container alignItems="center">
                    <Grid item>
                      <Typography variant="body1">Default Show Only Working Days</Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        checked={Boolean(userSettings.defaultShowOnlyWorkDays)}
                        onChange={(_, checked) => saveChanges("defaultShowOnlyWorkDays", checked)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center" spacing={2}>
                    <Grid item>
                      <Typography variant="body1">Default Day of the Week Start</Typography>
                    </Grid>
                    <Grid item>
                      <Select
                        fullWidth
                        value={userSettings.defaultDayOfWeekStart}
                        onChange={e => saveChanges("defaultDayOfWeekStart", e.target.value)}
                        sx={{ minWidth: "8rem" }}
                      >
                        <MenuItem value={DayOfWeekOrder.SUNDAY_FIRST}>Sunday First</MenuItem>
                        <MenuItem value={DayOfWeekOrder.MONDAY_FIRST}>Monday First</MenuItem>
                        <MenuItem value={DayOfWeekOrder.SATURDAY_FIRST}>Saturday First</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserSettings;
