import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography
} from "@mui/material";
import { DataGrid, GridCellEditCommitParams, GridColumns } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { ClipboardEvent, useEffect, useState } from "react";

import { Center } from "../../../models/Center";
import { CenterGoal } from "../../../models/CenterGoal";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import { AdminTool } from "../Administration";
import AdministrationTool from "../AdministrationTool";

interface MonthGoals {
  january?: number;
  february?: number;
  march?: number;
  april?: number;
  may?: number;
  june?: number;
  july?: number;
  august?: number;
  september?: number;
  october?: number;
  november?: number;
  december?: number;
}
interface CenterYearGoal extends MonthGoals {
  id: string;
  centerName?: string;
}

const monthNameToValue: Record<keyof MonthGoals, number> = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11
};
const monthValueToName: Record<number, keyof MonthGoals> = {
  0: "january",
  1: "february",
  2: "march",
  3: "april",
  4: "may",
  5: "june",
  6: "july",
  7: "august",
  8: "september",
  9: "october",
  10: "november",
  11: "december"
};
const alphabeticalComparator = (item1: CenterYearGoal, item2: CenterYearGoal) =>
  (item1.centerName || "") > (item2.centerName || "") ? 1 : -1;
const yearNow = new Date().getFullYear();
const years: number[] = [];
for (let x = -5; x < 6; x++) years.push(yearNow + x);
enum Exceptions {
  NoData = "There are no goals set for this calendar year."
}
const buildSpreadsheetCompatibleDataString = (goals: CenterYearGoal[], centers: Center[], year: number): string => {
  // add data title and column headers
  let value = `All Center TMC Goals for the Year ${year}`;
  value += "\nCenter\tJanuary\tFebruary\tMarch\tApril\tMay\tJune\tJuly\tAugust\tSeptember\tOctober\tNovember\tDecember";

  // populate a row for each center
  centers.forEach(center => {
    let rowText = center.name;
    const centerGoals = goals.find(goal => goal.centerName === center.name);
    // if there is no data, append an empty row
    if (!centerGoals) {
      value += `\n${rowText}\t\t\t\t\t\t\t\t\t\t\t\t`;
      return;
    }
    // otherwise, fill the row with existing goal values
    monthGoalKeys.forEach(monthKey => {
      rowText += `\t${centerGoals[monthKey] || ""}`;
    });
    value += `\n${rowText}`;
  });
  return value;
};
const initialGridColumns: GridColumns = [
  {
    field: "centerName",
    headerName: "Center",
    disableColumnMenu: true,
    minWidth: 200,
    editable: false
  }
];
const monthGoalKeys: (keyof MonthGoals)[] = Object.keys(monthNameToValue) as (keyof MonthGoals)[];
monthGoalKeys.forEach(monthName =>
  initialGridColumns.push({
    field: monthName,
    headerName: `${monthName.substring(0, 1).toUpperCase()}${monthName.substring(1)}`,
    type: "number",
    disableColumnMenu: true,
    resizable: false,
    sortable: false,
    editable: false,
    width: 120
  })
);
const mapGoalsModelToType = (data: Record<string, CenterGoal[]>): CenterYearGoal[] =>
  Object.values(data).map(monthGoals => {
    const goal: CenterYearGoal = { id: monthGoals[0].center.id, centerName: monthGoals[0].center.name };
    monthGoals.forEach(monthGoal => (goal[monthValueToName[new Date(monthGoal.month).getMonth()]] = monthGoal.value));
    return goal;
  });

const toolReadme = [
  "The Center TMC Goals tool allows the user to view and modify the assigned goals for all centers for a given year.",
  "You can view the current goals by selecting a year from the dropdown.",
  "To edit the goals, you must first toggle on the editing mode which will then allow you to directly change the values in the table, or paste in table data that you wish to be added."
];

/** The view for viewing and updating Center TMC Goals for a specified year. */
const CenterGoals: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [year, setYear] = useState(yearNow);
  const [goals, setGoals] = useState<CenterYearGoal[]>([]);
  const [originalGoals, setOriginalGoals] = useState<CenterYearGoal[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [columns, setColumns] = useState(initialGridColumns);

  // #region Get Centers
  const [centersRequest] = useState<UseCbpProps>({
    name: "Get Centers",
    request: { url: "/centers", config: { params: { isAdmin: true } } }
  });
  const { response: centers } = useCbp<Center[]>(centersRequest);
  // #endregion

  // #region Get Center TMC Goals
  const [getRequest, setGetRequest] = useState<UseCbpProps>({
    name: "Get Center TMC Goals",
    request: { url: "centers/goals", config: { params: { year: new Date(year, 0).toISOString() } } }
  });
  const { response: getGoalsResponse } = useCbp<Record<string, CenterGoal[]>>(getRequest);
  useEffect(() => {
    if (!getGoalsResponse) return;
    const newGoals = mapGoalsModelToType(getGoalsResponse);
    newGoals.sort(alphabeticalComparator);
    setOriginalGoals(newGoals.map(goal => ({ ...goal })));
    setGoals(newGoals);
  }, [getGoalsResponse]);
  // #endregion

  // #region Update Center TMC Goals
  const [updateRequest, setUpdateRequest] = useState<UseCbpProps<CenterGoal[]>>();
  const { response: updateResponse, isLoading: updateLoading } = useCbp<CenterGoal[], CenterGoal[]>(updateRequest);
  useEffect(() => {
    if (updateResponse) {
      setOriginalGoals(goals => {
        const newGoals = [...goals];
        updateResponse.forEach(updatedGoal => {
          const index = newGoals.findIndex(g => g.centerName === updatedGoal.center.name);
          const goalMonth = monthValueToName[new Date(updatedGoal.month).getMonth()];
          if (index === -1)
            newGoals.push({
              id: updatedGoal.center.id,
              centerName: updatedGoal.center.name,
              [goalMonth]: updatedGoal.value
            });
          else newGoals[index][goalMonth] = updatedGoal.value;
        });
        newGoals.sort(alphabeticalComparator);
        setGoals(newGoals.map(goal => ({ ...goal })));
        return newGoals;
      });
      setColumns(cols => cols.map(col => ({ ...col, editable: false })));
      setIsEditing(false);
      enqueueSnackbar("Center TMC Goals Updated", { variant: "success" });
    }
  }, [updateResponse]);
  // #endregion

  // #region Actions
  const onYearChange = (event: SelectChangeEvent<number>) => {
    const year = Number(event.target.value) || yearNow;
    setYear(oldYear => {
      if (oldYear !== year)
        setGetRequest({
          name: "Get Center TMC Goals",
          request: { url: "centers/goals", config: { params: { year: new Date(year, 0).toISOString() } } }
        });
      return year;
    });
  };
  const onPaste = (e: ClipboardEvent<HTMLDivElement>) => {
    // Do not handle past when an input element is currently focused
    if ((e.target as HTMLDivElement).tagName && (e.target as HTMLDivElement).tagName.match(/(input|textarea)/i)) return;
    // Get clipboard data as text
    const data = e.clipboardData!.getData("text");
    // if data exactly matches previous entry or is empty, return
    if (data === "") return;
    // Simplified parsing of the TSV data with hard-coded columns
    const rows = data.split("\n").filter(value => value !== "");
    const newGoals: CenterYearGoal[] = [];
    rows.forEach((row, rowIndex) => {
      let centerName: string | undefined = undefined;
      try {
        const cellValues = row.split("\t");
        centerName = cellValues.shift();
        // if the first cell does not exactly match a center name, skip the whole row
        if (!centers!.some(c => c.name === centerName)) return;
        // otherwise, parse any numerical cell values for the next 12 cells
        const goalCells = cellValues.map((cell, cellIndex) => {
          let value: number;
          try {
            if (cell !== "") value = Number(cell);
            else return undefined;
          } catch (e) {
            console.log(
              `Failed to parse table data for center ${centerName} cell ${
                cellIndex + 1
              }: "${cell}". Inner Exception: ${e}`
            );
            enqueueSnackbar(
              `Failed to parse table data for row ${centerName} cell ${
                cellIndex + 1
              }: "${cell}". Be sure to only use an integer numerical value.`,
              { variant: "error" }
            );
            return undefined;
          }
          return value;
        });
        // if none of the goal cells contain a non-zero value, skip the whole row
        if (!goalCells.some(value => value)) return;
        const newGoal: CenterYearGoal = { id: centers!.find(c => c.name === centerName)!.id, centerName };
        monthGoalKeys.forEach((monthName, index) => {
          newGoal[monthName] = goalCells[index];
        });
        newGoals.push(newGoal);
      } catch (e) {
        enqueueSnackbar(
          `Unknown error on row ${
            rowIndex + 1
          }. Be sure the first cell is a center name, and all other goal values are integers.`,
          { variant: "error" }
        );
      }
    });
    if (newGoals.length)
      setGoals(oldGoals => {
        oldGoals.forEach(oldGoal => {
          if (!newGoals.some(goal => goal.id === oldGoal.id)) newGoals.push({ ...oldGoal });
        });
        newGoals.sort(alphabeticalComparator);
        return newGoals;
      });
    else {
      enqueueSnackbar("The Pastebin is Empty or Unreadable", { variant: "warning" });
      setGoals([]);
    }
  };
  const onSubmit = () => {
    try {
      // build CenterGoal models from state data
      let centerGoals: CenterGoal[] = [];
      // for each center row
      goals.forEach(yearGoal => {
        const center = centers!.find(c => c.name === yearGoal.centerName);
        if (!center) throw "Center Not Found";
        const goals: CenterGoal[] = [];
        const previousYearGoal = originalGoals.find(goal => goal.centerName === center.name);
        // for each month in the row
        monthGoalKeys.forEach((key, index) => {
          const value = yearGoal[key];
          const previousGoal = previousYearGoal ? previousYearGoal[key] : undefined;
          // only add goals that have changed from the previous value
          if (value !== previousGoal)
            goals.push({ center, value: value || 0, month: DateTime.fromJSDate(new Date(year, index, 1)).toISODate() });
        });
        centerGoals = centerGoals.concat(goals);
      });
      if (!centerGoals.length) enqueueSnackbar("No Changes Detected", { variant: "warning" });
      // make the update request
      else
        setUpdateRequest({
          name: "Update Centers",
          request: { url: "centers/goals", method: "put", data: centerGoals }
        });
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Failed to Parse Table Data`, { variant: "error" });
    }
  };
  const onCopy = () => {
    try {
      navigator.clipboard.writeText(
        buildSpreadsheetCompatibleDataString(isEditing ? goals : originalGoals, centers!, year)
      );
      enqueueSnackbar("Table Copied", { variant: "info" });
    } catch (e) {
      switch (e) {
        case Exceptions.NoData:
          enqueueSnackbar(Exceptions.NoData, { variant: "warning" });
          break;
        default:
          console.log(`Unhandled Exception: ${e}`);
          enqueueSnackbar("An unknown error occurred, please contact IT.", { variant: "error" });
      }
    }
  };
  const onToggleEditing = () =>
    setIsEditing(oldIsEditing => {
      const isEditing = !oldIsEditing;
      if (!isEditing) {
        // reset goals table values
        setGoals(originalGoals.map(goal => ({ ...goal })));
        // set goal columns not editable
        setColumns(cols => cols.map(col => ({ ...col, editable: false })));
      } else {
        // add empty rows for centers without goals
        setGoals(goals => {
          const newGoals = [...goals];
          centers!.forEach(center => {
            if (!newGoals.some(g => g.centerName === center.name))
              newGoals.push({ id: center.id, centerName: center.name });
          });
          newGoals.sort(alphabeticalComparator);
          return newGoals;
        });
        // set goal columns as editable
        setColumns(cols => cols.map((col, index) => ({ ...col, editable: index !== 0 })));
      }
      return isEditing;
    });
  const onCellEditCommit = (params: GridCellEditCommitParams) => {
    if (params?.id && params?.field && params?.value)
      setGoals(goals => {
        const newGoals = [...goals];
        const rowIndex = newGoals.findIndex(g => g.id === params.id);
        if (rowIndex === -1) throw "row not found";
        newGoals[rowIndex][params.field as keyof MonthGoals] =
          params.value && params.value > 0 ? Number(params.value) : 0;
        return newGoals;
      });
  };
  // #endregion

  // show spinner while loading
  useEffect(() => setIsContentLoading(!centers), [centers]);

  return (
    <AdministrationTool title="Center TMC Goals" readme={toolReadme} sx={{ width: "100%", height: "100%" }}>
      <Grid className="w-100 h-100" container direction="column" spacing={3} wrap="nowrap">
        <Grid item container spacing={2} justifyContent="space-evenly" wrap="nowrap">
          <Grid item>
            <FormControl fullWidth>
              <InputLabel>Year</InputLabel>
              <Select
                label="Year"
                value={year}
                disabled={updateLoading || isEditing}
                onChange={onYearChange}
                sx={{ minWidth: "6rem" }}
              >
                {years.map(year => (
                  <MenuItem value={year} key={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {isEditing ? (
            <Grid item>
              <Button startIcon={<CancelIcon />} onClick={onToggleEditing} color="secondary">
                Cancel Changes
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Button startIcon={<EditIcon />} onClick={onToggleEditing}>
                Edit Goals
              </Button>
            </Grid>
          )}
          <Grid item>
            <Tooltip
              title={`Copies the current goals for the year ${year} and places a spreadsheet compatible version onto your clipboard.`}
            >
              <Button startIcon={<FileCopyIcon />} color="secondary" onClick={onCopy}>
                {`Copy ${isEditing ? "Change" : "Year"} To Clipboard`}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        {isEditing && (
          <Grid item>
            <Typography variant="body1">
              To edit data: double click on the value you wish to update, enter a new value, and press enter to commit
              the change.
            </Typography>
            <Typography variant="body1">
              To import data: click on a center name in the grid and paste copied excel data with Ctrl+V or ⌘+V.
            </Typography>
          </Grid>
        )}
        <Grid item xs sx={{ display: "flex" }}>
          <div className="w-100" onPaste={e => isEditing && onPaste(e)} style={{ flexGrow: 1, height: "100%" }}>
            <DataGrid rows={goals} columns={columns} disableSelectionOnClick onCellEditCommit={onCellEditCommit} />
          </div>
        </Grid>
        {isEditing && (
          <Grid item container justifyContent="flex-end" spacing={2}>
            <Grid item>
              {updateLoading ? (
                <CircularProgress />
              ) : (
                <Button onClick={onSubmit} disabled={goals.length === 0}>
                  Submit
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </AdministrationTool>
  );
};

export default CenterGoals;
