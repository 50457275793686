import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { Center, CenterSchedule, SimpleCenter } from "../../../models/Center";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import { AdminTool } from "../Administration";
import AdministrationFormLabel from "../AdministrationFormLabel";
import AdministrationTool from "../AdministrationTool";

interface CenterDayOption {
  dayOfWeek: keyof CenterSchedule;
  isOpen: boolean;
}
const keysOfCenterSchedule: (keyof CenterSchedule)[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];
const getDayOptions = (center?: Center): CenterDayOption[] =>
  keysOfCenterSchedule.map(dayOfWeek => ({ dayOfWeek, isOpen: center!.schedule[dayOfWeek] }));
const toStartCase = (string: string): string => `${string.substr(0, 1).toUpperCase()}${string.substr(1).toLowerCase()}`;

const toolReadme = [
  'The Center Work Week tool allows you to modify days of operation of the week for a given center. This is a mostly cosmetic setting, which is used in the Planner with the view setting "Only Work Week".'
];

const CenterWorkWeekConfig: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [center, setCenter] = useState<Center>();
  const [dayOptions, setDayOptions] = useState<CenterDayOption[]>([]);
  const [selectedCenter, setSelectedCenter] = useState<SimpleCenter>();
  const [selectedDay, setSelectedDay] = useState<CenterDayOption>();

  const [centersRequest] = useState({ name: "Get Centers", request: { url: "/centers" } });
  const { response: centersResponse } = useCbp<Center[]>(centersRequest);

  // #region Get Center
  const [getCenterRequest, setGetCenterRequest] = useState<UseCbpProps>();
  const { response: getCenterResponse } = useCbp<Center>(getCenterRequest);
  useEffect(() => {
    if (getCenterResponse) {
      setCenter(getCenterResponse);
      setDayOptions(getDayOptions(getCenterResponse));
    }
  }, [getCenterResponse]);
  // #endregion

  // #region Update Center
  const [updateCenterRequest, setUpdateCenterRequest] = useState<UseCbpProps<Center>>();
  const { response: updateCenterResponse, isLoading: loadingUpdateCenter } = useCbp<Center, Center>(
    updateCenterRequest
  );
  useEffect(() => {
    if (updateCenterResponse) {
      setCenter(updateCenterResponse);
      setSelectedDay(selectedDay => {
        setDayOptions(options => {
          const index = options.findIndex(o => o.dayOfWeek === selectedDay!.dayOfWeek);
          if (index !== -1) options[index] = selectedDay!;
          return [...options];
        });
        return undefined;
      });
      enqueueSnackbar("Center Updated", { variant: "success" });
    }
  }, [updateCenterResponse]);
  // #endregion

  // #region Actions
  const onCenterSelect = (_: unknown, center: SimpleCenter | null) => {
    if (center) setGetCenterRequest({ name: "Get Center", request: { url: `centers/${center.id}` } });
    else setDayOptions([]);
    setSelectedCenter(center || undefined);
    setSelectedDay(undefined);
  };
  const onSelectDay = (dayOption?: CenterDayOption) => setSelectedDay(dayOption);
  const onToggleChange = (_: unknown, checked: boolean) => setSelectedDay(sc => ({ ...sc!, isOpen: checked }));
  const onSaveChanges = () =>
    setUpdateCenterRequest({
      name: "Update Center",
      request: {
        url: `centers/${selectedCenter!.id}`,
        method: "put",
        data: { ...center!, schedule: { ...center!.schedule, [selectedDay!.dayOfWeek]: selectedDay!.isOpen } }
      }
    });
  // #endregion

  // show spinner while loading
  useEffect(() => setIsContentLoading(!centersResponse), [centersResponse]);

  return (
    <AdministrationTool title="Center Work Week" readme={toolReadme} sx={{ minWidth: "40rem", minHeight: "30rem" }}>
      <Grid className="m-0 w-100" container spacing={3} direction="column">
        <Grid item container spacing={2} justifyContent="center">
          <Autocomplete
            options={centersResponse || []}
            renderInput={params => <TextField {...params} label="Center" />}
            getOptionLabel={center => center.name}
            value={selectedCenter || null}
            onChange={onCenterSelect}
            sx={{ minWidth: "20rem" }}
          />
        </Grid>
        <Grid item container>
          <Grid item container xs direction="column" alignItems="center" spacing={2}>
            <SelectList
              title="Week Days"
              items={dayOptions}
              value={selectedDay || null}
              itemKey="dayOfWeek"
              onChange={selectedDay => onSelectDay(selectedDay || undefined)}
              disabled={loadingUpdateCenter || !selectedCenter}
              listItem={DayItem}
            />
          </Grid>
          <Grid item container xs direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6"></Typography>
              <AdministrationFormLabel
                label={`Settings for ${selectedCenter ? selectedCenter.name : "Center"}`}
                readme={[
                  'Centers that are open on the selected day will be included in the Planner "Work Days Only" filter.',
                  "Centers that are not open on the selected day will not be included in the filter."
                ]}
              />
            </Grid>
            {selectedDay && (
              <Grid item container className={selectedCenter ? "editing" : ""} spacing={2} justifyContent="center">
                <FormControlLabel
                  control={<Switch />}
                  disabled={!selectedCenter}
                  checked={selectedDay?.isOpen === true}
                  onChange={onToggleChange}
                  label={`Open on ${selectedDay ? toStartCase(selectedDay.dayOfWeek) : ""}`}
                />
              </Grid>
            )}
            <Grid item>
              <Button
                disabled={
                  !selectedDay ||
                  loadingUpdateCenter ||
                  selectedDay.isOpen === dayOptions.find(day => day.dayOfWeek === selectedDay.dayOfWeek)!.isOpen
                }
                onClick={onSaveChanges}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const DayItem: React.FC<CustomListItem<CenterDayOption>> = ({
  data: dayOption,
  selected,
  ...rest
}: CustomListItem<CenterDayOption>) => {
  return (
    <ListItem {...(rest as Record<string, unknown>)}>
      <ListItemIcon>
        <Checkbox edge="start" checked={selected} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText primary={toStartCase(dayOption.dayOfWeek)} />
      {dayOption.isOpen && (
        <ListItemIcon>
          <Chip size="small" label="Open" />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

export default CenterWorkWeekConfig;
