import { Card, CardContent, CardHeader, Grid, styled } from "@mui/material";
import React from "react";

import { CalendarDayCardProps } from "./types";

export const UnstyledCalendarDayCard = <T,>({
  isInMonth,
  date,
  children,
  ...rest
}: CalendarDayCardProps<T>): JSX.Element => {
  return (
    <Card {...rest}>
      {isInMonth && (
        <>
          <CardHeader title={date.day} />
          <CardContent>
            <Grid container direction="column" rowSpacing={0.3} wrap="nowrap">
              {children}
            </Grid>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export interface StyledCalendarDayCardProps<T = never> extends CalendarDayCardProps<T> {
  /** Component color. */
  color?: "today" | "inMonth" | "outOfMonth";
}

/** Styled */
const CalendarDayCard = styled(UnstyledCalendarDayCard, {
  shouldForwardProp: prop => prop !== "color"
})<StyledCalendarDayCardProps>(({ color = "inMonth", theme }) => ({
  "&.MuiCard-root": {
    height: "100%",
    backgroundColor: color === "outOfMonth" ? theme.palette.action.disabled : undefined,
    borderRadius: 2
  },
  "& .MuiCardContent-root": { p: 1, height: "100%" },
  "& .MuiCardHeader-root": {
    paddingTop: 0.5,
    paddingBottom: 0.5,
    backgroundColor: theme.palette.grey[300],
    ...(color === "today" && {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    })
  },
  "& .MuiCardHeader-root .MuiTypography-root": { ...theme.typography.body1, textAlign: "right" }
}));

export default CalendarDayCard;
