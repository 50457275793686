import BlockIcon from "@mui/icons-material/Block";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { Grid, Tooltip, Typography } from "@mui/material";
import React from "react";

import { BlockType } from "../../../models/Block";
import DateTime from "../../../types/DateTime";
import { DayViewSchedulerItem } from "../../../types/DayViewSchedulerTypes";
import OverflowDetector, { OverflowItem } from "../../Helpers/OverflowDetector";
import SchedulerItem from "../../Helpers/Scheduler/SchedulerItem";
import { SchedulerItemProps } from "../../Helpers/Scheduler/types";
import ScheduleBox from "./DayViewScheduleBox";

type BlockSchedulerItemBodyProps = SchedulerItemProps<DayViewSchedulerItem> & OverflowItem;

const BlockSchedulerItemBody: React.FC<BlockSchedulerItemBodyProps> = ({
  isOverflowing,
  item,
  orientation
}: BlockSchedulerItemBodyProps) => {
  const timeString = `${item.startDateTime.toLocaleString(DateTime.TIME_SIMPLE)} - ${item.endDateTime.toLocaleString(
    DateTime.TIME_SIMPLE
  )}`;
  const timespanString = `${item.timeSpanMinutes} Minutes`;
  return (
    <ScheduleBox
      variant={
        item.error ? "error" : item.block?.blockType || (item.appointment?.isSurgery ? "surgeryAppt" : "otherAppt")
      }
      sx={theme => ({ height: "100%", width: "100%", borderRadius: theme.shape.borderRadius })}
    >
      <Grid
        container
        direction={orientation === "horizontal" ? "row" : "column"}
        justifyContent="center"
        alignItems="center"
        spacing={1}
        onClick={event => item.onClick(event, item)}
        sx={{ m: 0, height: "100%", width: "100%" }}
      >
        {!isOverflowing ? (
          <>
            {item.error && (
              <Grid item>
                <Tooltip title={`${item.error.message} ${item.error.description || ""}`}>
                  <ErrorOutlineIcon />
                </Tooltip>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body2" component="span">
                {timeString}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="span">
                {timespanString}
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid container className="h-100" alignItems="center" justifyContent="center">
            {item.appointment ? (
              <EventIcon />
            ) : item.block?.blockType === BlockType.Slot ? (
              <EventAvailableIcon />
            ) : (
              <BlockIcon />
            )}
            {item.error && (
              <Tooltip title={`${item.error.message} ${item.error.description || ""}`}>
                <ErrorOutlineIcon />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </ScheduleBox>
  );
};

const BlockSchedulerItem: React.FC<SchedulerItemProps<DayViewSchedulerItem>> = ({
  item,
  orientation,
  isMovingDisabled,
  isResizingDisabled,
  ...rest
}: SchedulerItemProps<DayViewSchedulerItem>) => {
  // disable move/resize for slots when a surgeon is not assigned.
  const resizingDisabled =
    isResizingDisabled || (item.block?.blockType === BlockType.Slot && !item.block.surgeonId) || false;
  const movingDisabled =
    isMovingDisabled || (item.block?.blockType === BlockType.Slot && !item.block.surgeonId) || false;
  return (
    // TODO use styled SchedulerItem
    <SchedulerItem
      item={item}
      orientation={orientation}
      isResizingDisabled={resizingDisabled}
      isMovingDisabled={movingDisabled}
      {...rest}
    >
      <OverflowDetector>
        <BlockSchedulerItemBody
          item={item}
          orientation={orientation}
          isResizingDisabled={resizingDisabled}
          isMovingDisabled={movingDisabled}
          {...rest}
        />
      </OverflowDetector>
    </SchedulerItem>
  );
};

export default BlockSchedulerItem;
