import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";

import { WeightCategory } from "../../../models/WeightCategory";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import ValidationButton from "../../Helpers/ValidationButton";
import { AdminTool } from "../Administration";
import AdministrationTool from "../AdministrationTool";

const alphabeticalComparator = (item1: { name: string }, item2: { name: string }) => (item1.name > item2.name ? 1 : -1);

const baseRequest = { url: "weightCategories" };

const toolReadme = [
  "The Weight Categories Tool allows you to create, rename, and reconfigure surgeon weight categories.",
  "Note: Making changes to an existing weight category may have significant consequences for the data viewed in the Planner. Act wisely."
];

const WeightCategoryConfig: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCategory, setSelectedCategory] = useState<WeightCategory>();
  const [categories, setCategories] = useState<WeightCategory[]>();

  //#region GET Categories
  const [getCategoriesRequest] = useState(
    categories ? undefined : { name: "Get Weight Categories", request: baseRequest }
  );
  const { response: getCategoriesResponse, isLoading: getIsLoading } = useCbp<WeightCategory[]>(getCategoriesRequest);
  useEffect(() => {
    if (getCategoriesResponse) {
      setCategories(
        getCategoriesResponse.map(category => ({ ...category, selected: false })).sort(WeightCategory.ValueComparator)
      );
    }
  }, [getCategoriesResponse]);
  //#endregion
  //#region Add or Update WeightCategory Request
  const [addUpdateCategoriesRequest, setAddUpdateCategoriesRequest] = useState<UseCbpProps<WeightCategory>>();
  const { response: addUpdateCategory, isLoading: addUpdateIsLoading } = useCbp<WeightCategory, WeightCategory>(
    addUpdateCategoriesRequest
  );
  useEffect(() => {
    if (addUpdateCategory) {
      setCategories(categories => {
        if (!categories) categories = [];
        const existingIndex = categories?.findIndex(c => c.id === addUpdateCategory.id);
        if (existingIndex > -1) categories[existingIndex] = addUpdateCategory;
        else categories.push(addUpdateCategory);
        categories.sort(alphabeticalComparator);
        setSelectedCategory(undefined);
        return [...categories].sort(WeightCategory.ValueComparator);
      });
      enqueueSnackbar("Weight Category Updated", { variant: "success" });
    }
  }, [addUpdateCategory]);
  //#endregion
  const onNewCategoryClick = () => setSelectedCategory({ id: "", name: "", weightValue: 0 });
  const onNameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setSelectedCategory(category => ({ ...category!, name: e.target.value }));
  const onValueChange = (e: ChangeEvent<HTMLInputElement>) =>
    setSelectedCategory(category => ({
      ...category!,
      weightValue: e.target.value === "" ? 0 : Math.min(Math.max(0, Number(e.target.value)), 99)
    }));
  const onSaveWeightCategoryChanges = () =>
    setAddUpdateCategoriesRequest({
      name: "Update Weight Categories",
      request: {
        url: selectedCategory!.id ? `${baseRequest.url}/${selectedCategory!.id}` : baseRequest.url,
        method: selectedCategory!.id ? "put" : "post",
        data: selectedCategory
      }
    });

  // show spinner while loading
  useEffect(() => setIsContentLoading(!categories), [categories]);

  const validationMessage =
    selectedCategory && (!selectedCategory.name || !selectedCategory.weightValue)
      ? "Name and weight are required."
      : selectedCategory &&
        categories?.some(
          e =>
            e.id != selectedCategory.id &&
            (e.name === selectedCategory?.name || e.weightValue === selectedCategory?.weightValue)
        )
      ? "Another Category has a duplicate name or value."
      : "";
  const disableSave =
    !selectedCategory ||
    JSON.stringify(selectedCategory) === JSON.stringify(categories?.find(c => c.id === selectedCategory.id));

  return (
    <AdministrationTool title="Surgeon Weight Categories" readme={toolReadme} sx={{ minWidth: "40rem" }}>
      <Grid className="m-0 w-100" container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item container xs direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6">Weight Categories</Typography>
            </Grid>
            <Grid item className="w-100">
              <SelectList
                items={categories}
                value={selectedCategory || null}
                itemKey="id"
                disabled={getIsLoading || addUpdateIsLoading}
                onChange={category => setSelectedCategory(category || undefined)}
                listItem={WeightCategoryItem}
              />
            </Grid>
            <Grid item>
              <Button onClick={onNewCategoryClick}>Add New Category</Button>
            </Grid>
          </Grid>
          <Grid item container xs direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="h6">
                Settings
                {selectedCategory
                  ? selectedCategory.id
                    ? ` for ${categories!.find(c => c.id === selectedCategory.id)?.name}`
                    : " for a New Category"
                  : ""}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                disabled={!selectedCategory}
                label="Name"
                value={selectedCategory?.name || ""}
                onChange={onNameChange}
                sx={{ minWidth: "20rem" }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                disabled={!selectedCategory}
                label="Weight"
                type="number"
                value={selectedCategory?.weightValue || ""}
                onChange={onValueChange}
                sx={{ minWidth: "20rem" }}
              />
            </Grid>
            <Grid item>
              {addUpdateIsLoading ? (
                <CircularProgress />
              ) : (
                <ValidationButton
                  disabled={disableSave}
                  message={validationMessage}
                  onClick={onSaveWeightCategoryChanges}
                >
                  Save Changes
                </ValidationButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const WeightCategoryItem: React.FC<CustomListItem<WeightCategory>> = ({
  data,
  selected,
  ...rest
}: CustomListItem<WeightCategory>) => {
  return (
    <ListItem {...(rest as Record<string, unknown>)}>
      <ListItemIcon>
        <Checkbox edge="start" checked={selected} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText primary={data.name} />
      <ListItemIcon>
        <Chip size="small" label={data.weightValue} />
      </ListItemIcon>
    </ListItem>
  );
};

export default WeightCategoryConfig;
