import { DateTime, Interval } from "luxon";
import { FunctionComponent } from "react";

export type SchedulerOrientation = "horizontal" | "vertical";
export enum DragHandle {
  LEFT = "leftDragHandle",
  RIGHT = "rightDragHandle"
}

/** The requisite properties for an object rendered as a time block on the component. */
export class ScheduleItem {
  /** A unique identifier for this item. Required for drag and drop functionality. */
  id!: string;
  /** The datetime marking the start of the time span for the item. */
  startDateTime!: DateTime;
  /** The datetime marking the end of the time span for the item. */
  endDateTime!: DateTime;
  /** To keep from needing  */
  startDateTimeSet?: boolean;
  /** The number of minutes separating the start and end timespans. */
  timeSpanMinutes!: number;
  /** The z index to render the item on. Items without an index are rendered on the bottom, and higher indices
   *  are rendered on top of lower indices.
   */
  zIndex?: number;
  /** Function which returns whether or not this item should be allowed to be dropped into a given time target.
   * Accepts the row being considered, the item being moved, and the time target to be considered for dropping.
   * If not provided, the target is not draggable.
   */
  canDrop?: (row: ScheduleRow<ScheduleItem>, item: ScheduleItem, time: DateTime) => boolean;
  /** Function returns whether or not this item should be allowed to be resized into the given time target.
   * Accepts the row being considered, the item being moved, the time target to be considered for dropping, and the handle being dragged.
   * If not provided, the target cannot be resized by dragging.
   */
  canResize?: (row: ScheduleRow<ScheduleItem>, item: ScheduleItem, dragHandle: DragHandle, time: DateTime) => boolean;

  /** Comparator that orders schedule items by chronological order ascending. */
  static chronoComparator(
    item1: Pick<ScheduleItem, "startDateTime" | "endDateTime">,
    item2: Pick<ScheduleItem, "startDateTime" | "endDateTime">
  ): number {
    return item1.startDateTime > item2.startDateTime ? 1 : -1;
  }
}

/** The props available to a custom rendered item component. */
export interface SchedulerItemProps<T extends ScheduleItem> {
  /** Flag indicating if the item's ability to resize via drag and drop resize handles is disabled.*/
  isResizingDisabled: boolean;
  /** Flag indicating if the item's ability to move via drag and drop is disabled. */
  isMovingDisabled: boolean;
  /** The custom item data belonging to this item. */
  item: T;
  /** The orientation of the scheduler. */
  orientation: "horizontal" | "vertical";
  /** The index of the row to which this item belongs. */
  rowIndex: number;
  /** The index of the position within the row to which this item belongs. */
  itemIndex: number;
  /** The number of minutes between this one and the prior one. */
  gapMinutes?: number;
  /** The number of minutes of time incremented by the scheduler x axis. */
  timeIncrement: number;
  /** The number of units of render distance associated with a single increment of time on the scheduler x axis. */
  timeIncrementWidth: number;
  /** The z index of the item to be drawn.
   * Undefined indices are drawn on the bottom, following by increasing index values.
   * */
  zIndex?: number;
}
export interface SchedulerRowProps<TItem extends ScheduleItem, TRow extends ScheduleRow<TItem>>
  extends Pick<SchedulerProps<TItem, TRow>, "canPlaceModeClick" | "formatZoneLabel" | "placeModeFirstTarget"> {
  /** The type of item being dragged by the cursor. Undefined if nothing is being dragged. */
  dragItemType?: "item" | DragHandle;
  /** Flag indicating if place mode is active. */
  isPlaceModeActive: boolean;
  /** The orientation of the scheduler. */
  orientation: SchedulerOrientation;
  /** The number of minutes time is incremented each column in the row. */
  timeIncrement: number;
  /** The number of pixels of space that each time increment should be rendered at. */
  timeIncrementWidth: number;
  /** The interval of time that the row should render, broken into its individual time increments. */
  timeRange: Interval;
  /** The handler which determines whether the currently hovered time can receive the dragged item. */
  canDrop?: (time: DateTime) => boolean;
  /** The handler to be executed when a verified allowed hovered time receives a dragged item. */
  onDrop?: (startDateTime: DateTime, item: TItem) => void;
  /** Callback for when a time target is clicked while in place mode.
   * If not provided, place mode functionality is disabled.
   */
  onPlaceModeClick?: (targetDateTime: DateTime) => void;
}

/** The requisite properties for a row to be rendered within the component. */
export interface ScheduleRow<T extends ScheduleItem> {
  /** uuidv4 for comparison. */
  id: string;
  /** The schedule items to be rendered on this row. */
  items: T[];
}
/** The properties available to a custom rendered row header component. */
export interface SchedulerRowHeaderProps<TItem extends ScheduleItem, TRow extends ScheduleRow<TItem>>
  extends Pick<SchedulerProps<TItem, TRow>, "disabled" | "placeModeRowIndex"> {
  /** The data of this row to be rendered. */
  row: TRow;
  /** The index of this row within the list of rows to be rendered. */
  rowIndex: number;
}

export interface SchedulerBodyProps<TItem extends ScheduleItem, TRow extends ScheduleRow<TItem>>
  extends Pick<
    SchedulerProps<TItem, TRow>,
    "onDrop" | "onPlaceModeClick" | "onResize" | "canPlaceModeClick" | "formatZoneLabel" | "placeModeFirstTarget"
  > {
  disabled: boolean;
  orientation: SchedulerOrientation;
  rows: TRow[];
  ScheduleItem: React.FC<SchedulerItemProps<TItem>>;
  timeIncrement: number;
  timeIncrementWidth: number;
  timeRange: Interval;
  zIndexMap: Record<number, number>;
  placeModeRowIndex?: number;
}

export enum SchedulerTimeIncrementWidth {
  SMALL = 80,
  MEDIUM = 100,
  LARGE = 120
}

export enum SchedulerTimeIncrement {
  QUARTER_HOUR = 15,
  HALF_HOUR = 30,
  HOUR = 60
}

export const MinimumTimeSpanMinutes = 15;

export interface SchedulerProps<TItem extends ScheduleItem, TRow extends ScheduleRow<TItem>> {
  /** Flag indicating if editing should be disabled. */
  disabled?: boolean;
  /** Custom component of the column header times. */
  ColumnHeader?: FunctionComponent<{ time: DateTime }>;
  /** The desired orientation of the scheduler, either  `"horizontal"` or `"vertical"`.
   * @defaultValue `"horizontal"`.
   */
  orientation?: SchedulerOrientation;
  /** The schedule items that should be rendered in the form: `MyItem[itemsInARow][rowsOfItems]`. */
  rows: TRow[];
  /** Custom component of the content of a schedule item. */
  ScheduleItem?: React.FC<SchedulerItemProps<TItem>>;
  /** Custom components that should appear in front of each row of data, matched by index.*/
  RowHeader?: FunctionComponent<SchedulerRowHeaderProps<TItem, TRow>>;
  /** The value of time in minutes to increment the time headers.
   * @defaultValue `30`
   */
  timeIncrement?: number;
  /** The width in `px` that the incremented time on the x axis should use.
   * @defaultValue `100`.
   */
  timeIncrementWidth?: number;
  /** Custom set of the start/end time range of the scheduler.
   * @defaultValue the min start and max end times of the `items`.
   */
  timeRange?: Interval;
  /** The first selection target of Place Mode. */
  placeModeFirstTarget?: DateTime;
  /** Whether we are in Place Mode and which row is active. */
  placeModeRowIndex?: number;
  /** Define which times are valid targets when in place mode.
   * If not provided, no limitations are applied during place mode.
   */
  canPlaceModeClick?: (targetDateTime: DateTime) => boolean;
  /** Template function for modifying a scheduler zone's active label.
   * If a drag action is underway, the item type is provided.
   */
  formatZoneLabel?: (targetDateTime: DateTime, dragItemType?: "item" | DragHandle) => string;
  /** The handler to be executed when an item has been dragged to a new location.
   * If not provided, drag and drop functionality is disabled.
   */
  onDrop?: (rowIndex: number, startTime: DateTime, item: ScheduleItem) => void;
  /** Callback for when a time target is clicked while in place mode.
   * If not provided, place mode functionality is disabled.
   */
  onPlaceModeClick?: (rowIndex: number, targetDateTime: DateTime) => void;
  /** The handler to be executed when an item resize handle has been dragged to a new location.
   * If not provided, resize functionality is disabled.
   */
  onResize?: (rowIndex: number, time: DateTime, item: ScheduleItem, dragHandle: DragHandle) => void;
}

export interface ScheduleItemLocation {
  row: number;
  index: number;
}

export interface SchedulerRowDropZoneProps<TItem extends ScheduleItem> {
  /** Flag that indicates if the active status should be overridden as true. */
  activeOverrideEnabled: boolean;
  /** The item type that the drop zone should accept, if any. */
  accept?: string;
  /** Flag indicating if place mode is active. */
  isPlaceModeActive: boolean;
  /** The label to be displayed when active. */
  label?: string;
  /** The orientation of the scheduler. */
  orientation: SchedulerOrientation;
  /** The current value of the place mode item. */
  placeModeItem?: ScheduleItem;
  /** The size in pixels of the zone. */
  size: number;
  /** Handler action to be resolved when a drop zone is clicked. */
  onClick: () => void;
  /** Handler action to be resolved when a scheduler item is dropped in this zone. */
  onDrop: (item: TItem) => void;
}

export const dragNDropKey = (rowIndex: number): string => `row-${rowIndex}`;

export enum SchedulerClassKey {
  root,
  root_h = "sb_scheduler_root_h",
  root_v = "sb_scheduler_root_v",
  backgroundWrapper_h = "sb_scheduler_backgroundWrapper_h",
  backgroundWrapper_v = "sb_scheduler_backgroundWrapper_v",
  columnHeadersWrapper = "sb_scheduler_columnHeadersWrapper",
  columnHeadersWrapper_h = "sb_scheduler_columnHeadersWrapper_h",
  columnHeadersWrapper_v = "sb_scheduler_columnHeadersWrapper_v",
  columnHeaderSpacer_h = "sb_scheduler_columnHeaderSpacer_h",
  columnHeaderSpacer_v = "sb_scheduler_columnHeaderSpacer_v",
  columnHeaderTypography_h = "sb_scheduler_columnHeaderTypography_h",
  columnHeaderTypography_v = "sb_scheduler_columnHeaderTypography_v",
  dropZoneActive = "sb_scheduler_dropZoneActive",
  dropZoneVisible = "sb_scheduler_dropZoneVisible",
  itemRoot = "sb_scheduler_itemRoot",
  itemRoot_h = "sb_scheduler_itemRoot_h",
  itemRoot_v = "sb_scheduler_itemRoot_v",
  itemWrapper_h = "sb_scheduler_itemWrapper_h",
  itemWrapper_v = "sb_scheduler_itemWrapper_v",
  itemsWrapper = "sb_scheduler_itemsWrapper",
  itemsWrapper_h = "sb_scheduler_itemsWrapper_h",
  itemsWrapper_v = "sb_scheduler_itemsWrapper_v",
  rowHeader_h = "sb_scheduler_rowHeader_h",
  rowHeader_v = "sb_scheduler_rowHeader_v",
  dragHandle = "sb_scheduler_dragHandle",
  dragHandle_h = "sb_scheduler_dragHandle_h",
  dragHandle_v = "sb_scheduler_dragHandle_v"
}
