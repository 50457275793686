import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { Box, Button, Card, CircularProgress, Grid, Popover, Typography } from "@mui/material";
import { DateTime, Interval } from "luxon";
import { useSnackbar } from "notistack";
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { LocalStorageKeys } from "../../../Constants";
import { Block, BlockType, EnhancedBlock } from "../../../models/Block";
import { CbpError } from "../../../models/CbpError";
import { EnhancedCenter } from "../../../models/Center";
import { ReductionReason } from "../../../models/ReductionReason";
import { DayViewSchedulerItem, RoomRow, Schedule } from "../../../types/DayViewSchedulerTypes";
import { PlannerViewProps, PlannerViewType } from "../../../types/PlannerViewProps";
import { UserSettings } from "../../../types/UserSettings";
import { loadFromLocal } from "../../../utils/Helpers";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import Scheduler from "../../Helpers/Scheduler/Scheduler";
import {
  DragHandle,
  MinimumTimeSpanMinutes,
  ScheduleItem,
  ScheduleRow,
  SchedulerItemProps,
  SchedulerProps,
  SchedulerRowHeaderProps,
  SchedulerTimeIncrement,
  SchedulerTimeIncrementWidth
} from "../../Helpers/Scheduler/types";
import PlannerNavigation from "../PlannerNavigation";
import DayViewEditSurgeonForm from "./DayViewEditSurgeonForm";
import DayViewEditUnassignedForm from "./DayViewEditUnassignedForm";
import DayViewRowHeaderBuilder from "./DayViewRowHeader";
import ScheduleBox from "./DayViewScheduleBox";
import DayViewSchedulerItemComponent from "./DayViewSchedulerItem";
import DayViewSchedulerItemDetailForm from "./DayViewSchedulerItemDetailForm";
import DayViewSettingsForm from "./DayViewSettingsForm";

interface PutBlocksResponse {
  blocks: Block[];
  errors: CbpError<Block>[];
}

export interface SchedulerConfig
  extends Pick<SchedulerProps<ScheduleItem, RoomRow>, "timeIncrement" | "timeIncrementWidth" | "orientation"> {
  orderBy: "room" | "surgeon";
}

interface Props extends PlannerViewProps {
  /** The reasons for which a reduction of slots can be made. */
  reductionReasons: ReductionReason[];
}

const DayView: React.FC<Props> = ({
  calendarProps,
  centerHours,
  date,
  editDisabled,
  isLoading,
  isLoadingUpdate,
  reductionReasons,
  selectedSubCategories,
  serviceSubCategories,
  simpleCenter,
  simpleCenters,
  updateCalendar,
  setCenter,
  setCenterHoursRequest,
  setDate,
  setSelectedSubCategories
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showUnassignedForm, setShowUnassignedForm] = useState<{ anchor: Element; rowIndex: number }>();
  const [showEditForm, setShowEditForm] = useState<{ anchor: Element; rowIndex: number }>();
  const [showDetailForm, setShowDetailForm] = useState<{ anchor: Element; item: DayViewSchedulerItem }>();
  const [schedule, setSchedule] = useState<Schedule>({
    rows: [],
    bounds: Interval.fromDateTimes(date.set({ hour: 8 }), date.set({ hour: 17 })),
    placeModeRowIndex: undefined,
    placeModeFirstTarget: undefined
  });
  const [schedulerConfig, setSchedulerConfig] = useState<SchedulerConfig>(() => {
    const { defaultSchedulerIncrement, defaultSchedulerSize, defaultSchedulerOrder, defaultSchedulerOrientation } =
      loadFromLocal<UserSettings>(LocalStorageKeys.userSettings) || {};
    return {
      orderBy: defaultSchedulerOrder || "room",
      timeIncrement: defaultSchedulerIncrement || SchedulerTimeIncrement.HALF_HOUR,
      timeIncrementWidth: defaultSchedulerSize || SchedulerTimeIncrementWidth.MEDIUM,
      orientation: defaultSchedulerOrientation || "vertical"
    };
  });
  const centerRef = useRef<EnhancedCenter>();
  const centerHoursRef = useRef(centerHours);
  const editDisabledRef = useRef(editDisabled);
  const errorsRef = useRef<CbpError<Block>[]>([]);
  const schedulerRowHeaderRef = useRef(
    DayViewRowHeaderBuilder(
      (anchor, rowIndex) => setShowUnassignedForm({ anchor, rowIndex }),
      (anchor, rowIndex) => setShowEditForm({ anchor, rowIndex }),
      placeModeRowIndex =>
        setSchedule(s => {
          const disabling = s.placeModeRowIndex === placeModeRowIndex;
          return {
            ...s,
            placeModeFirstTarget: disabling ? undefined : s.placeModeFirstTarget,
            placeModeRowIndex: disabling ? undefined : placeModeRowIndex
          };
        })
    )
  );
  const schedulerItemOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, item: DayViewSchedulerItem) =>
      setShowDetailForm({ anchor: event.currentTarget, item }),
    []
  );
  const formatZoneLabel = useCallback(
    (targetDateTime: DateTime, dragItemType?: "item" | DragHandle) => {
      const renderedTime: DateTime =
        // show the end time of the zone if considering moving an end time
        dragItemType === DragHandle.RIGHT ||
        (schedule.placeModeFirstTarget && schedule.placeModeFirstTarget < targetDateTime)
          ? targetDateTime.plus({ minutes: MinimumTimeSpanMinutes })
          : // otherwise show the start time of the zone
            targetDateTime;
      return renderedTime.toLocaleString(DateTime.TIME_SIMPLE);
    },
    [schedule.placeModeFirstTarget]
  );
  const canPlaceModeClick = useCallback(
    (targetDateTime: DateTime) => {
      // if not in place mode, short circuit
      if (schedule.placeModeRowIndex === undefined) return false;
      // else if this is the first click, collide with blocks and non-surgeries
      if (!schedule.placeModeFirstTarget) {
        const obstacles = RoomRow.getObstacles(schedule.rows[schedule.placeModeRowIndex], ["block", "non-surgery"]);
        return !RoomRow.isObstacleCollision(obstacles, targetDateTime, MinimumTimeSpanMinutes);
      }
      // else this is the second click, validate the target by mimicking an item resize
      return RoomRow.canResize(
        schedule.rows[schedule.placeModeRowIndex],
        {
          id: "",
          block: { roomId: schedule.rows[schedule.placeModeRowIndex].roomId },
          startDateTime: schedule.placeModeFirstTarget,
          endDateTime: schedule.placeModeFirstTarget.plus({ minutes: MinimumTimeSpanMinutes })
        },
        targetDateTime < schedule.placeModeFirstTarget ? DragHandle.LEFT : DragHandle.RIGHT,
        targetDateTime
      );
    },
    [schedule]
  );

  // on receiving a new calendar update scheduler
  useEffect(() => {
    centerHoursRef.current = centerHours;
    if (!calendarProps || !centerHours) return;
    centerRef.current = calendarProps.center;
    const hours = centerHours[calendarProps.date.toISODate()];
    const rows = RoomRow.fromBaseArray(
      calendarProps!.calendar.days[calendarProps!.date.toISODate()],
      date,
      schedulerConfig.orderBy,
      errorsRef.current,
      schedulerItemOnClick
    );
    setSchedule({
      bounds: hours
        ? Interval.fromDateTimes(hours.startDateTime, hours.endDateTime)
        : RoomRow.getTimeRange(rows, calendarProps.center.surgeons, calendarProps.date),
      placeModeFirstTarget: undefined,
      placeModeRowIndex: undefined,
      rows
    });
  }, [calendarProps, centerHours, selectedSubCategories, serviceSubCategories]);

  // on changing date or center get hours if unknown
  useEffect(() => {
    if (!centerHoursRef.current || !centerHoursRef.current[date.toISODate()]) setCenterHoursRequest(date.toISODate());
  }, [simpleCenter, date]);

  // update row order on settings change
  useEffect(
    () =>
      setSchedule(oldSchedule => ({
        ...oldSchedule,
        rows: oldSchedule.rows
          .map(row => ({ ...row, orderBy: schedulerConfig.orderBy }))
          .sort(RoomRow.comparator(schedulerConfig.orderBy))
      })),
    [schedulerConfig.orderBy]
  );

  // #region PUT Blocks
  const [putBlocksRequest, setPutBlocksRequest] = useState<UseCbpProps<Block[]>>();
  const { response: putBlocksResponse, isLoading: isPutBlocksLoading } = useCbp<PutBlocksResponse, Block[]>(
    putBlocksRequest
  );
  useEffect(() => {
    if (putBlocksResponse) {
      errorsRef.current = putBlocksResponse.errors;
      updateCalendar(putBlocksResponse.blocks);
      if (putBlocksResponse.errors.length) enqueueSnackbar("Some Changes Were Not Saved", { variant: "warning" });
      else enqueueSnackbar("Schedule Updated", { variant: "success" });
    }
  }, [putBlocksResponse]);
  // #endregion

  // #region Actions
  const onCancelChange = useCallback(() => {
    setSelectedSubCategories([]);
    errorsRef.current = [];
    const rows = RoomRow.fromBaseArray(
      calendarProps!.calendar.days[calendarProps!.date.toISODate()],
      date,
      schedulerConfig.orderBy,
      errorsRef.current,
      schedulerItemOnClick
    );
    setSchedule({
      bounds: RoomRow.getTimeRange(rows, calendarProps!.center.surgeons, calendarProps!.date),
      placeModeFirstTarget: undefined,
      placeModeRowIndex: undefined,
      rows
    });
  }, [calendarProps, schedulerConfig.orderBy]);
  const onChangeDate = (date: DateTime) => setDate(DateTime.utc(date.year, date.month, date.day), PlannerViewType.DAY);
  const onSaveChanges = () => {
    // build a flat list of blocks from the prior state of the calendar
    const flatBlocks = calendarProps!.calendar.days[calendarProps!.date.toISODate()].reduce(
      (collector, roomDay) => collector.concat(roomDay.blocks),
      [] as EnhancedBlock[]
    );
    // aggregate a list of changed blocks from all rows by comparing existing blocks with the active changes
    const updatedBlocks = schedule.rows.reduce(
      (rowCollector, roomRow) =>
        rowCollector.concat(
          roomRow.items.reduce((itemCollector, item) => {
            if (!item.block) return itemCollector;
            if (!item.block.id) itemCollector.push(EnhancedBlock.toBase(item.block));
            else {
              const previousValue = flatBlocks.find(existing => existing.id === item.block!.id);
              if (!previousValue) throw `Could not find the original record for the block with id {${item.block.id}}.`;
              if (EnhancedBlock.isChanged(item.block, previousValue))
                itemCollector.push(EnhancedBlock.toBase(item.block));
            }
            return itemCollector;
          }, [] as Block[])
        ),
      [] as Block[]
    );
    if (!updatedBlocks.length) enqueueSnackbar("No Changes Detected", { variant: "warning" });
    else
      setPutBlocksRequest({
        name: "Update Schedule",
        request: { method: "put", url: `centers/${centerRef.current!.id}/blocks`, data: updatedBlocks }
      });
  };
  // Determines where what to do when a SchedulerRowDropZone is clicked in Place Mode.
  const onPlaceModeClick = useCallback(
    (rowIndex: number, targetDateTime: DateTime) =>
      setSchedule(oldSchedule => {
        // if this is the first click, save it and exit
        if (!oldSchedule.placeModeFirstTarget) return { ...oldSchedule, placeModeFirstTarget: targetDateTime };
        // otherwise this is the second click
        let startDateTime, endDateTime: DateTime;
        if (targetDateTime < oldSchedule.placeModeFirstTarget) {
          startDateTime = targetDateTime;
          endDateTime = oldSchedule.placeModeFirstTarget.plus({ minutes: MinimumTimeSpanMinutes });
        } else {
          startDateTime = oldSchedule.placeModeFirstTarget;
          endDateTime = targetDateTime.plus({ minutes: MinimumTimeSpanMinutes });
        }
        const roomDay = oldSchedule.rows[rowIndex];
        // attempt to reuse a reduced slot
        const reducedIndex = oldSchedule.rows[rowIndex].items.findIndex(i => i.block?.reductionReasonId);
        const reducedSlot =
          reducedIndex !== -1 ? oldSchedule.rows[rowIndex].items.splice(reducedIndex, 1)[0] : undefined;
        const newItem: DayViewSchedulerItem = {
          id: uuidv4(),
          ...reducedSlot,
          block: {
            ...reducedSlot?.block,
            blockType: BlockType.Slot,
            room: roomDay.room,
            roomId: roomDay.roomId,
            surgeon: roomDay.surgeon,
            surgeonId: roomDay.surgeon?.id,
            startDateTime,
            startTimeUtc: startDateTime.toUTC().toISO(),
            endDateTime,
            endTimeUtc: endDateTime.toUTC().toISO(),
            notes: "",
            reductionReasonId: undefined
          },
          endDateTime,
          startDateTime,
          timeSpanMinutes: Interval.fromDateTimes(startDateTime, endDateTime).length("minutes"),
          // TODO: Figure out why types are not compatible and remove type check skip
          canDrop: RoomRow.canDrop as never,
          canResize: RoomRow.canResize as never,
          onClick: schedulerItemOnClick
        };
        oldSchedule.rows[rowIndex].items.push(newItem);
        oldSchedule.rows[rowIndex].items = oldSchedule.rows[rowIndex].items.sort(ScheduleItem.chronoComparator);
        return {
          ...oldSchedule,
          rows: [...oldSchedule.rows],
          placeModeRowIndex: undefined,
          placeModeFirstTarget: undefined
        };
      }),
    []
  );
  const onDrop = useCallback((rowIndex: number, startDateTime: DateTime, item: DayViewSchedulerItem) => {
    const endDateTime = startDateTime.plus({ minutes: item.timeSpanMinutes });
    setSchedule(oldSchedule => {
      const itemIndex = oldSchedule.rows[rowIndex].items.findIndex(i => i.id === item.id);
      if (itemIndex === -1) throw `Could not locate dragged item with startTime ${item.startDateTime.toISO()}`;
      oldSchedule.rows[rowIndex].items[itemIndex] = {
        ...oldSchedule.rows[rowIndex].items[itemIndex],
        startDateTime,
        endDateTime,
        block: {
          ...oldSchedule.rows[rowIndex].items[itemIndex].block!,
          startDateTime,
          endDateTime,
          startTimeUtc: startDateTime.toUTC().toISO(),
          endTimeUtc: endDateTime.toUTC().toISO()
        }
      };
      return { ...oldSchedule, rows: [...oldSchedule.rows] };
    });
  }, []);
  const onResize = useCallback(
    (rowIndex: number, time: DateTime, item: DayViewSchedulerItem, handle: DragHandle) =>
      setSchedule(oldSchedule => {
        const itemIndex = oldSchedule.rows[rowIndex].items.findIndex(i => i.id === item.id);
        if (itemIndex === -1) throw `Could not locate dragged item with startTime ${item.startDateTime.toISO()}`;
        if (handle === DragHandle.LEFT) {
          if (time.equals(oldSchedule.rows[rowIndex].items[itemIndex].startDateTime)) return oldSchedule;
          oldSchedule.rows[rowIndex].items[itemIndex] = {
            ...oldSchedule.rows[rowIndex].items[itemIndex],
            startDateTime: time,
            timeSpanMinutes: Interval.fromDateTimes(
              time,
              oldSchedule.rows[rowIndex].items[itemIndex].endDateTime
            ).length("minutes"),
            block: {
              ...oldSchedule.rows[rowIndex].items[itemIndex].block!,
              startDateTime: time,
              startTimeUtc: time.toUTC().toISO()
            }
          };
        } else {
          if (time.equals(oldSchedule.rows[rowIndex].items[itemIndex].endDateTime)) return oldSchedule;
          oldSchedule.rows[rowIndex].items[itemIndex] = {
            ...oldSchedule.rows[rowIndex].items[itemIndex],
            endDateTime: time,
            timeSpanMinutes: Interval.fromDateTimes(
              oldSchedule.rows[rowIndex].items[itemIndex].startDateTime,
              time
            ).length("minutes"),
            block: {
              ...oldSchedule.rows[rowIndex].items[itemIndex].block!,
              endDateTime: time,
              endTimeUtc: time.toUTC().toISO()
            }
          };
        }
        return { ...oldSchedule, rows: [...oldSchedule.rows] };
      }),
    []
  );
  // #endregion

  // when releasing the editing lock, revert any pending changes
  useEffect(() => {
    if (editDisabledRef.current === false && editDisabled === true) {
      onCancelChange();
      setShowUnassignedForm(undefined);
      setShowEditForm(undefined);
      setShowDetailForm(undefined);
    }
    editDisabledRef.current = editDisabled;
  }, [editDisabled, onCancelChange]);

  const allowedSurgeons =
    calendarProps?.center.surgeons.filter(
      s =>
        // allow surgeon selection if they are not already assigned to a room
        !schedule.rows.some(row => row?.surgeonId === s.id) &&
        // and if they are not terminated
        (!s.terminationDateTime || s.terminationDateTime > date)
    ) || [];
  const roomDays = calendarProps?.calendar.days[calendarProps?.date.toISODate()];
  const lastUpdatedRoomDay = roomDays?.some(rd => rd.updatedDateTime)
    ? roomDays.reduce(
        (max, roomDay) => (roomDay.updatedDateTime && roomDay.updatedDateTime > max.updatedDateTime! ? roomDay : max),
        {
          ...roomDays[0],
          updatedDateTime: roomDays[0].updatedDateTime || DateTime.now().minus({ years: 50 })
        }
      )
    : undefined;
  const updatedMessage = lastUpdatedRoomDay?.updatedDateTime ? (
    <>
      <div>Day Last Updated:</div>
      <div>{lastUpdatedRoomDay.updatedBy}</div>
      <div>{lastUpdatedRoomDay.updatedDateTime.toLocaleString(DateTime.DATETIME_SHORT)}</div>
    </>
  ) : (
    "No Changes"
  );

  return (
    <PlannerNavigation
      date={date}
      disabled={isPutBlocksLoading}
      editDisabled={editDisabled}
      isCalendarLoaded={Boolean(calendarProps)}
      isLoading={isLoading}
      isLoadingUpdate={isLoadingUpdate}
      selectedSubCategories={selectedSubCategories}
      serviceSubCategories={serviceSubCategories}
      settingsMenu={<DayViewSettingsForm schedulerConfig={schedulerConfig} setSchedulerConfig={setSchedulerConfig} />}
      simpleCenter={simpleCenter}
      simpleCenters={simpleCenters}
      updatedMessage={updatedMessage}
      view={PlannerViewType.DAY}
      setCenter={setCenter}
      setDate={onChangeDate}
      setSelectedSubCategories={setSelectedSubCategories}
    >
      <Box sx={{ height: "100%", width: "100%", pb: 2 }}>
        {schedule.rows.length ? (
          <Grid className="m-0 w-100 h-100" container direction="column" spacing={2}>
            <Grid item xs={schedulerConfig.orientation === "vertical"} sx={{ maxWidth: "100%", width: "100%" }}>
              <Scheduler
                disabled={isPutBlocksLoading || editDisabled}
                rows={schedule.rows.map(row => ({
                  ...row,
                  items: row.items.filter(i => !i?.block?.reductionReasonId)
                }))}
                // TODO Figure out why the types aren't lining up
                ScheduleItem={DayViewSchedulerItemComponent as FunctionComponent<SchedulerItemProps<ScheduleItem>>}
                timeRange={schedule.bounds}
                RowHeader={
                  schedulerRowHeaderRef.current as FunctionComponent<
                    SchedulerRowHeaderProps<ScheduleItem, ScheduleRow<ScheduleItem>>
                  >
                }
                {...schedulerConfig}
                placeModeFirstTarget={schedule.placeModeFirstTarget}
                placeModeRowIndex={schedule.placeModeRowIndex}
                canPlaceModeClick={canPlaceModeClick}
                formatZoneLabel={formatZoneLabel}
                onPlaceModeClick={onPlaceModeClick}
                onDrop={onDrop as never}
                onResize={onResize as never}
              />
              <Popover
                open={Boolean(showUnassignedForm)}
                anchorEl={showUnassignedForm?.anchor}
                onClose={() => setShowUnassignedForm(undefined)}
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "center", horizontal: "left" }}
              >
                {showUnassignedForm && (
                  <Card sx={{ maxWidth: "30rem" }}>
                    <DayViewEditUnassignedForm
                      rowIndex={showUnassignedForm?.rowIndex}
                      schedule={schedule}
                      surgeons={allowedSurgeons}
                      setSchedule={setSchedule}
                      onClose={() => setShowUnassignedForm(undefined)}
                    />
                  </Card>
                )}
              </Popover>
              <Popover
                open={Boolean(showEditForm)}
                anchorEl={showEditForm?.anchor}
                onClose={() => setShowEditForm(undefined)}
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "center", horizontal: "left" }}
              >
                {showEditForm && (
                  <Card sx={{ maxWidth: "30rem" }}>
                    <DayViewEditSurgeonForm
                      schedule={schedule}
                      rowIndex={showEditForm.rowIndex}
                      itemProps={
                        {
                          canDrop: RoomRow.canDrop,
                          canResize: RoomRow.canResize,
                          onClick: schedulerItemOnClick
                        } as never
                      }
                      reductionReasons={reductionReasons}
                      surgeons={allowedSurgeons}
                      setSchedule={setSchedule}
                      onClose={() => setShowEditForm(undefined)}
                    />
                  </Card>
                )}
              </Popover>
              <Popover
                open={Boolean(showDetailForm)}
                anchorEl={showDetailForm?.anchor}
                onClose={() => setShowDetailForm(undefined)}
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "center", horizontal: "left" }}
              >
                {showDetailForm && (
                  <Card sx={{ maxWidth: "30rem" }}>
                    <DayViewSchedulerItemDetailForm
                      disabled={isPutBlocksLoading || editDisabled}
                      item={showDetailForm.item}
                      reductionReasons={reductionReasons}
                      schedule={schedule}
                      setSchedule={setSchedule}
                      onClose={() => setShowDetailForm(undefined)}
                    />
                  </Card>
                )}
              </Popover>
            </Grid>
            <Grid item>
              <Grid container justifyContent="space-between" sx={{ pr: 2 }}>
                <Grid item>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="h6">Key:</Typography>
                    </Grid>
                    <Grid item>
                      <ScheduleBox
                        variant={BlockType.Slot}
                        sx={theme => ({ borderRadius: theme.shape.borderRadius, padding: theme.spacing(1, 2) })}
                      >
                        <Typography variant="body2">Slot</Typography>
                      </ScheduleBox>
                    </Grid>
                    <Grid item>
                      <ScheduleBox
                        variant="surgeryAppt"
                        sx={theme => ({ borderRadius: theme.shape.borderRadius, padding: theme.spacing(1, 2) })}
                      >
                        <Typography variant="body2">Surgery</Typography>
                      </ScheduleBox>
                    </Grid>
                    <Grid item>
                      <ScheduleBox
                        variant="otherAppt"
                        sx={theme => ({ borderRadius: theme.shape.borderRadius, padding: theme.spacing(1, 2) })}
                      >
                        <Typography variant="body2">Other Appointment</Typography>
                      </ScheduleBox>
                    </Grid>
                    <Grid item>
                      <ScheduleBox
                        variant={BlockType.Other}
                        sx={theme => ({ borderRadius: theme.shape.borderRadius, padding: theme.spacing(1, 2) })}
                      >
                        <Typography variant="body2">Other</Typography>
                      </ScheduleBox>
                    </Grid>
                    <Grid item>
                      <ScheduleBox
                        variant="error"
                        sx={theme => ({ borderRadius: theme.shape.borderRadius, padding: theme.spacing(1, 2) })}
                      >
                        <Typography variant="body2">Error</Typography>
                      </ScheduleBox>
                    </Grid>
                  </Grid>
                </Grid>
                {!editDisabled && (
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button disabled={isLoading || isPutBlocksLoading} color="secondary" onClick={onCancelChange}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        {isPutBlocksLoading ? (
                          <CircularProgress />
                        ) : (
                          <Button disabled={isLoading || isPutBlocksLoading} onClick={onSaveChanges}>
                            Save Changes
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : !calendarProps || calendarProps.center.rooms.length ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
            <CircularProgress size="5rem" />
          </Grid>
        ) : (
          <Grid className="m-0" item container xs spacing={3} direction="column" justifyContent="center">
            <Grid item container spacing={2} justifyContent="center">
              <Grid item>
                <MeetingRoomIcon sx={theme => ({ fontSize: theme.typography.h5.fontSize })} />
              </Grid>
              <Grid item>
                <Typography variant="h5">No Rooms Available</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <Typography variant="body1" align="center">
                {`Be sure that Zenoti has rooms configured for ${calendarProps?.center.name}, and that they have room categories
              that are enabled for scheduling by CBP.`}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    </PlannerNavigation>
  );
};

export default DayView;
