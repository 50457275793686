import { IANAZone, DateTime as LuxonDateTime, Zone } from "luxon";

const timeZoneMap: Record<string, string> = {
  "Pacific Daylight Time": "America/Los_Angeles",
  "Pacific Standard Time": "America/Los_Angeles",
  "Mountain Daylight Time": "America/Denver",
  "Mountain Standard Time": "America/Denver",
  "US Mountain Standard Time": "America/Phoenix",
  "Central Daylight Time": "America/Chicago",
  "Central Standard Time": "America/Chicago",
  "Eastern Daylight Time": "America/New_York",
  "Eastern Standard Time": "America/New_York"
};

export default class DateTime extends LuxonDateTime {
  /** Attempt to fix a truncated datetime string. */
  static fixTruncatedISO(iso: string): string {
    if (/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.?\d?\d?\d?Z/.test(iso)) return iso;
    if (/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d\.\d\d\d/.test(iso)) return `${iso}Z`;
    if (/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d/.test(iso)) return `${iso}.000Z`;
    throw `Unable to correct the truncated ISO {${iso}}.`;
  }

  /** Create a DateTime from a truncated datetime string. */
  static fromTruncatedISO(iso: string): LuxonDateTime {
    return DateTime.fromISO(this.fixTruncatedISO(iso));
  }

  /** Convert a C# timezone name into the IANA time zone value. */
  static getZone(name: string): IANAZone {
    const ianaZone = timeZoneMap[name];
    if (!ianaZone) throw `Failed to find IANAZone value with C# zone key {${name}}.`;
    if (!IANAZone.isValidZone(ianaZone))
      throw `Luxon failed to parse the mapped IANA value {${ianaZone}} from key {${name}}.`;
    return IANAZone.create(ianaZone);
  }

  /** Returns true if the two dates are the same calendar day in local time, false otherwise. */
  static isSameLocalDay(date1: DateTime, date2: DateTime): boolean {
    const local1 = date1.toLocal();
    const local2 = date2.toLocal();
    return local1.day !== local2.day || local1.month !== local2.month || local1.year !== local2.year;
  }

  /** Create a DateTime object representing the current day at 00:00 AM. */
  static today(): LuxonDateTime {
    const { year, month, day } = DateTime.now();
    return DateTime.fromObject({ year, month, day });
  }

  /** Create a UTC `DateTime` from a datetime string. */
  static utcFromDateString(dateString: string): LuxonDateTime {
    const matches = /^(\d\d\d\d)-(\d?\d)-(\d?\d)$/.exec(dateString);
    if (!matches || matches.length !== 4) throw `Failed to parse date string ${dateString}`;
    return DateTime.utc(Number(matches[1]), Number(matches[2]), Number(matches[3]));
  }

  /** Parse a timespan locale string into an object. */
  static parseTimeString(value: string): { hour: number; minute: number; second: number } {
    const matches = value.match(/^(\d\d):(\d\d):(\d\d)$/);
    if (matches?.length !== 4) throw `Failed to parse time string {${value}}.`;
    return { hour: Number(matches[1]), minute: Number(matches[2]), second: Number(matches[3]) };
  }

  /** Returns a new DateTime with the time properties set to values parsed from the given string */
  static setTimeFromString(date: DateTime, value: string): LuxonDateTime {
    const { hour, minute, second } = this.parseTimeString(value);
    return date.set({ hour, minute, second });
  }

  /** Converts a time string to a UTC Luxon `DateTime` with the current date. */
  static utcFromLocalTime(value: string, zone: string | Zone = "local", localDay?: DateTime): LuxonDateTime {
    const { hour, minute, second } = this.parseTimeString(value);
    const { year, month, day } = localDay || DateTime.now();
    const date = DateTime.fromObject({ year, month, day, hour, minute, second }, { zone }).toUTC();
    return date;
  }
}
