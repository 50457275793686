import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { useCallback, useEffect, useRef, useState } from "react";

export interface SignalRConnectionInfo {
  url: string;
  accessToken: string;
}
export interface SignalRConnectionConfig {
  /** The time to wait to attempt re-connection to the service in milliseconds. **/
  reconnectTimeout?: number;
  onClose?: () => void;
  onReconnecting?: () => void;
  onReconnected?: () => void;
}

const useSignalR = (
  /** The necessary information required to establish a connection to signal r. **/
  connectionInfo?: SignalRConnectionInfo,
  /** Configuration for the connection hub behaviors. */
  config?: SignalRConnectionConfig
): {
  /** An untyped record for accessing payloads by their topic name. **/
  payloads: Record<string, unknown | null>;
  /** Callback to add a topic to listen to.
   * Successive calls for an existing subscribed topic will increment its listener count. */
  subscribe: (topics: string | string[]) => void;
  /** Callback to stop listening to specified topics.
   * Topics with a non-zero listener count will decrement it and listening will cease at 0. */
  unsubscribe: (topics: string | string[]) => void;
} => {
  const [payloads, setPayloads] = useState<Record<string, unknown | null>>({});
  const [topics, setTopics] = useState<{ name: string; count: number }[]>([]);
  const topicsRef = useRef(topics);
  const [connection, setConnection] = useState<HubConnection>();
  const connectionRef = useRef(connection);

  // ON SR CONNECTION INFO HAS VALUE
  useEffect(() => {
    // TODO: this may prevent reconnection attempts
    if (connectionInfo && !connectionRef.current) {
      // configure the connection
      const localSrConnection = new HubConnectionBuilder()
        .withUrl(connectionInfo.url, { accessTokenFactory: () => `${connectionInfo.accessToken}` })
        .withAutomaticReconnect({ nextRetryDelayInMilliseconds: () => config?.reconnectTimeout || 3000 })
        .configureLogging(LogLevel.Warning)
        .build();

      // set connection event behaviors
      localSrConnection.onclose(() => {
        localSrConnection.stop();
        connectionRef.current = undefined;
        setConnection(undefined);
        if (config?.onClose) config.onClose();
      });
      localSrConnection.onreconnecting(() => config?.onReconnecting && config.onReconnecting());
      localSrConnection.onreconnected(() => config?.onReconnected && config.onReconnected());
      localSrConnection.start().catch(() => {
        connectionRef.current = undefined;
        setConnection(undefined);
      });
      if (
        localSrConnection.state === HubConnectionState.Connecting ||
        localSrConnection.state === HubConnectionState.Connected
      ) {
        connectionRef.current = localSrConnection;
        setConnection(conn => {
          if (conn) conn.stop();
          return localSrConnection;
        });

        // set connection topic behaviors
        if (topicsRef.current.length) {
          setPayloads(payloads => {
            topicsRef.current.forEach(({ name }) => (payloads[name] = null));
            return { ...payloads };
          });
          topicsRef.current.forEach(({ name }) =>
            localSrConnection.on(name, data => setPayloads(oldPayloads => ({ ...oldPayloads, [name]: data })))
          );
        }
      }
    }
  }, [connectionInfo]);

  const subscribe = useCallback((topics: string | string[]) => {
    setTopics(t => {
      const topicChanges: string[] = topics.constructor === Array ? topics : [topics as string];
      const newTopics: string[] = [];
      topicChanges.forEach(topic => {
        const index = t.findIndex(({ name }) => name === topic);
        if (index !== -1) t[index].count++;
        else {
          t.push({ name: topic, count: 1 });
          newTopics.push(topic);
        }
      });
      if (newTopics.length)
        setPayloads(payloads => {
          newTopics.forEach(topic => (payloads[topic] = null));
          return { ...payloads };
        });
      if (connectionRef.current)
        newTopics.forEach(topic =>
          connectionRef.current!.on(topic, data => setPayloads(payloads => ({ ...payloads, [topic]: data })))
        );
      return t;
    });
  }, []);

  const unsubscribe = useCallback((topics: string | string[]) => {
    setTopics(t => {
      const topicChanges: string[] = topics.constructor === Array ? topics : [topics as string];
      const removeTopics: string[] = [];
      topicChanges.forEach(topic => {
        const index = t.findIndex(({ name }) => name === topic);
        if (index !== -1 && --t[index].count === 0) removeTopics.push(t.splice(index, 1)[0].name);
      });
      if (removeTopics.length)
        setPayloads(payloads => {
          removeTopics.forEach(topic => delete payloads[topic]);
          return { ...payloads };
        });
      if (connectionRef.current) removeTopics.forEach(topic => connectionRef.current!.off(topic));
      return t;
    });
  }, []);

  return { subscribe, unsubscribe, payloads };
};

export default useSignalR;
