import React, { useContext, useEffect, useState } from "react";

import { LocalStorageKeys } from "../../Constants";
import AppContext from "../../contexts/AppContext";
import { loadFromLocal, saveToLocal } from "../../utils/Helpers";
import useCbp, { UseCbpProps } from "../../utils/UseCbp";

interface SignalRConnectionInfo {
  url: string;
  accessToken: string;
}

const SignalRConnection: React.FC = () => {
  const { setSignalRConnectionInfo, token } = useContext(AppContext);

  // make a negotiation request if we haven't cached a connection.
  const [request, setRequest] = useState<UseCbpProps>();
  useEffect(() => {
    if (token && !loadFromLocal<SignalRConnectionInfo>(LocalStorageKeys.signalRConnectionInfo))
      setRequest({ name: "Get Connection Info", request: { method: "post", url: "signalr/Negotiate" } });
  }, [token]);

  const { response: connInfoResponse } = useCbp<SignalRConnectionInfo>(request);
  useEffect(() => {
    if (connInfoResponse) {
      setSignalRConnectionInfo(connInfoResponse);
      saveToLocal(connInfoResponse, LocalStorageKeys.signalRConnectionInfo);
    }
  }, [connInfoResponse, setSignalRConnectionInfo]);

  return <></>;
};

export default SignalRConnection;
