import { useMsal } from "@azure/msal-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EventIcon from "@mui/icons-material/Event";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import { AppBar, Badge, Button, Grid, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import { Interval } from "luxon";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Logo from "../../assets/CLogo_lowRezNoLeaf.png";
import { LocalStorageKeys } from "../../Constants";
import AppContext from "../../contexts/AppContext";
import DateTime from "../../types/DateTime";
import { UserSettings } from "../../types/UserSettings";
import { clearLocalStorage, loadFromLocal } from "../../utils/Helpers";
import useCbp, { UseCbpProps } from "../../utils/UseCbp";
import Modal from "../Helpers/Modal";

const getPageTitle = (pathName: string) =>
  pathName
    .split(/\//g)
    .filter(v => v)
    .map(token =>
      token
        .replace(/([A-Z])/g, " $1")
        .replace(/(-[a-z])/g, (_, p1) => ` ${p1.substring(1).toUpperCase()}`)
        .trim()
    )
    .map(token => token.charAt(0).toUpperCase() + token.slice(1))
    .reduce((collector, token) => (collector ? `${collector} - ${token}` : token), "");

interface MsalSecret {
  idTokenClaims: { name: string };
}

const Navigation: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { accounts, instance } = useMsal();
  const { centerLock, user, userCenterLock, setUserCenterLock } = useContext(AppContext);
  const [timeRemaining, setTimeRemaining] = useState(() => {
    if (!userCenterLock?.expirationDateTime) return;
    const minutes = Interval.fromDateTimes(DateTime.utc(), userCenterLock.expirationDateTime).length("minutes");
    const seconds = Math.floor((minutes % 1) * 60);
    return `${Math.floor(minutes)}:${seconds > 9 ? seconds : `0${seconds}`}`;
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userSettings] = useState(loadFromLocal<UserSettings>(LocalStorageKeys.userSettings));
  const [showModal, setShowModal] = useState(false);
  const putLockMethod = useRef<"delete" | "post">();
  const centerId = userCenterLock?.centerId || new URLSearchParams(location.search).get("center");

  // #region Put Locks
  const [putLocksRequest, setPutLocksRequest] = useState<UseCbpProps>();
  const { response: putLocksResponse, isLoading } = useCbp<Record<string, never>>(putLocksRequest);
  useEffect(() => {
    if (!putLocksResponse) return;
    enqueueSnackbar(`${putLockMethod.current === "delete" ? "Unlocked" : "Locked"} the Center.`, {
      variant: "success"
    });
    if (putLockMethod.current === "delete") {
      setTimeRemaining(undefined);
      setUserCenterLock(undefined);
    }
  }, [putLocksResponse]);
  // #endregion
  // decrement the lock timer on an interval
  useEffect(() => {
    if (!userCenterLock) return;
    const intervalId = setInterval(() => {
      const minutes = Interval.fromDateTimes(DateTime.utc(), userCenterLock.expirationDateTime).length("minutes");
      const seconds = Math.floor((minutes % 1) * 60);
      // if time remains update the display
      if (seconds > 0 || minutes > 0)
        setTimeRemaining(`${Math.floor(minutes)}:${seconds > 9 ? seconds : `0${seconds}`}`);
      // otherwise display the timeout modal and stop the loop
      else {
        clearInterval(intervalId);
        setShowModal(true);
        setTimeRemaining(undefined);
        setUserCenterLock(undefined);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [userCenterLock]);

  // #region Actions
  const onClickLock = () => {
    const method = userCenterLock ? "delete" : "post";
    putLockMethod.current = method;
    setPutLocksRequest({ name: "Update Lock", request: { method, url: `centers/${centerId}/locks` } });
  };
  const navigate = (path: string) => {
    history.push(path);
    setAnchorEl(null);
  };
  const logout = useCallback(() => {
    // if owning center lock, remove it
    if (userCenterLock) {
      putLockMethod.current = "delete";
      setPutLocksRequest({
        name: "Update Lock",
        request: { method: "delete", url: `centers/${userCenterLock.centerId}/locks` }
      });
    }
    instance.logoutPopup().then(clearLocalStorage);
  }, [userCenterLock, instance]);
  // #endregion

  const userFullName = accounts?.length ? (accounts[0] as MsalSecret).idTokenClaims.name : undefined;

  return (
    <AppBar sx={theme => ({ zIndex: theme.zIndex.drawer + 1 })}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Grid item>
          <Grid container alignItems="center" spacing={4}>
            <Grid item>
              <Badge
                color="secondary"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={process.env.REACT_APP_VERSION_NO}
                sx={{ mb: 1 }}
              >
                <img src={Logo} style={{ width: "2.5rem" }} />
              </Badge>
            </Grid>
            <Grid item>
              <Typography variant="h5">{`${getPageTitle(location.pathname)}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={2} wrap="nowrap">
            {userCenterLock ? (
              <>
                {userCenterLock.centerName && timeRemaining && (
                  <Grid item>{`${userCenterLock.centerName} | ${timeRemaining}`}</Grid>
                )}
                <Grid item>
                  <Button color="secondary" variant="outlined" onClick={onClickLock} disabled={isLoading}>
                    Unlock
                  </Button>
                </Grid>
              </>
            ) : (
              Boolean(centerId) &&
              user.centerPermissions[centerId!]?.isCapacityEditor && (
                <Grid item>
                  <Tooltip
                    title={
                      centerLock
                        ? `${centerLock.centerName} is locked by ${
                            centerLock.displayName
                          } until ${centerLock.expirationDateTime.toLocaleString(DateTime.TIME_SIMPLE)}.`
                        : ""
                    }
                  >
                    <span>
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={onClickLock}
                        disabled={Boolean(centerLock) || isLoading}
                      >
                        Lock
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              )
            )}
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                disableElevation
                // TODO: Get MSAL user avatar from claims
                startIcon={<AccountCircleIcon />}
                onClick={e => setAnchorEl(e.currentTarget)}
              >
                {userFullName}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Menu
          transformOrigin={{ vertical: "bottom", horizontal: "right" }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {!location.pathname.includes("/planner") && (
            <MenuItem onClick={() => navigate(`/planner/${userSettings?.defaultCalendarView || "month"}`)}>
              <ListItemIcon>
                <EventIcon fontSize="small" />
              </ListItemIcon>
              Planner
            </MenuItem>
          )}
          {(user.applicationPermissions.isAdminEditor ||
            user.applicationPermissions.isApplicationEditor ||
            user.aggregateCenterPermissions.isCenterEditor ||
            user.aggregateCenterPermissions.isCapacityEditor) &&
            !location.pathname.includes("/admin") && (
              <MenuItem onClick={() => navigate("/admin")}>
                <ListItemIcon>
                  <AdminPanelSettingsIcon fontSize="small" />
                </ListItemIcon>
                Admin Settings
              </MenuItem>
            )}
          {!location.pathname.includes("/settings") && (
            <MenuItem onClick={() => navigate("/settings")}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              User Settings
            </MenuItem>
          )}
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      </Toolbar>
      <Modal open={showModal} onClose={() => setShowModal(false)} onConfirm={() => setShowModal(false)}>
        <p>Your center editing lock has expired due to inactivity.</p>
      </Modal>
    </AppBar>
  );
};
export default Navigation;
