import CheckIcon from "@mui/icons-material/Check";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Badge,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { EnhancedAppointment } from "../../../models/Appointment";
import { BlockType, EnhancedBlock } from "../../../models/Block";
import { EnhancedSurgeon, Surgeon } from "../../../models/Surgeon";
import DateTime from "../../../types/DateTime";
import { DayViewFormProps } from "../../../types/DayViewFormProps";
import { RoomRow } from "../../../types/DayViewSchedulerTypes";

interface Props extends DayViewFormProps {
  rowIndex: number;
  surgeons: EnhancedSurgeon[];
  onClose: () => void;
}

const DayViewEditUnassignedForm: React.FC<Props> = ({ schedule, rowIndex, surgeons, setSchedule, onClose }: Props) => {
  const [surgeon, setSurgeon] = useState<EnhancedSurgeon>();
  const onSubmit = (surgeon: EnhancedSurgeon) => {
    setSchedule(schedule => {
      const endDateTime =
        DateTime.min(...schedule.rows[rowIndex].items.map(i => i.startDateTime)) || schedule.bounds.start;
      const startDateTime = endDateTime.minus({ minutes: 60 });
      schedule.rows[rowIndex] = {
        ...schedule.rows[rowIndex],
        header: {
          startDateTime,
          endDateTime,
          startTimeUtc: startDateTime.toISO(),
          endTimeUtc: endDateTime.toISO(),
          room: schedule.rows[rowIndex].room,
          roomId: schedule.rows[rowIndex].roomId,
          surgeon,
          surgeonId: surgeon.id,
          blockType: BlockType.Header,
          notes: ""
        },
        surgeonId: surgeon.id,
        surgeon: surgeon,
        items: schedule.rows[rowIndex].items.map(item =>
          item.block?.blockType === BlockType.Slot
            ? { ...item, block: { ...item.block, surgeonId: surgeon.id, surgeon } }
            : item
        )
      };
      return { ...schedule, rows: [...schedule.rows.sort(RoomRow.comparator(schedule.rows[rowIndex].orderBy))] };
    });
    onClose();
  };
  const row = schedule.rows[rowIndex];
  const surgeonData = row.items.reduce((dictionary, item) => {
    if (item?.block) {
      const blockSurgeonId = item.block.surgeonId;
      if (blockSurgeonId) {
        if (dictionary[blockSurgeonId]) dictionary[blockSurgeonId].blocks.push(item.block);
        else dictionary[blockSurgeonId] = { appointments: [], blocks: [item.block], surgeon: item.block.surgeon! };
      }
    } else {
      const apptSurgeonId = item.appointment!.surgeonId!;
      if (dictionary[apptSurgeonId]) dictionary[apptSurgeonId].appointments.push(item.appointment!);
      else
        dictionary[apptSurgeonId] = {
          appointments: [item.appointment!],
          blocks: [],
          surgeon: item.appointment!.surgeon
        };
    }
    return dictionary;
  }, {} as Record<string, { appointments: EnhancedAppointment[]; blocks: EnhancedBlock[]; surgeon?: EnhancedSurgeon }>);

  // Preset the Surgeon to our single discovered value if there is only one. Do this once on first load.
  useEffect(() => {
    const surgeonItems = Object.values(surgeonData);
    if (surgeonItems.length == 1) setSurgeon(surgeonItems[0].surgeon);
  }, []);

  return (
    <>
      <CardHeader title={row.room.name} />
      <CardContent>
        <Grid container direction="column" spacing={2} alignItems="center" sx={{ minWidth: "20rem" }}>
          {Object.values(surgeonData).map(
            data =>
              data.surgeon && (
                <Grid item container alignItems="center" key={data.surgeon.id} wrap="nowrap" spacing={2}>
                  <Grid item xs>
                    <Typography variant="body1">{Surgeon.GetName(data.surgeon)}</Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Appointments">
                      <Badge badgeContent={data.appointments.length} color="secondary">
                        <EventIcon />
                      </Badge>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Blocks">
                      <Badge badgeContent={data.blocks.length || "0"} color="secondary">
                        <ScheduleIcon />
                      </Badge>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Assign">
                      <IconButton onClick={() => onSubmit(data.surgeon!)}>
                        <CheckIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
          )}
          <Grid item container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <InputLabel>Assign Surgeon</InputLabel>
                <Select
                  fullWidth
                  value={surgeon?.id || ""}
                  onChange={e => setSurgeon(() => surgeons.find(s => s.id === e.target.value))}
                >
                  {surgeons.map(surgeon => (
                    <MenuItem value={surgeon.id} key={surgeon.id}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography variant="body2">{`${surgeon.firstName} ${surgeon.lastName}`}</Typography>
                        </Grid>
                        <Grid item>
                          {surgeon?.preferredRoom?.id === row.room.id && (
                            <Chip label="Preferred" color="primary" size="small" />
                          )}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <Grid container justifyContent="center">
          <Button onClick={() => onSubmit(surgeon!)} disabled={!surgeon} variant="text" size="small">
            Submit
          </Button>
        </Grid>
      </CardActions>
    </>
  );
};

export default DayViewEditUnassignedForm;
