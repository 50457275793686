import { DateTime, Zone } from "luxon";

import { ScheduleItem } from "../components/Helpers/Scheduler/types";
import { Appointment, EnhancedAppointment } from "./Appointment";
import { Block, BlockType, EnhancedBlock } from "./Block";
import { Room } from "./Room";
import { ServiceCategory } from "./ServiceCategory";
import { EnhancedSurgeon, Surgeon } from "./Surgeon";

/** The dto model used to transact RoomDays with the backend. */
export class RoomDay {
  /** The associated appointments of the room day. */
  appointments?: Appointment[];
  /** The associated blocks of the room day. */
  blocks!: Block[];
  /** The key of the room associated with the room day. */
  roomId!: string;
  /** The key of the surgeon associated with the room day. */
  surgeonId?: string;
  /** The UTC ISO value of the most recent aggregate time a room day element was updated. */
  updatedAtUtc?: string;
  /** The username belonging to the owner of the most recent aggregate change to a room day element. */
  updatedBy?: string;
}

export interface TimeItem {
  startDateTime: DateTime;
  endDateTime: DateTime;
}

/** Order room days by header block surgeon name, then by unassigned room days.*/
export const roomDayComparator = (rd1: EnhancedRoomDay, rd2: EnhancedRoomDay): number => {
  if (!rd1?.surgeon && !rd2?.surgeon) return 0;
  if (!rd1?.surgeon) return -1;
  if (!rd2?.surgeon) return 1;
  return EnhancedSurgeon.GetName(rd1.surgeon) > EnhancedSurgeon.GetName(rd2.surgeon) ? 1 : -1;
};

/** Expanded RoomDay model with additional utility props. */
export class EnhancedRoomDay extends RoomDay {
  /** The appointments for the room day enhanced with linked objects. */
  appointments!: EnhancedAppointment[];
  /** The total appointment weight for the appointments assigned to the header surgeon. */
  appointmentsWeight!: number;
  /** The slot or other blocks for the room day enhanced with linked objects. */
  blocks!: EnhancedBlock[];
  /** The header block for the  room day. */
  header?: EnhancedBlock;
  /** The room model represented by the `roomId`. */
  room!: Room;
  /** The surgeon model represented by the `surgeonId`. */
  surgeon?: EnhancedSurgeon;
  /** The Datetime of the timestamp of the last time the day's slots were updated. */
  updatedDateTime?: DateTime;

  constructor(
    base: RoomDay,
    centerLuxonTimeZone: Zone,
    room: Room,
    enabledServiceCategories: ServiceCategory[],
    surgeons: EnhancedSurgeon[]
  ) {
    super();
    const header = base.blocks.find(b => b.blockType === BlockType.Header);
    this.appointments =
      base.appointments?.map(appt =>
        EnhancedAppointment.fromBase(
          appt,
          enabledServiceCategories,
          surgeons.find(s => s.id === appt.surgeonId)!,
          centerLuxonTimeZone
        )
      ) || [];
    this.appointmentsWeight = this.appointments
      .filter(appt => base.surgeonId && appt.surgeonId === base.surgeonId && appt.isSurgery)
      .reduce((sum, appt) => sum + appt.weight, 0);
    this.blocks = base.blocks
      .filter(b => b.blockType !== BlockType.Header)
      .map(b =>
        EnhancedBlock.fromBase(
          b,
          centerLuxonTimeZone,
          room,
          surgeons.find(s => s.id === b.surgeonId)
        )
      );
    this.header = header
      ? EnhancedBlock.fromBase(
          header,
          centerLuxonTimeZone,
          room,
          // Since this is the enhanced header, we will use the header surgeonId instead of the roomDay surgeonId.
          surgeons.find(s => s.id === header?.surgeonId)
        )
      : undefined;
    this.roomId = base.roomId;
    this.room = room;
    this.roomId = base.roomId;
    this.surgeonId = base.surgeonId;
    this.surgeon = surgeons.find(s => s.id === base.surgeonId);
    this.updatedBy = base.updatedBy;
    this.updatedDateTime = base.updatedAtUtc ? DateTime.fromISO(base.updatedAtUtc) : undefined;
  }

  /** Identify obstacles in a RoomDay of the specified types.
   * @param roomDay - The RoomDay to be evaluated for scheduling obstacles.
   * @param params - the type of obstacle to retrieve.
   * `block` is any Block, slots or otherwise.
   * `appointment` is any Appointment,
   * `surgery` is any appointment that has at least one enabled service subcategory.
   * `non-surgery` is any appointment that does not have any enabled service categories.
   */
  static getObstacles(
    roomDay: EnhancedRoomDay,
    params: ("block" | "appointment" | "surgery" | "non-surgery")[]
  ): TimeItem[] {
    let obstacles: TimeItem[] = [];
    if (params.includes("block"))
      obstacles = obstacles.concat(
        roomDay.blocks.reduce((collector, block) => {
          if (!block.reductionReasonId)
            collector.push({ startDateTime: block.startDateTime, endDateTime: block.endDateTime });
          return collector;
        }, [] as TimeItem[])
      );
    if (params.includes("appointment")) {
      obstacles = obstacles.concat(
        roomDay.appointments.map(({ startDateTime, endDateTime }) => ({
          startDateTime,
          endDateTime
        }))
      );
      return obstacles.sort(ScheduleItem.chronoComparator);
    }
    if (params.includes("surgery"))
      obstacles = obstacles.concat(
        roomDay.appointments
          .filter(appt => appt.isSurgery)
          .map(({ startDateTime, endDateTime }) => ({ startDateTime, endDateTime }))
      );
    if (params.includes("non-surgery"))
      obstacles = obstacles.concat(
        roomDay.appointments
          .filter(appt => !appt.isSurgery)
          .map(({ startDateTime, endDateTime }) => ({ startDateTime, endDateTime }))
      );
    return obstacles.sort(ScheduleItem.chronoComparator);
  }

  /** Helper function determining if there is a scheduling collision for the given obstacles and proposed new time item.
   * @param obstacles - Obstacles that may cause a collision.
   * @param startTime - The start time of the item to be evaluated for a collision.
   * @param durationMinutes - The end time of the item to be evaluated for a collision.
   */
  static isObstacleCollision(obstacles: TimeItem[], startTime: DateTime, durationMinutes: number): boolean {
    const itemTime: TimeItem = { startDateTime: startTime, endDateTime: startTime.plus({ minutes: durationMinutes }) };
    return obstacles.some(obstacle => this.isItemCollision(obstacle, itemTime));
  }

  /** Returns true if items overlap, false otherwise. Touching items are not considered overlapping. */
  static isItemCollision(item1: TimeItem, item2: TimeItem): boolean {
    return item1.endDateTime > item2.startDateTime && item1.startDateTime < item2.endDateTime;
  }

  /** Get the earliest start/end time for a new item of given length for the given RoomDay.
   * @param obstacles - The RoomDay to which the new item will be added.
   * @param startTime - The earliest start time at which the item may be placed.
   * @param durationMinutes - The duration in minutes of the item to be added.
   * @param postSlotGap - The duration in minutes of spacing time to observe when finding a time after an obstacle.
   */
  static getNewItemTime(
    obstacles: TimeItem[],
    startTime: DateTime,
    durationMinutes: number,
    postSlotGap: number
  ): { startDateTime: DateTime; endDateTime: DateTime } {
    // pad obstacles with a post slot gap
    const paddedObstacles = obstacles.map(o => ({ ...o, endDateTime: o.endDateTime.plus({ minutes: postSlotGap }) }));
    const proposedTimes: TimeItem = {
      startDateTime: startTime.plus({ hours: 0 }),
      endDateTime: startTime.plus({ minutes: durationMinutes })
    };
    let found = false;
    while (!found) {
      const paddedTimes = {
        startDateTime: proposedTimes.startDateTime.plus({ minutes: 0 }),
        endDateTime: proposedTimes.endDateTime.plus({ minutes: postSlotGap })
      };
      // get collisions with obstacles
      const collisions = paddedObstacles.filter(obstacle => this.isItemCollision(obstacle, paddedTimes));
      // if there were no collisions, the time has been found
      if (!collisions.length) found = true;
      // otherwise, move the new proposed start time to the end of the last collision
      else {
        proposedTimes.startDateTime = collisions
          .reduce((latest, obstacle) => (latest.endDateTime > obstacle.endDateTime ? latest : obstacle))
          .endDateTime.plus({ minutes: 0 });
        proposedTimes.endDateTime = proposedTimes.startDateTime.plus({ minutes: durationMinutes });
      }
    }

    return proposedTimes;
  }

  /** Create a given number of new slots with the appropriate spread and avoiding obstacles.
   * @param roomDay - The room day to build slots for.
   * @param slotCount - The number of slots to be built.
   * @param defaultStart - The default starting time of the first block.
   */
  static buildNewSlots(roomDay: EnhancedRoomDay, slotCount: number, defaultStart: DateTime): EnhancedBlock[] {
    if (!roomDay.surgeon) throw `tried to create spread slots for an unassigned room {${roomDay.room.name}}.`;

    const newSlotDurationMinutes = 150;
    const newSlots: EnhancedBlock[] = [];
    const oldSlots = roomDay.blocks.filter(block => block.id && block.blockType === BlockType.Slot);
    const postOpGapMinutes = Surgeon.GapToMinutes(roomDay.surgeon);
    let startTime = roomDay.surgeon.startDateTime?.plus({ hour: 0 }) || defaultStart.plus({ hour: 0 });
    const obstacles = EnhancedRoomDay.getObstacles(roomDay, ["block", "appointment"]);

    // for each block that should now exist, construct a new block or re-use an existing one.
    for (let x = 0; x < slotCount; x++) {
      const blockTimes = this.getNewItemTime(obstacles, startTime, newSlotDurationMinutes, postOpGapMinutes);

      // build the new block and add it to the RoomDay
      const oldItem = oldSlots.shift();
      const changes = {
        endDateTime: blockTimes.endDateTime,
        endTimeUtc: blockTimes.endDateTime.toUTC().toISO(),
        reductionReasonId: undefined,
        startDateTime: blockTimes.startDateTime,
        startTimeUtc: blockTimes.startDateTime.toUTC().toISO()
      };
      const newItem: EnhancedBlock = oldItem
        ? { ...oldItem, ...changes }
        : {
            blockType: BlockType.Slot,
            notes: "",
            room: roomDay.room,
            roomId: roomDay.room.id,
            surgeon: roomDay.surgeon,
            surgeonId: roomDay.surgeonId,
            ...changes
          };
      newSlots.push(newItem);
      obstacles.push({ startDateTime: newItem.startDateTime, endDateTime: newItem.endDateTime });
      startTime = blockTimes.endDateTime.plus({ minutes: postOpGapMinutes });
    }

    return newSlots;
  }
}
