import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { ActiveDirectoryGroup } from "../../../models/ActiveDirectoryGroup";
import { Center } from "../../../models/Center";
import { CenterPermissions as CenterPermissionsType } from "../../../models/CenterGroupPermissions";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import { AdminTool } from "../Administration";
import AdministrationFormLabel from "../AdministrationFormLabel";
import AdministrationTool from "../AdministrationTool";

const alphabeticalComparator = (item1: CenterGroupPermissions, item2: CenterGroupPermissions) =>
  item1.center.name > item2.center.name ? 1 : -1;
// sort ad groups by those with assigned permissions first, then by name
const groupsComparator = (item1: EnhancedActiveDirectoryGroup, item2: EnhancedActiveDirectoryGroup) => {
  if (item1.permissions && !item2.permissions) return -1;
  if (!item1.permissions && item2.permissions) return 1;
  return item1.label > item2.label ? 1 : -1;
};
// build a dictionary of ad group ids with the count of permissions assigned to that group
const indexPermissionCountByGroupId = (permissions: CenterGroupPermissions[]) =>
  permissions.reduce((aggregate, { activeDirectoryGroup: { id } }) => {
    if (aggregate[id]) aggregate[id] = aggregate[id] + 1;
    else aggregate[id] = 1;
    return aggregate;
  }, {} as Record<string, number>);

interface EnhancedActiveDirectoryGroup extends ActiveDirectoryGroup {
  label: string;
  permissions: number;
}
interface CenterGroupPermissions extends CenterPermissionsType {
  activeDirectoryGroup: EnhancedActiveDirectoryGroup;
}

const toolReadme = [
  "The Center Permissions tool allows you to modify the assigned permissions which control center-specific access. You control the permission assignments by assigning them to an active directory group. All members of the group will inherit the specified permissions.",
  "You can modify permissions by first selecting an active directory group, followed by the desired center, then the desired permission settings, and finally saving the changes.",
  'You can change the selection method order by selecting the "Change By" button, which will allow you to first choose a center, followed by an active directory group to edit.',
  "Adding a center to the group with no assigned permissions will grant read-only access to the center's schedule.",
  "Note: Changes to permissions require the user to log out and in again to become effective."
];
const permissionsLabelReadme = [
  "Users who are responsible for modifying the schedule for a given center should be granted access to change the schedule.",
  "Users with access to changing the schedule can assign surgeons to rooms, change slot allotments, delete assigned slots, and configure surgeon settings in the centers which they are authorized to access.",
  "Users who are direct managers for a given center should be granted access to that center's settings.",
  "Center Settings allows a user access to the Center Work Week tool to manage the centers in which they have been authorized. Additionally, they can view  (but not modify) surgeon configurations for centers in which they have been authorized."
];

const CenterPermissions: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [adGroups, setAdGroups] = useState<EnhancedActiveDirectoryGroup[]>([]);
  const [formOptions, setFormOptions] = useState<{
    adGroup?: EnhancedActiveDirectoryGroup;
    center?: Center;
    pivot: "adGroups" | "centers";
  }>({ pivot: "adGroups" });
  const [selectedPermissions, setSelectedPermissions] = useState<CenterGroupPermissions>();
  const [permissions, setPermissions] = useState<CenterGroupPermissions[]>([]);
  const adGroupsRef = useRef(adGroups);
  const permissionsRef = useRef(permissions);

  // #region GET AD Groups
  const [groupsRequest] = useState({ name: "Get AD Groups", request: { url: "authorization/groups" } });
  const { response: getGroupsResponse, isLoading: loadingGroupsRequest } =
    useCbp<ActiveDirectoryGroup[]>(groupsRequest);
  useEffect(() => {
    // differentiate between duplicate group names by appending the id where necessary
    const groupNames: Record<string, number> = {};
    setAdGroups(() => {
      if (!getGroupsResponse) return [];
      const aggregatePermissions = indexPermissionCountByGroupId(permissionsRef.current);
      const newGroups = getGroupsResponse
        .map(group => {
          const index = groupNames[group.name] || 0;
          const label = index ? `${group.name} (${index})` : group.name;
          groupNames[group.name] = index + 1;
          return { ...group, label, permissions: aggregatePermissions[group.id] || 0 };
        })
        .sort(groupsComparator);
      setPermissions(permissions =>
        permissions.map(p => ({
          ...p,
          activeDirectoryGroup: newGroups.find(g => g.id === p.activeDirectoryGroup.id) || p.activeDirectoryGroup
        }))
      );
      adGroupsRef.current = newGroups;
      return newGroups;
    });
  }, [getGroupsResponse]);
  // #endregion

  // #region Get All Centers
  const [centersRequest] = useState<UseCbpProps>({
    name: "Get Centers",
    request: { url: "/centers", config: { params: { isAdmin: true } } }
  });
  const { response: centers } = useCbp<Center[]>(centersRequest);
  // #endregion

  // #region Get Center Group Permissions
  const [permissionsRequest] = useState({ name: "Get Group Permissions", request: { url: "authorization/centers" } });
  const { response: getPermissionsResponse, isLoading: loadingGetPermissions } =
    useCbp<CenterGroupPermissions[]>(permissionsRequest);
  useEffect(() => {
    if (getPermissionsResponse) {
      const newPermissions = getPermissionsResponse.sort(alphabeticalComparator).map(p => ({
        ...p,
        activeDirectoryGroup:
          adGroupsRef.current.find(g => g.id === p.activeDirectoryGroup.id) || p.activeDirectoryGroup
      }));
      permissionsRef.current = newPermissions;
      setPermissions(newPermissions);
      setAdGroups(currentGroups => {
        if (!currentGroups.length) return currentGroups;
        const aggregatePermissions = indexPermissionCountByGroupId(newPermissions);
        return currentGroups
          .map(group => ({ ...group, permissions: aggregatePermissions[group.id] || 0 }))
          .sort(groupsComparator);
      });
    }
  }, [getPermissionsResponse]);
  // #endregion

  // #region Update Permissions
  const [updatePermissionsRequest, setUpdatePermissionsRequest] = useState<UseCbpProps<CenterGroupPermissions>>();
  const { response: updatePermissionsResponse, isLoading: loadingUpdatePermissions } = useCbp<
    CenterGroupPermissions,
    CenterGroupPermissions | Record<string, unknown>
  >(updatePermissionsRequest);
  useEffect(() => {
    if (updatePermissionsResponse) {
      enqueueSnackbar("Group Permissions Updated", { variant: "success" });
      setFormOptions(oldOptions => ({
        adGroup: oldOptions.pivot === "adGroups" ? oldOptions.adGroup : undefined,
        center: oldOptions.pivot === "centers" ? oldOptions.center : undefined,
        pivot: oldOptions.pivot
      }));
      let removePermission: CenterGroupPermissions | undefined = undefined;
      if (!updatePermissionsResponse.center)
        setSelectedPermissions(permission => {
          removePermission = permission;
          return undefined;
        });
      else setSelectedPermissions(undefined);
      setPermissions(permissions => {
        if (updatePermissionsResponse.center) {
          const recordIndex = permissions!.findIndex(
            p =>
              p.center.id === updatePermissionsResponse.center.id &&
              p.activeDirectoryGroup.id === updatePermissionsResponse.activeDirectoryGroup.id
          );
          if (recordIndex !== -1) permissions![recordIndex] = updatePermissionsResponse;
          else {
            permissions!.push(updatePermissionsResponse);
            permissions!.sort((p1, p2) => (p1.center.name < p2.center.name ? -1 : 1));
          }
        } else if (removePermission) {
          const recordIndex = permissions!.findIndex(
            p =>
              p.center.id === removePermission!.center.id &&
              p.activeDirectoryGroup.id === removePermission!.activeDirectoryGroup.id
          );
          permissions.splice(recordIndex, 1);
        }
        const newPermissions = [...permissions!].map(p => ({
          ...p,
          activeDirectoryGroup:
            adGroupsRef.current.find(g => g.id === p.activeDirectoryGroup.id) || p.activeDirectoryGroup
        }));
        setAdGroups(oldGroups => {
          const aggregatePermissions = indexPermissionCountByGroupId(newPermissions);
          return oldGroups
            .map(group => ({ ...group, permissions: aggregatePermissions[group.id] || 0 }))
            .sort(groupsComparator);
        });
        return newPermissions;
      });
    }
  }, [updatePermissionsResponse]);
  // #endregion

  // #region Actions
  const onAdGroupSelect = (_: unknown, adGroup: EnhancedActiveDirectoryGroup | null) => {
    setSelectedPermissions(
      adGroup && formOptions.center
        ? permissions.find(
            p => p.center.id === formOptions.center?.id && p.activeDirectoryGroup.id === adGroup?.id
          ) || {
            center: formOptions.center,
            isCapacityEditor: false,
            isCenterEditor: false,
            activeDirectoryGroup: adGroup
          }
        : undefined
    );
    setFormOptions(oldOptions => ({ ...oldOptions, adGroup: adGroup || undefined }));
  };
  const onCenterSelectChange = (_: unknown, center: Center | null) => {
    if (formOptions.adGroup)
      setSelectedPermissions(
        center && formOptions.adGroup
          ? permissions.find(
              p => p.activeDirectoryGroup.id === formOptions.adGroup?.id && p.center.id === center.id
            ) || {
              center,
              isCapacityEditor: false,
              isCenterEditor: false,
              activeDirectoryGroup: formOptions.adGroup
            }
          : undefined
      );
    setFormOptions(oldOptions => ({ ...oldOptions, center: center || undefined }));
  };
  const onPermissionsChange = (
    propName: keyof Omit<CenterPermissionsType, "center">,
    e: ChangeEvent<HTMLInputElement>
  ) => setSelectedPermissions(permissions => ({ ...permissions!, [propName]: e.target.checked }));
  const onPivot = () => {
    setSelectedPermissions(undefined);
    setFormOptions(oldOptions => ({ pivot: oldOptions.pivot === "adGroups" ? "centers" : "adGroups" }));
  };
  const onSelectListChange = (newValue: CenterGroupPermissions | null) => {
    setSelectedPermissions(newValue || undefined);
    if (newValue)
      setFormOptions(oldOptions => ({
        ...oldOptions,
        center: oldOptions.adGroup ? undefined : oldOptions.center,
        adGroup: oldOptions.center ? undefined : oldOptions.adGroup
      }));
  };
  const onRemoveCenter = () => {
    setUpdatePermissionsRequest({
      name: "Save Group Permissions",
      request: { url: "authorization/centers", method: "delete", data: selectedPermissions! }
    });
  };
  const onSaveChanges = () => {
    setUpdatePermissionsRequest({
      name: "Save Group Permissions",
      request: { url: "authorization/centers", method: "put", data: selectedPermissions! }
    });
  };
  //#endregion

  // show spinner while loading
  useEffect(
    () => setIsContentLoading(!centers || loadingGetPermissions || loadingGroupsRequest),
    [centers, loadingGetPermissions, loadingGroupsRequest]
  );

  return (
    <AdministrationTool title="Center Permissions" readme={toolReadme} sx={{ minWidth: "60rem" }}>
      <Grid container direction="column" alignItems="center" spacing={4}>
        <Grid item container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            {formOptions.pivot === "adGroups" ? (
              <Autocomplete
                options={adGroups}
                renderInput={params => <TextField {...params} label="Active Directory Group" />}
                renderOption={(props, option) => <ADGroupOption props={props} option={option} />}
                value={formOptions.adGroup || null}
                onChange={onAdGroupSelect}
                sx={{ minWidth: "20rem" }}
              />
            ) : (
              <Autocomplete
                options={centers || []}
                renderInput={params => <TextField {...params} label="Center" />}
                getOptionLabel={center => center.name}
                value={formOptions.center || null}
                onChange={onCenterSelectChange}
                sx={{ minWidth: "20rem" }}
              />
            )}
          </Grid>
          <Grid item>
            <Button onClick={onPivot}>{`Change By ${formOptions.pivot === "adGroups" ? "Centers" : "Groups"}`}</Button>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          {formOptions.pivot === "adGroups" ? (
            <Grid item xs>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <AdministrationFormLabel
                    label="Center"
                    readme={["The permissions currently assigned by center to the selected Active Directory Group."]}
                  />
                  <SelectList
                    items={permissions.filter(p => p.activeDirectoryGroup.id === formOptions.adGroup?.id)}
                    itemKey={(item: CenterGroupPermissions, value: CenterGroupPermissions) =>
                      item.center.id === value.center.id
                    }
                    value={formOptions.center ? null : selectedPermissions || null}
                    disabled={loadingUpdatePermissions}
                    onChange={onSelectListChange}
                    listItem={CenterListItem}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    disabled={loadingUpdatePermissions || !formOptions.adGroup}
                    options={(centers || []).filter(
                      ({ id }) =>
                        !permissions?.some(
                          p => p.center.id === id && p.activeDirectoryGroup.id === formOptions.adGroup?.id
                        )
                    )}
                    renderInput={params => <TextField {...params} label="Add Center" />}
                    getOptionLabel={center => center.name}
                    value={formOptions.center || null}
                    onChange={onCenterSelectChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <AdministrationFormLabel
                    label="Active Directory Group"
                    readme={["The permissions currently assigned by Active Directory Group to the selected Center."]}
                  />
                  <SelectList
                    items={permissions.filter(p => p.center.id === formOptions.center?.id)}
                    itemKey={(item: CenterGroupPermissions, value: CenterGroupPermissions) =>
                      item.activeDirectoryGroup.id === value.activeDirectoryGroup.id
                    }
                    value={formOptions.adGroup && formOptions.center ? null : selectedPermissions || null}
                    disabled={loadingUpdatePermissions}
                    onChange={onSelectListChange}
                    listItem={AdGroupListItem}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    disabled={loadingUpdatePermissions || !formOptions.center}
                    options={adGroups.filter(
                      ({ id }) =>
                        !permissions?.some(
                          p => p.activeDirectoryGroup.id === id && p.center.id === formOptions.center?.id
                        )
                    )}
                    renderOption={(props, option) => <ADGroupOption props={props} option={option} />}
                    renderInput={params => <TextField {...params} label="Add Active Directory Group" />}
                    value={formOptions.adGroup || null}
                    onChange={onAdGroupSelect}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs>
            <Grid container direction="column" spacing={2} sx={{ height: "100%" }} wrap="nowrap">
              <Grid item>
                <AdministrationFormLabel
                  label={`Permissions${
                    selectedPermissions
                      ? ` for ${
                          formOptions.pivot === "adGroups"
                            ? selectedPermissions.center.name
                            : selectedPermissions.activeDirectoryGroup.label
                        }`
                      : ""
                  }`}
                  readme={permissionsLabelReadme}
                />
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch />}
                    disabled={!selectedPermissions}
                    checked={selectedPermissions?.isCapacityEditor || false}
                    onChange={e => onPermissionsChange("isCapacityEditor", e as ChangeEvent<HTMLInputElement>)}
                    label="Grant Access to Change the Schedule"
                  />
                  <FormControlLabel
                    control={<Switch />}
                    disabled={!selectedPermissions}
                    checked={selectedPermissions?.isCenterEditor || false}
                    onChange={e => onPermissionsChange("isCenterEditor", e as ChangeEvent<HTMLInputElement>)}
                    label="Grant Access to Center Settings"
                  />
                </FormGroup>
              </Grid>
              <Grid item container justifyContent="space-around">
                {loadingUpdatePermissions ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Grid item>
                      <Button
                        disabled={
                          !selectedPermissions ||
                          !permissions.some(
                            p =>
                              p.activeDirectoryGroup.id === selectedPermissions.activeDirectoryGroup.id &&
                              p.center.id === selectedPermissions.center.id
                          )
                        }
                        onClick={onRemoveCenter}
                        color="secondary"
                      >
                        Remove Center
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button disabled={!selectedPermissions} onClick={onSaveChanges}>
                        Save Changes
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const AdGroupListItem: React.FC<CustomListItem<CenterGroupPermissions>> = ({
  data,
  selected,
  ...rest
}: CustomListItem<CenterGroupPermissions>) => {
  return (
    <ListItem {...(rest as Record<string, unknown>)}>
      <ListItemIcon>
        <Checkbox edge="start" checked={selected} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText primary={data.activeDirectoryGroup.label} />
      {data.isCapacityEditor && (
        <ListItemIcon>
          <Chip size="small" label="Schedule" />
        </ListItemIcon>
      )}
      {data.isCenterEditor && (
        <ListItemIcon>
          <Chip size="small" label="Administrator" />
        </ListItemIcon>
      )}
    </ListItem>
  );
};
const CenterListItem: React.FC<CustomListItem<CenterGroupPermissions>> = ({
  data,
  selected,
  ...rest
}: CustomListItem<CenterGroupPermissions>) => {
  return (
    <ListItem {...(rest as Record<string, unknown>)}>
      <ListItemIcon>
        <Checkbox edge="start" checked={selected} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText primary={data.center.name} />
      {data.isCapacityEditor && (
        <ListItemIcon>
          <Chip size="small" label="Schedule" />
        </ListItemIcon>
      )}
      {data.isCenterEditor && (
        <ListItemIcon>
          <Chip size="small" label="Center" />
        </ListItemIcon>
      )}
    </ListItem>
  );
};
interface ADGroupOptionProps {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: EnhancedActiveDirectoryGroup;
}
const ADGroupOption: React.FC<ADGroupOptionProps> = ({ props, option }: ADGroupOptionProps) => {
  return (
    <Box component="li" {...props}>
      {Boolean(option.permissions) && <Chip label={option.permissions} sx={{ mr: 1 }} />}
      {option.label}
    </Box>
  );
};

export default CenterPermissions;
