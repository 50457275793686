import { useSnackbar } from "notistack";
import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from "react";

import { LocalStorageKeys } from "../Constants";
import { Center } from "../models/Center";
import { EnhancedCenterLock } from "../models/CenterLock";
import { User } from "../models/User";
import DateTime from "../types/DateTime";
import { NotificationBanner } from "../types/NotificationBanner";
import Token from "../types/Token";
import { loadFromLocal, queryFromSession, saveToLocal } from "../utils/Helpers";
import useSignalR, { SignalRConnectionConfig, SignalRConnectionInfo } from "../utils/UseSignalR";

const signalRReconnectTimeout = process.env.REACT_APP_SIGNALR_RECONNECTION_TIMEOUT_SECONDS
  ? Number(process.env.REACT_APP_SIGNALR_RECONNECTION_TIMEOUT)
  : 3000;

interface AppContextProps {
  /** If truthy, renders a banner at the top of the application with content and actions dictated by the state.
   * If `undefined`, no banner is shown.
   */
  bannerNotification?: NotificationBanner;
  /** A center lock for the currently selected center held by a foreign user. */
  centerLock?: EnhancedCenterLock;
  /** The state for all payloads from tracked Signal R topics. */
  payloads: Record<string, unknown>;
  /** The raw value of the bearer token assigned to the user. */
  token?: Token;
  /** The user currently logged in to the application. */
  user: User;
  /** A center lock owned by the current user. */
  userCenterLock?: EnhancedCenterLock;
  /** State setter for {@link AppContextProps.bannerNotification}. */
  setBannerNotification: Dispatch<SetStateAction<NotificationBanner | undefined>>;
  /** State setter for {@link AppContextProps.centerLock}. */
  setCenterLock: Dispatch<SetStateAction<EnhancedCenterLock | undefined>>;
  /** State setter for the connection configuration to signal R. */
  setSignalRConnectionInfo: (info: SignalRConnectionInfo) => void;
  /** State setter for {@link AppContextProps.token}. */
  setToken: Dispatch<SetStateAction<Token | undefined>>;
  /** State setter for {@link AppContextProps.user}. */
  setUser: Dispatch<SetStateAction<User | undefined>>;
  /** State setter for {@link AppContextProps.userCenterLock}. */
  setUserCenterLock: Dispatch<SetStateAction<EnhancedCenterLock | undefined>>;
  /** Callback to add topics to the SignalR websocket. */
  subscribe: (topics: string | string[]) => void;
  /** Callback to remove topics from the SignalR websocket. */
  unsubscribe: (topics: string | string[]) => void;
}

const AppContext = createContext({} as AppContextProps);

export const AppContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [bannerNotification, setBannerNotification] = useState<NotificationBanner>();
  const [centerLock, setCenterLock] = useState<EnhancedCenterLock>();
  const [signalRConnectionInfo, setSignalRConnectionInfo] = useState(() =>
    loadFromLocal<SignalRConnectionInfo>("signalRConnectionInfo")
  );
  const [token, setToken] = useState<Token | undefined>(() => {
    const sessionData = queryFromSession<{ secret: string; expiresOn: string }>("credentialType", "AccessToken");
    if (!sessionData?.secret || !sessionData?.expiresOn) return undefined;
    else
      return {
        secret: sessionData.secret,
        expiresOn: DateTime.fromSeconds(parseInt(sessionData?.expiresOn))
      };
  });

  const [userMe, setUserMe] = useState(() => loadFromLocal<User>(LocalStorageKeys.userMe));
  const [userCenterLock, setUserCenterLock] = useState(() => {
    // load the user's center lock from local.
    let lock = loadFromLocal<EnhancedCenterLock>(LocalStorageKeys.userCenterLock);
    // rebuild the DateTime object since it doesn't serialize as such
    if (lock) {
      lock = new EnhancedCenterLock(lock, { id: lock.centerId, name: lock.centerName } as Center);
      // if has expired discard it
      if (DateTime.fromISO(lock.expiration) < DateTime.utc()) return undefined;
    }
    return lock;
  });

  const [signalRConfig] = useState<SignalRConnectionConfig>({
    reconnectTimeout: signalRReconnectTimeout,
    onReconnecting: () => enqueueSnackbar("Reconnecting to the server...", { variant: "info" }),
    onReconnected: () => enqueueSnackbar("Connected", { variant: "success" })
  });
  const { subscribe, unsubscribe, payloads } = useSignalR(signalRConnectionInfo, signalRConfig);

  // cache the user's own center lock
  useEffect(() => {
    if (userCenterLock) saveToLocal(userCenterLock, LocalStorageKeys.userCenterLock);
    else localStorage.removeItem(LocalStorageKeys.userCenterLock);
  }, [userCenterLock]);

  return (
    <AppContext.Provider
      value={{
        bannerNotification,
        centerLock,
        payloads,
        token,
        user: userMe!,
        userCenterLock,
        setBannerNotification,
        setCenterLock,
        setSignalRConnectionInfo,
        setToken,
        setUser: setUserMe,
        setUserCenterLock,
        subscribe,
        unsubscribe
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
