import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Chip, Grid, IconButton, TextField, Tooltip, Typography, styled } from "@mui/material";
import { red } from "@mui/material/colors";
import { DateTime, Zone } from "luxon";
import React from "react";

import { Block, BlockType } from "../../../models/Block";
import { CbpError } from "../../../models/CbpError";
import { Room } from "../../../models/Room";
import { EnhancedRoomDay } from "../../../models/RoomDay";
import { EnhancedSurgeon, Surgeon } from "../../../models/Surgeon";
import CalendarDayCard from "../../Helpers/Calendar/CalendarDayCard";
import { CalendarDayCardProps, DayItemProps } from "../../Helpers/Calendar/types";

interface StyledCalendarDayCardProps extends CalendarDayCardProps {
  variant?: "secondary" | "error";
}

const StyledCalendarDayCard = styled(CalendarDayCard, {
  shouldForwardProp: prop => prop !== "variant"
})<StyledCalendarDayCardProps>(({ variant, theme }) => ({
  ...(variant === "secondary" && {
    "&.MuiCard-root": { backgroundColor: theme.palette.secondary.light }
  }),
  ...(variant === "error" && {
    "&.MuiCard-root": { backgroundColor: red[50] }
  })
}));

export interface EditDayItem {
  /** Flag indicating if this day card is assigned to the editing surgeon. */
  assigned: boolean;
  /** The error message of a current interface user-error for this day card. */
  error?: string;
  /** The Http error response from an attempt to save data for this day card. */
  errorData?: CbpError<Block>[];
  /** The weight sought by the sub-category filter. */
  fitServiceWeight: number;
  /** Flag indicating if the slot input should be disabled. */
  disabled: boolean;
  /** The current editing value of the slot count input value. */
  slotCount?: number;
  /** The current surgeon selected for assignment. */
  surgeon?: EnhancedSurgeon;
  /**  The current room selected for assignment. */
  room?: Room;
  /** The room-days for this calendar day card. */
  roomDays: EnhancedRoomDay[];
  /** The Luxon timezone of the currently selected center. */
  centerZone: Zone;
  /** Callback when the user toggles the assignment of the card. */
  onAssignToggle: () => void;
  /** Callback when the user changes the number of slots input value. */
  onSlotChange: (value?: number) => void;
  /** Callback when the user focuses the slots input. */
  onInputFocus: (date: string) => void;
}

const BatchEditViewDayCard: React.FC<DayItemProps<EditDayItem>> = ({
  data,
  date,
  ...rest
}: DayItemProps<EditDayItem>) => {
  const editingRoomDay = data?.room ? data?.roomDays.find(rd => rd.roomId === data?.room?.id) : undefined;
  const oldSlotCount = editingRoomDay?.blocks.filter(b => b.blockType === BlockType.Slot).length;

  const disableEditing: boolean =
    // block edits if either room or surgeon is not selected for batch editing
    !data?.room ||
    !data.surgeon ||
    // block edits if the surgeon is terminated
    (data.surgeon.terminationDateTime && data.surgeon.terminationDateTime <= date) ||
    // block edits if the room is already assigned to another surgeon
    (editingRoomDay?.surgeonId && editingRoomDay.surgeonId !== data.surgeon.id) ||
    // or the surgeon is already assigned to another room
    data.roomDays.some(rd => rd.surgeonId === data.surgeon!.id && rd.roomId !== data.room!.id);

  // only display rows for room days containing blocks of type "slot" or appointments.
  const displayRows =
    data?.roomDays
      ?.map(rowDay => ({
        ...rowDay,
        blocks: rowDay.blocks.filter(b => b.blockType === BlockType.Slot),
        appointments: rowDay.appointments?.filter(a => a.isSurgery)
      }))
      // hide info rows lacking any blocks or appointments or if the slots are being edited
      .filter(rowDay => rowDay.blocks.length || rowDay.appointments?.length) || [];

  const onChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(value);
    if (!isNaN(newValue)) data?.onSlotChange(value ? newValue : undefined);
  };
  const surgeonWeightCapacity = data?.surgeon?.weightCategory?.weightValue || 0;
  const putErrors = data?.errorData && (
    <>
      {data.errorData.map((errorDatum, index) => {
        const errorMEssage = `${errorDatum.message} ${errorDatum.description || ""}`;
        const message = errorDatum.data
          ? `${DateTime.fromISO(errorDatum.data.startTimeUtc, { zone: data.centerZone }).toLocaleString(
              DateTime.TIME_SIMPLE
            )}: ${errorMEssage}`
          : errorMEssage;
        return <div key={index}>{message}</div>;
      })}
    </>
  );

  return (
    <StyledCalendarDayCard
      data={data as never}
      variant={
        // style as error if the state has an error
        data?.error
          ? "error"
          : // style as edited if count is different, or the surgeon has been assigned
          data?.slotCount && (data?.slotCount != oldSlotCount || data?.assigned)
          ? "secondary"
          : undefined
      }
      date={date}
      {...rest}
    >
      <Grid container direction="column">
        {displayRows.map((roomDay, index) => {
          const surgeonName = roomDay?.surgeon ? Surgeon.GetName(roomDay.surgeon) : "";
          const isSurgeonTerminated =
            roomDay.surgeon?.terminationDateTime && roomDay.surgeon.terminationDateTime <= date;
          return (
            <div key={index}>
              <Grid item container alignItems="center" key={index}>
                <Grid item xs>
                  {!disableEditing && roomDay.roomId === data?.room?.id && roomDay.surgeonId === data?.surgeon?.id ? (
                    <TextField
                      disabled={data.disabled}
                      label={`${surgeonName} - ${data.room!.name}`}
                      type="number"
                      onChange={onChange}
                      onFocus={() => data.onInputFocus(date.toISODate())}
                      value={data.slotCount || ""}
                      variant="standard"
                    />
                  ) : roomDay?.surgeon ? (
                    <Typography variant={isSurgeonTerminated ? "captionStrikethrough" : "caption"}>
                      {surgeonName}
                    </Typography>
                  ) : (
                    <>
                      <Grid item container alignItems="center">
                        <Grid item xs>
                          {data?.assigned && roomDay.roomId === editingRoomDay?.roomId ? (
                            <TextField
                              disabled={data.disabled}
                              label={roomDay.room.name}
                              type="number"
                              onChange={({ target: { value } }) => data.onSlotChange(value ? Number(value) : undefined)}
                              onFocus={() => data.onInputFocus(date.toISODate())}
                              value={data.slotCount || ""}
                              variant="standard"
                            />
                          ) : (
                            <Typography variant="caption">{roomDay.room.name}</Typography>
                          )}
                        </Grid>
                        {!disableEditing && roomDay.roomId === editingRoomDay?.roomId && (
                          <Grid item>
                            <Tooltip
                              title={
                                data?.assigned ? "Undo Assignment" : `Assign to ${Surgeon.GetName(data!.surgeon!)}`
                              }
                            >
                              <IconButton onClick={data?.onAssignToggle} size="small">
                                {data?.assigned ? <RemoveIcon /> : <AddIcon />}
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
                {roomDay?.surgeon ? (
                  <Grid item>
                    <Tooltip
                      title={
                        <>
                          <div>{`${roomDay.appointments.length} Surger${
                            roomDay.appointments.length > 1 ? "ies" : "y"
                          } / ${roomDay.blocks.length} Slot${roomDay.blocks.length > 1 ? "s" : ""}`}</div>
                          {isSurgeonTerminated && (
                            <div>{`Surgeon was terminated effective ${roomDay.surgeon.terminationDateTime?.toLocaleString(
                              DateTime.DATE_MED
                            )}`}</div>
                          )}
                        </>
                      }
                    >
                      <Chip
                        color="default"
                        label={`${roomDay.appointments.length}/${roomDay.blocks.length}`}
                        size="small"
                        variant={
                          // if surgeon will have been terminated, color as over-capacity
                          isSurgeonTerminated ||
                          // if capacity exceeds the surgeon's maximum, color as over-capacity
                          roomDay.appointmentsWeight > surgeonWeightCapacity ||
                          // if appointments exceed the surgeon's slots, color as over-capacity
                          roomDay.appointments.length > roomDay.blocks.length
                            ? "error"
                            : // otherwise, if remaining capacity can fit a service
                            surgeonWeightCapacity - (data?.fitServiceWeight || 0) - roomDay.appointmentsWeight >= 0 &&
                              // and if the surgeon has remaining slots, color as under-capacity
                              roomDay.appointments.length < roomDay.blocks.length
                            ? "success"
                            : // finally, color as default
                              undefined
                        }
                      />
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item>
                    <Tooltip
                      arrow
                      placement="right"
                      title={
                        <>
                          <div>{`${roomDay.appointments.length} Surger${
                            roomDay.appointments.length !== 1 ? "ies" : "y"
                          }, ${roomDay.blocks.length} Slot${roomDay.blocks.length !== 1 ? "s" : ""}`}</div>
                          <Box sx={{ opacity: 0 }}>spacer</Box>
                          <div>
                            {"This room is not assigned to a Surgeon"}
                            {roomDay.appointments.length
                              ? `, but has ${roomDay.appointments.length === 1 ? "a " : ""}Surger${
                                  roomDay.appointments.length !== 1 ? "ies" : "y"
                                } for ${
                                  roomDay.appointments
                                    .filter(p => p.isSurgery)
                                    .flatMap(value => value.surgeon)
                                    ?.at(0)?.firstName
                                } ${
                                  roomDay.appointments
                                    .filter(p => p.isSurgery)
                                    .flatMap(value => value.surgeon)
                                    ?.at(0)?.lastName
                                }.`
                              : `.`}
                          </div>
                        </>
                      }
                    >
                      <Chip
                        label={
                          <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                            {`${roomDay.appointments.length}/${roomDay.blocks.length}`}
                          </Box>
                        }
                        color="warning"
                        size="small"
                      />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </div>
          );
        })}
      </Grid>
      {!disableEditing && !oldSlotCount && (
        <TextField
          disabled={data?.disabled}
          label={Surgeon.GetName(data!.surgeon!)}
          type="number"
          onChange={({ target: { value } }) => data?.onSlotChange(value ? Number(value) : undefined)}
          onFocus={() => data.onInputFocus(date.toISODate())}
          value={data?.slotCount || ""}
          variant="standard"
        />
      )}
      {data?.error && (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="caption" color="error">
              {data.error}
            </Typography>
          </Grid>
          {putErrors && (
            <Grid item>
              <Tooltip title={putErrors}>
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
    </StyledCalendarDayCard>
  );
};

export default BatchEditViewDayCard;
