import { Chip, CircularProgress, Grid, Palette, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { LocalStorageKeys } from "../../../Constants";
import { Center } from "../../../models/Center";
import DateTime from "../../../types/DateTime";
import { PlannerViewProps, PlannerViewType } from "../../../types/PlannerViewProps";
import { UserSettings } from "../../../types/UserSettings";
import { loadFromLocal } from "../../../utils/Helpers";
import CalendarComponent from "../../Helpers/Calendar/Calendar";
import { DayOfWeekOrder, WeekdayKey } from "../../Helpers/Calendar/types";
import PlannerNavigation from "../PlannerNavigation";
import MonthlyViewDayCard, { MonthDayItem } from "./MonthlyViewDayCard";
import MonthViewOptions from "./MonthlyViewOptions";
import MonthlyViewSummary from "./MonthlyViewSummary";

// color map for colorizing the key and summary items
export const StateColors: Record<
  string,
  keyof Pick<Palette, "info" | "success" | "error" | "primary" | "warning" | "secondary"> | "default"
> = {
  missed: "error",
  overflowing: "warning",
  filled: "default",
  open: "success"
};

const MonthlyView: React.FC<PlannerViewProps> = ({
  calendarProps,
  date,
  today,
  editDisabled = false,
  isLoading,
  isLoadingUpdate,
  selectedSubCategories,
  serviceSubCategories,
  simpleCenter,
  simpleCenters,
  setCenter,
  setDate,
  setSelectedSubCategories
}: PlannerViewProps) => {
  const [userSettings] = useState(loadFromLocal<UserSettings>(LocalStorageKeys.userSettings));
  const [dayOfWeekOrder, setDayOfWeekOrder] = useState(
    userSettings?.defaultDayOfWeekStart !== undefined ? userSettings.defaultDayOfWeekStart : DayOfWeekOrder.SUNDAY_FIRST
  );
  const [onlyWorkDays, setOnlyWorkDays] = useState<{ enabled: boolean; days: WeekdayKey[] }>({
    enabled: false,
    days: []
  });
  const [monthCalendar, setMonthCalendar] = useState<Record<string, MonthDayItem>>({});
  const calendarPropsRef = useRef(calendarProps);

  // update operational days on center change
  useEffect(() => {
    setOnlyWorkDays(oldState => ({
      enabled:
        // if first calendar and user settings turn on only operational days, do so
        (!calendarPropsRef.current && calendarProps && userSettings?.defaultShowOnlyWorkDays) ||
        // otherwise maintain enabled state
        oldState.enabled,
      days: calendarProps ? Center.getWorkDays(calendarProps.center) : []
    }));
    calendarPropsRef.current = calendarProps;
  }, [calendarProps]);

  // build the month calendar state from the calendar and categories
  useEffect(() => {
    if (!calendarProps?.calendar.days) return;
    // get the minimum weight value for all subcategories
    const fitServiceWeight = selectedSubCategories.length
      ? selectedSubCategories.reduce((sum, subCat) => sum + (subCat.weightValue || 0), 0)
      : undefined;
    const calendar: Record<string, MonthDayItem> = {};
    // build a month view planner object for the calendar data
    Object.entries(calendarProps.calendar.days).forEach(([key, roomDays]) => {
      calendar[key] = {
        roomDays,
        fitServiceWeight,
        onClick: () => setDate(DateTime.utcFromDateString(key), PlannerViewType.DAY)
      };
    });
    setMonthCalendar(calendar);
  }, [calendarProps, selectedSubCategories, serviceSubCategories]);

  // #region Actions
  const onChangeDate = (date: DateTime) => {
    setDate(DateTime.utc(date.year, date.month, 1), PlannerViewType.MONTH);
  };
  const updateOnlyWorkDays = useCallback(
    (enabled: boolean) => setOnlyWorkDays(oldState => ({ ...oldState, enabled })),
    []
  );
  // #endregion
  const updatedMessage = calendarProps?.calendar.updatedDateTime ? (
    <>
      <div>Month Last Updated:</div>
      <div>{calendarProps?.calendar.updatedBy}</div>
      <div>{calendarProps?.calendar.updatedDateTime.toLocaleString(DateTime.DATETIME_SHORT)}</div>
    </>
  ) : (
    "No Changes"
  );
  const calendarIsLoaded =
    calendarProps?.center.id === simpleCenter.id && calendarProps?.date.equals(date) && !isLoading;

  return (
    <PlannerNavigation
      date={date}
      editDisabled={editDisabled}
      isCalendarLoaded={Boolean(calendarProps)}
      isLoading={isLoading}
      isLoadingUpdate={isLoadingUpdate}
      selectedSubCategories={selectedSubCategories}
      serviceSubCategories={serviceSubCategories}
      settingsMenu={
        <MonthViewOptions
          dayOfWeekOrder={dayOfWeekOrder}
          hiddenWeekDays={onlyWorkDays.enabled}
          setDayOfWeekOrder={setDayOfWeekOrder}
          setOnlyWorkDays={updateOnlyWorkDays}
        />
      }
      simpleCenter={simpleCenter}
      simpleCenters={simpleCenters}
      updatedMessage={updatedMessage}
      view={PlannerViewType.MONTH}
      setCenter={setCenter}
      setDate={onChangeDate}
      setSelectedSubCategories={setSelectedSubCategories}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        rowSpacing={1}
        wrap="nowrap"
        sx={{ width: "100%", height: "100%", pt: 2 }}
      >
        {calendarProps?.calendar ? (
          <>
            <Grid className="w-100" item xs>
              <CalendarComponent
                data={monthCalendar}
                today={today}
                month={date.set({ day: 1 })}
                // TODO remove assertion
                DayItem={MonthlyViewDayCard as never}
                hideWeekdays={onlyWorkDays.enabled ? onlyWorkDays.days : []}
                dayOfWeekOrder={dayOfWeekOrder}
              />
            </Grid>
            <Grid item container justifyContent="space-between" sx={{ pb: 1 }}>
              <Grid item>
                <Grid container alignItems="center" columnSpacing={1}>
                  <Grid item>
                    <Typography variant="h6">Key:</Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={
                        <>
                          <div>A room assignment for a future day that can accept additional slots.</div>
                          <div>The surgeon has open slots which can be filled with new surgeries.</div>
                        </>
                      }
                      placement="top"
                      arrow
                    >
                      <Chip color={StateColors.open} label="Open Slots" />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={
                        <>
                          <div>
                            A room assignment that could have accepted additional slots which is now in the past.
                          </div>
                          <div>The surgeon had open slots which could have been filled with surgeries.</div>
                        </>
                      }
                      placement="top"
                      arrow
                    >
                      <Chip color={StateColors.missed} label="Missed Slots" />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={
                        <>
                          <div>A room assignment that is full and cannot accept additional slots.</div>
                          <div>The surgeon has met the weight limit and/or the slot limit for this room.</div>
                        </>
                      }
                      placement="top"
                      arrow
                    >
                      <Chip color={StateColors.filled} label="All Slots Filled" />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title="The surgeon has more surgeries than slots. The room is not assigned to a surgeon, but contains surgeries or slots. The surgeon was terminated prior to their assigned day."
                      placement="top"
                    >
                      <Chip color="warning" label="Problem" />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              {calendarIsLoaded && (
                <Grid item>
                  <MonthlyViewSummary calendar={monthCalendar} today={today} goal={calendarProps.calendar.centerGoal} />
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <Grid className="w-100" item xs container justifyContent="center" alignItems="center">
            <CircularProgress size="5rem" />
          </Grid>
        )}
      </Grid>
    </PlannerNavigation>
  );
};

export default MonthlyView;
