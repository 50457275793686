import React, { CSSProperties, useEffect, useState } from "react";
import { useDrop } from "react-dnd";

import { ScheduleItem, SchedulerClassKey, SchedulerRowDropZoneProps } from "./types";

export const SchedulerRowDropZone = <TItem extends ScheduleItem>({
  activeOverrideEnabled,
  accept = "",
  isPlaceModeActive,
  label,
  orientation,
  size,
  onClick,
  onDrop
}: SchedulerRowDropZoneProps<TItem>): JSX.Element => {
  const [mouseInside, setMouseInside] = useState(false);
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [accept],
      collect: monitor => ({
        isOver: Boolean(monitor) && monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop()
      }),
      drop: (item: TItem) => onDrop(item)
    }),
    [accept]
  );
  useEffect(() => {
    if (!accept || !isPlaceModeActive) setMouseInside(false);
  }, [accept, isPlaceModeActive]);
  const zoneIsActive = (isOver && canDrop) || (isPlaceModeActive && (mouseInside || activeOverrideEnabled));
  const zoneIsVisible = accept || isPlaceModeActive;
  const zoneClassName = zoneIsActive
    ? `${SchedulerClassKey.dropZoneActive}`
    : zoneIsVisible
    ? `${SchedulerClassKey.dropZoneVisible}`
    : undefined;
  const style: CSSProperties = {
    position: "relative",
    width: orientation === "horizontal" ? `${size}px` : "100%",
    height: orientation === "horizontal" ? "100%" : `${size}px`
  };
  const handleClick = () => {
    setMouseInside(false);
    onClick();
  };
  return (
    <div
      onMouseEnter={() => isPlaceModeActive && setMouseInside(true)}
      onMouseLeave={() => isPlaceModeActive && setMouseInside(false)}
      onClick={() => zoneIsVisible && handleClick()}
      className={zoneClassName}
      ref={drop}
      style={style}
    >
      {zoneIsActive && label}
    </div>
  );
};

export default SchedulerRowDropZone;
