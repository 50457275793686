import React from "react";

interface Props extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactChild | React.ReactChildren;
  /** Define the filter wrapper component as either a `<span />` or a `<div />` element.
   * @defaultValue `"div"`
   */
  component?: "span" | "div";
  /** Flag indicating if the wrapper should have `css: display: "none"` set.*/
  isHidden: boolean;
}

/** Helper for hiding a component with css so that it can be constructed but not rendered to the user. */
const CssDisplayFilter: React.FC<Props> = ({ children, component = "div", isHidden, style, ...rest }: Props) =>
  component === "div" ? (
    <div style={{ ...style, display: isHidden ? "none" : undefined }} {...rest}>
      {children}
    </div>
  ) : (
    <span style={{ ...style, display: isHidden ? "none" : undefined }} {...rest}>
      {children}
    </span>
  );

export default CssDisplayFilter;
