import { Box, Button, Grid, ModalProps, Modal as MuiModal } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface Props extends ModalProps {
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const Modal: React.FC<PropsWithChildren<Props>> = ({
  cancelText = "Cancel",
  confirmText = "Ok",
  onCancel,
  onConfirm,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  const buttons: JSX.Element[] = [];
  if (onCancel)
    buttons.push(
      <Button onClick={onCancel} variant="text">
        {cancelText}
      </Button>
    );
  if (onConfirm)
    buttons.push(
      <Button onClick={onConfirm} variant="text">
        {confirmText}
      </Button>
    );

  return (
    <MuiModal {...rest}>
      <Box
        sx={theme => ({
          position: "absolute",
          backgroundColor: theme.palette.background.paper,
          border: "2px solid #000",
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)"
        })}
      >
        <Grid container direction="column">
          <Grid item>{children}</Grid>
          {Boolean(buttons.length) && (
            <Grid item container justifyContent="flex-end">
              {buttons.map((b, index) => (
                <Grid item key={index}>
                  {b}
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Box>
    </MuiModal>
  );
};

export default Modal;
