import { Zone } from "luxon";

import { WeekdayKey } from "../components/Helpers/Calendar/types";
import { Room } from "./Room";
import { CenterSurgeon, EnhancedSurgeon } from "./Surgeon";
import { TimeZone } from "./TimeZone";

/** The dto model used to transact Centers with the backend. */
export class Center {
  /** The primary key of the center. */
  id!: string;
  /** The plain text name of the center. */
  name!: string;
  /** The associated time zone of the center. */
  timeZone!: TimeZone;
  /** The associated week day schedule of the center. */
  schedule!: CenterSchedule;
  /** The associated rooms for the center. */
  rooms!: Room[];
  /** THe associated surgeons for the center. */
  surgeons!: CenterSurgeon[];

  static getWorkDays = (center: Center): WeekdayKey[] =>
    Object.keys(center.schedule).filter(day => !center.schedule[day as WeekdayKey]) as WeekdayKey[];
}

/** The dto model used to transact a center's week day operational days with the backend. */
export interface CenterSchedule extends Record<WeekdayKey, boolean> {
  /** Flag indicating if the center is open on Sunday. */
  sunday: boolean;
  /** Flag indicating if the center is open on Monday. */
  monday: boolean;
  /** Flag indicating if the center is open on Tuesday. */
  tuesday: boolean;
  /** Flag indicating if the center is open on Wednesday. */
  wednesday: boolean;
  /** Flag indicating if the center is open on Thursday. */
  thursday: boolean;
  /** Flag indicating if the center is open on Friday. */
  friday: boolean;
  /** Flag indicating if the center is open on Sunday. */
  saturday: boolean;
}

/** Expanded Center model with additional utility props. */
export interface EnhancedCenter extends Omit<Center, "surgeons"> {
  /** The `surgeons` prop enhanced with additional utility props. */
  surgeons: EnhancedSurgeon[];
  /** The luxon time zone offset string. */
  luxonTimeZone: Zone;
}

/** A simplified expression of a center with only minimal properties. */
export type SimpleCenter = Pick<Center, "id" | "name">;
