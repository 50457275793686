import { DateTime } from "luxon";

import { Block } from "../models/Block";
import { EnhancedCalendar } from "../models/Calendar";
import { EnhancedCenter, SimpleCenter } from "../models/Center";
import { EnhancedCenterHours } from "../models/CenterHours";
import { ServiceSubCategory } from "../models/ServiceSubCategory";

export enum PlannerViewType {
  BATCH_EDIT = "batch-edit",
  DAY = "day",
  MONTH = "month"
}

/** Properties shared by all Planner view components. */
export interface PlannerViewProps {
  /** The singular object used to render a view's calendar. */
  calendarProps?: {
    /** The fully enhanced center including relevant surgeons and room data. */
    center: EnhancedCenter;
    /** The fully enhanced calendar including relevant room data. */
    calendar: EnhancedCalendar;
    /** The date for the associated calendar. */
    date: DateTime;
  };
  /** The operational hours for a given centered keyed by date string. */
  centerHours: Record<string, EnhancedCenterHours>;
  /** The calendar day for the schedule to be shown. */
  date: DateTime;
  /** The current date in the same timezone as date. */
  today: DateTime;
  /** If all interactivity of the view is disabled. */
  disabled?: boolean;
  /** If editing of the view data is disabled. */
  editDisabled: boolean;
  /** Flag indicating when there is a pending GET calendar query. */
  isLoading: boolean;
  /** Flag indicating if request getting an updated calendar for the current month and center is pending. */
  isLoadingUpdate: boolean;
  /** The group of selected service subcategories. */
  selectedSubCategories: ServiceSubCategory[];
  /** All service subcategories in CBP. */
  serviceSubCategories: ServiceSubCategory[];
  /** The simple center that is currently selected. */
  simpleCenter: SimpleCenter;
  /** The center options available to select. */
  simpleCenters: SimpleCenter[];
  /** Callback when the user changes the planner day/type to be viewed. */
  setDate: (newDate: DateTime, view: PlannerViewType) => void;
  /** Callback when the user changes the center to be viewed. */
  setCenter: (centerId: string) => void;
  /** Callback when the center hours are required that are not available in the state dictionary. */
  setCenterHoursRequest: (date: string) => void;
  /** Callback to set the currently selected group of service subcategories. */
  setSelectedSubCategories: (subCats: ServiceSubCategory[]) => void;
  /** Callback when a successful update to the calendar has been made, passing the updated entities. */
  updateCalendar: (blocks: Block[]) => void;
}
