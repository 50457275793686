import { Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import React from "react";

import { CbpError } from "../../models/CbpError";

interface Props {
  /** The name of the request triggering this message. Used as a fallback error message. */
  requestName: string;
  /** The axios error that triggered this message. Used to provide message details. */
  error: AxiosError<CbpError>;
}

/** The content of an error Snackbar triggered by a failed useCbp request. */
const RequestErrorSnackMessage: React.FC<Props> = ({ error, requestName }: Props) => {
  const secondLine = error.response?.data?.description
    ? `${error.response.statusText} - ${error.response.data.description}`
    : undefined;
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="body2">{error.response?.data.message || `${requestName} Failed`}</Typography>
      </Grid>
      {secondLine && (
        <Grid item>
          <Typography variant="caption">{secondLine}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default RequestErrorSnackMessage;
