import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CategoryIcon from "@mui/icons-material/Category";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FaceIcon from "@mui/icons-material/Face";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import SecurityIcon from "@mui/icons-material/Security";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import KeyIcon from "@mui/icons-material/VpnKey";
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Redirect, Route, Switch } from "react-router-dom";

import AppContext from "../../contexts/AppContext";
import { User } from "../../models/User";
import AuthorizedRoute from "../Helpers/AuthorizedRoute";
import CssDisplayFilter from "../Helpers/CssDisplayFilter";
import ApplicationPermissions from "./Tools/ApplicationPermissions";
import BusinessUnitConfig from "./Tools/BusinessUnitConfig";
import CenterGoals from "./Tools/CenterGoals";
import CenterPermissions from "./Tools/CenterPermissions";
import CenterWorkWeekConfig from "./Tools/CenterWorkWeekConfig";
import InspectUserPermissions from "./Tools/InspectUserPermissions";
import ReductionReasons from "./Tools/ReductionReasons";
import RoomCategoryConfig from "./Tools/RoomCategoryConfig";
import ServiceCategoryConfig from "./Tools/ServiceCategoryConfig";
import SurgeonConfig from "./Tools/SurgeonConfig";
import WeightCategoryConfig from "./Tools/WeightCategoryConfig";

export interface AdminTool {
  setIsContentLoading: (state: boolean) => void;
}

interface DrawerListGroup {
  title: string;
  items: {
    text: string;
    icon?: JSX.Element;
    route: string;
    disabled: boolean;
  }[];
}

const buildDrawerItems = (user: User): DrawerListGroup[] => [
  {
    title: "Authorization",
    items: [
      {
        text: "Application Permissions",
        icon: <SecurityIcon />,
        route: "/admin/ApplicationPermissions",
        disabled: !user.applicationPermissions.isApplicationEditor
      },
      {
        text: "Center Permissions",
        icon: <KeyIcon />,
        route: "/admin/CenterPermissions",
        disabled: !user.applicationPermissions.isApplicationEditor
      },
      {
        text: "Inspect User Permissions",
        icon: <SupervisorAccountIcon />,
        route: "/admin/InspectUserPermissions",
        disabled: !user.applicationPermissions.isApplicationEditor
      }
    ]
  },
  {
    title: "Center Settings",
    items: [
      {
        text: "Center TMC Goals",
        icon: <TrendingUpIcon />,
        route: "/admin/CenterTMCGoals",
        disabled: !user.applicationPermissions.isAdminEditor
      },
      {
        text: "Center Work Week",
        icon: <DateRangeIcon />,
        route: "/admin/CenterWorkWeek",
        disabled: !user.aggregateCenterPermissions.isCenterEditor
      }
    ]
  },
  {
    title: "Scheduling Settings",
    items: [
      {
        text: "Business Units",
        icon: <BusinessCenterIcon />,
        route: "/admin/BusinessUnits",
        disabled: !user.applicationPermissions.isAdminEditor
      },
      {
        text: "Service Categories",
        icon: <CategoryIcon />,
        route: "/admin/ServiceCategories",
        disabled: !user.applicationPermissions.isAdminEditor
      },
      {
        text: "Reduction Reasons",
        icon: <TrendingDownIcon />,
        route: "/admin/ReductionReasons",
        disabled: !user.applicationPermissions.isAdminEditor
      },
      {
        text: "Room Categories",
        icon: <MeetingRoomIcon />,
        route: "/admin/RoomCategories",
        disabled: !user.applicationPermissions.isAdminEditor
      }
    ]
  },
  {
    title: "Surgeon Settings",
    items: [
      {
        text: "Surgeons",
        icon: <FaceIcon />,
        route: "/admin/Surgeons",
        disabled:
          !user.aggregateCenterPermissions.isCenterEditor &&
          !user.aggregateCenterPermissions.isCapacityEditor &&
          !user.applicationPermissions.isAdminEditor
      },
      {
        text: "Weight Categories",
        icon: <FitnessCenterIcon />,
        route: "/admin/WeightCategories",
        disabled: !user.applicationPermissions.isAdminEditor
      }
    ]
  }
];

/** Renders the administration view and its multiple child tool views. */
const Administration: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useContext(AppContext);
  const [drawerItems] = useState(buildDrawerItems(user));
  const [isContentLoading, setIsContentLoading] = useState(false);

  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <Drawer variant="permanent" sx={{ width: 265 }}>
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {drawerItems.map((listGroup, key) => (
            <div key={key}>
              <List>
                <ListSubheader>{listGroup.title}</ListSubheader>
                {listGroup.items.map((item, key2) => (
                  <ListItem
                    button
                    selected={location.pathname === item.route}
                    key={`${key}${key2}`}
                    disabled={item.disabled}
                    onClick={() => history.push(item.route)}
                  >
                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
              {key !== drawerItems.length - 1 && <Divider />}
            </div>
          ))}
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 2, height: "100%" }}>
        <CssDisplayFilter style={{ flexGrow: 1, height: "100%" }} isHidden={isContentLoading}>
          <Switch>
            <AuthorizedRoute
              path="/admin/ApplicationPermissions"
              authorize={user => user.applicationPermissions.isApplicationEditor}
              redirectPath="/admin"
            >
              <ApplicationPermissions setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/BusinessUnits"
              authorize={user => user.applicationPermissions.isAdminEditor}
              redirectPath="/admin"
            >
              <BusinessUnitConfig setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/CenterTMCGoals"
              authorize={user => user.applicationPermissions.isAdminEditor}
              redirectPath="/admin"
            >
              <CenterGoals setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/CenterPermissions"
              authorize={user => user.applicationPermissions.isApplicationEditor}
              redirectPath="/admin"
            >
              <CenterPermissions setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/CenterWorkWeek"
              authorize={user => user.aggregateCenterPermissions.isCenterEditor}
              redirectPath="/admin"
            >
              <CenterWorkWeekConfig setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/InspectUserPermissions"
              authorize={user => user.applicationPermissions.isApplicationEditor}
              redirectPath="/admin"
            >
              <InspectUserPermissions setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/ReductionReasons"
              authorize={user => user.applicationPermissions.isAdminEditor}
              redirectPath="/admin"
            >
              <ReductionReasons setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/RoomCategories"
              authorize={user => user.applicationPermissions.isAdminEditor}
              redirectPath="/admin"
            >
              <RoomCategoryConfig setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/WeightCategories"
              authorize={user => user.applicationPermissions.isAdminEditor}
              redirectPath="/admin"
            >
              <WeightCategoryConfig setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/ServiceCategories"
              authorize={user => user.applicationPermissions.isAdminEditor}
              redirectPath="/admin"
            >
              <ServiceCategoryConfig setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <AuthorizedRoute
              path="/admin/Surgeons"
              authorize={user =>
                user.aggregateCenterPermissions.isCenterEditor ||
                user.aggregateCenterPermissions.isCapacityEditor ||
                user.applicationPermissions.isAdminEditor
              }
              redirectPath="/admin"
            >
              <SurgeonConfig setIsContentLoading={setIsContentLoading} />
            </AuthorizedRoute>
            <Route exact path="/admin">
              <Grid
                className="m-0 w-100 h-100"
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={3}
              >
                <Grid item>
                  <Typography variant="h5">Administrator Settings</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">Please select a tool from the list to get started.</Typography>
                </Grid>
              </Grid>
            </Route>
            <Route path="/">
              <Redirect to="/admin" />
            </Route>
          </Switch>
        </CssDisplayFilter>
        <CssDisplayFilter style={{ flexGrow: 1, height: "100%" }} isHidden={!isContentLoading}>
          <Grid
            className="h-100 w-100 m-0"
            direction="column"
            container
            item
            xs
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size="5rem" />
          </Grid>
        </CssDisplayFilter>
      </Box>
    </Box>
  );
};

export default Administration;
