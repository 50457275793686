import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { ChangeEvent, useEffect, useState } from "react";

import { ActiveDirectoryGroup } from "../../../models/ActiveDirectoryGroup";
import { ApplicationGroupPermissions } from "../../../models/Permissions";
import useCbp, { UseCbpProps } from "../../../utils/UseCbp";
import SelectList, { CustomListItem } from "../../Helpers/SelectList";
import { AdminTool } from "../Administration";
import AdministrationFormLabel from "../AdministrationFormLabel";
import AdministrationTool from "../AdministrationTool";

interface EnhancedActiveDirectoryGroup extends ActiveDirectoryGroup {
  label: string;
}

const toolReadme = [
  "The application Permissions tool allows you to modify the assigned permissions which control core application features. You control the permission assignments by assigning them to an active directory group. All members of the group will inherit the specified permissions.",
  "You can modify permissions by first selecting an active directory group, then by selecting the desired permission settings, and finally saving the changes.",
  "Note: Changes to permissions require the user to log out and in again to become effective."
];
const adGroupsLabelReadme = [
  "Select an active directory group to apply permissions to.",
  "If you identify any issues with the available options, please contact IT at ITHelp@sonobello.com."
];
const permissionsLabelReadme = [
  "Helpdesk/IT users who are responsible for managing access to center calendars of other users to centers should be granted Administrator access.",
  "Administrator access allows a user to authorize access for other users to centers within the Center Permissions tool, and inspect permissions of users in the Inspect User Permissions tool.",
  "Managers of the CBP application who are directly responsible for configuring CBP behaviors and options should be granted Manager access.",
  "Manager access allows a user access to the Business Units, Center TMC Goals, Reduction Reasons, Room Categories, Service Categories, and Weight Categories tools."
];

const permissionsComparator = (p1: ApplicationGroupPermissions, p2: ApplicationGroupPermissions) =>
  p1.activeDirectoryGroup.name > p2.activeDirectoryGroup.name ? 1 : -1;

const ApplicationPermissions: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [adGroups, setAdGroups] = useState<EnhancedActiveDirectoryGroup[]>([]);
  const [permissions, setPermissions] = useState<ApplicationGroupPermissions[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<EnhancedActiveDirectoryGroup>();
  const [selectedPermission, setSelectedPermission] = useState<ApplicationGroupPermissions>();

  // #region Get Active Directory Groups
  const [groupsRequest] = useState({ name: "Get AD Groups", request: { url: "authorization/groups" } });
  const { response: getGroupsResponse, isLoading: loadingGroupsRequest } =
    useCbp<ActiveDirectoryGroup[]>(groupsRequest);
  useEffect(() => {
    // differentiate between duplicate group names by appending the id where necessary
    const groupNames: Record<string, number> = {};
    setAdGroups(
      getGroupsResponse?.map(group => {
        const index = groupNames[group.name] || 0;
        const label = index ? `${group.name} (${index})` : group.name;
        groupNames[group.name] = index + 1;
        return { ...group, label };
      }) || []
    );
  }, [getGroupsResponse]);
  // #endregion

  // #region Get Permissions
  const [permissionsRequest] = useState({ name: "Get App Permissions", request: { url: "authorization/application" } });
  const { response: getPermissionsResponse, isLoading: loadingGetPermissions } =
    useCbp<ApplicationGroupPermissions[]>(permissionsRequest);
  useEffect(() => {
    if (getPermissionsResponse) setPermissions([...getPermissionsResponse].sort(permissionsComparator));
  }, [getPermissionsResponse]);
  // #endregion

  // #region Update Permissions
  const [updatePermissionsRequest, setUpdatePermissionsRequest] = useState<UseCbpProps<ApplicationGroupPermissions>>();
  const { response: updatePermissionsResponse, isLoading: loadingUpdatePermissions } = useCbp<
    ApplicationGroupPermissions,
    ApplicationGroupPermissions
  >(updatePermissionsRequest);
  useEffect(() => {
    if (updatePermissionsResponse) {
      enqueueSnackbar("Group Permissions Updated", { variant: "success" });
      setSelectedPermission(undefined);
      setSelectedGroup(undefined);
      setPermissions(permissions => {
        const index = permissions.findIndex(
          p => p.activeDirectoryGroup.id === updatePermissionsResponse.activeDirectoryGroup.id
        );
        if (index !== -1) permissions[index] = updatePermissionsResponse;
        else permissions.push(updatePermissionsResponse);
        return [...permissions].sort(permissionsComparator);
      });
    }
  }, [updatePermissionsResponse]);
  // #endregion

  //#region Actions
  const onSelectListChange = (newValue: ApplicationGroupPermissions | null) => {
    setSelectedPermission(newValue || undefined);
    setSelectedGroup(undefined);
  };
  const onPermissionsChange = (
    propName: keyof Omit<ApplicationGroupPermissions, "activeDirectoryGroup">,
    e: ChangeEvent<HTMLInputElement>
  ) => setSelectedPermission(permission => ({ ...permission!, [propName]: e.target.checked }));
  const onSaveChanges = () => {
    const oldPermission = permissions.find(
      p => p.activeDirectoryGroup.id === selectedPermission!.activeDirectoryGroup.id
    );
    if (
      // if no prior permission and no values have been set, deny
      (!oldPermission && !selectedPermission!.isAdminEditor && !selectedPermission!.isApplicationEditor) ||
      // if prior permissions exist but the values have not changed, deny
      (oldPermission &&
        oldPermission.isAdminEditor === selectedPermission!.isAdminEditor &&
        oldPermission.isApplicationEditor === selectedPermission!.isApplicationEditor)
    )
      enqueueSnackbar("No Changes Detected", { variant: "warning" });
    else
      setUpdatePermissionsRequest({
        name: "Update App Permissions",
        request: { url: "authorization/application", method: "put", data: selectedPermission }
      });
  };
  const onSelectNewGroup = (_: unknown, adGroup: EnhancedActiveDirectoryGroup | null) => {
    if (adGroup) {
      setSelectedGroup(adGroup);
      setSelectedPermission(
        permissions.find(p => p.activeDirectoryGroup.id === adGroup.id) || {
          activeDirectoryGroup: adGroup,
          isAdminEditor: false,
          isApplicationEditor: false
        }
      );
    } else {
      setSelectedGroup(undefined);
      setSelectedPermission(undefined);
    }
  };
  //#endregion

  // show spinner while loading
  useEffect(
    () => setIsContentLoading(loadingGetPermissions || loadingGroupsRequest),
    [loadingGetPermissions, loadingGroupsRequest]
  );

  return (
    <AdministrationTool title="Application Permissions" readme={toolReadme} sx={{ minWidth: "50rem" }}>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid container item xs spacing={2}>
          <Grid item xs>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <AdministrationFormLabel label="Active Directory Groups" readme={adGroupsLabelReadme} />
                <SelectList
                  items={permissions.filter(permission => permission.isAdminEditor || permission.isApplicationEditor)}
                  itemKey={(item: ApplicationGroupPermissions, value: ApplicationGroupPermissions) =>
                    item.activeDirectoryGroup.id === value.activeDirectoryGroup.id
                  }
                  value={selectedPermission || null}
                  disabled={loadingUpdatePermissions}
                  onChange={onSelectListChange}
                  listItem={ADGroupItem}
                />
              </Grid>
              <Grid item container spacing={1} alignItems="center">
                <Grid item xs>
                  <Autocomplete
                    fullWidth
                    options={adGroups.filter(({ id }) => !permissions.some(p => p.activeDirectoryGroup.id === id))}
                    disabled={loadingGroupsRequest}
                    renderInput={params => <TextField {...params} label="Add New Group" />}
                    getOptionLabel={adGroup => adGroup.label}
                    value={selectedGroup || null}
                    onChange={onSelectNewGroup}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <AdministrationFormLabel
                  label={`Permissions ${
                    selectedPermission?.activeDirectoryGroup.name
                      ? ` for ${selectedPermission?.activeDirectoryGroup.name}`
                      : ""
                  }`}
                  readme={permissionsLabelReadme}
                />
              </Grid>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch />}
                    disabled={!selectedPermission}
                    checked={selectedPermission?.isApplicationEditor || false}
                    onChange={e => onPermissionsChange("isApplicationEditor", e as ChangeEvent<HTMLInputElement>)}
                    label="Grant Administrator Access"
                  />
                  <FormControlLabel
                    control={<Switch />}
                    disabled={!selectedPermission}
                    checked={selectedPermission?.isAdminEditor || false}
                    onChange={e => onPermissionsChange("isAdminEditor", e as ChangeEvent<HTMLInputElement>)}
                    label="Grant Manager Access"
                  />
                </FormGroup>
              </Grid>
              <Grid item container justifyContent="center">
                {loadingUpdatePermissions ? (
                  <CircularProgress />
                ) : (
                  <Button
                    disabled={
                      !selectedPermission ||
                      JSON.stringify(
                        permissions.find(p => p.activeDirectoryGroup.id === selectedPermission.activeDirectoryGroup.id)
                      ) === JSON.stringify(selectedPermission)
                    }
                    onClick={onSaveChanges}
                  >
                    Save Changes
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdministrationTool>
  );
};

const ADGroupItem: React.FC<CustomListItem<ApplicationGroupPermissions>> = ({
  data: permission,
  selected,
  ...rest
}: CustomListItem<ApplicationGroupPermissions>) => {
  return (
    <ListItem {...(rest as Record<string, unknown>)}>
      <ListItemIcon>
        <Checkbox edge="start" checked={selected} tabIndex={-1} />
      </ListItemIcon>
      <ListItemText primary={permission.activeDirectoryGroup.name} />
      {permission.isApplicationEditor && (
        <ListItemIcon>
          <Chip size="small" label="Administrator" />
        </ListItemIcon>
      )}
      {permission.isAdminEditor && (
        <ListItemIcon>
          <Chip size="small" label="Manager" />
        </ListItemIcon>
      )}
    </ListItem>
  );
};

export default ApplicationPermissions;
