import axios, { AxiosError, AxiosInstance } from "axios";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useRef, useState } from "react";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import RequestErrorSnackMessage from "../components/Helpers/RequestErrorSnackMessage";
import AppContext from "../contexts/AppContext";
import { CbpError } from "../models/CbpError";
import Token from "../types/Token";
import useApi, { Exceptions, UseApiProps, UseApiResponse } from "./UseApi";

const axiosInstance: AxiosInstance = axios.create({ baseURL: `${process.env.REACT_APP_CBP_URL}/api` });

export interface UseCbpProps<TData = undefined> extends Omit<UseApiProps<TData>, "token" | "axiosInstance"> {
  name: string;
  token?: Token;
}

/** Custom hook which requests information from the CBP Api in a manner prescribed by the provided config. */
const useCbp = <
  /** The type expected by the http request response. */
  TResponse,
  /** The type of the payload data provided if any. */
  TData = undefined,
  /** The type of the possible request error payload. */
  TError extends CbpError = CbpError
>(
  props?: UseCbpProps<TData>
): UseApiResponse<TError, TResponse> => {
  const { enqueueSnackbar } = useSnackbar();
  const { token, setToken } = useContext(AppContext);
  const [queryConfig, setQueryConfig] = useState<UseApiProps<TData>>();
  const responseRef = useRef<TResponse>();
  const errorRef = useRef<{ id: string; error: AxiosError<TError> }>();
  const tokenRef = useRef(token);

  // handle updating the useApi query configuration
  useEffect(() => {
    let executeQuery = false;
    // if the config and token are both defined, consider executing a query
    if (props && token) {
      // If the token exists and has an expiresOn, check if it is expiring in the next 5 minutes
      // if the token will expire, unset it to get a new one.
      if (token.expiresOn.plus({ minutes: -5 }) < DateTime.now()) {
        setToken(undefined);
        return;
      }
      // if token has been updated
      if (!tokenRef.current && token) {
        if (
          // execute if the previous request failed due to a bad token, or
          errorRef.current?.error?.response?.status === 401 ||
          // execute if the request has not fired yet
          (!errorRef.current && !responseRef.current)
        )
          executeQuery = true;
      }
      // otherwise token is unchanged, execute
      else executeQuery = true;
    }
    if (executeQuery) setQueryConfig({ tokenSecret: token?.secret, axiosInstance, ...props! });
    tokenRef.current = token;
  }, [props, token]);

  // handle the useApi hook states
  try {
    const hook = useApi<TError, TResponse, TData>(queryConfig);
    responseRef.current = hook.response;
    // if error changed
    if (hook.error?.root !== errorRef.current?.error) {
      // if an error occurred
      if (hook.error) {
        errorRef.current = { id: uuidv4(), error: hook.error.root };
        if (hook.error.root?.response?.status === 401) setToken(undefined);
        else {
          enqueueSnackbar(
            React.createElement(RequestErrorSnackMessage, {
              error: hook.error.root as AxiosError<TError>,
              requestName: props!.name
            }),
            { variant: "error", autoHideDuration: 8000 }
          );
        }
      }
      // else if a subsequent request was successful
      else if (errorRef.current) {
        enqueueSnackbar(`${props!.name} Resolved`, { variant: "success" });
        errorRef.current = undefined;
      }
    }
    return hook;
  } catch (e) {
    switch (e) {
      case Exceptions.MissingToken:
        // setLogOutProtocol && setLogOutProtocol("expired");
        break;
      case Exceptions.NetworkError:
        // const behavior = ErrorBehaviors[Error.UncaughtNetwork]["default"];
        // addRequestError && addRequestError(behavior);
        break;
      case Exceptions.UncaughtUnauthorizedResponse:
        // swallow if a token request, otherwise throw.
        // if (!url.includes("token")) throw e;
        break;
      case Exceptions.AuthorizationExpired:
        // const nowUtc = moment.utc();
        // IF token expired log out
        // if (moment.utc(token.refreshExpires).isBefore(nowUtc)) setLogOutProtocol && setLogOutProtocol("expired");
        // // ELSE renew it IF NOT disabled
        // else if (renewsToken) setShouldRenewToken(true);
        break;
      default:
        throw e;
    }
    // TODO: Improve caught response
    return { response: undefined, error: undefined, isLoading: false };
  }
};

export default useCbp;
