import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateRangeIcon from "@mui/icons-material/DateRange";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { PropsWithChildren, ReactFragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { SimpleCenter } from "../../models/Center";
import { ServiceSubCategory } from "../../models/ServiceSubCategory";
import { PlannerViewType } from "../../types/PlannerViewProps";
import LoadingShade from "../Helpers/LoadingShade";

interface Props {
  /** The currently selected date. */
  date: DateTime;
  /** Flag indicating if all navigation should be disabled. */
  disabled?: boolean;
  /** Flag indicating if the user has a lock and edits are currently allowed. */
  editDisabled: boolean;
  /** Flag indicating if any calendar is currently loaded in the planner. */
  isCalendarLoaded: boolean;
  /** Flag indicating when there is a pending GET calendar query. */
  isLoading: boolean;
  /** Flag indicating if a schedule change made by another user is loading. */
  isLoadingUpdate: boolean;
  /** The currently selected filter subCategories. */
  selectedSubCategories: ServiceSubCategory[];
  /** The list of service sub-category options. */
  serviceSubCategories: ServiceSubCategory[];
  /** The React element of the current view's settings menu. */
  settingsMenu?: React.ReactNode;
  /** The currently selected center. */
  simpleCenter: SimpleCenter;
  /** The list of center selection options. */
  simpleCenters: SimpleCenter[];
  /** The message to be rendered when the schedule has been successfully updated by another user. */
  updatedMessage: ReactFragment | string;
  /** The current calendar view type. */
  view: PlannerViewType;
  /** Callback when the user changes the currently selected center. */
  setCenter: (centerId: string) => void;
  /** Callback when the user changes the currently selected date. */
  setDate: (date: DateTime) => void;
  /** Callback when the user changes the current service subcategory filter. */
  setSelectedSubCategories: (subcats: ServiceSubCategory[]) => void;
}

const PlannerNavigation: React.FC<PropsWithChildren<Props>> = ({
  date,
  disabled,
  editDisabled,
  isCalendarLoaded,
  isLoading,
  isLoadingUpdate,
  selectedSubCategories,
  serviceSubCategories,
  settingsMenu,
  simpleCenter,
  simpleCenters,
  updatedMessage,
  view,
  setCenter,
  setDate,
  setSelectedSubCategories,
  children
}: PropsWithChildren<Props>) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [settingsAnchor, setSettingsAnchor] = useState<HTMLButtonElement>();
  const loadingUpdateRef = useRef(isLoadingUpdate);

  const [localDate, setLocalDate] = useState<DateTime>(date);
  const [localInvalidDateExplanation, setLocalInvalidDateExplanation] = useState<string>();
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  // When an update has been completed show a notification.
  useEffect(() => {
    if (!isLoadingUpdate && loadingUpdateRef.current) enqueueSnackbar("Calendar Updated", { variant: "info" });
    loadingUpdateRef.current = isLoadingUpdate;
  }, [isLoadingUpdate]);

  const selectedSubcatNames = selectedSubCategories.map(subCat => subCat.name);
  const selectedSubcatLabel = `Subcategories${
    selectedSubcatNames.length
      ? ` (Count: ${selectedSubcatNames.length}, Weight: ${selectedSubCategories.reduce(
          (sum, subCat) => sum + (subCat.weightValue || 0),
          0
        )})`
      : ""
  }`;

  const handleDatePickerChange = (value: DateTime | null) => {
    if (!value) return;

    setLocalDate(value);

    // If Luxon believes the DateTime is invalid, set the Tooltip to display the reason and show.
    if (!value.isValid) {
      setLocalInvalidDateExplanation(value.invalidExplanation || "Invalid Date");
      setTooltipOpen(true);
    } else {
      // Otherwise, update the date and clear the errors.
      setDate(value);
      setLocalInvalidDateExplanation(undefined);
      setTooltipOpen(false);
    }
  };

  const handleDatePickerAccept = (value: DateTime | null) => {
    if (!value) return;

    setDate(value);
    // In case they went from typing something invalid to accepting from the picker.
    setLocalInvalidDateExplanation(undefined);
  };

  return (
    <Grid container direction="column" sx={{ height: "100%" }} wrap="nowrap">
      <Grid item>
        <AppBar position="relative" color="default">
          <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Grid item>
              <Autocomplete
                disabled={disabled}
                options={simpleCenters}
                renderInput={params => <TextField {...params} label="Center" />}
                getOptionLabel={center => center.name}
                value={simpleCenter}
                onChange={(_: unknown, simpleCenter: SimpleCenter | null) => simpleCenter && setCenter(simpleCenter.id)}
                sx={{ minWidth: "20rem" }}
                clearIcon={null}
                clearOnEscape={false}
                isOptionEqualToValue={(o, v) => o.id === v.id}
              />
            </Grid>
            <Grid item>
              <Grid item container spacing={2} xs alignItems="center" wrap="nowrap">
                <Grid item>
                  <Typography variant="h6">{`${view === PlannerViewType.DAY ? "Date" : "Month"}:`}</Typography>
                </Grid>
                <Tooltip
                  title={
                    localInvalidDateExplanation ??
                    (view === PlannerViewType.DAY
                      ? `You may type a full date such as '${DateTime.now().toFormat("D")}'.`
                      : `You may type a full month and year such as '${DateTime.now().toFormat("LLLL yyyy")}'.`)
                  }
                  open={tooltipOpen}
                  placement="top"
                  onMouseEnter={() => (localInvalidDateExplanation ? undefined : setTooltipOpen(true))}
                  onMouseLeave={() => (localInvalidDateExplanation ? undefined : setTooltipOpen(false))}
                >
                  <Grid item>
                    <DatePicker
                      views={view === PlannerViewType.DAY ? ["year", "month", "day"] : ["year", "month"]}
                      minDate={DateTime.fromISO(`${process.env.REACT_APP_MINIMUM_DATE}`)}
                      value={localDate}
                      disabled={disabled}
                      onChange={handleDatePickerChange}
                      onAccept={handleDatePickerAccept}
                      openTo={view === PlannerViewType.DAY ? "day" : "month"}
                      renderInput={params => <TextField {...params} />}
                    />
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ minWidth: "19rem", maxWidth: "20rem" }}>
                <InputLabel>{selectedSubcatLabel}</InputLabel>
                <Select
                  multiple
                  value={selectedSubcatNames}
                  onChange={e =>
                    setSelectedSubCategories(
                      (e.target.value as string[])
                        .sort()
                        .map(value => serviceSubCategories.find(sc => sc.name === value)!)
                    )
                  }
                  input={<Input />}
                  renderValue={selected =>
                    (selected as string[]).reduce((collector, name) => (collector ? `${collector}, ${name}` : name), "")
                  }
                  MenuProps={{ PaperProps: { style: { maxHeight: "40rem" } } }}
                >
                  {serviceSubCategories.map(subCat => (
                    <MenuItem key={subCat.id} value={subCat.name}>
                      <Checkbox
                        checked={selectedSubCategories.some(subCatSelected => subCatSelected.id === subCat.id)}
                      />
                      <ListItemText primary={subCat.name} secondary={`Weight: ${subCat.weightValue}`} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Grid item container spacing={2} xs alignItems="center" wrap="nowrap">
                <Grid item>
                  <Typography variant="h6">View:</Typography>
                </Grid>
                <Grid item>
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={(_, value) =>
                      value && history.push(`/planner/${value}?center=${simpleCenter.id}&date=${date.toISODate()}`)
                    }
                  >
                    <ToggleButton value={PlannerViewType.DAY}>
                      <Tooltip title="Day View">
                        <ScheduleIcon />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={PlannerViewType.MONTH}>
                      <Tooltip title="Month View">
                        <CalendarTodayIcon />
                      </Tooltip>
                    </ToggleButton>
                    {!editDisabled && (
                      <ToggleButton value={PlannerViewType.BATCH_EDIT}>
                        <Tooltip title="Batch Edit">
                          <DateRangeIcon />
                        </Tooltip>
                      </ToggleButton>
                    )}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              {isLoadingUpdate || isLoading ? (
                <CircularProgress size="1.5rem" />
              ) : (
                <Tooltip title={updatedMessage}>
                  <InfoOutlinedIcon />
                </Tooltip>
              )}
              {settingsMenu && (
                <Tooltip title="View Settings">
                  <IconButton onClick={e => setSettingsAnchor(e.currentTarget)}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid item xs sx={{ p: 2, width: "100%" }}>
        <Paper elevation={3} sx={{ px: 2, width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
          <LoadingShade show={isLoading && isCalendarLoaded} />
          {children}
        </Paper>
      </Grid>
      <Popover
        open={Boolean(settingsAnchor)}
        anchorEl={settingsAnchor}
        onClose={() => setSettingsAnchor(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
      >
        {settingsMenu}
      </Popover>
    </Grid>
  );
};

export default PlannerNavigation;
