import { Badge, BadgeProps, Chip, Grid, Tooltip, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";

import { BlockType } from "../../../models/Block";
import DateTime from "../../../types/DateTime";
import { StateColors } from "./MonthlyView";
import { MonthDayItem } from "./MonthlyViewDayCard";

class CalendarSummaryInfo {
  futureOpenSlots: number;
  futureSurgeries: number;
  futureTotalSlots: number;
  monthToTodayOpenSlots: number;
  monthToTodaySlots: number;
  problems: number;
  totalOpenSlots: number;
  totalSlots: number;
  totalSurgeries: number;

  constructor() {
    this.futureOpenSlots = 0;
    this.futureSurgeries = 0;
    this.futureTotalSlots = 0;
    this.monthToTodayOpenSlots = 0;
    this.monthToTodaySlots = 0;
    this.problems = 0;
    this.totalOpenSlots = 0;
    this.totalSlots = 0;
    this.totalSurgeries = 0;
  }
}

interface Props {
  calendar: Record<string, MonthDayItem>;
  today: DateTime;
  goal?: number;
}

const MonthlyViewSummary: React.FC<Props> = ({ calendar, today, goal }: Props) => {
  const [summaryInfo, setSummaryInfo] = useState(new CalendarSummaryInfo());

  // aggregate calendar summary info
  useEffect(() => {
    const totals = new CalendarSummaryInfo();
    Object.entries(calendar).forEach(([key, monthDayItem]) => {
      const date = DateTime.fromISO(key);
      // for each day total the number of problems, surgeries, total slots, and open slots
      const dayTotals = monthDayItem.roomDays.reduce(
        (totals, roomDay) => {
          if (!roomDay.surgeon) return totals;
          const isSurgeonTerminated =
            roomDay.surgeon?.terminationDateTime && roomDay.surgeon.terminationDateTime <= date;
          const slotCount = roomDay.blocks.filter(p => p.blockType === BlockType.Slot).length;
          // increment problems if surgeon is terminated or if overflowing slots
          if (isSurgeonTerminated || roomDay.appointments.filter(p => p.isSurgery).length > slotCount)
            totals.problems++;
          const surgeryCount = roomDay.appointments.filter(p => p.isSurgery).length;
          const slotsMinusAppointments = slotCount - surgeryCount;
          if (slotsMinusAppointments > 0) totals.totalOpenSlots += slotsMinusAppointments;
          totals.surgeries += surgeryCount;
          totals.totalSlots += slotCount;
          return totals;
        },
        { problems: 0, surgeries: 0, totalOpenSlots: 0, totalSlots: 0 }
      );

      // increment month totals from day totals
      if (date <= today) {
        totals.monthToTodayOpenSlots += dayTotals.totalOpenSlots;
        totals.monthToTodaySlots += dayTotals.totalSlots;
      } else {
        totals.futureOpenSlots += dayTotals.totalOpenSlots;
        totals.futureTotalSlots += dayTotals.totalSlots;
        totals.futureSurgeries += dayTotals.surgeries;
      }
      totals.problems += dayTotals.problems;
      totals.totalOpenSlots += dayTotals.totalOpenSlots;
      totals.totalSlots += dayTotals.totalSlots;
      totals.totalSurgeries += dayTotals.surgeries;
    });
    setSummaryInfo(totals);
  }, [calendar]);

  const startOfCalendarDate = DateTime.fromISO(Object.keys(calendar)[0]).startOf("day");

  return (
    <Grid container columnSpacing={3}>
      {Boolean(summaryInfo.problems) && (
        <Grid item>
          <Tooltip
            title={
              <>
                <div>One or more room assignments have problems and should be fixed.</div>
                <div>
                  The surgeon has more surgeries than slots, or the surgeon was terminated prior to their assigned day.
                </div>
              </>
            }
            placement="top"
            arrow
          >
            <Chip
              color={StateColors.overflowing}
              label={`${summaryInfo.problems} Problem${summaryInfo.problems === 1 ? "" : "s"}`}
            />
          </Tooltip>
        </Grid>
      )}
      {startOfCalendarDate <= today && (
        <Grid item>
          <Tooltip
            title={`${summaryInfo.monthToTodayOpenSlots} past slots in this month were not filled by Surgeries.`}
            placement="top"
            arrow
          >
            <StyledBadge
              badgeContent="Missed"
              color={StateColors.missed}
              anchorOrigin={{ horizontal: "left", vertical: "top" }}
              overlap="circular"
            >
              <Chip
                label={`${summaryInfo.monthToTodayOpenSlots} Slot${summaryInfo.monthToTodaySlots === 1 ? "" : "s"}`}
              />
            </StyledBadge>
          </Tooltip>
        </Grid>
      )}
      {startOfCalendarDate.endOf("month") >= today && (
        <Grid item>
          <Tooltip title={`${summaryInfo.futureOpenSlots} upcoming slots remain unfilled.`} placement="top" arrow>
            <StyledBadge
              badgeContent="Remaining"
              color={summaryInfo.futureOpenSlots ? "success" : "default"}
              anchorOrigin={{ horizontal: "left", vertical: "top" }}
              overlap="circular"
            >
              <Chip label={`${summaryInfo.futureOpenSlots} Slot${summaryInfo.futureOpenSlots === 1 ? "" : "s"}`} />
            </StyledBadge>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <Tooltip title={`${summaryInfo.totalSlots} total slots were planned this month.`} placement="top" arrow>
          <StyledBadge
            badgeContent="Total"
            color={StateColors.filled}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            overlap="circular"
          >
            <Chip label={`${summaryInfo.totalSlots} Slot${summaryInfo.totalSlots === 1 ? "" : "s"}`} />
          </StyledBadge>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={`${summaryInfo.totalSurgeries} of ${summaryInfo.totalSlots} are planned this month.`}
          placement="top"
          arrow
        >
          <StyledBadge
            badgeContent="Filled"
            color={summaryInfo.totalSurgeries > summaryInfo.totalSlots ? StateColors.overflowing : StateColors.filled}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            overlap="circular"
          >
            <Chip label={`${summaryInfo.totalSurgeries} Slot${summaryInfo.totalSurgeries === 1 ? "" : "s"}`} />
          </StyledBadge>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={`Transformation Goal for ${startOfCalendarDate.monthLong}`} placement="top" arrow>
          <Chip label={`TMC Goal: ${goal ?? "Not Set"}`} />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const StyledBadge = styled(Badge)<BadgeProps>(({ color }) => ({
  "& .MuiBadge-badge": { top: -4, backgroundColor: color === "default" ? grey[200] : undefined }
}));

export default MonthlyViewSummary;
