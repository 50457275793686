import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Divider, styled } from "@mui/material";
import React, { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";
import { useDrag } from "react-dnd";

import { DragHandle, ScheduleItem, SchedulerClassKey, SchedulerItemProps } from "./types";

// build the css distance expression for a value with a given length of time
const cssDistance = (minutes: number, timeIncrementMinutes: number, timeIncrementWidth: number) =>
  `${(minutes / timeIncrementMinutes) * timeIncrementWidth}px`;

const UnstyledSchedulerItem = <T extends ScheduleItem>({
  gapMinutes,
  isResizingDisabled,
  isMovingDisabled,
  item: scheduleItem,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemIndex,
  orientation,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rowIndex,
  timeIncrement,
  timeIncrementWidth,
  zIndex,
  children,
  className,
  style,
  ...rest
}: PropsWithChildren<SchedulerItemProps<T> & HTMLAttributes<HTMLSpanElement>>): JSX.Element => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: "item",
    item: scheduleItem,
    collect: monitor => ({ isDragging: monitor.isDragging() })
  });
  const [{ isDraggingLeft }, dragLeftHandle] = useDrag({
    type: DragHandle.LEFT,
    item: scheduleItem,
    collect: monitor => ({ isDraggingLeft: monitor.isDragging() })
  });
  const [{ isDraggingRight }, dragRightHandle] = useDrag({
    type: DragHandle.RIGHT,
    item: scheduleItem,
    collect: monitor => ({ isDraggingRight: monitor.isDragging() })
  });
  const positionOffset = gapMinutes ? cssDistance(gapMinutes, timeIncrement, timeIncrementWidth) : "unset";
  const size = cssDistance(scheduleItem.timeSpanMinutes, timeIncrement, timeIncrementWidth);
  const zSize = zIndex ? `calc(100% - ${zIndex * 16}px)` : undefined;
  const zOffset = zIndex ? `${zIndex * 8}px` : undefined;
  const sharedProps: CSSProperties = {
    display: "flex",
    visibility: isDragging || isDraggingRight || isDraggingLeft ? "hidden" : undefined,
    zIndex
  };
  const orientationProps: {
    wrapperStyle: CSSProperties;
    dragHandleClasses: string;
    dividerOrientation: "vertical" | "horizontal";
    dragIconStyle?: CSSProperties;
  } =
    orientation === "horizontal"
      ? {
          wrapperStyle: {
            ...style,
            ...sharedProps,
            alignItems: "center",
            flexShrink: 0,
            height: zSize,
            left: positionOffset,
            marginTop: zOffset,
            width: size,
            zIndex
          },
          dragHandleClasses: `${SchedulerClassKey.dragHandle} ${SchedulerClassKey.dragHandle_h}`,
          dividerOrientation: "vertical"
        }
      : {
          wrapperStyle: {
            ...style,
            ...sharedProps,
            flexDirection: "column",
            height: size,
            marginLeft: zOffset,
            top: positionOffset,
            width: zSize
          },
          dragHandleClasses: `${SchedulerClassKey.dragHandle} ${SchedulerClassKey.dragHandle_v}`,
          dividerOrientation: "horizontal",
          dragIconStyle: { transform: "rotateZ(90deg)" }
        };
  return (
    <div
      className={`${SchedulerClassKey.itemRoot} ${className}`}
      ref={preview}
      style={orientationProps.wrapperStyle}
      {...rest}
    >
      {scheduleItem.canResize && !isResizingDisabled && (
        <>
          <div className={orientationProps.dragHandleClasses} ref={dragLeftHandle}>
            <DragIndicatorIcon style={orientationProps.dragIconStyle} />
          </div>
          <Divider orientation={orientationProps.dividerOrientation} light />
        </>
      )}
      <div className="h-100 w-100" ref={scheduleItem.canDrop && !isMovingDisabled ? drag : null}>
        {children}
      </div>
      {scheduleItem.canResize && !isResizingDisabled && (
        <>
          <Divider orientation={orientationProps.dividerOrientation} light />
          <div className={orientationProps.dragHandleClasses} ref={dragRightHandle}>
            <DragIndicatorIcon style={orientationProps.dragIconStyle} />
          </div>
        </>
      )}
    </div>
  );
};

const SchedulerItem = styled(UnstyledSchedulerItem)<
  PropsWithChildren<SchedulerItemProps<ScheduleItem> & HTMLAttributes<HTMLSpanElement>>
>({
  [`& .${SchedulerClassKey.dragHandle}`]: { display: "flex", alignItems: "center", justifyContent: "center" },
  [`& .${SchedulerClassKey.dragHandle_h}`]: { height: "100%" },
  [`& .${SchedulerClassKey.dragHandle_v}`]: { width: "100%" }
});

export default SchedulerItem;
