import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { Interval } from "luxon";
import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";

import { BlockType } from "../../../models/Block";
import { ReductionReason } from "../../../models/ReductionReason";
import DateTime from "../../../types/DateTime";
import { DayViewFormProps } from "../../../types/DayViewFormProps";
import { DayViewSchedulerItem } from "../../../types/DayViewSchedulerTypes";
import { DragHandle } from "../../Helpers/Scheduler/types";
import TabPanel from "../../Helpers/TabPanel";
import TimeRangePicker from "../../Helpers/TimeRangePicker";

interface Props extends DayViewFormProps {
  /** Flag indicating if the delete dialog should be disabled. */
  disabled: boolean;
  /** The schedule object linked to this form. */
  item: DayViewSchedulerItem;
  /** The reduction reason options that this item can be deleted. */
  reductionReasons: ReductionReason[];
}

const DayViewSchedulerItemDetailForm: React.FC<Props> = ({
  disabled,
  item,
  schedule,
  reductionReasons,
  setSchedule,
  onClose
}: Props) => {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [movedItem, setMovedItem] = useState<Omit<DayViewSchedulerItem, "onClick">>();
  const [moveDialogStartTime, setMoveDialogStartTime] = useState(item.startDateTime);
  const [moveDialogEndTime, setMoveDialogEndTime] = useState(item.endDateTime);
  const [moveDialogStartTimeError, setMoveDialogStartTimeError] = useState<string>();
  const [moveDialogEndTimeError, setMoveDialogEndTimeError] = useState<string>();
  const [reductionReason, setReductionReason] = useState<ReductionReason>();

  // Reset DateTime states if they cancel the Move dialog.
  useEffect(() => {
    if (!showMoveDialog) {
      setMoveDialogStartTime(item.startDateTime);
      setMoveDialogEndTime(item.endDateTime);
      setMoveDialogStartTimeError(undefined);
      setMoveDialogEndTimeError(undefined);
    }
  }, [showMoveDialog]);

  // #region Actions
  // Handles changes and validation to the TimeRangePicker.
  const onChangeTimeRangePicker = (start: DateTime, end: DateTime) => {
    // Set the states to update the display.
    setMoveDialogStartTime(start);
    setMoveDialogEndTime(end);

    // Times must be on the 15s.
    const startMinuteInvalid = start.minute !== 0 && start.minute !== 15 && start.minute !== 30 && start.minute !== 45;
    const endMinuteInvalid = end.minute !== 0 && end.minute !== 15 && end.minute !== 30 && end.minute !== 45;
    if (startMinuteInvalid) setMoveDialogStartTimeError("Minute must on a quarter hour.");

    if (endMinuteInvalid) setMoveDialogEndTimeError("Minute must on a quarter hour.");

    // If either of these error conditions exist return early.
    if (startMinuteInvalid || endMinuteInvalid) {
      setMovedItem(undefined);
      return;
    }

    // Since we receive a whole datetime but might be working in the past, create appropriate times.
    const newStart = DateTime.fromObject(
      {
        year: item.startDateTime.year,
        month: item.startDateTime.month,
        day: item.startDateTime.day,
        hour: start.hour,
        minute: start.minute
      },
      { zone: item.startDateTime.zone }
    );
    const newEnd = DateTime.fromObject(
      {
        year: item.endDateTime.year,
        month: item.endDateTime.month,
        day: item.endDateTime.day,
        hour: end.hour,
        minute: end.minute
      },
      { zone: item.endDateTime.zone }
    );

    // Validate that start is before end.
    if (newStart > newEnd) {
      setMoveDialogStartTimeError("Start must be before End.");
      setMovedItem(undefined);
      // Return early if it is not.
      return;
    }

    const newStartBeforeBoundStart = newStart < schedule.bounds.start;

    // Validate that the item is not before the Center start time.
    if (newStartBeforeBoundStart)
      setMoveDialogStartTimeError(`Start must be after ${schedule.bounds.start.toLocaleString(DateTime.TIME_SIMPLE)}.`);

    const newEndAfterBoundEnd = newEnd > schedule.bounds.end;

    // Validate that the item is not after the Center end time.
    if (newEndAfterBoundEnd)
      setMoveDialogEndTimeError(`End must be before ${schedule.bounds.end.toLocaleString(DateTime.TIME_SIMPLE)}.`);

    if (newStartBeforeBoundStart || newEndAfterBoundEnd) {
      setMovedItem(undefined);
      // Return early if it is not.
      return;
    }

    // Approximate what the new item will look like cor usage in canDrop.
    const newItem: Omit<DayViewSchedulerItem, "onClick"> = {
      ...item,
      startDateTime: newStart,
      endDateTime: newEnd,
      timeSpanMinutes: Interval.fromDateTimes(newStart, newEnd).length("minutes")
    };

    const rowIndex = schedule.rows.findIndex(r => r.room.id === item.block!.roomId);
    const cannotChange =
      item.block &&
      // Left Drag Handle
      ((item.startDateTime !== newItem.startDateTime &&
        item.endDateTime === newItem.endDateTime &&
        item.canResize &&
        !item.canResize(schedule.rows[rowIndex], newItem, DragHandle.LEFT, newStart)) ||
        // Right Drag Handle
        (item.startDateTime === newItem.startDateTime &&
          item.endDateTime !== newItem.endDateTime &&
          item.canResize &&
          !item.canResize(schedule.rows[rowIndex], newItem, DragHandle.RIGHT, newStart)) ||
        // Moving the whole thing.
        (item.canDrop && !item.canDrop(schedule.rows[rowIndex], newItem, newStart)));

    // If it can't be dropped one of the times is invalid.
    if (cannotChange) {
      setMoveDialogStartTimeError("Time is occupied.");
      setMoveDialogEndTimeError("Time is occupied.");
      setMovedItem(undefined);
      // Return early.
      return;
    }

    // If everything is OK then unset the errors.
    if (
      !startMinuteInvalid &&
      !endMinuteInvalid &&
      !newEndAfterBoundEnd &&
      !newStartBeforeBoundStart &&
      !cannotChange
    ) {
      setMoveDialogStartTimeError(undefined);
      setMoveDialogEndTimeError(undefined);
      setMovedItem(newItem);
    }
  };

  const remove = () => {
    setSchedule(oldSchedule => {
      const rowIndex = oldSchedule.rows.findIndex(r => r.room.id === item.block!.roomId);
      const itemIndex = oldSchedule.rows[rowIndex].items.findIndex(i => i.id === item.id);
      if (oldSchedule.rows[rowIndex].items[itemIndex].block!.id)
        oldSchedule.rows[rowIndex].items[itemIndex] = {
          ...oldSchedule.rows[rowIndex].items[itemIndex],
          block: { ...oldSchedule.rows[rowIndex].items[itemIndex].block!, reductionReasonId: reductionReason!.id }
        };
      else oldSchedule.rows[rowIndex].items.splice(itemIndex, 1);
      oldSchedule.rows[rowIndex] = { ...oldSchedule.rows[rowIndex], items: [...oldSchedule.rows[rowIndex].items] };
      return { ...oldSchedule, rows: [...oldSchedule.rows] };
    });
    onClose();
  };

  const move = () => {
    setSchedule(oldSchedule => {
      const rowIndex = oldSchedule.rows.findIndex(r => r.room.id === item.block!.roomId);
      const itemIndex = oldSchedule.rows[rowIndex].items.findIndex(i => i.id === item.id);
      oldSchedule.rows[rowIndex].items[itemIndex] = {
        ...oldSchedule.rows[rowIndex].items[itemIndex],
        block: {
          ...oldSchedule.rows[rowIndex].items[itemIndex].block!,
          startDateTime: movedItem!.startDateTime,
          endDateTime: movedItem!.endDateTime,
          startTimeUtc: movedItem!.startDateTime.toUTC().toISO(),
          endTimeUtc: movedItem!.endDateTime.toUTC().toISO()
        },
        startDateTime: movedItem!.startDateTime,
        endDateTime: movedItem!.endDateTime,
        timeSpanMinutes: Interval.fromDateTimes(movedItem!.startDateTime, movedItem!.endDateTime).length("minutes")
      };
      return { ...oldSchedule, rows: [...oldSchedule.rows] };
    });
    onClose();
  };

  const handleCancelRemoveDialog = () => {
    setShowRemoveDialog(false);
    setReductionReason(undefined);
  };

  const handleCancelMoveDialog = () => {
    setShowMoveDialog(false);
    setMoveDialogStartTime(item.startDateTime);
    setMoveDialogEndTime(item.endDateTime);
    setMoveDialogStartTimeError(undefined);
    setMoveDialogEndTimeError(undefined);
  };

  const handleChangeTimeRangePicker = (start?: string, end?: string) => {
    if (!start || !end) return;
    onChangeTimeRangePicker(DateTime.fromFormat(start, "hh:mm"), DateTime.fromFormat(end, "hh:mm"));
  };
  // #endregion

  const apptFormValues = item.appointment && {
    title: item.appointment.isSurgery ? "Surgery" : "Other Appointment",
    businessUnit: item.appointment.businessUnits.reduce(
      (collector, b) => (collector ? `${collector}, ${b.name}` : b.name),
      ""
    ),
    customer: `${item.appointment.customer.firstName} ${item.appointment.customer.lastName}`,
    customerUrl: `${process.env.REACT_APP_CRM_BASEURL}/Guests/GuestProfile.aspx?UserId=${item.appointment.customer.externalId}`,
    services: item.appointment.services.reduce((collector, s) => (collector ? `${collector}, ${s.name}` : s.name), "")
  };
  const blockFormValues = item.block && {
    title: item.block.blockType === BlockType.Slot ? "Slot" : "Block",
    notes: item.block.notes
  };
  const intervalHours = Interval.fromDateTimes(item.startDateTime, item.endDateTime).length("hours");
  const intervalMinutes = (intervalHours % 1) * 60;
  return (
    <>
      <CardHeader
        title={apptFormValues?.title || blockFormValues?.title || ""}
        subheader={`${item.startDateTime.toLocaleString(DateTime.TIME_SIMPLE)} - ${item.endDateTime.toLocaleString(
          DateTime.TIME_SIMPLE
        )}`}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Grid item>
              <Typography variant="body2">Duration:</Typography>
            </Grid>
            {apptFormValues && (
              <>
                <Grid item>
                  <Typography variant="body2">Customer:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">Business Units:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">Services:</Typography>
                </Grid>
              </>
            )}
            {blockFormValues && (
              <Grid item>
                <Typography variant="body2">Notes:</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs>
            <Grid item>
              <Typography variant="body2">{`${
                intervalHours ? `${Math.floor(intervalHours)} Hour${intervalHours > 2 ? "s" : ""}` : ""
              }${intervalHours && intervalMinutes ? ", " : ""}${
                intervalMinutes ? `${intervalMinutes} Minutes` : ""
              }`}</Typography>
            </Grid>
            {apptFormValues && (
              <>
                <Grid item>
                  <Typography variant="body2">
                    <a href={apptFormValues.customerUrl} target="_blank" rel="noopener noreferrer">
                      {apptFormValues.customer}
                    </a>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{apptFormValues.businessUnit}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">{apptFormValues.services}</Typography>
                </Grid>
              </>
            )}
            {blockFormValues && (
              <>
                <Grid item>
                  <Typography variant="body2" sx={{ maxWidth: "24rem" }}>
                    {blockFormValues.notes}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
      {!disabled && item.block?.blockType === BlockType.Slot && item.block?.surgeonId && (
        <CardActions disableSpacing>
          <SwipeableViews className="w-100" index={showRemoveDialog ? 1 : showMoveDialog ? 2 : 0} axis="x">
            <TabPanel className="w-100 h-100" visible>
              <Grid className="h-100" container alignItems="center">
                <Grid item>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => setShowMoveDialog(true)}
                    color="primary"
                    disabled={!item.canDrop && !item.canResize}
                  >
                    Move
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => (item.block?.id ? setShowRemoveDialog(true) : remove())}
                    color="error"
                  >
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel className="w-100 h-100" visible>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  <FormControl fullWidth sx={{ minWidth: "10rem" }}>
                    <InputLabel>Removal Reason</InputLabel>
                    <Select
                      value={reductionReason?.id || ""}
                      onChange={e => setReductionReason(reductionReasons.find(r => r.id === e.target.value))}
                    >
                      {reductionReasons.map(reason => (
                        <MenuItem value={reason.id!} key={reason.id!}>
                          {reason.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <IconButton color="secondary" onClick={handleCancelRemoveDialog}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton disabled={!reductionReason} onClick={remove} color="primary">
                    <CheckCircleIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel className="w-100 h-100" visible>
              <Grid container justifyContent="space-between" wrap="nowrap">
                <Grid item>
                  <TimeRangePicker
                    start={moveDialogStartTime.toLocaleString(DateTime.TIME_24_SIMPLE) || undefined}
                    end={moveDialogEndTime.toLocaleString(DateTime.TIME_24_SIMPLE) || undefined}
                    startProps={
                      moveDialogStartTimeError ? { error: true, helperText: moveDialogStartTimeError } : undefined
                    }
                    endProps={moveDialogEndTimeError ? { error: true, helperText: moveDialogEndTimeError } : undefined}
                    onChange={handleChangeTimeRangePicker}
                  />
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <IconButton color="secondary" onClick={handleCancelMoveDialog}>
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={moveDialogStartTimeError || moveDialogEndTimeError || !movedItem ? true : false}
                        onClick={move}
                        color="primary"
                      >
                        <CheckCircleIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
          </SwipeableViews>
        </CardActions>
      )}
    </>
  );
};

export default DayViewSchedulerItemDetailForm;
