import { Card, CardContent, CardHeader, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

import { SchedulerTimeIncrement, SchedulerTimeIncrementWidth } from "../../Helpers/Scheduler/types";
import { SchedulerConfig } from "./DayView";

interface Props {
  schedulerConfig: SchedulerConfig;
  setSchedulerConfig: Dispatch<SetStateAction<SchedulerConfig>>;
}

const DayViewSettingsForm: React.FC<Props> = ({ schedulerConfig, setSchedulerConfig }: Props) => {
  return (
    <Card>
      <CardHeader title="View Options" />
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1">Time Increments:</Typography>
            <ToggleButtonGroup
              value={schedulerConfig.timeIncrement}
              exclusive
              onChange={(_, value?: number) =>
                value &&
                value !== schedulerConfig.timeIncrement &&
                setSchedulerConfig(config => ({ ...config, timeIncrement: value }))
              }
            >
              <ToggleButton value={SchedulerTimeIncrement.QUARTER_HOUR}>15 Minutes</ToggleButton>
              <ToggleButton value={SchedulerTimeIncrement.HALF_HOUR}>30 Minutes</ToggleButton>
              <ToggleButton value={SchedulerTimeIncrement.HOUR}>60 Minutes</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <Typography variant="body1">Time Increment Size:</Typography>
            <ToggleButtonGroup
              value={schedulerConfig.timeIncrementWidth}
              exclusive
              onChange={(_, value?: number) =>
                value &&
                value !== schedulerConfig.timeIncrementWidth &&
                setSchedulerConfig(config => ({ ...config, timeIncrementWidth: value }))
              }
            >
              <ToggleButton value={SchedulerTimeIncrementWidth.SMALL}>Small</ToggleButton>
              <ToggleButton value={SchedulerTimeIncrementWidth.MEDIUM}>Medium</ToggleButton>
              <ToggleButton value={SchedulerTimeIncrementWidth.LARGE}>Large</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <Typography variant="body1">Order Data By:</Typography>
            <ToggleButtonGroup
              value={schedulerConfig.orderBy}
              exclusive
              onChange={(_, value?: "room" | "surgeon") =>
                value &&
                value !== schedulerConfig.orderBy &&
                setSchedulerConfig(config => ({ ...config, orderBy: value }))
              }
            >
              <ToggleButton value={"room"}>Room</ToggleButton>
              <ToggleButton value={"surgeon"}>Surgeon</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <Typography variant="body1">Scheduler Orientation:</Typography>
            <ToggleButtonGroup
              value={schedulerConfig.orientation}
              exclusive
              onChange={(_, value?: "horizontal" | "vertical") =>
                value &&
                value != schedulerConfig.orientation &&
                setSchedulerConfig(config => ({ ...config, orientation: value }))
              }
            >
              <ToggleButton value={"horizontal"}>Horizontal</ToggleButton>
              <ToggleButton value={"vertical"}>Vertical</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DayViewSettingsForm;
