import { Box } from "@mui/material";
import React, { ReactNode, useRef } from "react";
export interface OverflowItem {
  /** True if the component would overflow its container if this value is falsey. False otherwise. */
  isOverflowing?: boolean;
}

/** A utility wrapper component which renders a copy of its children in an invisible, non-interactive form. It then
 * injects the overflow state of the clone into the original child, allowing it to easily change its content if its
 * expanded form would otherwise overflow its container.
 * **This is an expensive utility, and it should be used with care. Use other alternatives to dynamic sizing where possible.**
 */
const OverflowDetector: React.FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isOverflowing =
    containerRef.current &&
    (containerRef.current.offsetHeight < containerRef.current.scrollHeight ||
      containerRef.current.offsetWidth < containerRef.current.scrollWidth);
  const childrenWithProps = React.Children.map(children, child =>
    React.isValidElement(child) ? React.cloneElement(child, { isOverflowing }) : child
  );
  return (
    <>
      {childrenWithProps}
      <Box
        sx={{
          height: "100%",
          width: "100%",
          position: "absolute",
          opacity: 0,
          pointerEvents: "none",
          overflow: "auto"
        }}
        ref={ref => (containerRef.current = ref as HTMLDivElement | null)}
      >
        {children}
      </Box>
    </>
  );
};

export default OverflowDetector;
