export const LocalStorageKeys = {
  admin: {
    simpleCenters: "adminSimpleCenters"
  },
  userCenterLock: "userCenterLock",
  planner: {
    simpleCenters: "plannerSimpleCenters"
  },
  signalRConnectionInfo: "signalRConnectionInfo",
  userMe: "userMe",
  userSettings: "userSettings"
};
