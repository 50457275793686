import { Grid } from "@mui/material";
import { DateTime } from "luxon";
import React, { PropsWithChildren, useEffect, useState } from "react";

import SchedulerRowDropZone from "./SchedulerRowDropZone";
import { DragHandle, ScheduleItem, ScheduleRow, SchedulerClassKey, SchedulerRowProps } from "./types";

const SchedulerRow = <TItem extends ScheduleItem, TRow extends ScheduleRow<TItem>>({
  dragItemType,
  isPlaceModeActive,
  orientation,
  placeModeFirstTarget,
  timeIncrement,
  timeIncrementWidth,
  timeRange,
  canDrop,
  canPlaceModeClick,
  formatZoneLabel,
  onDrop,
  onPlaceModeClick,
  children
}: PropsWithChildren<SchedulerRowProps<TItem, TRow>>): JSX.Element => {
  const [dropZones, setDropZones] = useState<{ accept?: string; time: DateTime }[]>([]);

  // update DnD zones on item body being dragged
  useEffect(() => {
    const zoneTimes: DateTime[] = [];
    let increment = timeRange.start;
    while (increment < timeRange.end) {
      zoneTimes.push(increment);
      increment = increment.plus({ minutes: timeIncrement });
    }
    setDropZones(zoneTimes.map(time => ({ accept: canDrop && canDrop(time) ? dragItemType : undefined, time })));
  }, [canDrop, dragItemType, timeIncrement, timeIncrementWidth, timeRange]);

  const zones = dropZones.map(zone => {
    const validPlaceTarget = !canPlaceModeClick || canPlaceModeClick(zone.time);
    return (
      <SchedulerRowDropZone
        activeOverrideEnabled={placeModeFirstTarget ? zone.time.equals(placeModeFirstTarget) : false}
        accept={zone.accept}
        isPlaceModeActive={isPlaceModeActive && validPlaceTarget}
        label={
          formatZoneLabel
            ? formatZoneLabel(zone.time, dragItemType)
            : (dragItemType === DragHandle.RIGHT
                ? zone.time.plus({ minutes: timeIncrement })
                : zone.time
              ).toLocaleString(DateTime.TIME_SIMPLE)
        }
        onClick={() => onPlaceModeClick && isPlaceModeActive && validPlaceTarget && onPlaceModeClick(zone.time)}
        onDrop={item =>
          onDrop &&
          onDrop(
            dragItemType === DragHandle.RIGHT ? zone.time.plus({ minutes: timeIncrement }) : zone.time,
            item as TItem
          )
        }
        orientation={orientation}
        size={timeIncrementWidth}
        key={zone.time.toISO()}
      />
    );
  });

  return orientation === "horizontal" ? (
    <Grid className={`${SchedulerClassKey.itemWrapper_h}`} item container wrap="nowrap">
      <div className={`${SchedulerClassKey.itemWrapper_h}`} style={{ position: "absolute", display: "flex" }}>
        {zones}
      </div>
      {children}
    </Grid>
  ) : (
    <Grid className={`${SchedulerClassKey.itemWrapper_v}`} item>
      <Grid container direction="column" wrap="nowrap">
        <div className={`${SchedulerClassKey.itemWrapper_v}`} style={{ position: "absolute" }}>
          {zones}
        </div>
        <div className="w-100">{children}</div>
      </Grid>
    </Grid>
  );
};

export default SchedulerRow;
