import { LocalStorageKeys } from "../Constants";

// helping functions to save/load a local storage value into the given type
export const saveToLocal = <T>(obj: T, key: string): void => localStorage.setItem(key, JSON.stringify(obj));
export const loadFromLocal = <T>(key: string): T | undefined => {
  const value = JSON.parse(localStorage.getItem(key) as string);
  return !value ? undefined : (value as T);
};
export const loadFromSession = <T>(key: string): T | undefined => {
  const value = JSON.parse(sessionStorage.getItem(key) as string);
  return !value ? undefined : (value as T);
};

/** Get the first value from the session store which has a key matching the supplied regex. */
export const queryFromSession = <T extends Record<string, unknown>>(key: string, value: unknown): T | undefined => {
  const count = sessionStorage.length;
  for (let x = 0; x < count; x++) {
    const storeKey = sessionStorage.key(x);
    if (!storeKey) continue;
    const storeValue = JSON.parse(sessionStorage.getItem(storeKey) as string);
    try {
      if (storeValue[key] === value) return storeValue as T;
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export const clearLocalStorage = (): void => {
  const length = localStorage.length;
  for (let x = 0; x < length; x++) {
    const key = localStorage.key(x);
    if (key && key !== LocalStorageKeys.userSettings) localStorage.removeItem(key);
  }
  sessionStorage.clear();
};

// map an axios error into a telemetry message payload
// export const buildError = (error: AxiosError<GemError>): Record<string, unknown> => {
//   const {
//     message,
//     name,
//     config: { url, method, data, baseURL },
//     response
//   } = error;
//   return {
//     request: {
//       message,
//       name,
//       config: { url, method, data, baseURL }
//     },
//     response: {
//       headers: { "x-invocation-id": response?.headers["x-invocation-id"] },
//       data: response?.data,
//       status: response?.status,
//       statusText: response?.statusText
//     }
//   };
// };
