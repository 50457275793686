import { AccountInfo, InteractionRequiredAuthError, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Box, CircularProgress, CssBaseline, Grid, ThemeProvider, Toolbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { SnackbarProvider } from "notistack";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Theme from "./assets/Theme";
import Administration from "./components/Administration/Administration";
import AuthorizedRoute from "./components/Helpers/AuthorizedRoute";
import NotificationBanner from "./components/Helpers/NotificationBanner";
import Login from "./components/Login/Login";
import LoginCard from "./components/Login/LoginCard";
import Navigation from "./components/Navigation/Navigation";
import Planner from "./components/Planner/Planner";
import SignalRConnection from "./components/SignalR/SignalRConnection";
import UserSettings from "./components/UserSettings/UserSettings";
import { LocalStorageKeys } from "./Constants";
import AppContext, { AppContextProvider } from "./contexts/AppContext";
import { User } from "./models/User";
import DateTime from "./types/DateTime";
import { PlannerViewType } from "./types/PlannerViewProps";
import { UserSettings as UserSettingsModel } from "./types/UserSettings";
import { loadFromLocal, saveToLocal } from "./utils/Helpers";
import useCbp, { UseCbpProps } from "./utils/UseCbp";

interface Props {
  msalInstance: PublicClientApplication;
}

const App: React.FC<Props> = ({ msalInstance }: Props) => (
  <MsalProvider instance={msalInstance}>
    <BrowserRouter>
      <ThemeProvider theme={Theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"en-US"}>
          <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} maxSnack={3}>
            <CssBaseline />
            <UnauthenticatedTemplate>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/">
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <AppContextProvider>
                <AppContent />
              </AppContextProvider>
            </AuthenticatedTemplate>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  </MsalProvider>
);

export default App;

const plannerViews = Object.values(PlannerViewType).map(view => `/planner/${view}`);

const AppContent: React.FC = () => {
  const { accounts, instance: msalClient } = useMsal();
  const { bannerNotification, token, user, setToken, setUser } = useContext(AppContext);
  const [userSettings] = useState(loadFromLocal<UserSettingsModel>(LocalStorageKeys.userSettings));
  const tokenRef = useRef(token);

  // #region Get User Me
  const [getUserMeRequest] = useState<UseCbpProps | undefined>(
    !user ? { token, name: "Get User Me", request: { url: "users/me" } } : undefined
  );
  const { response: userMeResponse } = useCbp<User>(getUserMeRequest);
  useEffect(() => {
    if (userMeResponse) {
      saveToLocal(userMeResponse, LocalStorageKeys.userMe);
      setUser(userMeResponse);
    }
  }, [userMeResponse]);
  // #endregion

  // watch the state of the msal access token
  useEffect(() => {
    // if token is cleared, renew it
    if (tokenRef.current && !token) {
      const silentRequest: SilentRequest = {
        account: accounts[0] as AccountInfo,
        scopes: [`${process.env.REACT_APP_MSAL_AUTHENTICATION_SCOPE}`],
        forceRefresh: true,
        tokenQueryParameters: { resource: `${process.env.REACT_APP_MSAL_APP_ID}` }
      };
      msalClient
        .acquireTokenSilent(silentRequest)
        .then(token => {
          if (!token.accessToken || !token.expiresOn) {
            // If a valid token was not received, fallback to interaction.
            return msalClient.acquireTokenRedirect(silentRequest);
          } else
            setToken({
              secret: token.accessToken,
              expiresOn: DateTime.fromJSDate(token.expiresOn)
            });
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalClient.acquireTokenRedirect(silentRequest);
          }
          console.log(error);
        });
    }
    tokenRef.current = token;
  }, [token, accounts, msalClient]);

  const preferredPlannerPath = `/planner/${userSettings?.defaultCalendarView || "month"}`;
  return user ? (
    // display content if bootstrapping is complete
    <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
      <SignalRConnection />
      <Navigation />
      <Toolbar />
      <NotificationBanner dismissable={!(bannerNotification?.dismissable === false)}>
        {bannerNotification?.content}
      </NotificationBanner>
      <Box sx={{ height: "100%", width: "100%" }}>
        <Switch>
          <Route path={plannerViews}>
            <Planner />
          </Route>
          <Route path="/settings">
            <UserSettings />
          </Route>
          <AuthorizedRoute
            path="/admin"
            redirectPath={preferredPlannerPath}
            authorize={user =>
              user.applicationPermissions.isAdminEditor ||
              user.applicationPermissions.isAdminEditor ||
              user.aggregateCenterPermissions.isCenterEditor ||
              user.aggregateCenterPermissions.isCapacityEditor
            }
          >
            <Administration />
          </AuthorizedRoute>
          <Route path={["/", "/planner"]}>
            <Redirect to={preferredPlannerPath} />
          </Route>
        </Switch>
      </Box>
    </Box>
  ) : (
    // display while bootstrapping app requirements
    <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <LoginCard>
        <Grid container direction="column" spacing={2} alignItems="center">
          {/* <Grid item>
            <Typography variant="h6">{GetLoadingMessage()}</Typography>
          </Grid> */}
          <Grid item>
            <CircularProgress size="5rem" />
          </Grid>
        </Grid>
      </LoginCard>
    </Box>
  );
};
