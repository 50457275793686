import { PopupRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import { clearLocalStorage } from "../../utils/Helpers";
import LoginCard from "./LoginCard";
import { loginRequest } from "./MsalConfig";

const clearUserData = () => {
  clearLocalStorage();
  sessionStorage.clear();
};

/** Renders the login splashscreen and handles SSO sign on. */
const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { instance } = useMsal();
  const [clicked, setClicked] = useState(false);
  useEffect(() => clearUserData(), []);
  const login = () => {
    instance
      .loginPopup(loginRequest as PopupRequest)
      .then(() => enqueueSnackbar("Successfully Logged In", { variant: "success" }))
      .catch(e => {
        // Leaving console.log so we can get info if the problem returns in production.
        console.log(e);
        enqueueSnackbar("Failed to authenticate the user. Please Try again.", { variant: "error" });
        clearUserData();
      })
      .finally(() => setClicked(false));
    setClicked(true);
  };
  return (
    <Box className="h-100 w-100" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <LoginCard>
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="body1">Please sign-in to see your profile information.</Typography>
          </Grid>
          <Grid item>
            <LoadingButton startIcon={<ExitToAppIcon />} onClick={login} loading={clicked} variant="outlined">
              Sign In
            </LoadingButton>
          </Grid>
        </Grid>
      </LoginCard>
    </Box>
  );
};

export default Login;
